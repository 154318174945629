<div class="copyright-container">
    <div class="footer-left">
        <div>
            <img src="../../../assets/icons/footerlogo.png" alt="footer logo" title="footer logo">
        </div>
        <p class="footer-content">Copyright &copy; 2022</p>
    </div>
    <div class="footer-right">
        <ul>
            <li><a href="javascript:void(0);">Terms of Use</a></li>
            <li><a href="javascript:void(0);">Contact Us</a></li>
            <li><a href="javascript:void(0);">Privacy Policy</a></li>
        </ul>
    </div>
</div>