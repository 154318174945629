import { MSAL_INSTANCE, MsalService, MsalBroadcastService, MsalGuard, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication, Configuration, InteractionType } from "@azure/msal-browser";
import { Environment_URL } from "src/app/common/app.constants";
import { DevEnvironment } from "src/environments/environment.dev";
import { LocalEnvironment } from "src/environments/environment.local";
import { ProdEnvironment } from "src/environments/environment.prod";
import { QaEnvironment } from "src/environments/environment.qa";
import { TestEnvironment } from "src/environments/environment.test";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

let environment: any = LocalEnvironment;
const url = window.location.href;
if (url.search(Environment_URL.LOCAL) !== -1) {
    environment = LocalEnvironment;
} else if (url.search(Environment_URL.DEV) !== -1) {
    environment = DevEnvironment;
} else if (url.search(Environment_URL.TEST) !== -1) {
    environment = TestEnvironment;
} else if (url.search(Environment_URL.QA) !== -1) {
    environment = QaEnvironment;
} else {
    environment = ProdEnvironment;
}

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.CLIENT_ID,
        authority: `${environment.INSTANCE_ID}${environment.TENANT_ID}`,
        redirectUri: environment.REDIRECT_URI,
        postLogoutRedirectUri: environment.POST_LOGOUT_REDIRECT_URI,
        navigateToLoginRequestUrl: environment.NAVIGATE_TO_LOGIN_REQUEST_URL,
    }, cache: {
        cacheLocation: environment.CACHE_LOCATION
    }
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
    };
}

export const AUTH_PROVIDERS = [
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
]
