import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModelCode, DropDownModel } from 'src/app/models/dropdown.model';
import { HttpErrorResponse } from '@angular/common/http';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { PcpsDistributorService } from 'src/app/core/services/api/pcps-distributor.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { StaticData } from 'src/app/modules/static-data';
import { ModelCodeApiModel } from 'src/app/core/model/api/modelcode-api.model';

@Component({
  selector: 'vma-single-select-modelcode',
  templateUrl: './single-select-modelcode.component.html',
  styleUrls: ['./single-select-modelcode.component.scss']
})
export class SingleSelectModelcodeComponent implements OnInit {
  selectedModelCode: any;
  modelCodes: ModelCode[];
  selectedBrand: any;
  selectedModelYear: any;
  selectedSeries: any;
  isMandatoryField: boolean;
  @Output() modelcodeEvent = new EventEmitter<any>();
  viewMode = false;
  editMode = false;

  constructor(
    private readonly sharedService: SharedService,
    private readonly pcpsDistributorService: PcpsDistributorService
  ) { }

  ngOnInit(): void {
    this.isMandatoryField = this.sharedService.isMandatoryField;
  }

  /**
   * @param  {DropDownModel} setbrandId   * 
   */
  @Input()
  set brandId(brandId: DropDownModel) {
    this.modelCodes = [];
    this.selectedBrand = brandId;
    this.selectedModelYear = null;
    this.selectedSeries = null;
    this.selectedModelCode = null;
  }

  /**
   * @param  {number} setyearNo   *
   */
  @Input()
  set yearNo(yearNo: number) {
    this.modelCodes = [];
    this.selectedSeries = null;
    this.selectedModelCode = null;
    this.selectedModelYear = yearNo;
  }

  /**
   * @param  {DropDownModel} seriesId   * 
   */
  @Input()
  set seriesId(series: DropDownModel) {
    this.modelCodes = [];
    this.selectedSeries = series;
    this.selectedModelCode = null;
    this.listModelcodes();
  }

  /**
   * @param  {Distributor} distributorId 
   * @param  {DropDownModel} brandId
   * @param  {number} yearNo
   * @param  {DropDownModel} seriesId
   */
  listModelcodes() {
    const distributor = StaticData.getDistributorList();
    if (!isNullOrUndefined(this.selectedBrand) && !isNullOrUndefined(this.selectedModelYear)
      && !isNullOrUndefined(this.selectedSeries)) {
      const parames = {
        brandid: this.selectedBrand.id,
        yearno: this.selectedModelYear,
        productgroupid: this.selectedSeries.id
      };
      this.sharedService.clearPreviousMessages();
      this.pcpsDistributorService.listModelCodes(distributor[0].distributorId, parames).subscribe(res => {
        this.modelCodes = new Array<ModelCode>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          const modelcodes: Array<ModelCodeApiModel> = res.data[0].models;
          modelcodes.forEach(element => {
            const data: ModelCode = {
              name: "" + element.modelCd, code: "" + element.modelCd
            };
            this.modelCodes.push(data);
          });
          this.setSelectedmodelCodeValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  onModelCodeChange(event: any) {
    // this.sharedService.buttonEnabler = false;
    this.selectedModelCode = event.value;
    this.modelcodeEvent.emit(this.selectedModelCode);
  }

  setSelectedmodelCodeValue() {
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData)
      && !isNullOrUndefined(this.sharedService.vehicleSearchData.modelCodeId)) {
      this.selectedModelCode = this.sharedService.vehicleSearchData.modelCodeId;
      this.modelcodeEvent.emit(this.selectedModelCode);
      if(this.sharedService.vehicleSearchData.searchType ==="adminview") {
        this.viewMode = true;
      }
      else if(this.sharedService.vehicleSearchData.searchType === "TLSEDITVIEW") {
        this.editMode = true;
      }
    }
  }
}
