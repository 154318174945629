import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectAccessoriesComponent } from './multi-select-accessories.component';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [
    MultiSelectAccessoriesComponent
  ],
  imports: [
    CommonModule,
    MultiSelectModule,
    FormsModule
  ], exports: [
    MultiSelectAccessoriesComponent
  ],
})
export class MultiSelectAccessoriesModule { }
