<div class="p-fluid p-formgrid p-grid" style="justify-content: center; margin: auto; margin-top: 6px;">
    <div class="p-col-12 p-md-2">
        <vma-multi-select-brand (brandEvent)="selectedBrand($event)"></vma-multi-select-brand>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-modelyear-dashboard [brandId]="brand" (yearEvent)="selectedYear($event)"></vma-modelyear-dashboard>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-multi-select-series [brandId]="brand" [yearNo]="year" (seriesEvent)="selectedSeries($event)"></vma-multi-select-series>
    </div>
    <div class="p-col-12 p-md-2">
        <dashboard-vma-multi-select-modelcode [brandId]="brand" [yearNo]="year" 
            [seriesId]="series" (modelcodeEvent)="selectedModelCode($event)">
        </dashboard-vma-multi-select-modelcode>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-accessories-dashboard [brandId]="brand" [yearNo]="year" [seriesId]="series" 
            [vdcCd]="vdcValue" [modelcodeId]="modelcodeValue"
           (accessoryEvent)="selectedAccessory($event)"></vma-accessories-dashboard>
    </div>
</div>
