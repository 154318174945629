import { AuditVehicleData, AuditVehicleList, AuditVehicleListDetails, VehicleFacultyCode, FacultyCodeList, AccessoryList, VehicleAccessory, AuditWaiverList } from "../ui/audit-vehicle.data.model";
import * as VMA_Util from '../../utilities/vma-common.util';

export class ListAuditWaiverApiModel extends AuditWaiverList { }

export class ListAuditWaiverApiRespModel {
    auditWaiverList: Array<ListAuditWaiverApiModel> = new Array<ListAuditWaiverApiModel>();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.auditWaiverList)) {
                options.data.auditWaiverList.forEach((element: any) => {
                    this.auditWaiverList.push({
                        modelYear: element.modelYear,
                        modelCd: element.modeCd,
                        productGroupId: element.productGroupId,
                        auditWaiverId: element.auditWaiverId,
                        auditWaiverExcp: element.auditWaiverExcp,
                        auditWaiverExcpTypeCode: element.auditWaiverExcpTypeCode,
                        activeInd: element.activeInd == "Y" ? "Active" : "Inactive",
                        createdts: this.removeTimeZone(element),
                        userName: element.userName
                    })
                });
            }
        }
    }

    removeTimeZone(data) {
        let updatedDate = '';
        if (!VMA_Util.isNullOrUndefined(data.createdts)) {
            const timewithoutZone = VMA_Util.formatDateWithOutZone(data.createdts);
            updatedDate = VMA_Util.formatDateWithOutT(timewithoutZone);
        }
        if (!VMA_Util.isNullOrUndefined(data.userName)) {
            updatedDate = updatedDate.concat(' ').concat(data.userName);            
        }
        return updatedDate;
    }

}


