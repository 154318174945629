<div class="cmn-div-padding">
    <h3>ADD / SCAN VEHICLE</h3>

    <h5 style="text-align: center;">AVAILABLE OPTIONS TO ADD VEHICLE</h5>
    <div class="p-grid">
        <div class="p-col-5 p-d-flex p-ai-center p-jc-center">
            <div class="p-fluid">
                <div class="p-field">
                    <label class="cmn-label-font-style">Manual VIN Input</label>
                    <input class="cmn-input-font-style" pInputText type="text" placeholder="Enter" [(ngModel)]="vin"
                        (keyup.enter)="add()" />
                </div>
            </div>
        </div>
        <div class="p-col-2">
            <p-divider layout="vertical">
                <strong>OR</strong>
            </p-divider>
        </div>
        <div class="p-col-5 p-d-flex p-ai-center p-jc-center">
            <div class="p-fluid">
                <div class="p-field">
                    <label class="cmn-label-font-style">Barcode</label>
                    <p-button label="SCAN" styleClass="p-button-warning cmn-button-style" (click)="onBarCodeScan()">
                    </p-button>
                </div>
            </div>
        </div>
    </div>

    <div class="align-right add-vehicle-action-panel-style">
        <p-button icon="pi pi-times" label="CLEAR" styleClass="p-button-text p-mr-2 cmn-button-style" (click)="clear()">
        </p-button>
        <p-button icon="pi pi-check" [disabled]="!vin" styleClass="cmn-button-style" label="ADD" (click)="add()">
        </p-button>
    </div>


    <barcode-scanner-livestream-overlay [type]="allowedBarCodeFormats" (valueChanges)="onBarCodeValueChanges($event)">
    </barcode-scanner-livestream-overlay>

    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayAddVehicleConfirmPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure want to perform audit for below VIN ? </h4>
            <h3 class="selected-value-highlight-style">{{vin}}</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" styleClass="cmn-button-style" (click)="onConfirmAddVehicle()" label="Yes">
            </p-button>
        </ng-template>
    </p-dialog>
</div>
