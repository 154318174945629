import { Component, Input, OnInit } from '@angular/core';
import { AuditVehicleInfoModel } from 'src/app/core/model/ui/export-excel.model';
import { SharedService } from 'src/app/core/services/ui/shared.service';

@Component({
  selector: 'vma-page-title-info',
  templateUrl: './page-title-info.component.html',
  styleUrls: ['./page-title-info.component.scss']
})
export class PageTitleInfoComponent implements OnInit {

  vin: string;
  accessories: string;
  vdc: string;
  modelYear: string;
  modelCode: string;

  secondaryAuditFlag: boolean;
  secondaryAudit = 'SECONDARY AUDIT';

  constructor(
    private readonly sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.secondaryAuditFlag = this.sharedService.getSecondaryAuditInputFlag();
  }

  @Input()
  set auditVehicleInfo(auditVehicleInfo: AuditVehicleInfoModel) {
    this.vin = auditVehicleInfo.vin;
    this.accessories = auditVehicleInfo.accessories;
    this.vdc = auditVehicleInfo.vdc;
    this.modelYear = auditVehicleInfo.modelYear;
    this.modelCode = auditVehicleInfo.modelCode;
  }

}
