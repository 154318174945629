<div class="cmn-div-padding">
    <h3>DRC APPROVAL VIEW</h3>

    <p-tabView styleClass="cmn-tabmenu-style" [scrollable]="false" (onChange)="onTabChange($event)"
        [(activeIndex)]="activeIndex">
        <p-tabPanel header="AUDITS PENDING FOR REVIEW">
            <p-table #table1 [value]="pendingApprovalsList" styleClass="p-datatable-sm" selectionMode="single"
                [(selection)]="selectedPendingApprovalRow" responsiveLayout="scroll">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex1">
                        <p-divider align="right" type="no">
                            <button type="button" label="Export" pButton pRipple icon="pi pi-file-excel"
                                class="p-button-success cmn-button-style p-mr-1"
                                (click)="exportPendingApprovalAuditsListAsExcelReport()"></button>
                            <button type="button" label="Reload" [disabled]="true" pButton pRipple icon="pi pi-refresh"
                                (click)="reload()" class="p-button-secondary cmn-button-style p-mr-1"></button>
                        </p-divider>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th class="cmn-table-header-style" style="width: 100px" scope="col">Vin</th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">Accessories</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Year</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Code</th>
                        <th class="cmn-table-header-style" style="width: 60px" scope="col">Vdc</th>
                        <th class="cmn-table-header-style" style="width: 130px" pSortableColumn="workflowStatus"
                            scope="col">Status
                            <p-sortIcon field="workflowStatus"></p-sortIcon>
                        </th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">User</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Updated On</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                        <td class="cmn-table-data-style">{{rowData.vin}}</td>
                        <td class="cmn-table-data-style">{{rowData.accessorList}}</td>
                        <td class="cmn-table-data-style">{{rowData.modelYear}}</td>
                        <td class="cmn-table-data-style">{{rowData.modelCode}}</td>
                        <td class="cmn-table-data-style">{{rowData.fcltyCd}}</td>
                        <td class="cmn-table-data-style">
                            <span [class]="'workflow-status-badge workflow-status-' + rowData.workflowStatus">
                                {{rowData.workflowStatus}}
                            </span>
                        </td>
                        <td class="cmn-table-data-style">{{rowData.actorName}}</td>
                        <td class="cmn-table-data-style">{{rowData.UpdatedDate}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="pendingApprovalsList.length" class="cmn-table-data-style">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [style]="{'font-family' : 'Roboto' , 'font-size': '12px'}" [rows]="limit"
                [totalRecords]="totalRecords" [pageLinkSize]="totalPages" [hidden]="!showPagination"
                [rowsPerPageOptions]="[limit]" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                (onPageChange)="onPaginator($event)"></p-paginator>
            <div class="align-right page-action-panel-style">

                <button pButton type="button" class="p-button-secondary cmn-button-style p-mr-2"
                    label="VIEW AUDIT REPORT" [disabled]="!selectedPendingApprovalRow"
                    (click)="onViewReport()"></button>
                <button pButton type="button" class="cmn-button-style" label="APPROVE" (click)="onApprove()"
                    [disabled]="true"></button>
            </div>
        </p-tabPanel>
        <p-tabPanel header="REVIEWED AUDITS">
            <p-table #table2 [value]="approvedAuditsList" styleClass="p-datatable-sm" selectionMode="single"
                [(selection)]="selectedApprovedAuditsRow" responsiveLayout="scroll">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex2">
                        <p-divider align="right" type="no">
                            <button type="button" label="Export" pButton pRipple icon="pi pi-file-excel"
                                class="p-button-success cmn-button-style p-mr-1"
                                (click)="exportApprovedAuditsListAsExcelReport()"></button>
                            <button type="button" label="Reload" [disabled]="true" pButton pRipple icon="pi pi-refresh"
                                (click)="reload()" class="p-button-secondary cmn-button-style p-mr-2"></button>
                        </p-divider>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th class="cmn-table-header-style" style="width: 100px" scope="col">Vin</th>
                        <th class="cmn-table-header-style" style="width: 150px" scope="col">Accessories</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Year</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Model Code</th>
                        <th class="cmn-table-header-style" style="width: 60px" scope="col">Vdc</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Status</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">User</th>
                        <th class="cmn-table-header-style" style="width: 80px" scope="col">Updated On</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                        <td class="cmn-table-data-style">{{rowData.vin}}</td>
                        <td class="cmn-table-data-style">{{rowData.accessorList}}</td>
                        <td class="cmn-table-data-style">{{rowData.modelYear}}</td>
                        <td class="cmn-table-data-style">{{rowData.modelCode}}</td>
                        <td class="cmn-table-data-style">{{rowData.fcltyCd}}</td>
                        <td class="cmn-table-data-style">
                            <span [class]="'workflow-status-badge workflow-status-' + rowData.workflowStatus">
                                {{rowData.workflowStatus}}
                            </span>
                        </td>
                        <td class="cmn-table-data-style">{{rowData.actorName}}</td>
                        <td class="cmn-table-data-style">{{rowData.UpdatedDate}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="approvedAuditsList.length" class="cmn-table-data-style">
                            No records found
                        </td>
                    </tr>
                </ng-template>

            </p-table>
            <p-paginator [style]="{'font-family' : 'Roboto' , 'font-size': '12px'}" [rows]="limit"
                [totalRecords]="totalRecords" [pageLinkSize]="totalPages" [hidden]="!showPagination"
                [rowsPerPageOptions]="[limit]" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                (onPageChange)="onPaginator($event)"></p-paginator>
            <div class="align-right page-action-panel-style">
                <button pButton type="button" class="p-button-secondary cmn-button-style p-mr-2"
                    label="VIEW AUDIT REPORT" [disabled]="!selectedApprovedAuditsRow" (click)="onViewReport()"></button>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>