import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryViewComponent } from './history-view.component';
import { HistoryViewRoutingModule } from './history-view-routing.module';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { BadgeModule } from 'primeng/badge';
import { AccordionModule } from 'primeng/accordion';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';

@NgModule({
    declarations: [
        HistoryViewComponent
    ],
    imports: [
        CommonModule,
        TableModule,
        TabViewModule,
        BadgeModule,
        AccordionModule,
        DividerModule,
        ButtonModule,
        TimelineModule,
        CardModule,
        HistoryViewRoutingModule
    ],
    exports: [
        HistoryViewComponent
    ],
    providers: []
})
export class HistoryViewModule { }