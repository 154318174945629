<div id="content">
    <table aria-describedby="unauthorizedtable">
        <tbody>
            <tr>
                <th id="empty"></th>
            </tr>
            <tr class="logo_container">
                <td style="font-size:100px; color : red">VMA </td>
            </tr>
            <tr>
                <td style="height:3em;">&nbsp;</td>
            </tr>
            <tr>
                <td>
                    <p>You are not authorized to access this application.</p>
                    <br><br><input type="button" class="myButton" value="Back to login" (click)="logOut()">
                    <br><br><span> <a class="link"
                            href="https://tmna.service-now.com/1ts?id=1ts_cat_item&sys_id=4b5e5331dbed4300a7e1f236bf9619fa"
                            title="Get Help" target="_self">Create Ticket to get access</a></span>
                </td>
            </tr>
            <tr>
                <td style="height:100px; padding-bottom: 6%;">&nbsp;</td>
            </tr>
        </tbody>
    </table>
</div>