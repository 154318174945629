import { Observable } from 'rxjs';
import { HttpServiceImpl } from '../http/http.service.impl'
import { Response } from '../model/response';
import { DevEnvironment } from 'src/environments/environment.dev';
import { LocalEnvironment } from 'src/environments/environment.local';
import { ProdEnvironment } from 'src/environments/environment.prod';
import { QaEnvironment } from 'src/environments/environment.qa';
import { TestEnvironment } from 'src/environments/environment.test';
import { ApiType, Environment_URL } from 'src/app/common/app.constants';
import { Injectable } from '@angular/core';

export type IMap<T> = (item: any) => T;
@Injectable({
    providedIn: 'root'
})
export class RestService {
    constructor(private http: HttpServiceImpl) { }

    setApiBaseEndPoint(apiType: ApiType) {
        let apiEndPoint: string;
        let apiEndPointPropertyName = 'VMA_API_ENDPOINT';
        if (apiType === ApiType.PCPS) {
            apiEndPointPropertyName = 'PCPS_API_ENDPOINT'
        }

        const url = window.location.href;
        if (url.search(Environment_URL.LOCAL) !== -1) {
            apiEndPoint = LocalEnvironment[apiEndPointPropertyName];
        } else if (url.search(Environment_URL.DEV) !== -1) {
            apiEndPoint = DevEnvironment[apiEndPointPropertyName];
        } else if (url.search(Environment_URL.TEST) !== -1) {
            apiEndPoint = TestEnvironment[apiEndPointPropertyName];
        } else if (url.search(Environment_URL.QA) !== -1) {
            apiEndPoint = QaEnvironment[apiEndPointPropertyName];
        } else {
            apiEndPoint = ProdEnvironment[apiEndPointPropertyName];
        }
        this.http.server = apiEndPoint;
        this.http.port = '';
        this.http.prefix = '';
        this.http.secure = true;
    }

    public _get<T>(endpoint: string, map?: IMap<T>, params?: object): Observable<Response<T>> {
        return this.http.get<T>(endpoint, map, params);
    }

    public _put<T>(endpoint: string, model: any, params: object, map?: IMap<T>): Observable<Response<T>> {
        return this.http.put<T>(endpoint, model, map, params);
    }

    public _post<T>(endpoint: string, model: any, params?: object, map?: IMap<T>): Observable<Response<T>> {
        return this.http.post<T>(endpoint, model, map, params);
    }

    public _delete<T>(endpoint: string, params?: object, map?: IMap<T>): Observable<Response<T>> {
        return this.http.delete<T>(endpoint, params, map);
    }
}
