import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddVehicleComponent } from './add-vehicle.component';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { BarcodeScannerLivestreamOverlayModule } from 'ngx-barcode-scanner';
import { AddVehicleRoutingModule } from './add-vehicle-routing.module';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [
    AddVehicleComponent
  ],
  imports: [
    CommonModule,
    AddVehicleRoutingModule,
    ButtonModule,
    DividerModule,
    DialogModule,
    FormsModule,
    InputTextModule,
    BarcodeScannerLivestreamOverlayModule
  ], exports: [
    AddVehicleComponent
  ]
})
export class AddVehicleModule { }
