import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleInfoComponent } from './page-title-info.component';
import { BadgeModule } from 'primeng/badge';


@NgModule({
  declarations: [
    PageTitleInfoComponent
  ],
  imports: [
    CommonModule,
    BadgeModule
  ], exports: [
    PageTitleInfoComponent
  ]
})
export class PageTitleInfoModule { }
