import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import {  WorkflowStatus } from 'src/app/common/app.constants';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { Router } from '@angular/router';
import { ExportExcelService } from 'src/app/core/services/ui/export-excel.service';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { AuditDrcListDetails } from 'src/app/core/model/ui/audit-drc.data.model';

@Component({
  selector: 'app-drc-approval',
  templateUrl: './drc-approval.component.html',
  styleUrls: ['./drc-approval.component.scss']
})
export class DrcApprovalComponent implements OnInit {
  pendingApprovalsList: AuditDrcListDetails[] = [];
  selectedPendingApprovalRow: any;
  activeIndex: number;
  approvedAuditsList: AuditDrcListDetails[] = [];
  selectedApprovedAuditsRow: any;
  currentWorkflowStatus: string = `${WorkflowStatus.SUBMITTED}`;

  offset = 0;
  limit = 100;
  totalRecords = 0;
  totalPages = 0;
  showPagination = false;
  count = 0;

  constructor(
    private readonly router: Router,
    private sharedService: SharedService,
    private vehicleService: VehicleService,
    private readonly exportExcelService: ExportExcelService,
  ) { }

  ngOnInit(): void {
    this.listDRCData();
    this.sharedService.setSecondaryAuditInputFlag(false);
  }

  listDRCData() {
    const params: any = {};
    params.status = this.currentWorkflowStatus;
    this.vehicleService.listDRCData(params).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        if (!isNullOrUndefined(res.data[0].data.drcDataList)) {
          // if (this.currentWorkflowStatus === `${WorkflowStatus.SUBMITTED},${WorkflowStatus.DRC_REVIEWED}`) {
          if (this.currentWorkflowStatus === `${WorkflowStatus.SUBMITTED}`) {
            this.pendingApprovalsList = this.transformAcessoryList(res.data[0].data.drcDataList);
            if (!isNullOrUndefined(res.data[0].data.total)) {
              this.totalRecords = res.data[0].data.total;
              this.offset = res.data[0].data.offset;
              this.setPaginationData();
              this.showPagination = true;
            } else {
              this.sharedService.handledNoRecords();
            }
          } 

          else if (this.currentWorkflowStatus === WorkflowStatus.DRC_REVIEWED) {
            this.approvedAuditsList = this.transformAcessoryList(res.data[0].data.drcDataList);
            if (!isNullOrUndefined(res.data[0].data.total)) {
              this.totalRecords = res.data[0].data.total;
              this.offset = res.data[0].data.offset;
              this.setPaginationData();
              this.showPagination = true; 
            } else {
              this.sharedService.handledNoRecords();
            }
          }
        } else {
          this.sharedService.handledNoRecords();
        }
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    });
    this.setActiveTabIndex();
  }

  transformAcessoryList(drcDataList: Array<any>) {
    drcDataList.forEach(element => {
      element.accessorList = this.setAccessories(element.accessorList);
    });
    return drcDataList;
  }

  onTabChange(e: any) {
    this.sharedService.setDrcApprovalTabIndex(e.index);
    this.setWorkflowStatus(e.index);
    this.listDRCData();
  }

  setWorkflowStatus(tabIndex: number) {
    if (tabIndex === 0) {
    this.currentWorkflowStatus = `${WorkflowStatus.SUBMITTED}`;
    } else if (tabIndex === 1) {
      this.currentWorkflowStatus = WorkflowStatus.DRC_REVIEWED;
    }
  }
  setActiveTabIndex() {
    this.activeIndex = this.sharedService.getDrcApprovalTabIndex();
  }
  setAccessories(data): string {
    return data.map(u => u.componentCd).join(' | ');
  }

  navigateToAuditReport() {
    this.router.navigate(['audit/report']);
  }

  onViewReport(): void {
    this.sharedService.setAuditReportFlag(true);
    if (this.selectedPendingApprovalRow) {
      this.sharedService.setVehAssmntEventId(this.selectedPendingApprovalRow.vehAssmntEventId);
    } else if (this.selectedApprovedAuditsRow) {
      this.sharedService.setVehAssmntEventId(this.selectedApprovedAuditsRow.vehAssmntEventId);
    }
    this.navigateToAuditReport();
  }
  reload() { }
  onApprove() {
  }
  exportPendingApprovalAuditsListAsExcelReport() {
    this.exportExcelService.generateDrcAuditsListExcel(this.pendingApprovalsList, "pendingApprovalList")
  }
  exportApprovedAuditsListAsExcelReport() {
    this.exportExcelService.generateDrcAuditsListExcel(this.approvedAuditsList, "ApprovedAuditsList");
  }

  setPaginationData() {
    this.totalPages = Math.ceil(this.totalRecords / this.limit);
    if (this.totalPages > 5) {
      this.totalPages = 5;
    }
  }

  onPaginator(event: any) {
    this.offset = event.first;
    this.limit = event.rows;
    //this.searchAuditVehicles();
  }
}
