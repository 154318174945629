import { ValidationStatusCdUI, ValidationStatusCdAPI, VehAssmntEventScopeTypeUI, 
    VehAssmntEventScopeTypeAPI, VehAssmntEventScopeItemUI, VehAssmntEventScopeItemAPI 
} from "src/app/common/app.constants";

export function transformValidationStatusCdForUI(statusCd: string): ValidationStatusCdUI {
    let transformedStatusCd = ValidationStatusCdUI.NotVerified;
    if (statusCd === ValidationStatusCdAPI.MATCH) {
        transformedStatusCd = ValidationStatusCdUI.Match;
    } else if (statusCd === ValidationStatusCdAPI.MISMATCH) {
        transformedStatusCd = ValidationStatusCdUI.Mismatch;
    }
    return transformedStatusCd;
}

export function transformValidationStatusCdForAPI(statusCd: string): ValidationStatusCdAPI {
    let transformedStatusCd = ValidationStatusCdAPI.NOT_VERIFIED;
    if (statusCd === ValidationStatusCdUI.Match) {
        transformedStatusCd = ValidationStatusCdAPI.MATCH;
    } else if (statusCd === ValidationStatusCdUI.Mismatch) {
        transformedStatusCd = ValidationStatusCdAPI.MISMATCH;
    }
    return transformedStatusCd;
}

export function transformScopeTypeForUI(scopeType: string): VehAssmntEventScopeTypeUI {
    let transformedScopeType = VehAssmntEventScopeTypeUI.MONRONEY_LABEL;
    if (scopeType === VehAssmntEventScopeTypeAPI.AALA_LABEL) {
        transformedScopeType = VehAssmntEventScopeTypeUI.AALA_LABEL;
    } else if (scopeType === VehAssmntEventScopeTypeAPI.PTAG_1) {
        transformedScopeType = VehAssmntEventScopeTypeUI.PTAG_1;
    } else if (scopeType === VehAssmntEventScopeTypeAPI.PTAG_2) {
        transformedScopeType = VehAssmntEventScopeTypeUI.PTAG_2;
    } else if (scopeType === VehAssmntEventScopeTypeAPI.AWR_LABEL) {
        transformedScopeType = VehAssmntEventScopeTypeUI.AWR_LABEL;
    } else if (scopeType === VehAssmntEventScopeTypeAPI.CR_LABEL) {
        transformedScopeType = VehAssmntEventScopeTypeUI.CR_LABEL;
    } else if (scopeType === VehAssmntEventScopeTypeAPI.ADDITIONAL_EVID) {
        transformedScopeType = VehAssmntEventScopeTypeUI.ADDITIONAL_EVID;
    } else if (scopeType === VehAssmntEventScopeTypeAPI.EMISSION_LABEL) {
        transformedScopeType = VehAssmntEventScopeTypeUI.EMISSION_LABEL;
    }
    return transformedScopeType;
}

export function transformScopeItemForUI(scopeItem: string): VehAssmntEventScopeItemUI {
    let transformedScopeItem = VehAssmntEventScopeItemUI.MONRONEY_LABEL_VERSION;
    if (scopeItem === VehAssmntEventScopeItemAPI.FINAL_ASSEMBLY_POINT) {
        transformedScopeItem = VehAssmntEventScopeItemUI.FINAL_ASSEMBLY_POINT;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.STANDARD_EQUIP) {
        transformedScopeItem = VehAssmntEventScopeItemUI.STANDARD_EQUIP;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.OPTIONAL_EQUIP) {
        transformedScopeItem = VehAssmntEventScopeItemUI.OPTIONAL_EQUIP;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.COLOR) {
        transformedScopeItem = VehAssmntEventScopeItemUI.COLOR;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.CNTRY_ORIGIN_ENG_PRT) {
        transformedScopeItem = VehAssmntEventScopeItemUI.CNTRY_ORIGIN_ENG_PRT;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.CNTRY_ORIGIN_TRANS_PRT) {
        transformedScopeItem = VehAssmntEventScopeItemUI.CNTRY_ORIGIN_TRANS_PRT;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.US_CAN_PRT_CNTNT) {
        transformedScopeItem = VehAssmntEventScopeItemUI.US_CAN_PRT_CNTNT;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.GVWR) {
        transformedScopeItem = VehAssmntEventScopeItemUI.GVWR;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_1) {
        transformedScopeItem = VehAssmntEventScopeItemUI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_1;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_2) {
        transformedScopeItem = VehAssmntEventScopeItemUI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_2;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_1) {
        transformedScopeItem = VehAssmntEventScopeItemUI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_1;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_2) {
        transformedScopeItem = VehAssmntEventScopeItemUI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_2;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.MODEL_FSA) {
        transformedScopeItem = VehAssmntEventScopeItemUI.MODEL_FSA;
    } else if (scopeItem === VehAssmntEventScopeItemAPI.PD_IMAGE) {
        transformedScopeItem = VehAssmntEventScopeItemUI.PD_IMAGE;
    }
    return transformedScopeItem;
}
