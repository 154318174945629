<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12 p-md-2">
        <vma-brand (brandEvent)="selectedBrand($event)"></vma-brand>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-modelyear [brandId]="brand" (yearEvent)="selectedYear($event)"></vma-modelyear>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-series [brandId]="brand" [yearNo]="year" (seriesEvent)="selectedSeries($event)"></vma-series>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-single-select-vdc [brandId]="brand" [yearNo]="year" [seriesId]="series"
            (vdcEvent)="selectedVdcValue($event)"></vma-single-select-vdc>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-multi-select-modelcode [brandId]="brand" [yearNo]="year" [seriesId]="series" [vdcCd]="vdcValue"
            (modelcodeEvent)="selectedModelCode($event)">
        </vma-multi-select-modelcode>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-multi-select-accessories [brandId]="brand" [yearNo]="year" [seriesId]="series" [vdcCd]="vdcValue"
            [modelcodeId]="modelcodeValue" (accessoryEvent)="selectedAccessory($event)">
        </vma-multi-select-accessories>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-arrival-date (arrivalDateEvent)="onArrivalDateEvent($event)"> </vma-arrival-date>
    </div>
</div>
