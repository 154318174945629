import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessoriesDashboardComponent } from './accessories-dashboard.component';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [
    AccessoriesDashboardComponent
  ],
  imports: [
    CommonModule,
    MultiSelectModule,
    FormsModule
  ], exports: [
    AccessoriesDashboardComponent
  ],
})
export class AccessoriesDashboardModule { }
