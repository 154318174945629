import { ValidationStatusCdUI, VehAssmntEventScopeItemAPI } from "src/app/common/app.constants";

export class DataVerificationModel {
    assessId: number;
    type: VehAssmntEventScopeItemAPI;
    data: string;
    status: ValidationStatusCdUI;
    initialStatus: ValidationStatusCdUI;
    comment: any;
    evidences: Array<File>;
    secondaryEvidences?: Array<any>;
}
