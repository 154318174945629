import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleSelectVdcComponent } from './single-select-vdc.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    SingleSelectVdcComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule
  ], exports: [
    SingleSelectVdcComponent
  ]
})
export class SingleSelectVdcModule { }
