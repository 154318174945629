import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Accessory, DropDownModel } from 'src/app/models/dropdown.model';
import { isNullOrUndefined, isEmpty, isArray } from 'src/app/core/utilities/vma-common.util';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'vma-multi-select-accessories',
  templateUrl: './multi-select-accessories.component.html',
  styleUrls: ['./multi-select-accessories.component.scss']
})
export class MultiSelectAccessoriesComponent implements OnInit {
  selectedAccessories: any;
  accessories: Accessory[];

  selectedBrand: any;
  selectedModelYear: any;
  selectedSeries: any;
  selectedVdc: any;
  selectedModelCode: any;

  @Output() accessoryEvent = new EventEmitter<any>();

  constructor(private readonly sharedService: SharedService,
    private readonly vehicleService: VehicleService) { }

  ngOnInit(): void {
  }

  /**
   * @param  {DropDownModel} setbrandId   * 
   */
  @Input()
  set brandId(brandId: DropDownModel) {
    this.accessories = [];
    this.selectedBrand = brandId;
    this.selectedModelYear = null;
    this.selectedSeries = null;
    this.selectedVdc = null;
    this.selectedModelCode = null;
    this.selectedAccessories = null;
  }

  /**
   * @param  {number} setyearNo   *
   */
  @Input()
  set yearNo(yearNo: number) {
    this.accessories = [];
    this.selectedModelYear = yearNo;
    this.selectedSeries = null;
    this.selectedVdc = null;
    this.selectedModelCode = null;
    this.selectedAccessories = null;
  }

  /**
   * @param  {DropDownModel} setseriesId   * 
   */
  @Input()
  set seriesId(series: DropDownModel) {
    this.accessories = [];
    this.selectedSeries = series;
    this.selectedVdc = null;
    this.selectedModelCode = null;
    this.selectedAccessories = null;
  }

  /**
   * @param  {DropDownModel} setseriesId   * 
   */
  @Input()
  set vdcCd(vdc: any) {
    this.accessories = [];
    this.selectedVdc = vdc;
    this.selectedAccessories = null;
    if (!isNullOrUndefined(this.selectedVdc)) {
      if (!isArray(this.selectedVdc)) {
        this.selectedModelCode = null;
      } else if (this.selectedVdc.length !== 0) {
        this.listAccessories();
      }
    }    
  }

  @Input()
  set modelcodeId(modelcode: any) {
    this.accessories = [];
    this.selectedAccessories = null;
    this.selectedModelCode = modelcode;
    if (!isNullOrUndefined(this.selectedModelCode)) {
      if (!isArray(this.selectedModelCode)) {
        this.selectedVdc = null;
      } else if (this.selectedModelCode.length !== 0) {
        this.listAccessories();
      }
    }
  }


  /**
   * @param  {Distributor} distributorId 
   * @param  {DropDownModel} brandId
   * @param  {number} yearNo
   * @param  {DropDownModel} seriesId
   */
  listAccessories() {
    const distributorCd = "91041";
    if (!isNullOrUndefined(this.selectedBrand) && !isNullOrUndefined(this.selectedModelYear)
      && !isNullOrUndefined(this.selectedSeries) && !isNullOrUndefined(this.selectedVdc)
      && !isNullOrUndefined(this.selectedModelCode)) {
      let modelcodeValue = null; let vdcValue = null;
      if (isArray(this.selectedModelCode)) {
        modelcodeValue = this.selectedModelCode.map(x => x.name).join(',');
      } else {
        modelcodeValue = this.selectedModelCode.name;
      }
      if (isArray(this.selectedVdc)) {
        vdcValue = this.selectedVdc.map(x => x.code).join(',');
      } else {
        vdcValue = this.selectedVdc.code;
      }
      const parames = {
        brandid: this.selectedBrand.id,
        modelyear: this.selectedModelYear,
        series: this.selectedSeries.id,
        vdccd: vdcValue,
        modelcd: modelcodeValue
      };
      this.sharedService.clearPreviousMessages();
      this.vehicleService.getAccessoriesList(distributorCd, parames).subscribe(res => {
        this.accessories = new Array<Accessory>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          this.accessories = res.data[0].accessoryList.accessory;
          this.setSelectedAccessoriesValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  onAccessoriesChange(event: any) {
    this.selectedAccessories = event.value;
    this.accessoryEvent.emit(this.selectedAccessories);
  }

  setSelectedAccessoriesValue() {
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData) 
      && !isNullOrUndefined(this.sharedService.vehicleSearchData.accessory)) {
      this.selectedAccessories = this.sharedService.vehicleSearchData.accessory;
      this.accessoryEvent.emit(this.selectedAccessories);
    }
    else { //for tls component
      if (!isNullOrUndefined(this.sharedService.accessoryName)) {
        const accessoryName = this.sharedService.accessoryName.toUpperCase();
        const accessoryValue = this.accessories.find(item => item.code === accessoryName);
        if (!isNullOrUndefined(accessoryValue)) {
          this.selectedAccessories = [accessoryValue];
          console.log("selectedAccessories", this.selectedAccessories);
          this.accessoryEvent.emit(this.selectedAccessories);
        }        
      }
    }
  }
}
