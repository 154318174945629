import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BarcodeScannerLivestreamOverlayComponent } from 'ngx-barcode-scanner';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { FilterMatchMode } from 'primeng/api';
import { Table } from 'primeng/table';
import { WorkflowStatus } from 'src/app/common/app.constants';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DbStores } from 'src/app/core/indexed-db/indexed-db.config';
import { AuditData } from 'src/app/core/model/ui/audit-data.model';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';

@Component({
  selector: 'vma-audit-inbox',
  templateUrl: './audit-inbox.component.html',
  styleUrls: ['./audit-inbox.component.scss']
})
export class AuditInboxComponent implements OnInit {

  readyForAuditDataList: AuditData[] = [];
  readyToSubmitDataList: AuditData[] = [];
  partialAuditDataList: AuditData[] = [];
  secondaryAuditDataList: AuditData[] = [];
  selectedReadyForAuditRow: AuditData;
  selectedPartialAuditRow: AuditData;
  selectedReadyToSubmitRow: AuditData[] = [];
  selectedReadyToSubmitRowForEdit: AuditData;
  selectedReadyForSecondaryAuditRow: AuditData;
  auditSubmitList: AuditData[] = [];
  disableEditAuditButton = true;
  disableSubmitAuditButton = true;
  disableInputAuditButton = true;
  displaySubmitPopup = false;
  activeIndex: number;
  @ViewChild('readyForAuditTableInstance') readyForAuditTableInstance: Table;
  allowedBarCodeFormats = ['code_39'];
  @ViewChild(BarcodeScannerLivestreamOverlayComponent) barcodeScannerOverlay: BarcodeScannerLivestreamOverlayComponent;

  indexedDBData: Array<AuditData>

  constructor(
    private readonly router: Router,
    private readonly indexedDBService: NgxIndexedDBService,
    public sharedService: SharedService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.sharedService.setSecondaryAuditInputFlag(false);
    this.fetchAuditDataListFromIndexedDB();
  }

  selectRowFromAuditedVehicle(indexedDBData: Array<AuditData>) {
    indexedDBData.forEach(element => {
      if(this.sharedService.selectedAuditedRow.vehAssmntEventId === element.vehAssmntEventId) {
        if (element.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.READY_FOR_AUDIT) {
          this.activeIndex = 0;
          this.selectedReadyForAuditRow = element;
        } else if (element.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.READY_TO_SUBMIT) {
          this.activeIndex = 1;
          this.selectedReadyToSubmitRow.push(element);
        } else if (element.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.PARTIALLY_AUDITED) {
          this.activeIndex = 2;
          this.selectedPartialAuditRow = element;
        }
      }
    });
  }

  fetchAuditDataListFromIndexedDB(): void {
    this.setActiveTabIndex();
    this.sharedService.clearPreviousMessages();
    this.indexedDBService.getAll(DbStores.VEH_ASSMNT_EVENT).subscribe((indexedDBResponse: any) => {
      if (!isNullOrUndefined(indexedDBResponse) && !isEmpty(indexedDBResponse)) {
        this.setAuditDataBasedOnStatus(indexedDBResponse);
      }
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }
  setAuditDataBasedOnStatus(indexedDBResponse: Array<AuditData>): void {
    this.readyForAuditDataList = [];
    this.readyToSubmitDataList = [];
    this.partialAuditDataList = [];
    this.secondaryAuditDataList = [];
    indexedDBResponse.forEach(element => {
      if (element.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.READY_FOR_AUDIT) {
        this.readyForAuditDataList.push(element);
      } else if (element.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.READY_TO_SUBMIT  || element.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.READY_TO_SECONDARY_SUBMIT) {
        this.readyToSubmitDataList.push(element);
      } else if (element.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.PARTIALLY_AUDITED  || element.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.PARTIALLY_SECONDARY_AUDITED) {
        this.partialAuditDataList.push(element);
      } else if (element.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.READY_FOR_SECONDARY_AUDIT) {
        this.secondaryAuditDataList.push(element);
      }
    });
    if(this.sharedService.isNavigatedFromAuditedVehicle) {
      this.selectRowFromAuditedVehicle(indexedDBResponse);
    }
  }
  setActiveTabIndex() {
    this.activeIndex = this.sharedService.getAuditInboxTabIndex();
  }
  navigateToInputAuditForm() {
    this.router.navigate(['/audit/input']);
  }
  onInputAudit() {
    if (this.selectedReadyForAuditRow) {
      this.sharedService.setAuditInboxTabIndex(0);
      this.sharedService.setVehAssmntEventId(this.selectedReadyForAuditRow.vehAssmntEventId);
      this.sharedService.setSecondaryAuditInputFlag(false);
      this.navigateToInputAuditForm();
    }
  }
  onSubmitAudit() {
    if (!isEmpty(this.selectedReadyToSubmitRow)) {
      this.displaySubmitPopup = true;
      this.auditSubmitList = this.selectedReadyToSubmitRow;
    }
  }
  onEditAudit() {
    if (this.selectedPartialAuditRow) {
      this.sharedService.setAuditInboxTabIndex(2);
      this.sharedService.setVehAssmntEventId(this.selectedPartialAuditRow.vehAssmntEventId);
      if(this.selectedPartialAuditRow.vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.PARTIALLY_SECONDARY_AUDITED) {
        this.sharedService.setSecondaryAuditInputFlag(true);
      } else {
        this.sharedService.setSecondaryAuditInputFlag(false);
      }
      this.navigateToInputAuditForm();
    }
  }

  onEditSubmittedAudit() {
    if (this.selectedReadyToSubmitRow) {
      this.sharedService.setAuditInboxTabIndex(1);
      this.sharedService.setVehAssmntEventId(this.selectedReadyToSubmitRow[0].vehAssmntEventId);
      if(this.selectedReadyToSubmitRow[0].vehAssmntEventWorkflow.workflowStatusCd === WorkflowStatus.READY_TO_SECONDARY_SUBMIT) {
        this.sharedService.setSecondaryAuditInputFlag(true);
      } else {
        this.sharedService.setSecondaryAuditInputFlag(false);
      }
      this.navigateToInputAuditForm();
    }
  }

  onBarCodeScan() {
    this.barcodeScannerOverlay.show();
  }
  onBarCodeValueChanges(result: any) {
    const barcodeValue = result.codeResult.code;
    this.filterVinBasedonBarCodeResult(barcodeValue);
    this.barcodeScannerOverlay.hide();
  }
  filterVinBasedonBarCodeResult(barcodeValue: any) {
    this.readyForAuditTableInstance.filter(barcodeValue, "vin", FilterMatchMode.EQUALS);
  }
  applyFilterGlobal($event, stringVal, table) {
    table.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  back() {
    this.navigateToSelectedAuditTab();
  }
  navigateToSelectedAuditTab() {
    this.sharedService.setVehicleSelectionTabIndex(0);
    this.router.navigate(['/audit/vehicle']);
  }

  onInputSecondaryAudit() {
    if (this.selectedReadyForSecondaryAuditRow) {
      this.sharedService.setAuditInboxTabIndex(3);
      this.sharedService.setVehAssmntEventId(this.selectedReadyForSecondaryAuditRow.vehAssmntEventId);
      this.sharedService.setSecondaryAuditInputFlag(true);
      this.navigateToInputAuditForm();
    }
  }
}
