import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectSeriesComponent } from './multi-select-series.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [
    MultiSelectSeriesComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule
  ], exports: [
    MultiSelectSeriesComponent
  ],
})
export class MultiSelectSeriesModule { }
