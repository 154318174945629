import { isNullOrUndefined } from "../../utilities/vma-common.util";
import { Instance } from '../../../common/app.constants';
import * as VMA_Util from '../../utilities/vma-common.util';


export class DashboardDataList {
    limit: number;
    offset: number;
    total: string;
    dashboardData: Array<DashboardData> = new Array<DashboardData>();

instance(){
    return Instance.DashboardModel
} 

}

export class DashboardData {
    seriesCd: string;
    data: Array<SeriesData> = new Array<SeriesData>();
}

export class SeriesData {
    modelYear : string;
    modelCd : string;
    accessories : Array<Accessory> = new Array<Accessory>()
}
export class Accessory{
    accessory : string;
    status : string;
    configId: number;
    vdcCodes ?: Array<VdcList>;
}

export class VdcList{
    vdcCode : string;
    vdcStatus : string;
    updatedTs? : string;
}

export class DashboardHistoryList {
    HistoryDataList: Array<DashboardHistoryDataDetails> = new Array<DashboardHistoryDataDetails>();
}

export class DashboardHistoryDataDetails {
    modelYear: number;
    modelCd: number;
    seriesCd: string;
    componentCd: string;
    vdcCD: string;
    workflowStatusCode: string;
    createdts: string;
    userName: string;
    userComments: string;
    vin: string;
    vehAssmntEventId: number;
    constructor(data: any) {
        this.modelYear = data.modelYear;
        this.modelCd = data.modelCd;
        this.seriesCd = data.seriesCd;
        this.componentCd = data.componentCd;
        this.vdcCD = data.vdcCD;
        this.workflowStatusCode = data.workflowStatusCode;
        this.createdts = this.removeTimeZone(data.createdts);
        this.userName = data.userName;
        this.userComments = data.userComments;
        this.vin = data.vin;
        this.vehAssmntEventId = data.vehAssmntEventId;
    }

    removeTimeZone(timeStamp) {
        let updatedDate = '';
        if (!VMA_Util.isNullOrUndefined(timeStamp)) {
            const timewithoutZone = VMA_Util.formatDateWithOutZone(timeStamp);
            updatedDate = VMA_Util.formatDateWithOutT(timewithoutZone);
        }
        return updatedDate;
    }
}
