import { NgModule } from '@angular/core';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AppComponent } from './app.component';
import { APP_IMPORTS } from './app.imports';
import { AUTH_PROVIDERS } from './core/auth/auth.config';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    APP_IMPORTS
  ],
  providers: [
    AUTH_PROVIDERS
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
