import { Injectable } from '@angular/core';
import * as Excel from "node_modules/exceljs/dist/exceljs.min";
import * as fs from 'file-saver';
import { AuditVehicleInfoModel, ExportExcelModel } from '../../model/ui/export-excel.model';
import {
  AdditionalLabelEvidenceModel, BaseLabelEvidenceModel, EvidenceDtlModel, InputDataComparisonReportModel,
  LineByLineVerificationReportModel, DrcCommentModel
} from '../../model/ui/audit-report.model';
import { ValidationStatusCdUI, WorkflowStatus } from 'src/app/common/app.constants';
import { SharedService } from './shared.service';
import { isEmpty, isNullOrUndefined, isUndefined } from '../../utilities/vma-common.util';
import { AuditVehicleListDetails, AuditWaiverList } from '../../model/ui/audit-vehicle.data.model';
import { AuditDrcListDetails } from '../../model/ui/audit-drc.data.model';
import { TreeNode } from 'primeng/api';
import { Table } from 'primeng/table';
import { AuditadminDataDetails } from '../../model/ui/audit-admin.data.model';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  sheetName = "Audit Report";
  reportTitle = "Audit Report";

  auditVehicleInfoTitle = "Audit Vehicle Information";
  auditVehicleInfoHeaders = ["VIN", "Accessories", "VDC", "Model Year", "Model Code"];
  inputDataComparisonReportTitle = "Input vs NVS Value Comparison Report";
  inputDataComparisonReportHeaders = ["Label Type", "Parameter Name", "Input Value", "NVS Value", "Validation Status"]
  lineBylineVerificationReportTitle = "Line By Line Verification Report";
  lineBylineVerificationReportHeaders = ["Parameter Name", "NVS Value", "Validation Status", "User Comment", "Evidence Link"]
  baseLabelEvidenceReportTitle = "Base Label Evidences";
  baseLabelEvidenceReportHeaders = ["Label Type", "Evidence Link"];

  additionalLabelEvidenceReportTitle = "Additional Label Evidences";
  additionalLabelEvidenceReportHeaders = ["Label Type", "Comment", "Evidence Link"];
  commentTitle = "DRC Reviewer Comment";
  drcCommentHeaders = ["Comment", "UserName", "Last updated timestamp"];


  constructor(private readonly sharedService: SharedService) {
  }

  getDrcDataForExcel(drcCommentData: DrcCommentModel): Array<any> {
    return new Array(drcCommentData.comment, drcCommentData.userName, drcCommentData.updatedDate);
  }

  getInputDataForExcel(inputDataComparisonReportList: InputDataComparisonReportModel[]): Array<Array<any>> {
    const inputDataForExcel: Array<Array<any>> = new Array<Array<any>>();
    inputDataComparisonReportList.forEach(element => {
      inputDataForExcel.push(
        new Array(element.scopeType, element.scopeItem, element.inputValue, element.nvsValue, element.validationStatus)
      )
    });
    return inputDataForExcel;
  }

  getLineByLineVerficationDataForExcel(lineByLineVerificationReportList: LineByLineVerificationReportModel[]): Array<Array<any>> {
    const lineByLineVerificationReportDataForExcel: Array<Array<any>> = new Array<Array<any>>();
    lineByLineVerificationReportList.forEach(element => {
      const evidenceLinks: Array<string> = this.generateEvidenceLink(element.evidence);
      const tempArray = [element.scopeItem, element.nvsValue, element.validationStatus, element.comment];
      evidenceLinks.forEach(evidence => {
        tempArray.push(evidence)
      });
      lineByLineVerificationReportDataForExcel.push(tempArray);
    });
    return lineByLineVerificationReportDataForExcel;
  }

  getBaseLabelEvidenceDataForExcel(baseLabelEvidenceList: BaseLabelEvidenceModel[]): Array<Array<any>> {
    const baseLabelEvidenceDataForExcel: Array<Array<any>> = new Array<Array<any>>();
    baseLabelEvidenceList.forEach(element => {
      if (!isUndefined(element.evidence)) {
        const evidenceLinks: Array<string> = this.generateEvidenceLink([element.evidence]);
        baseLabelEvidenceDataForExcel.push(
          new Array(element.scopeType, evidenceLinks[0])
        )
      }
    });
    return baseLabelEvidenceDataForExcel;
  }

  getAdditionalLabelEvidenceDataForExcel(additionalLabelEvidenceList: AdditionalLabelEvidenceModel[]): Array<Array<any>> {
    const additionalLabelEvidenceDataForExcel: Array<Array<any>> = new Array<Array<any>>();
    additionalLabelEvidenceList.forEach(element => {
      const evidenceLinks: Array<string> = this.generateEvidenceLink([element.evidence]);
      additionalLabelEvidenceDataForExcel.push(
        new Array(element.scopeType, element.comment, evidenceLinks[0])
      )
    });
    return additionalLabelEvidenceDataForExcel;
  }

  generateEvidenceLink(evidenceList: EvidenceDtlModel[]): Array<string> {
    const appUrl = this.sharedService.getCurrentEnvironmentDtl().UI_URL;
    const evidenceLinks: Array<string> = new Array<string>();
    evidenceList.forEach(element => {
      if (element !== undefined) {
        evidenceLinks.push(`${appUrl}#/audit/evidence?key=${element.key}`);
      }
    });
    return evidenceLinks;
  }

  getAuditInfoForExcel(auditVehicleInfo: AuditVehicleInfoModel): Array<any> {
    return new Array(auditVehicleInfo.vin, auditVehicleInfo.accessories, auditVehicleInfo.vdc, auditVehicleInfo.modelYear, auditVehicleInfo.modelCode);
  }

  generateExcelFileName(auditVehicleInfo: AuditVehicleInfoModel): string {
    return `VMA-Export-${auditVehicleInfo.modelYear}-${auditVehicleInfo.modelCode}-${auditVehicleInfo.vin}.xlsx`
  }

  generateAuditReportExcel(excelData: ExportExcelModel) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(this.sheetName);

    const titleRow = worksheet.addRow([this.reportTitle]);
    titleRow.alignment = { horizontal: 'center' }
    titleRow.font = { name: 'Roboto', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.mergeCells('A1:E1');
    worksheet.mergeCells('A2:E2');

    const auditVehicleInfoRow = worksheet.addRow([this.auditVehicleInfoTitle]);
    auditVehicleInfoRow.font = { name: 'Roboto', family: 4, size: 13, bold: true };

    const reportInfoHeaderRow = worksheet.addRow(this.auditVehicleInfoHeaders);
    reportInfoHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDD9C4' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    worksheet.addRow(this.getAuditInfoForExcel(excelData.auditVehicleInfoModel));
    worksheet.mergeCells('A6:E6');
    const section1TitleRow = worksheet.addRow([this.inputDataComparisonReportTitle]);
    section1TitleRow.font = { name: 'Roboto', family: 4, size: 13, bold: true };
    worksheet.mergeCells('A7:E7');
    const section1HeaderRow = worksheet.addRow(this.inputDataComparisonReportHeaders);
    section1HeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDD9C4' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    const section1Data = this.getInputDataForExcel(excelData.inputDataComparisonReportList);
    section1Data.forEach(d => {
      const row = worksheet.addRow(d);
      const status = row.getCell(5);
      let color = 'FF99FF99';
      if (status == ValidationStatusCdUI.Mismatch) {
        color = 'FF9999'
      }
      status.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
    }

    );
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 40;
    worksheet.getColumn(4).width = 40;
    worksheet.getColumn(5).width = 20;
    worksheet.addRow([]);

    const section2TitleRow = worksheet.addRow([this.lineBylineVerificationReportTitle]);
    section2TitleRow.font = { name: 'Roboto', family: 4, size: 13, bold: true };
    worksheet.mergeCells(`A${section2TitleRow.number}:E${section2TitleRow.number}`);
    const section2HeaderRow = worksheet.addRow(this.lineBylineVerificationReportHeaders);
    section2HeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDD9C4' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    const section2Data = this.getLineByLineVerficationDataForExcel(excelData.lineByLineVerificationReportList);
    section2Data.forEach(d => {
      const row = worksheet.addRow(d);
      const noOfEvidences = row._cells.length - 4;
      for (let i = 1; i <= noOfEvidences; i++) {
        const cell = row.getCell(i + 4);
        cell.value = {
          text: "Evidence_" + i,
          hyperlink: cell.value
        };
        cell.font = { color: { argb: "004e47cc" } };
        worksheet.getColumn(i + 4).width = 20;
      }
      const status = row.getCell(3);
      let color = 'FF99FF99';
      if (status == ValidationStatusCdUI.Mismatch) {
        color = 'FF9999'
      }
      status.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }
    });
    worksheet.addRow([]);

    const section3TitleRow = worksheet.addRow([this.baseLabelEvidenceReportTitle]);
    section3TitleRow.font = { name: 'Roboto', family: 4, size: 13, bold: true };
    worksheet.mergeCells(`A${section3TitleRow.number}:E${section3TitleRow.number}`);
    const section3HeaderRow = worksheet.addRow(this.baseLabelEvidenceReportHeaders);
    section3HeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDD9C4' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })
    const section3Data = this.getBaseLabelEvidenceDataForExcel(excelData.baseLabelEvidenceList);
    section3Data.forEach(d => {
      const row = worksheet.addRow(d);
      const cell = row.getCell(2);
      cell.value = {
        text: "Evidence",
        hyperlink: cell.value
      };
      cell.font = { color: { argb: "004e47cc" } };
    });
    worksheet.addRow([]);

    if (!isEmpty(excelData.additionalLabelEvidenceList)) {
      const section4TitleRow = worksheet.addRow([this.additionalLabelEvidenceReportTitle]);
      section4TitleRow.font = { name: 'Roboto', family: 4, size: 13, bold: true };
      worksheet.mergeCells(`A${section4TitleRow.number}:E${section4TitleRow.number}`);
      const section4HeaderRow = worksheet.addRow(this.additionalLabelEvidenceReportHeaders);
      section4HeaderRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDD9C4' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      const section4Data = this.getAdditionalLabelEvidenceDataForExcel(excelData.additionalLabelEvidenceList);
      section4Data.forEach(d => {
        const row = worksheet.addRow(d);
        const cell = row.getCell(3);
        cell.value = {
          text: "Evidence",
          hyperlink: cell.value
        };
        cell.font = { color: { argb: "004e47cc" } };
      });
      worksheet.addRow([]);
    }

    if (!isNullOrUndefined(excelData.drcComment.comment)) {
      const section5TitleRow = worksheet.addRow([this.commentTitle]);
      section5TitleRow.font = { name: 'Roboto', family: 4, size: 13, bold: true };
      worksheet.mergeCells(`A${section5TitleRow.number}:E${section5TitleRow.number}`);
      const section5HeaderRow = worksheet.addRow(this.drcCommentHeaders);
      section5HeaderRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDD9C4' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      const section5Data = this.getDrcDataForExcel(excelData.drcComment);
      worksheet.addRow(section5Data);
      worksheet.addRow([]);
    }

    const footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'DDD9C4' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    worksheet.mergeCells(`A${footerRow.number}:E${footerRow.number}`);
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.generateExcelFileName(excelData.auditVehicleInfoModel));
    })
  }


  generateVehicleListExcel(vehicleList: AuditVehicleListDetails[]) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(this.sheetName);
    const reportTitle = "Audit Vehicle List";
    const vehicleInfoHeaders = ["VIN", "Accessories", "Model Code", "VDC", "Arrival Date", "Serial No", "Extr/Intr Color", "Status", "User"];

    const titleRow = worksheet.addRow([reportTitle]);

    titleRow.alignment = { horizontal: 'center' }
    titleRow.font = { name: 'Roboto', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.mergeCells('A1:E1');
    worksheet.mergeCells('A2:E2');

    const vehicleInfoHeadersRow = worksheet.addRow(vehicleInfoHeaders);
    vehicleInfoHeadersRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDD9C4' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    const section1Data = this.getVehicleListDataForExcel(vehicleList);
    section1Data.forEach(d => {
      const row = worksheet.addRow(d);
      const status = row.getCell(8);
      if (status == WorkflowStatus.INPROGRESS) {
        const color = 'FABF8F';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      } else if (status == WorkflowStatus.SELECTED) {
        const color = '8DB4E2';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      } else if (status == WorkflowStatus.UN_SELECTED) {
        const color = 'FE6C68';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      } else if (status == WorkflowStatus.SUBMITTED) {
        const color = '99FF99';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      }

    }

    );
    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 70;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 10;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 50;
    worksheet.addRow([]);

    const footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'DDD9C4' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    worksheet.mergeCells(`A${footerRow.number}:E${footerRow.number}`);
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `VMA-Export-VehicleList.xlsx`);
    })
  }

  getVehicleListDataForExcel(vehicleList: AuditVehicleListDetails[]): Array<Array<any>> {
    const inputDataForExcel: Array<Array<any>> = new Array<Array<any>>();
    vehicleList.forEach(element => {
      inputDataForExcel.push(
        new Array(element.vin, element.accessories, element.modelCode, element.vdc, element.arrivalDate, element.serialNo,
          element.colorCd, element.workflowStatus, element.actorName)
      )
    });
    return inputDataForExcel;
  }

  generateDrcAuditsListExcel(drcDataList: AuditDrcListDetails[], fileName: string) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(this.sheetName);
    const vehicleInfoHeaders = ["VIN", "Accessories", "Model Year", "Model Code", "VDC", "User", "Updated On"];
    const reportTitle = "DRC Audits List";
    const titleRow = worksheet.addRow([reportTitle]);

    titleRow.alignment = { horizontal: 'center' }
    titleRow.font = { name: 'Roboto', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.mergeCells('A1:E1');
    worksheet.mergeCells('A2:E2');

    const vehicleInfoHeadersRow = worksheet.addRow(vehicleInfoHeaders);
    vehicleInfoHeadersRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDD9C4' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    const section1Data = this.getDrcAuditsListForExcel(drcDataList);
    section1Data.forEach(d => {
      const row = worksheet.addRow(d);
      const status = row.getCell(8);
      if (status == WorkflowStatus.INPROGRESS) {
        const color = 'FABF8F';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      } else if (status == WorkflowStatus.SELECTED) {
        const color = '8DB4E2';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      } else if (status == WorkflowStatus.UN_SELECTED) {
        const color = 'FE6C68';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      } else if (status == WorkflowStatus.SUBMITTED) {
        const color = '99FF99';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      }

    }

    );
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 10;
    worksheet.getColumn(6).width = 25;
    worksheet.getColumn(7).width = 25;
    worksheet.addRow([]);

    const footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'DDD9C4' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    worksheet.mergeCells(`A${footerRow.number}:G${footerRow.number}`);
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'VMA-Export-' + fileName + '_' + new Date().getTime() + '.xlsx');
    })
  }
  getDrcAuditsListForExcel(drcDataList: AuditDrcListDetails[]): Array<Array<any>> {
    const inputDataForExcel: Array<Array<any>> = new Array<Array<any>>();
    drcDataList.forEach(element => {
      inputDataForExcel.push(
        new Array(element.vin, element.accessorList, element.modelYear, element.modelCode, element.fcltyCd, element.actorName, element.UpdatedDate)
      )
    });
    return inputDataForExcel;
  }
  // Auditview
  generateadminDataExcel(adminDataList: AuditWaiverList[]) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(this.sheetName);
    const reportTitle = "Admin Data List";
    const vehicleInfoHeaders = ["Model Year", "Series", "Model Code", "Audit Exception Info", "Last Updated Info", "Status"];

    const titleRow = worksheet.addRow([reportTitle]);

    titleRow.alignment = { horizontal: 'center' }
    titleRow.font = { name: 'Roboto', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.mergeCells('A1:F1');
    worksheet.mergeCells('A2:F2');

    const vehicleInfoHeadersRow = worksheet.addRow(vehicleInfoHeaders);
    vehicleInfoHeadersRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDD9C4' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    const section1Data = this.getadminDataForExcel(adminDataList);
    section1Data.forEach(d => {
      const row = worksheet.addRow(d);
      const status = row.getCell(8);
      if (status == WorkflowStatus.INPROGRESS) {
        const color = 'FABF8F';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      } else if (status == WorkflowStatus.SELECTED) {
        const color = '8DB4E2';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      } else if (status == WorkflowStatus.UN_SELECTED) {
        const color = 'FE6C68';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      } else if (status == WorkflowStatus.SUBMITTED) {
        const color = '99FF99';
        status.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color }
        }
      }

    }

    );
    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.addRow([]);
    var timeStamp = this.sharedService.convertTimeStamp(new Date());
    const footerRow = worksheet.addRow(['This is system generated excel sheet on: ' + timeStamp]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'DDD9C4' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `VMA-Export-adminDataList` + '_' + timeStamp + `.xlsx`);
    })
  }

  getadminDataForExcel(adminDataList: any[]): Array<Array<any>> {
    const inputDataForExcel: Array<Array<any>> = new Array<Array<any>>();
    adminDataList.forEach(element => {
      inputDataForExcel.push(
        new Array(element.modelYear, element.productGroupId, element.modelCd, element.auditWaiverExcp, element.createdts, element.activeInd)
      )
    });
    return inputDataForExcel;
  }

  // Chronic Hold
  generateChronicHoldExcel(tlsHoldDataList: any, infoMessage: string) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(this.sheetName);
    const reportTitle = "TLS Hold Shipment";
    const vehicleInfoHeaders = ["Series", "Model Code", "VDC", "Accessory", "Status", "Last Updated Info"];

    const titleRow = worksheet.addRow([reportTitle]);

    titleRow.alignment = { horizontal: 'center' }
    titleRow.font = { name: 'Roboto', family: 4, size: 16, underline: 'double', bold: true };

    if(infoMessage !== '') {
      const subtitle = worksheet.addRow([infoMessage]);
      subtitle.alignment = { horizontal: 'center' }
      subtitle.font = { name: 'Roboto', family: 4, size: 8, style: 'italic', bold: true };
    }
    worksheet.mergeCells('A1:E1');
    worksheet.mergeCells('A2:E2');
    worksheet.mergeCells('A3:E3');

    const vehicleInfoHeadersRow = worksheet.addRow(vehicleInfoHeaders);
    vehicleInfoHeadersRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDD9C4' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    const section1Data = this.getChronicHoldDataForExcel(tlsHoldDataList);
    section1Data.forEach(d => {
      const row = worksheet.addRow(d);
      const status = row.getCell(8);
    }

    );
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 10;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.addRow([]);

    const footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'DDD9C4' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    worksheet.mergeCells(`A${footerRow.number}:E${footerRow.number}`);
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `VMA-Export-ChronicHoldList.xlsx`);
    })
  }

  getChronicHoldDataForExcel(tlsHoldDataList: any): Array<Array<any>> {
    const inputDataForExcel: Array<any> = new Array<any>();
    tlsHoldDataList.forEach(element => {
      inputDataForExcel.push(
        new Array(element.series, element.modelCode, element.vdc, element.accessory, element.status, element.updatedTs)
      )
    });
    return inputDataForExcel;
  }

  // Dashboard excel
  generateDashboardExcel(dashboardData:any){
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(this.sheetName);
    const reportTitle = "Model Launch Audit Configuration";
    const vehicleInfoHeaders = ["Series", "Model Year", "Model Code", "Accessories"];

    const titleRow = worksheet.addRow([reportTitle]);

    titleRow.alignment = { horizontal: 'center' }
    titleRow.font = { name: 'Roboto', family: 4, size: 16, underline: 'double', bold: true };

    worksheet.mergeCells('A1:E1');
    worksheet.mergeCells('A2:E2');
    worksheet.mergeCells('A3:E3');

    const vehicleInfoHeadersRow = worksheet.addRow(vehicleInfoHeaders);
    vehicleInfoHeadersRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDD9C4' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    })

    const section1Data = this.getDashboardDataForExcel(dashboardData, worksheet);
    section1Data.forEach(d => {
      const row = worksheet.addRow(d);
      const status = row.getCell(8);
    }

    );
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 10;
    worksheet.getColumn(4).width = 15;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 20;
    worksheet.addRow([]);

    const footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'DDD9C4' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    worksheet.mergeCells(`A${footerRow.number}:E${footerRow.number}`);
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `VMA-Export-ModelLaunchAuditList.xlsx`);
    })

  } 

  getDashboardDataForExcel(dashboardDataList: any, worksheet:any): Array<Array<any>> {
    const inputDataForExcel: Array<any> = new Array<any>();
    worksheet.addRow
    const holdStatusFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FABF8F' }
    }
    const releaseStatusFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FABF8F' }
    }
    const progressStatusFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FABF8F' }
    }

    dashboardDataList.forEach(element => {
      console.log("excel element", element);
      const rowData: (string | number)[] = [];
      rowData.push(element.series, element.modelYear, element.modelCode);
      for (const accessory of element.accessories) {
        rowData.push(accessory.accessory + ' - '+ accessory.status);
      }
      console.log("rowData@@", rowData)
      inputDataForExcel.push(
        rowData
      )
    });
    return inputDataForExcel;
  }

}