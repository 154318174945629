<div *ngIf="isAdminView" class="p-fluid p-formgrid p-grid" style="justify-content: center; margin: auto; margin-top: 6px;">
    <div style="margin: 10px;">
        <h3>ADMIN VIEW</h3>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-modelyear [brandId]="brand" (yearEvent)="selectedYear($event)"></vma-modelyear>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-series [brandId]="brand" [yearNo]="year" (seriesEvent)="selectedSeries($event)"></vma-series>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-single-select-modelcode [brandId]="brand" [yearNo]="year" [seriesId]="series" (modelcodeEvent)="selectedModelcode($event)">
        </vma-single-select-modelcode>
    </div>
    <div style="display:flex; flex-direction: row; justify-content: center; margin-block-start: 7px; align-items: center">
        <div style="padding: 0.5rem;">
            <button type="button" label="Search" pButton pRipple icon="pi pi-search" style="margin: 0.25rem 0.125rem;"
                class="cmn-button-style p-mr-2" (click)="vehicleListSearch()"></button>
        </div>
        <div style="padding: 0.5rem;">
            <button type="button" label="Reset" pButton pRipple icon="pi pi-undo" style="margin: 0.25rem 0.125rem;"
                 class="p-button-secondary cmn-button-style p-mr-2" (click)="resetVehicleList()"></button>
        </div>
        <div style="padding: 0.5rem;">
            <button type="button" label="Add to Waive List" pButton pRipple icon="pi pi-list" style="margin: 0.25rem 0.125rem;"
                class="p-button-help cmn-button-style" (click)="onAddWaiveList()"></button>
        </div>
    </div>
</div>

<div *ngIf="!isAdminView" class="p-formgrid p-grid" style="margin: auto;">
    <div>
        <vma-modelyear [brandId]="brand" (yearEvent)="selectedYear($event)"></vma-modelyear>
    </div>
    <div>
        <vma-series [brandId]="brand" [yearNo]="year" (seriesEvent)="selectedSeries($event)"></vma-series>
    </div>
    <div>
        <vma-single-select-modelcode [brandId]="brand" [yearNo]="year" [seriesId]="series" (modelcodeEvent)="selectedModelcode($event)">
        </vma-single-select-modelcode>
    </div>
</div>
    