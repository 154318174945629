import { EnvironmentModel } from "src/app/core/model/ui/environment-property.model";

export const ProdEnvironment: EnvironmentModel = {
  production: true,
  ENV_NAME: 'prod',
  VMA_API_ENDPOINT: 'apig.vma.toyota.com',
  PCPS_API_ENDPOINT: 'api.pcps.toyota.com',
  TENANT_ID: '8c642d1d-d709-47b0-ab10-080af10798fb',
  CLIENT_ID: '827ccfdf-2958-4797-982b-8903e685acda',
  INSTANCE_ID: 'https://login.microsoftonline.com/',
  UI_URL: 'https://ui.vma.toyota.com/',
  REDIRECT_URI: '/',
  POST_LOGOUT_REDIRECT_URI: '',
  NAVIGATE_TO_LOGIN_REQUEST_URL: true,
  CACHE_LOCATION: 'localStorage'
};

