<p-dialog header="Confirmation" [closeOnEscape]="false" [draggable]="false" [resizable]="false" [closable]="false"
    styleClass="cmn-input-font-style" [(visible)]="sharedService.isSessionExpired" [modal]="true">
    <h3 class="session-expired">Your session has expired !</h3>
    <h4>Please extend the session (or) login again . Time left to extend session :
        {{sharedService.sessionTime}}
        minutes.
    </h4>
    <ng-template pTemplate="footer">
        <button pButton type="button" class="p-button-secondary cmn-button-style p-mr-2" label="Extend"
            [disabled]="sharedService.sessionTime == '0'" (click)="extendSession()"></button>
        <button pButton type="button" class="cmn-button-style" label="Re Login" (click)="login()"></button>
    </ng-template>
</p-dialog>