import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  private readonly requests: HttpRequest<any>[] = [];

  constructor(private readonly loaderService: NgxSpinnerService,
    private readonly messageService: MessageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.messageService.clear();
    return this.processApiRequest(next, request, this.loaderService);
  }
  processApiRequest(next: HttpHandler, request: HttpRequest<any>, loadingService: NgxSpinnerService): Observable<HttpEvent<any>> {
    if (!request.reportProgress) {
      loadingService.show();
    }
    return next.handle(request).pipe(
      tap(function (event) {
        if (event instanceof HttpResponse) {
          loadingService.hide();
        }
      }), catchError(function (error: any, caught: Observable<HttpEvent<any>>) {
        loadingService.hide();
        throw error;
      })
    );
  }
}
