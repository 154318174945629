<div class="padding">
    <h3>TLS Shipment Hold View</h3>

    <div>
        <vma-vehicle-search-by-tls-hold (searchEvent)="searchVehicleEvent($event)"></vma-vehicle-search-by-tls-hold>
    </div>
    <em *ngIf="!yearNoParams" class="info-style"> ( Info - Only Hold Active data will be loaded by default. ) </em>
    <br *ngIf="!yearNoParams">

    <!-- <p-divider align="center" type="dashed">
        <button pButton type="button" icon="pi pi-undo" class="p-button-secondary cmn-button-style p-mr-2" label="RESET"
            (click)="globalReset()"></button>
        <button pButton type="button" icon="pi pi-search" class="cmn-button-style" label="SEARCH"
            (click)="searchAuditVehicles()"></button>
    </p-divider> -->

    <div style="justify-content: space-between; margin-bottom: 25px;">
        <div class="hold-header" style="display: flex; justify-content: flex-end;">
            <button type="button" label="Export" pButton pRipple icon="pi pi-file-excel" (click)="export()"
                style="margin: 0.150rem;" class="button-export p-button-success cmn-button-style p-mr-2" pTooltip="XLS"
                tooltipPosition="bottom" [disabled]="!allchronicHoldList.length"></button>
        </div>
        <!-- <p-tabPanel> -->
        <p-table #table1 [value]="chronicHoldList" styleClass="p-datatable-sm" sortField="yearSeriesComb" sortMode="single"
            [scrollable]="true" scrollHeight="38vh" responsiveLayout="scroll" dataKey="yearSeriesComb"
            [globalFilterFields]="['yearSeriesComb','modelCode','vdc','accessory','status','updatedTs']"
            [(selection)]="selectedRowItems" (onFilter)="onFilter($event)" (sortFunction)="customSort($event)" [customSort]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th id="series" class="cmn-table-header-style filterStyle" style="width: 19%">Series
                        <p-sortIcon [pSortableColumn]="'yearSeriesComb'" [field]="'yearSeriesComb'"></p-sortIcon>
                        <button type="button" icon="pi pi-filter" class="p-button-secondary" pButton pRipple
                            (click)="onFilterSeries(table1)"></button>
                    </th>
                    <th id="modelCode" class="cmn-table-header-style filterStyle" style="width: 15%">Model Code
                        <p-sortIcon [pSortableColumn]="'modelCode'" [field]="'modelCode'"></p-sortIcon>
                        <button type="button" icon="pi pi-filter" class="p-button-secondary" pButton pRipple
                            (click)="onFilterModelCd(table1)"></button>
                    </th>
                    <th id="vdc" class="cmn-table-header-style filterStyle" style="width: 15%">Vdc
                        <p-sortIcon [pSortableColumn]="'vdc'" [field]="'vdc'"></p-sortIcon>
                        <button type="button" icon="pi pi-filter" class="p-button-secondary" pButton pRipple
                            (click)="onFilterVdc(table1)"></button>
                    </th>
                    <th id="accessory" class="cmn-table-header-style filterStyle" style="width: 15%">Accessory
                        <p-sortIcon [pSortableColumn]="'accessory'" [field]="'accessory'"></p-sortIcon>
                        <button type="button" icon="pi pi-filter" class="p-button-secondary" pButton pRipple
                            (click)="onFilterAccessory(table1)"></button>
                    </th>
                    <th id="status" class="cmn-table-header-style filterStyle" style="width: 16%">Status
                        <p-sortIcon [pSortableColumn]="'status'" [field]="'status'"></p-sortIcon>
                        <button type="button" icon="pi pi-filter" class="p-button-secondary" pButton pRipple
                            (click)="onFilterStatus(table1)"></button>
                    </th>
                    <th id="updatedTs" class="cmn-table-header-style filterStyle" style="width: 18%">Last Updated Info
                        <p-sortIcon [pSortableColumn]="'updatedTs'" [field]="'updatedTs'"></p-sortIcon>
                        <button type="button" icon="pi pi-filter" class="p-button-secondary" pButton pRipple
                            (click)="onFilterUserInfo(table1)"></button>
                    </th>
                </tr>

                <tr>
                    <th style="width: 135px" scope="col">
                        <div class="p-inputgroup" *ngIf="filterAction">
                            <input class="cmn-table-data-style" minlength="2" [(ngModel)]="filteredSeries" type="text"
                                pInputText placeholder="Filter" (input)="onClearSeries(table1)">
                        </div>
                    </th>
                    <th style="width: 135px" scope="col">
                        <div class="p-inputgroup" *ngIf="filterAction">
                            <input class="cmn-table-data-style" minlength="2" [(ngModel)]="filteredModelCd" type="text"
                                pInputText placeholder="Filter" (input)="onClearModelCd(table1)">
                        </div>
                    </th>
                    <th style="width: 135px" scope="col">
                        <div class="p-inputgroup" *ngIf="filterAction">
                            <input class="cmn-table-data-style" minlength="2" [(ngModel)]="filteredVdc" type="text"
                                pInputText placeholder="Filter" (input)="onClearVdc(table1)">
                        </div>
                    </th>
                    <th style="width: 135px" scope="col">
                        <div class="p-inputgroup" *ngIf="filterAction">
                            <input class="cmn-table-data-style" minlength="2" [(ngModel)]="filteredAccessory"
                                type="text" pInputText placeholder="Filter" (input)="onClearAccessory(table1)">
                        </div>
                    </th>
                    <th style="width: 135px" scope="col">
                        <div class="p-inputgroup" *ngIf="filterAction">
                            <input class="cmn-table-data-style" minlength="2" [(ngModel)]="filteredStatus" type="text"
                                pInputText placeholder="Filter" (input)="onClearStatus(table1)">
                        </div>
                    </th>
                    <th style="width: 135px" scope="col">
                        <div class="p-inputgroup" *ngIf="filterAction">
                            <input class="cmn-table-data-style" minlength="2" [(ngModel)]="filteredUserInfo" type="text"
                                pInputText placeholder="Filter" (input)="onClearUserInfo(table1)">
                        </div>
                    </th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-searchData let-rowIndex="rowIndex" let-expanded="expanded">
                <tr class="text-data" *ngIf="rowGroupMetadata[searchData.yearSeriesComb].index === rowIndex">
                    <td colspan="6" class="cmn-table-data-style">
                        <span class="p-text-bold p-ml-2">
                            <div class="row-wrapper">
                                <div class="col-md-3 padd-left-30" style="display: inline-flex; align-items: center;">
                                    <input type="checkbox" class="checkBoxStyle"
                                        (click)="headerCheckboxEvent($event,searchData)"
                                        [(ngModel)]="searchData.headerCheckbox"
                                        [checked]="rowGroupMetadata[searchData.yearSeriesComb].seriesCheckbox" />
                                    <button type="button" pButton pRipple [pRowToggler]="searchData"
                                        class="p-button-text p-button-rounded p-button-plain p-button-sm"
                                        style="font-size: 0.8rem"
                                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                    &nbsp;
                                    {{searchData.yearSeriesComb}}
                                </div>
                                <div class="col-md-5 marg-top7">
                                    <div class="row-wrapper">
                                        <div class="col-md-4 total-ind">
                                            <span> Total
                                                Models
                                                :{{calculateCustomerTotal(searchData.yearSeriesComb)}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-searchData class="inner-table" let-rowIndex>
                <tr class="table-inner-data" [ngClass]="searchData.rowCheckbox == true ?'bg-clor-active':''"
                    [pSelectableRow]="searchData" [pSelectableRowIndex]="rowIndex">
                    <td style="padding-left: 30px;">
                        <input type="checkbox" class="checkBoxStyle" [value]="searchData"
                            (click)="rowCheckboxEvent(searchData)" [checked]="searchData.rowCheckbox">
                        <button [ngClass]="searchData.status === 'Hold Released'? 'edit-disabled':''" pButton type="button" icon="pi pi-pencil"
                            (click)="onEditRowData(searchData)" class="p-button-text p-button-rounded"></button>
                        <button pButton type="button" icon="pi pi-clock" (click)="historyLog(searchData)"
                            class="p-button-text p-button-rounded"></button>
                    </td>
                    <td class="cmn-table-data-style">{{searchData.modelCode}}</td>
                    <td class="cmn-table-data-style">{{searchData.vdc}} </td>
                    <td class="cmn-table-data-style">{{searchData.accessory}}</td>
                    <td class="cmn-table-data-style">{{searchData.status}}</td>
                    <td class="cmn-table-data-style">{{searchData.updatedTs}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="6" class="cmn-table-data-style">
                        No records found
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="showPagination">
            <p-paginator [style]="{'font-family' : 'Roboto' , 'font-size': '12px', 'margin-bottom' :'20px'}"
                [rows]="rows" [totalRecords]="totalRecords" [pageLinkSize]="totalPages"
                [rowsPerPageOptions]="[5,10,20,50]" (onPageChange)="onPaginator($event)">
            </p-paginator>
        </div>

    </div>

    <div class="align-right page-action-panel-style">
        <button pButton type="button" style="margin-bottom: 4px;"
            class="p-button-secondary p-button-raised cmn-button-style p-mr-2" label="Check NPPS Status"
            (click)="nppsStatus()" [disabled]="!allchronicHoldList.length"></button>
        <button pButton type="button" style="margin-bottom: 4px;"
            class="p-button-secondary p-button-raised cmn-button-style p-mr-2" label="Add New TLS Shipment Hold"
            (click)="addNewShipment()"></button>
        <button pButton type="button" style="margin-bottom: 4px;"
            class="p-button-secondary p-button-raised cmn-button-style p-mr-2" label="Turn On TLS Shipment Hold"
            (click)="turnOnTls()" [disabled]="!allchronicHoldList.length"></button>
        <button pButton type="button" style="margin-bottom: 4px;"
            class="p-button-secondary p-button-raised cmn-button-style p-mr-2" label="Release TLS Shipment Hold"
            (click)="releaseTls()" [disabled]="!allchronicHoldList.length"></button>
    </div>

    <p-confirmDialog #cd [style]="{width: '28vw'}">
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style" (click)="cd.reject()" label="No">
            </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" (click)="cd.accept()" label="Yes">
            </p-button>
        </ng-template>
    </p-confirmDialog>
    <!-- <p-messages [value]="toastMessages"></p-messages> -->


    <p-dialog [modal]="true" header="History Log" [(visible)]="displayHistoryLog" [closeOnEscape]="false"
        [draggable]="false" [style]="{'width': '50vw'}" class="history-log-container">
        <br>
        <p-timeline [value]="chronicHistList" align="left" style-class="customized-timeline">
            <ng-template pTemplate="marker" let-logs>
                <i class="pi pi-arrow-circle-right" style="font-size: 2rem"></i>
                <span class="custom-marker shadow-2" [style.backgroundColor]="logs.color">
                    <i [ngClass]="logs.icon"></i>
                </span>
            </ng-template>
            <ng-template pTemplate="content" let-logs>
                <p-card [header]="logs.status" [subheader]="logs.date">
                    <div>
                        <h5>{{logs.eventEResp}} by {{logs.actorNm}} {{logs.eventTs}}</h5>
                    </div>
                </p-card>
                <div *ngIf="logs.eventNotes">
                    <h5 class="user-info-style">User Comment: <em class="action-comment-class">
                            {{logs.eventNotes}}</em></h5>
                </div>
            </ng-template>
        </p-timeline>
    </p-dialog>


    <p-dialog [modal]="true" [(visible)]="displayModal" position="bottom" widgetVar="dlg2"  borderRadius="5px"  [closeOnEscape]="false"
    [draggable]="false">
        <ng-template pTemplate="content">
            <div class="grid">
                <div class="input-part">
                    <vma-vehicle-search-by-tls-hold
                        (searchEvent)="addModelData($event)"></vma-vehicle-search-by-tls-hold>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">

        </ng-template>
    </p-dialog>

    <!-- <p-dialog [modal]="true" [(visible)]="displayModal2" (onHide)="dialogClose()" position="bottom" widgetVar="dlg3" borderRadius="5px"
    [style]="{'width': '28vw'}"> -->

    <p-dialog [modal]="true" [showHeader]="true" (onHide)="dialogClose()" [(visible)]="displayModal2" position="center" [closeOnEscape]="false"
    [draggable]="false">

        <ng-template pTemplate="content">
            <div class="grid">
                <div class="input-part">
                    <vma-vehicle-search-by-tls-hold
                        (searchEvent)="searchShipment($event)"></vma-vehicle-search-by-tls-hold>
                </div>
            </div>
        </ng-template>
    </p-dialog>
</div>