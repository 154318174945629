import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { PcpsDistributorService } from 'src/app/core/services/api/pcps-distributor.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isArray, isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { Distributor, DropDownModel } from 'src/app/models/dropdown.model'
import { StaticData } from '../../static-data';
import { YearApiModel } from 'src/app/core/model/api/year-api.model';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'vma-modelyear-dashboard',
  templateUrl: './modelyear-dashboard.component.html',
  styleUrls: ['./modelyear-dashboard.component.scss']
})
export class ModelyearDashboardComponent implements OnInit {

  brandIdParams: DropDownModel;
  selectedModelYear: any;
  modelYears: Array<number>;
  @Output() yearEvent = new EventEmitter<any>();
  brandSubscription = new Subscription();
  viewMode = false;
  editMode = false;

  constructor(
    private sharedService: SharedService,
    private readonly pcpsDistributorService: PcpsDistributorService
  ) { }

  ngOnInit(): void {
    // const distributors = StaticData.getDistributorList();
    // this.listModelYears(distributors[0]);
    // this.handleClearButton();
  }

  @Input()
  set brandId(brandId: DropDownModel) {
    this.modelYears = [];
    this.selectedModelYear = null;
    this.brandIdParams = brandId;
    const distributors = StaticData.getDistributorList();
    // if(!isNullOrUndefined(this.brandIdParams)) {
    //   for(const id of this.brandIdParams) {
    //     this.listModelYears(distributors[0], this.brandIdParams);
    //   }
    // }
    this.listModelYears(distributors[0], this.brandIdParams);
  }

  listModelYears(distributor: Distributor, brandId: any) {
    if (!isNullOrUndefined(distributor) && !isNullOrUndefined(brandId) && !isEmpty(brandId)) {
      const distributorId: number = distributor.distributorId;
      const brandIdRef: string = this.formatQueryParams(brandId);
      this.sharedService.clearPreviousMessages();
      const parames = { brandid: brandIdRef };
      // const parames: any = {};
      // if(isArray(brandId) && isNullOrUndefined(brandId.id)) {
      //   parames.brandId = brandId.join();
      // } else {
      //   parames.brandid = brandId.id;
      // }
      this.pcpsDistributorService.listYears(distributorId, parames).subscribe((res) => {
        this.modelYears = new Array<number>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          const yearsResp: Array<YearApiModel> = res.data[0].years;
          yearsResp.forEach(element => {
            this.modelYears.push(element.modelyearNo);
          });
          this.setSelectedYearNoValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  formatQueryParams(object: any): string {
    let queryParams = '';
    if (object.selectAll) {
      queryParams = 'all';
    } else {
      let count = 0;
      object.forEach(element => {
        if (count !== 0) {
          queryParams = queryParams + ',';
        }
        queryParams = queryParams + element.id;
        count = count + 1;
      });
    }
    return queryParams;
  }

  handleClearButton() {
    this.brandSubscription = this.sharedService.myObservable$.subscribe(res => {
      if (res) {
        this.selectedModelYear = null;
        this.yearEvent.emit(this.selectedModelYear);
      }
    })
  }

  onModelYearChange(event: any) {
    this.selectedModelYear = event.value;
    this.yearEvent.emit(this.selectedModelYear);
  }

  setSelectedYearNoValue() {
    if (!isNullOrUndefined(this.sharedService.dashoardSearchData)
      && !isNullOrUndefined(this.sharedService.dashoardSearchData.yearNo)) {
      this.selectedModelYear = this.sharedService.dashoardSearchData.yearNo;
      this.yearEvent.emit(this.selectedModelYear);
      if(this.sharedService.dashoardSearchData.searchType ==="adminview") {
        this.viewMode = true;
      }
      else if(this.sharedService.dashoardSearchData.searchType === "TLSEDITVIEW") {
        this.editMode = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.brandSubscription.unsubscribe();
  }
}
