import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FileUploadModule} from 'primeng/fileupload';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { AuditReportRoutingModule } from './audit-report-routing.module';
import { AuditReportComponent } from './audit-report.component';
import { TableModule } from 'primeng/table';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { BadgeModule } from 'primeng/badge';
import { PageTitleInfoModule } from 'src/app/modules/page-title-info/page-title-info.module';

@NgModule({
  declarations: [
    AuditReportComponent
  ],
  imports: [
    CommonModule,
    AuditReportRoutingModule,
    TableModule,
    GalleriaModule,
    ButtonModule,
    ImageModule,
    DividerModule,
    InputTextModule,
    FileUploadModule,
    FormsModule,
    InputTextareaModule,
    DialogModule,
    TooltipModule,
    BadgeModule,
    PageTitleInfoModule
  ], exports: [
    AuditReportComponent
  ]
})
export class AuditReportModule { }
