import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReleaseAuditComponent } from './release-audit.component';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { AccordionModule } from 'primeng/accordion';

@NgModule({
  declarations: [
    ReleaseAuditComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    ProgressBarModule,
    ButtonModule,
    CardModule,
    TabViewModule,
    BadgeModule,
    AccordionModule
  ], exports: [
    ReleaseAuditComponent
  ]
})
export class ReleaseAuditModule { }
