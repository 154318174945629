import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrcApprovalRoutingModule } from './drc-approval-routing.module';
import { DrcApprovalComponent } from './drc-approval.component';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { BadgeModule } from 'primeng/badge';
import { PaginatorModule } from 'primeng/paginator';
import { PageTitleInfoModule } from 'src/app/modules/page-title-info/page-title-info.module';

@NgModule({
  declarations: [
    DrcApprovalComponent
  ],
  imports: [
    CommonModule,
    DrcApprovalRoutingModule,
    TableModule,
    GalleriaModule,
    ButtonModule,
    ImageModule,
    DividerModule,
    TooltipModule,
    BadgeModule,
    PageTitleInfoModule,
    TabViewModule,
    PaginatorModule
  ], exports: [
    DrcApprovalComponent
  ]
})
export class DrcApprovalModule { }
