import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '../../rest-api/service/rest.service';
import { Response } from '../../rest-api/model/response';
import * as VMA_Util from '../../utilities/vma-common.util';
import { ListVehicleApiRespModel, AuditVehicleListApiRespModel, FacultyCodeListApiRespModel, AccessoryListApiRespModel } from '../../model/api/list-vehicle.model';
import { GetAuditApiResModel } from '../../model/api/get-audit.model';
import { AuditData } from '../../model/ui/audit-data.model';
import { ApiType } from 'src/app/common/app.constants';
import { ChronicHoldListApiRespModel, ChronicHistListApiRespModel } from '../../model/api/list-chronicHold';
import { MultiChronicHoldData, ShipmentHoldRequestData, SmartChronicData, SmartChronicHoldReqObj, SmartChronicRequestData, UpdateTlsRequestData} from '../../model/ui/chronic-hold-data.model';
import { ListAuditWaiverApiRespModel } from '../../model/api/listAuditWaiver-api.model';
import { ListWaiverHistoryApiRespModel } from '../../model/api/list-waiver.model';
import { AuditWaiverData, NPPSPropagationData } from '../../model/ui/audit-admin.data.model';
import { DashboardDataListApiRespModel } from '../../model/api/list-dashboard.model';
import { DashboardDataListVdcApiRespModel } from '../../model/api/list-dashboard-vdcStatus.model';
import { listDashboardHistoryApiRespModel } from '../../model/api/list-dashboard.model';
import { SecondaryAuditListApiRespModel } from '../../model/api/secondary-audit-api.model';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  static readonly ADD_VEHICLE_POST_API: string = 'audit/vehicle/vehAssmntEventId';
  static readonly SELECT_VEHICLE_POST_API: string = 'audit/vin';
  static readonly LIST_VEHICLES_GET_API: string = 'audit/vehicles';
  static readonly UPDATE_WORKFLOW_PUT_API: string = 'audit/vehicle/vehAssmntEventId/workflow';
  static readonly FETCH_AUDIT_DATA_GET_API: string = 'audit/vehicle/vehAssmntEventId';
  static readonly FETCH_S3_PRESIGNED_GET_URL: string = 'audit/s3url';
  static readonly LIST_FINAL_ASSEMBLY_POINTS_GET_API: string = 'content-origin/finalassemblypoint';
  static readonly VMA_FINAL_ASSEMBLY_POINTS_GET_API: string = 'audit/vehicle/finalAssemblyPoint';
  static readonly LIST_COUNTRY_OF_ORIGINS_GET_API: string = 'content-origin/countryoforigin';
  static readonly UPDATE_AUDIT_DATA_PUT_API: string = 'audit/vehicle/vehAssmntEventId';
  static readonly LIST_VEHICLES_MODEL_GET_API: string = 'audit/distributors/distributorcd/vehicles';
  static readonly VDCLIST_GET_API: string = 'audit/distributors/distributorcd/vdc';
  static readonly ACCESSORIESLIST_GET_API: string = 'audit/distributors/distributorcd/accessory';
  static readonly LIST_DRC_DATA_GET_API: string = 'audit/vehicle/drc';
  static readonly LIST_CHRONIC_HOLD_DATA_GET_API: string = 'audit/vehicle/hold';
  static readonly LIST_CHRONIC_HIST_DATA_GET_API: string = 'audit/vehicle/hold/chronicHoldId';
  static readonly UPDATE_MULTI_SELECT_CHRONIC_PUT_API: string = 'audit/vehicle/hold/multiSelect';
  static readonly ADD_SHIPMENT_HOLD_POST_API: string = 'audit/vehicle/hold';
  static readonly LIST_AUDIT_WAIVER_HISTORY_API: string = 'audit/admin/audit/auditId';
  static readonly LIST_AUDIT_WAIVER_API: string = 'audit/admin/audit';
  static readonly UPDATE_AUDIT_WAIVER_DETAILS_API: string = 'audit/admin/audit/auditId';
  static readonly UPDATE_AUDIT_WAIVER_API: string = 'audit/admin/audit/auditId';
  static readonly ADD_AUDIT_WAIVER_POST_API: string = 'audit/admin/audit';
  static readonly ALL_VEHICLES_GET_API: string = 'audit/distributors/allvehicles';
  static readonly UPDATE_CHRONIC_HOLD_PUT_API: string = 'audit/vehicle/hold/chronicHoldId';
  static readonly LIST_DASHBOARD_DATA_GET_API: string = 'audit/distributors/distributorcd/dashboard';
  static readonly LIST_DASHBOARD_VDC_DATA_GET_API: string = 'audit/vehicle/vdc';
  static readonly LIST_DASHBOARD_HISTORY_GET_API: string = 'audit/vehicle/dashboardHistory';
  static readonly UPDATE_MULTI_SELECT_SMART_CHRONIC_PUT_API: string = 'audit/vehicle/smartChronic';
  static readonly UPDATE_NPPS_DATA_PUT_API: string = 'audit/vehicle/dataPropagation/vehAssmntEventId';
  static readonly GET_SECONDARY_AUDIT_LIST: string = 'audit/vehicle/identifySecondaryAudit';
  static readonly SECONDARY_AUDIT_POST_API: string = 'audit/vehicle/secondaryAudit/vehAssmntEventId';

  constructor(private readonly restService: RestService) { }

  public addVehicle(vehAssmntEventId: any): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.ADD_VEHICLE_POST_API, { vehAssmntEventId });
    return this.restService._post<any>(url, {});
  }

  public selectAuditVehicle(vin: string): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.SELECT_VEHICLE_POST_API, { vin });
    return this.restService._post<any>(url, {});
  }

  public listVehicle(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_VEHICLES_GET_API, {});
    return this.restService._get<ListVehicleApiRespModel>(url, this.listVehicleRespMap, params);
  }

  private listVehicleRespMap(item: any): ListVehicleApiRespModel {
    return new ListVehicleApiRespModel(item);
  }

  public listDRCData(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_DRC_DATA_GET_API, {});
    return this.restService._get<any>(url, this.listDRCDataRespMap, params);
  }

  private listDRCDataRespMap(item: any): any {
    return item;
  }

  public updateWorkflow(vehAssmntEventId: number, reqBody: object, params:object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_WORKFLOW_PUT_API, { vehAssmntEventId });
    return this.restService._put<any>(url, reqBody, params);
  }

  public getAudit(vehAssmntEventId: number): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.FETCH_AUDIT_DATA_GET_API, { vehAssmntEventId });
    return this.restService._get<GetAuditApiResModel>(url, this.getAuditRespMap);
  }
  private getAuditRespMap(item: any): GetAuditApiResModel {
    return new GetAuditApiResModel(item);
  }

  public getFinalAssemblyPoints(): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.VMA_FINAL_ASSEMBLY_POINTS_GET_API, {});
    return this.restService._get<any>(url);
  }

  public getCountryOfOrigins(): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.PCPS);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_COUNTRY_OF_ORIGINS_GET_API, {});
    return this.restService._get<any>(url);
  }

  public getS3PresignedUrl(params: object): Observable<any> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.FETCH_S3_PRESIGNED_GET_URL, {});
    return this.restService._get<any>(url, this.getS3PresignedUrlResMap, params);
  }
  private getS3PresignedUrlResMap(item: any): any {
    return item;
  }

  public updateAudit(auditData: AuditData): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const vehAssmntEventId = auditData.vehAssmntEventId;
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_AUDIT_DATA_PUT_API, { vehAssmntEventId });
    return this.restService._put<any>(url, auditData, {});
  }

  public getAuditVehicleList(distributorcd: string, params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_VEHICLES_MODEL_GET_API, { distributorcd });
    return this.restService._get<AuditVehicleListApiRespModel>(url, this.auditListVehicleRespMap, params);
  }

  private auditListVehicleRespMap(item: any): AuditVehicleListApiRespModel {
    return new AuditVehicleListApiRespModel(item);
  }

  public getVdcList(distributorcd: string, params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.VDCLIST_GET_API, { distributorcd });
    return this.restService._get<FacultyCodeListApiRespModel>(url, this.vdcListRespMap, params);
  }

  private vdcListRespMap(item: any): FacultyCodeListApiRespModel {
    return new FacultyCodeListApiRespModel(item);
  }

  public getAccessoriesList(distributorcd: string, params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.ACCESSORIESLIST_GET_API, { distributorcd });
    return this.restService._get<AccessoryListApiRespModel>(url, this.accessoryListRespMap, params);
  }

  private accessoryListRespMap(item: any): AccessoryListApiRespModel {
    return new AccessoryListApiRespModel(item);
  }
  
  public listAuditWaiver(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_AUDIT_WAIVER_API, {});
    return this.restService._get<ListAuditWaiverApiRespModel>(url, this.listAuditWaiverResMap, params);
  }
  private listAuditWaiverResMap(item: any): ListAuditWaiverApiRespModel {
    return new ListAuditWaiverApiRespModel(item);
  }
  public listWaiverHistory(auditId: number, params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_AUDIT_WAIVER_HISTORY_API, {auditId});
    return this.restService._get<ListWaiverHistoryApiRespModel>(url, this.listWaiverHistoryRespMap, params);
  }

  public listChronicHoldData(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_CHRONIC_HOLD_DATA_GET_API, {});
    return this.restService._get<ChronicHoldListApiRespModel>(url, this.listChronicHoldDataRespMap, params);
  }

  private listChronicHoldDataRespMap(item: any): ChronicHoldListApiRespModel {
    return new ChronicHoldListApiRespModel(item);
  }

  public listChronicHistData(chronicHoldId:number): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_CHRONIC_HIST_DATA_GET_API, {chronicHoldId}) ;
    return this.restService._get<ChronicHistListApiRespModel>(url, this.listChronicHistDataRespMap, {chronicHoldId});
  }

  private listChronicHistDataRespMap(item: any): ChronicHistListApiRespModel {
    return new ChronicHistListApiRespModel(item);
  }

  public updateMultiSelectChronic(multiChronicData: MultiChronicHoldData): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_MULTI_SELECT_CHRONIC_PUT_API, {});
    return this.restService._put<any>(url, multiChronicData, {});
  }

  public addShipmentHold(shipmentHoldRequestData: ShipmentHoldRequestData): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.ADD_SHIPMENT_HOLD_POST_API, {});
    return this.restService._post<any>(url, shipmentHoldRequestData, {},this.postAddShipmentRespMap);
  }
    
  private postAddShipmentRespMap(item: any):any{
    return item;
  }
  private listWaiverHistoryRespMap(item: any):ListWaiverHistoryApiRespModel {
    return new ListWaiverHistoryApiRespModel(item);
  }

  public updateAuditWaiverDetails(auditData: AuditWaiverData, auditId: number): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_AUDIT_WAIVER_DETAILS_API, { auditId });
    return this.restService._put<any>(url, auditData,{}, this.updateWaiverRespMap);
  }

  private updateWaiverRespMap(item: any):any {
    return item;
  }

  public addAuditWaiver(auditData): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VehicleService.ADD_AUDIT_WAIVER_POST_API;
    return this.restService._post<any>(url, auditData, {}, this.postAuditWaiverRespMap);
  }
 
  private postAuditWaiverRespMap(item: any):any{
    return item;
  }

  public getAllVehicleList(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.ALL_VEHICLES_GET_API, {});
    return this.restService._get<AuditVehicleListApiRespModel>(url, this.auditListVehicleRespMap, params);
  }

   public updateTlsHold(updateTlsRequestData: UpdateTlsRequestData, chronicHoldId: string): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_CHRONIC_HOLD_PUT_API, {chronicHoldId});
    return this.restService._put<any>(url, updateTlsRequestData, {}, this.updateTlsRespMap);
  }

  private updateTlsRespMap(item: any):any {
    return item;
  }

  public listDashboardData(distributorcd: string, params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_DASHBOARD_DATA_GET_API, {distributorcd});
    return this.restService._get<DashboardDataListApiRespModel>(url, this.listDashboardDataRespMap, params);
  }

  private listDashboardDataRespMap(item: any): DashboardDataListApiRespModel {
    return new DashboardDataListApiRespModel(item);
  }

  public listDashboardVdcData(params:object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_DASHBOARD_VDC_DATA_GET_API, {});
    return this.restService._get<DashboardDataListVdcApiRespModel>(url, this.listDashboardVdcDataRespMap, params);
  }

  private listDashboardVdcDataRespMap(item: any): DashboardDataListVdcApiRespModel {
    return new DashboardDataListVdcApiRespModel(item);
  }

  public listDashboardHistory(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.LIST_DASHBOARD_HISTORY_GET_API, {});
    return this.restService._get<listDashboardHistoryApiRespModel>(url, this.listDashboardHistoryRespMap, params);
  }

  private listDashboardHistoryRespMap(item: any): listDashboardHistoryApiRespModel {
    return new listDashboardHistoryApiRespModel(item);
  }
  public updateMultiSelectSmartChronic(multiSmartChronicData: SmartChronicHoldReqObj): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_MULTI_SELECT_SMART_CHRONIC_PUT_API, {});
    return this.restService._put<any>(url, multiSmartChronicData, {});
  }

  // public nppsDataPropagation(nppsData: NPPSPropagationData, vehAssmntEventId:number): Observable<Response<Object>> {
  //   this.restService.setApiBaseEndPoint(ApiType.VMA);
  //   const url = VMA_Util.replaceKeyValueWithString(VehicleService.UPDATE_NPPS_DATA_PUT_API, { vehAssmntEventId });
  //   return this.restService._put<any>(url, nppsData, {});
  // }

  public secondaryAuditVehicleList(params: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.GET_SECONDARY_AUDIT_LIST, params);
    return this.restService._get<SecondaryAuditListApiRespModel>(url, this.listSecondaryAuditsRespMap, params);
  }
  
  private listSecondaryAuditsRespMap(item: any): SecondaryAuditListApiRespModel {
    return new SecondaryAuditListApiRespModel(item);
  }

  public secondaryAuditService(vehAssmntEventId: any, param: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);
    const url = VMA_Util.replaceKeyValueWithString(VehicleService.SECONDARY_AUDIT_POST_API, { vehAssmntEventId });
    return this.restService._post<any>(url, {}, param);
  }
}
