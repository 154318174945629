import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { BarcodeScannerLivestreamOverlayComponent } from 'ngx-barcode-scanner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CountryOfOrigin, FinalAssemblyPoint } from 'src/app/core/model/ui/audit-vehicle.data.model';
import { IndexedDBKey, WorkflowStatus } from 'src/app/common/app.constants';
import { DbStores } from 'src/app/core/indexed-db/indexed-db.config';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuditData } from 'src/app/core/model/ui/audit-data.model';
import { Router } from '@angular/router';
import { NPPSPropagationData } from 'src/app/core/model/ui/audit-admin.data.model';

@Component({
  selector: 'vma-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss']
})
export class AddVehicleComponent implements OnInit {

  vin: string;
  vehAssmntEventId: string;
  isCollapsed: boolean = true;
  @Output() createEvent = new EventEmitter<any>();
  allowedBarCodeFormats = ['code_39'];
  displayAddVehicleConfirmPopup = false;
  auditData: AuditData;
  finalAssemblyPointList: FinalAssemblyPoint[] = [];
  countryOfOriginList: CountryOfOrigin[] = [];

  selectedRowForNPPSSync: NPPSPropagationData = new NPPSPropagationData ();

  @ViewChild(BarcodeScannerLivestreamOverlayComponent) barcodeScannerOverlay: BarcodeScannerLivestreamOverlayComponent;

  constructor(
    private readonly vehicleService: VehicleService,
    private readonly sharedService: SharedService,
    private readonly indexedDBService: NgxIndexedDBService,
    private readonly router: Router,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService
  ) { }
  ngOnInit(): void {
  }
  add(): void {
    if (this.vin) {
      this.displayAddVehicleConfirmPopup = true;
    }
  }
  clear(): void {
    this.resetVin();
  }
  onScanBarCode(): void {
  }
  onBarCodeScan() {
    this.barcodeScannerOverlay.show();
  }
  onBarCodeValueChanges(result: any) {
    const barcodeValue = result.codeResult.code;
    this.vin = barcodeValue;
    this.barcodeScannerOverlay.hide();
  }
  onToggleEvent() {
    this.isCollapsed = false;
  }
  resetVin(): void {
    this.vin = "";
  }

  onConfirmAddVehicle() {
    this.vehicleService.selectAuditVehicle(this.vin).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        const vehAssmntEventId = res.data[0].data.vehAssmtEventId;
        this.getFinalAssemblyPoints();
        this.getCountryOfOrigins();
        this.createAudit(vehAssmntEventId);
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  createAudit(vehAssmntEventId: number): void {
    this.vehicleService.addVehicle(vehAssmntEventId).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.getAuditData(vehAssmntEventId);
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  setNPPSData(rowData: any) {
    this.selectedRowForNPPSSync.modelYear = rowData.vehDocMaster.modelYear;
    this.selectedRowForNPPSSync.modelCode = rowData.vehDocMaster.modelCode;
    this.selectedRowForNPPSSync.vin = rowData.vehDocMaster.vin;
    if(!isNullOrUndefined(rowData.vehDocMaster.dummyVin)) {
      this.selectedRowForNPPSSync.dummyVin = rowData.vehDocMaster.dummyVin;
    } else {
      this.selectedRowForNPPSSync.dummyVin = rowData.vehDocMaster.vin;
    }
    this.selectedRowForNPPSSync.vdc = rowData.vehDocMaster.facilityCd;
    this.selectedRowForNPPSSync.action = 'in_progress_audit';
  }

  // nppsDataSync(data: NPPSPropagationData, vehAssmntEventId: number) {
  //   this.vehicleService.nppsDataPropagation(data, vehAssmntEventId).subscribe(res => {
  //     if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
  //       this.messageService.add({ severity: 'info', summary: '', detail: "Data synced with NPPS and NVS.", life: 5000  });
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     this.sharedService.handleHttpError(error);
  //   })
  // }

  getAuditData(vehAssmntEventId: number): void {
    this.vehicleService.getAudit(vehAssmntEventId).subscribe(res => {
      this.auditData = new AuditData();
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.auditData = res.data[0].auditData;
        this.addAuditDataToIndexedDB();
        this.setNPPSData(this.auditData);
        // this.nppsDataSync(this.selectedRowForNPPSSync, vehAssmntEventId);
        this.goToMyAuditInbox();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  goToMyAuditInbox(): void {
    this.router.navigate(['audit/inbox']);
  }

  confirmNavigateToInbox(): void {
    this.confirmationService.confirm({
      message: 'Do you want to Add/Scan another VIN or Go to Audit Inbox ?',
      accept: () => {
        this.goToMyAuditInbox();
      }, reject: () => {
        this.resetVin();
      }
    });
  }

  addAuditDataToIndexedDB(): void {
    this.auditData.vehAssmntEventWorkflow.workflowStatusCd = WorkflowStatus.READY_FOR_AUDIT;
    this.indexedDBService.add(DbStores.VEH_ASSMNT_EVENT, this.auditData).subscribe((result) => {
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }

  getFinalAssemblyPoints(): void {
    this.vehicleService.getFinalAssemblyPoints().subscribe(res => {
      this.finalAssemblyPointList = [];
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.finalAssemblyPointList = res.data[0].data.finalAssemblyPoint;
        this.addFinalAssemblyPointsToIndexedDB();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  addFinalAssemblyPointsToIndexedDB(): void {
    const params = {
      key: IndexedDBKey.FinalAssemblyPoints,
      value: this.finalAssemblyPointList
    }
    this.indexedDBService.update(DbStores.FINAL_ASSEMBLY_POINT, params).subscribe((result) => {
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }

  getCountryOfOrigins(): void {
    this.vehicleService.getCountryOfOrigins().subscribe(res => {
      this.countryOfOriginList = [];
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.countryOfOriginList = res.data[0].data.countryOfOrigin;
        this.addCountryOfOriginsToIndexedDB();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  addCountryOfOriginsToIndexedDB(): void {
    const params = {
      key: IndexedDBKey.CountryOfOrigins,
      value: this.countryOfOriginList
    }
    this.indexedDBService.update(DbStores.COUNTRY_OF_ORIGIN, params).subscribe((result) => {
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }
}
