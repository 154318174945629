import { AdditionalLabelEvidenceModel, BaseLabelEvidenceModel, DrcCommentModel, InputDataComparisonReportModel, LineByLineVerificationReportModel } from "./audit-report.model";

export class AuditVehicleInfoModel {
    vin: string;
    accessories: string;
    vdc: string
    modelYear: string;
    modelCode: string;
    vehAssmntEventId : number;
}

export class ExportExcelModel {
    auditVehicleInfoModel: AuditVehicleInfoModel;
    inputDataComparisonReportList: InputDataComparisonReportModel[];
    lineByLineVerificationReportList: LineByLineVerificationReportModel[];
    baseLabelEvidenceList: BaseLabelEvidenceModel[];
    additionalLabelEvidenceList: AdditionalLabelEvidenceModel[];
    drcComment: DrcCommentModel;
}
