import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as VMA_Util from '../../utilities/vma-common.util';
import { Response } from '../../rest-api/model/response';
import { RestService } from '../../rest-api/service/rest.service';
import { BrandApiResModel } from '../../model/api/brand-api.model';
import { YearApiResModel } from '../../model/api/year-api.model';
import { SeriesApiResModel } from '../../model/api/series-api.model';
import { DistModelCodeApiResModel, ModelCodeApiResModel } from '../../model/api/modelcode-api.model';
import { ApiType } from 'src/app/common/app.constants';

@Injectable({
  providedIn: 'root'
})
export class PcpsDistributorService {

  constructor(private readonly restService: RestService) { }

  static readonly LIST_BRANDS_GET_API: string = 'distributors/distributorid/brands';
  static readonly LIST_YEARS_GET_API: string = 'distributors/distributorid/yearno';
  static readonly LIST_SERIES_GET_API: string = 'distributors/distributorid/productgroups';
  static readonly LIST_MODELCODE_GET_API: string = 'distributors/distributorid/models';
  static readonly LIST_DIST_MODELCODE_API: string = 'distributors/distributorid/brands/brandid/modelyear/yearno/models';

  public listBrands(distributorid: number, parames: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.PCPS);
    const url = VMA_Util.replaceKeyValueWithString(PcpsDistributorService.LIST_BRANDS_GET_API, { distributorid });
    return this.restService._get<BrandApiResModel>(url, this._list_brand_map, parames);
  }
  private _list_brand_map(item: any): BrandApiResModel {
    return new BrandApiResModel(item);
  }

  public listYears(distributorid: number, parames: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.PCPS);
    const url = VMA_Util.replaceKeyValueWithString(PcpsDistributorService.LIST_YEARS_GET_API, { distributorid });
    return this.restService._get<YearApiResModel>(url, this._list_year_map, parames);
  }
  private _list_year_map(item: any): YearApiResModel {
    return new YearApiResModel(item);
  }

  public listSeries(distributorid: number, parames: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.PCPS);
    const url = VMA_Util.replaceKeyValueWithString(PcpsDistributorService.LIST_SERIES_GET_API, { distributorid });
    return this.restService._get<SeriesApiResModel>(url, this._list_series_map, parames);
  }
  private _list_series_map(item: any): SeriesApiResModel {
    return new SeriesApiResModel(item);
  }

  public listModelCodes(distributorid: number, parames: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.PCPS);
    const url = VMA_Util.replaceKeyValueWithString(PcpsDistributorService.LIST_MODELCODE_GET_API, { distributorid });
    return this.restService._get<ModelCodeApiResModel>(url, this._list_modelcode_map, parames);
  }

  private _list_modelcode_map(item: any): ModelCodeApiResModel {
    return new ModelCodeApiResModel(item);
  }

  public listMmyDistributorIdModelCodes(distributorid: number, brandid: number, yearno: number,
    parames: object): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.PCPS);
    const url = VMA_Util.replaceKeyValueWithString(PcpsDistributorService.LIST_DIST_MODELCODE_API,
      { distributorid, brandid, yearno });
    return this.restService._get<DistModelCodeApiResModel>(url, this._list_dist_modelcode_map, parames);
  }

  private _list_dist_modelcode_map(item: any): DistModelCodeApiResModel {
    return new DistModelCodeApiResModel(item);
  }
}
