import { DashboardData, DashboardDataList, SeriesData, Accessory, VdcList, DashboardHistoryList, DashboardHistoryDataDetails  } from "../ui/dashboard-data.model";
import * as VMA_Util from '../../utilities/vma-common.util';


export class DashboardDataListApiModel extends DashboardDataList { }

export class DashboardDataListApiRespModel {
    dashboardDataObj: DashboardDataListApiModel = new DashboardDataListApiModel();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.dashboardData)) {
                this.dashboardDataObj.limit = options.data.limit;
                this.dashboardDataObj.offset = options.data.offset;
                this.dashboardDataObj.total = options.data.total;
                options.data.dashboardData.forEach((element) => {
                    this.dashboardDataObj.dashboardData.push(this.getRowData(element));
                });
            }
        }
    }

    getRowData(data: any): DashboardData {
        const dashboardDataResp: DashboardData = new DashboardData();
        dashboardDataResp.seriesCd = data.seriesCd;
        data.data.forEach(element => {
             dashboardDataResp.data.push(this.transformSeriesData(element))
        });
        return dashboardDataResp;
    }

    transformSeriesData(seriesdata: any):SeriesData {
        const dashboardDataSeriesResp: SeriesData = new SeriesData();
        dashboardDataSeriesResp.modelCd = seriesdata.modelCd;
        dashboardDataSeriesResp.modelYear = seriesdata.modelYear;
        seriesdata.accessories.forEach(element => {
            dashboardDataSeriesResp.accessories.push(this.transformAccesoriesData(element))

        });
        return dashboardDataSeriesResp;
    }

    transformAccesoriesData(accessoryData: any): Accessory {
        const dashboardDataResp: Accessory = new Accessory();
        dashboardDataResp.accessory = accessoryData.accessory;
        dashboardDataResp.configId = accessoryData.configId;
        dashboardDataResp.status = accessoryData.status;
        dashboardDataResp.vdcCodes = accessoryData.vdcCodes;
        return dashboardDataResp;

    }
}

export class listDashboardHistoryApiModel extends DashboardHistoryList { }

export class listDashboardHistoryApiRespModel {
    dashboardHistory: listDashboardHistoryApiModel = new listDashboardHistoryApiModel();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.dashboardHistoryList)) {            
                options.data.dashboardHistoryList.forEach((element: any) => {                    
                    this.dashboardHistory.HistoryDataList.push(new DashboardHistoryDataDetails(element));
                });
            }
        }
    }
}