
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHttpService } from './http.service';
import { HttpBaseService } from './http.base.service';
import { Response } from '../model/response';

export type IMap<T> = (item: any) => T;

@Injectable()
export class HttpServiceImpl implements IHttpService {

  constructor(
    public http: HttpBaseService
  ) { }

  public get port() {
    return this.http.port;
  }
  public set port(value: string) {
    this.http.port = value;
  }
  public get server() {
    return this.http.server;
  }
  public set server(value: string) {
    this.http.server = value;
  }
  public get secure() {
    return this.http.secure;
  }
  public set secure(value: boolean) {
    this.http.secure = value;
  }
  public get prefix() {
    return this.http.prefix;
  }
  public set prefix(value: string) {
    this.http.prefix = value;
  }


  public get<T>(endpoint: string, cb?: IMap<T>, params?: object): Observable<Response<T>> {
    return this.http.get(endpoint, params).pipe(
      map(resp => new Response<T>(resp, cb))
    );
  }
  public put<T>(endpoint: string, model: any, cb?: IMap<T>, params?: object): Observable<Response<T>> {
    return this.http.put(endpoint, model, params).pipe(
      map(resp => new Response<T>(resp, cb))
    );
  }

  public post<T>(endpoint: string, model: any, cb?: IMap<T>, params?: object): Observable<Response<T>> {
    return this.http.post(endpoint, model, params).pipe(
      map(resp => new Response<T>(resp, cb))
    );
  }

  public delete<T>(endpoint: string, params?: object, cb?: IMap<T>): Observable<Response<T>> {
    return this.http.delete(endpoint, params).pipe(
      map(resp => new Response<T>(resp, cb))
    );
  }
}
