
<div *ngIf="errorArray.length > 0 || externalFailedArray.length > 0 || tooManyRequestsArray.length > 0" >
    <p *ngFor ="let item of errorArray" class="cmn-input-font-style status-ReleaseFailed">Accessory not found in the database for{{item}}</p>
    <p *ngFor ="let item of externalFailedArray" class="cmn-input-font-style status-ReleaseFailed">{{item}}</p>
    <p *ngFor ="let item of tooManyRequestsArray" class="cmn-input-font-style status-ReleaseFailed">Unable to connect with NPPS for {{item}}</p>
</div>
<div *ngIf="alreadyExistArray.length > 0" >
    <p *ngFor ="let item of alreadyExistArray" class="info-style status-ReleaseSuccess">{{item}}</p>
</div>
<button *ngIf = "globalFailedAccFlag" type="button" label="Retry All" (click)="setGlobalRetryData()" pButton pRipple icon="pi pi-replay"
          style="margin: 0.150rem;" class="p-button-secondary"></button>
          <br>
<p-table [value] = "groupedData" styleClass="p-datatable-sm" selectionMode="single" [paginator]="false" [rows]="20"
[showCurrentPageReport]="true">
    <ng-template pTemplate="header" >
        <tr>
            <th class="cmn-table-header-style" style="width: 80px" scope="col">VDC</th>
            <th class="cmn-table-header-style" style="width: 80px" scope="col">Model</th>
            <th class="cmn-table-header-style" style="width: 150px" scope="col">Factory Accy</th>
            <th class="cmn-table-header-style" style="width: 60px; padding-left: 50px" scope="col">VIN</th>
            <th class="cmn-table-header-style" style="width: 80px;" scope="col">Retry</th>
            <th class="cmn-table-header-style" style="width: 125px;" scope="col">Status</th>
            <th  [ngClass] = "{'hide-column': hideColumn}"  class="cmn-table-header-style" style="width: 150px" scope="col">Progress</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-group let-rowIndex = "rowIndex">
        <tr>
            <td class="cmn-table-data-style">{{group.vdc}}</td>
            <td class="cmn-table-data-style">{{group.modelCode}}</td>
            <td> 
                <ng-container >
                    <div *ngIf = "succeededAccessories.length === 0 && failedAccessories.length > 0"> 
                        <span *ngFor="let accessory of group.accessoryArray" [ngClass]= "accessory.errorInd?'failed-accessory':''"> {{accessory.code}} </span>
                    </div>
                    <div *ngIf="succeededAccessories.length > 0 || releasedAccessories.length > 0">   
                        <span *ngFor="let accessory of group.accessoryArray" [ngClass]= "accessory.errorInd?'released-accessory':'succeeded-accessory'"> {{accessory.code}} </span>
                    </div>
                    <div *ngIf="succeededAccessories.length === 0 && failedAccessories.length === 0 && releasedAccessories.length === 0">   
                        <span *ngFor="let accessory of group.accessoryArray" [ngClass]= "'released-accessory'"> {{accessory.code}} </span>
                    </div>
                    
                </ng-container>
            </td>
            <td> 
                <ng-container> {{group.vin}}
                </ng-container>
            </td>
            <td class="cmn-table-data-style" > 
                
                <ng-container>
                    <span *ngIf = "group.errStatus && failedAccessories.length != 0">
                        <i class="rowData-icon pi pi-replay" (click)="retry(group)"> </i> 
                    </span>                    
                </ng-container>
            </td>
            <td class="cmn-table-data-style" > {{group.apiStatus}}
            </td>
            <td [ngClass]="{'hide-column': hideColumn}" class="cmn-table-data-style">
            
                <ng-container> <span *ngIf = "(group.loadingStatus && group.loadingPercentage!=0)">
                        <p-progressBar [value]="group.loadingPercentage" [style]="{width: '100%'}">
                        </p-progressBar>
                    </span></ng-container>
            </td>
        </tr>
        
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="groupedData.length" class="cmn-table-data-style">
                No records found
            </td>
        </tr>
    </ng-template>
</p-table>