import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpMethod } from 'src/app/common/app.constants';

@Injectable({
  providedIn: 'root'
})
export class FileUploadToS3Service {

  MultiPartFormData = "multipart/form-data";
  _EmptyString = "";

  constructor(private readonly http: HttpClient) {
  }

  public uploadFileToS3(s3PresignedUrl: string, file: File): Observable<HttpEvent<any>> {
    const req = new HttpRequest(HttpMethod.PUT, s3PresignedUrl, file, {
      headers: this.setUploadHeaders(),
      reportProgress: true,
      responseType: "json"
    });
    return this.http.request(req);
  }
  setUploadHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": this.MultiPartFormData,
      "ngsw-bypass": this._EmptyString
    })
  }
}
