import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VdcViewComponent } from './vdc-view.component';
import { MultiSelectBrandModule } from '../../dropdowns/multi-select-brand/multi-select-brand.module';
import { ModelyearDashboardModule } from '../../dropdowns/modelyear-dashboard/modelyear-dashboard.module';
import { SeriesModule } from '../../dropdowns/series/series.module';
import { MultiSelectSeriesModule } from '../../dropdowns/multi-select-series/multi-select-series.module';
import { ButtonModule } from 'primeng/button';
import { DashboardVdcModule } from '../../dropdowns/vdc/dashboard-vdc/dashboard-vdc.module';
import { DashboardMultiSelectModelcodeModule } from '../../dropdowns/modelcode/dashboard-multi-select-modelcode/dashboard-multi-select-modelcode.module';
import { AccessoriesDashboardModule } from '../../dropdowns/accessories/accessories-dashboard/accessories-dashboard.module';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ArrivalDateModule } from '../../checkbox/arrival-date/arrival-date.module';

@NgModule({
  declarations: [
    VdcViewComponent
  ],
  imports: [
    CommonModule,
    MultiSelectBrandModule,
    ModelyearDashboardModule,
    SeriesModule,
    MultiSelectSeriesModule,
    DashboardVdcModule,
    ArrivalDateModule,
    DashboardMultiSelectModelcodeModule,
    AccessoriesDashboardModule,
    ButtonModule,
    CheckboxModule,
    FormsModule
  ],
  exports: [
    VdcViewComponent
  ]
})
export class VdcViewModule { }
