import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EvidenceDownloadRoutingModule } from './evidence-download-routing.module';
import { EvidenceDownloadComponent } from './evidence-download.component';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { TableModule } from 'primeng/table';


@NgModule({
  declarations: [
    EvidenceDownloadComponent
  ],
  imports: [
    CommonModule,
    EvidenceDownloadRoutingModule,
    TableModule,
    ButtonModule,
    ImageModule,
  ], exports: [
    EvidenceDownloadComponent
  ]
})
export class EvidenceDownloadModule { }
