import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IAuthenticatedUserProfile, IAuthorizedUser } from '../model/ui/user.model';
import { isNullOrUndefined } from '../utilities/vma-common.util';
import { LocalStorageProperty, SessionStorageProperty } from 'src/app/common/app.constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private readonly _destroying$ = new Subject<void>();
  offlineResources = ["inbox", "input"];
  constructor(
    private readonly msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly router: Router
  ) {
    this.handleRedirectResp();
  }

  handleRedirectResp() {
    return this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        this.setAccessToken(payload.idToken);
        this.setAuthenticatedUserProfile(this.getAccessToken());
      });
  }
  isUserLoggedIn() {
    let status = false;
    if (localStorage.getItem(LocalStorageProperty.VMATok)) {
      status = true;
    }
    return status;
  }
  setAuthenticatedUserProfile(token: any) {
    if (!isNullOrUndefined(token)) {
      const _token = token.split('.')[1];
      const resp = JSON.parse(atob(_token));
      const userMdl: IAuthenticatedUserProfile = {
        name: resp['name'],
        email: resp['preferred_username'],
        roles: resp['roles'],
        expiryTime: resp['exp'],
        workdayId: resp['workdayId']
      }
      localStorage.setItem(LocalStorageProperty.VMAAuthenticatedUserProfile, JSON.stringify(userMdl));
    }
  }
  getAuthenticatedUserProfile(): IAuthenticatedUserProfile {
    let authenticatedUserProfile: any;
    const data = localStorage.getItem(LocalStorageProperty.VMAAuthenticatedUserProfile);
    if (data != null) {
      authenticatedUserProfile = JSON.parse(data);
    }
    return authenticatedUserProfile;
  }
  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }
  setAccessToken(token: string) {
    localStorage.setItem(LocalStorageProperty.VMATok, token);
  }
  getAccessToken() {
    return localStorage.getItem(LocalStorageProperty.VMATok) || "";
  }
  getEvidenceUrl() {
    return sessionStorage.getItem(SessionStorageProperty.VMASavedEvidenceUrl);
  }
  setEvidenceUrl(requestUrl: any) {
    return sessionStorage.setItem(SessionStorageProperty.VMASavedEvidenceUrl, requestUrl);
  }
  removeEvidenceUrl() {
    return sessionStorage.removeItem(SessionStorageProperty.VMASavedEvidenceUrl);
  }
  logout() {
    localStorage.removeItem(LocalStorageProperty.VMATok);
    localStorage.removeItem(LocalStorageProperty.VMAAuthenticatedUserProfile);
    localStorage.removeItem(LocalStorageProperty.VMAAuthorizedUserProfile);
    this.msalService.logout();
  }
  getRoleFromToken(token: string) {
    const _token = token.split('.')[1];
    const resp = JSON.parse(atob(_token))
    return resp.roles;
  }
  isAuthorized(): boolean {
    let status = false;
    if (localStorage.getItem(LocalStorageProperty.VMAAuthorizedUserProfile)) {
      status = true;
    }
    return status;
  }
  setAuthorizationModel(dbResp: string): void {
    localStorage.setItem(LocalStorageProperty.VMAAuthorizedUserProfile, dbResp);
  }
  getLoggedInUserRole(): string {
    if (!isNullOrUndefined(this.getAuthorizedUserProfile())) {
      return this.getAuthorizedUserProfile().role.name.split('.')[2];
    }
    return "";
  }
  getAuthorizedUserProfile(): IAuthorizedUser {
    let authorizedUserProfile: any;
    const data = localStorage.getItem(LocalStorageProperty.VMAAuthorizedUserProfile);
    if (data != null) {
      authorizedUserProfile = JSON.parse(data);
    }
    return authorizedUserProfile;
  }
  navigateToUnauthorizedView() {
    this.router.navigate(['unauthorized']);
  }
  isOfflineResources(currentRoutePath: any): boolean {
    let status = false;
    if (this.offlineResources.includes(currentRoutePath)) {
      status = true;
    }
    return status;
  }
  isEvidenceAccessRequest(currentRoutePath: any): boolean {
    if (currentRoutePath === "evidence") {
      return true;
    }
    return false;
  }
  navigateToMyInbox() {
    this.router.navigate(['audit/inbox']);
  }
  navigateToEvidenceView(evidenceurl: string) {
    this.router.navigateByUrl(evidenceurl);
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  refreshAccessToken(): void {
    const silentTokenRequest: any = {
      forceRefresh: true,
      scopes: ["user.read"]
    };

    this.msalService.acquireTokenSilent(silentTokenRequest).subscribe((res: AuthenticationResult) => {
      this.setAccessToken(res.idToken);
      this.setAuthenticatedUserProfile(this.getAccessToken());
    }, (error) => {
      console.log("acquireTokenSilent error", error);
      if (error.name === "InteractionRequiredAuthError") {
        this.msalService.acquireTokenPopup(silentTokenRequest).subscribe((res: AuthenticationResult) => {
          this.setAccessToken(res.idToken);
          this.setAuthenticatedUserProfile(this.getAccessToken());
        }, (error) => {
          console.log("acquireTokenPopup error", error);
        })
      }
    })
  }
}
