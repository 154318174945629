import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TlsHoldSelectVdcComponent } from './tls-hold-select-vdc.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    TlsHoldSelectVdcComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule
  ], exports: [
    TlsHoldSelectVdcComponent
  ]
})
export class TlsHoldSelectVdcModule { }
