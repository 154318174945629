import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { S3PreSignedUrlOperation, ValidationStatusCdUI, VehAssmntEventScopeItemAPI, VehAssmntEventScopeTypeAPI, WorkflowStatus } from 'src/app/common/app.constants';
import { AuditData, VehAssmntData, VehAssmntDataEvidence, VehAssmntEventPlan, VehAssmntPlanEvidence } from 'src/app/core/model/ui/audit-data.model';
import { AdditionalLabelEvidenceModel, BaseLabelEvidenceModel, DrcCommentModel, EvidenceDtlModel, InputDataComparisonReportModel, LineByLineVerificationReportModel, PDSingleImageModel } from 'src/app/core/model/ui/audit-report.model';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { transformScopeItemForUI, transformScopeTypeForUI, transformValidationStatusCdForUI } from 'src/app/core/utilities/data-transform.util';
import { isEmpty, isNullOrUndefined, isUndefined } from 'src/app/core/utilities/vma-common.util';
import * as fs from 'file-saver';
import { ExportExcelService } from 'src/app/core/services/ui/export-excel.service';
import { AuditVehicleInfoModel, ExportExcelModel } from 'src/app/core/model/ui/export-excel.model';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NPPSPropagationData } from 'src/app/core/model/ui/audit-admin.data.model';

@Component({
  selector: 'vma-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnInit {
  vehAssmntEventId: number;
  auditData: AuditData;
  inputDataComparisonReportList: InputDataComparisonReportModel[] = [];
  lineByLineVerificationReportList: LineByLineVerificationReportModel[] = [];
  baseLabelEvidenceList: BaseLabelEvidenceModel[] = [];
  additionalLabelEvidenceList: AdditionalLabelEvidenceModel[] = [];
  pdImageList: PDSingleImageModel[];
  pdImageFlag: boolean = false;
  anyMismatch = true;
  displayMismatchPopup: boolean;
  displayCommentPopup: boolean;
  displayAttachmentsPopup: boolean;
  comparisonCommentModel: InputDataComparisonReportModel;
  currentFiles: Array<File>;

  globalCommentData: DrcCommentModel = new DrcCommentModel();

  displayGlobalCommentPopup: boolean;
  viewGlobalCommentPopup: boolean;
  tempGlobalComment: any;
  editingGlobalComment: boolean;
  existingGlobalComment: any;
  displayDeleteConfirmPopup: boolean;

  auditReportFlag: boolean = this.sharedService.getAuditReportFlag();
  selectedRowForNVSSync: NPPSPropagationData = new NPPSPropagationData ();

  displayGallery: boolean;
  images: any[] = [];
  auditVehicleInfo: AuditVehicleInfoModel;
  title: null;
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  constructor(
    private sharedService: SharedService,
    private vehicleService: VehicleService,
    private exportExcelService: ExportExcelService,
    private router: Router,
    private messageService: MessageService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.auditVehicleInfo = new AuditVehicleInfoModel();
    this.vehAssmntEventId = this.sharedService.getVehAssmntEventId();
    if (!isUndefined(this.vehAssmntEventId)) {
      this.getAuditReportData();
    } else {
      this.navigateToSubmittedTab();
    }
  }
  getAuditReportData(): void {
    this.vehicleService.getAudit(this.vehAssmntEventId).subscribe(async res => {
      this.auditData = new AuditData();
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.auditData = res.data[0].auditData;
        this.setGlobalComment(res.data[0].auditData.vehAssmntEventWorkflow);
        this.createAuditReport();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  setGlobalComment(workflowData: any) {
    this.globalCommentData = new DrcCommentModel();
    this.globalCommentData.comment = workflowData.actorComments;
    this.globalCommentData.userName = workflowData.actorNm;
    this.globalCommentData.updatedDate = workflowData.updatedTs;
  }


  createAuditReport(): void {
    this.setAuditVehicleInformation();
    this.createInputValueComparsionReport();
    this.removeAuditData();
    this.enablePdSingleImageFlag();
    this.createLineByLineVerificationReport();
    this.createBaseLabelEvidenceList();
    this.createAdditionalLabelEvidenceList();
  }
  setAuditVehicleInformation() {
    this.auditVehicleInfo = new AuditVehicleInfoModel();
    this.auditVehicleInfo.vin = this.auditData.vehDocMaster.vin;
    this.auditVehicleInfo.accessories = this.auditData.vehDocMaster.accessories;
    this.auditVehicleInfo.vdc = this.auditData.vehDocMaster.facilityCd;
    this.auditVehicleInfo.modelYear = this.auditData.vehDocMaster.modelYear;
    this.auditVehicleInfo.modelCode = this.auditData.vehDocMaster.modelCode;
    this.auditVehicleInfo.vehAssmntEventId = this.auditData.vehAssmntEventId;
  }
  exportExcel() {
    const excelData = new ExportExcelModel();
    excelData.auditVehicleInfoModel = this.auditVehicleInfo;
    excelData.inputDataComparisonReportList = this.inputDataComparisonReportList;
    excelData.lineByLineVerificationReportList = this.lineByLineVerificationReportList;
    excelData.baseLabelEvidenceList = this.baseLabelEvidenceList;
    excelData.additionalLabelEvidenceList = this.additionalLabelEvidenceList;
    excelData.drcComment = this.globalCommentData;
    this.exportExcelService.generateAuditReportExcel(excelData);
  }

  createInputValueComparsionReport(): void {
    this.inputDataComparisonReportList = [];
    let status = false;
    this.auditData.vehAssmntEventPlan.forEach((element: VehAssmntEventPlan) => {
      if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.MONRONEY_LABEL) {
        status = this.checkVehScopeItems(element.vehAssmntScopeTypeCd);
        this.createMonroneyInputValueComparsionReport(element, status);
      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.AALA_LABEL) {
        status = this.checkVehScopeItems(element.vehAssmntScopeTypeCd);
        this.createAalaInputValueComparsionReport(element, status);
      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.CR_LABEL) {
        status = this.checkVehScopeItems(element.vehAssmntScopeTypeCd);
        this.createCrInputValueComparsionReport(element, status);
      }
    });
  }
  createMonroneyInputValueComparsionReport(assessPlan: VehAssmntEventPlan, scopeStatus: boolean): void {
    assessPlan.vehAssmntData.forEach((assessData: VehAssmntData) => {
      if (assessData.vehAssmntScopeItemCd == VehAssmntEventScopeItemAPI.MONRONEY_LABEL_VERSION) {
        this.setInputValueComparisonReportData(assessPlan, assessData, scopeStatus);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.FINAL_ASSEMBLY_POINT) {
        this.setInputValueComparisonReportData(assessPlan, assessData, scopeStatus);
      }
    });
  }
  createAalaInputValueComparsionReport(assessPlan: VehAssmntEventPlan, status: boolean): void {
    assessPlan.vehAssmntData.forEach((assessData: VehAssmntData) => {
      if (assessData.vehAssmntScopeItemCd == VehAssmntEventScopeItemAPI.US_CAN_PRT_CNTNT) {
        this.setInputValueComparisonReportData(assessPlan, assessData, status);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.FINAL_ASSEMBLY_POINT) {
        this.setInputValueComparisonReportData(assessPlan, assessData, status);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_1) {
        this.setInputValueComparisonReportData(assessPlan, assessData, status);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_1) {
        this.setInputValueComparisonReportData(assessPlan, assessData, status);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_CNTRY_2) {
        this.setInputValueComparisonReportData(assessPlan, assessData, status);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MJR_SRC_FOR_PRT_CNTNT_PERCENT_2) {
        this.setInputValueComparisonReportData(assessPlan, assessData, status);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.CNTRY_ORIGIN_ENG_PRT) {
        this.setInputValueComparisonReportData(assessPlan, assessData, status);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.CNTRY_ORIGIN_TRANS_PRT) {
        this.setInputValueComparisonReportData(assessPlan, assessData, status);
      }
    });
  }
  createCrInputValueComparsionReport(assessPlan: VehAssmntEventPlan, status: boolean): void {
    assessPlan.vehAssmntData.forEach((assessData: VehAssmntData) => {
      if (assessData.vehAssmntScopeItemCd == VehAssmntEventScopeItemAPI.GVWR) {
        this.setInputValueComparisonReportData(assessPlan, assessData, status);
      }
    });
  }
  setInputValueComparisonReportData(assessPlan: VehAssmntEventPlan, assessData: VehAssmntData, status: boolean): void {
    const inputDataComparisonReportModel = new InputDataComparisonReportModel();
    inputDataComparisonReportModel.scopeType = transformScopeTypeForUI(assessPlan.vehAssmntScopeTypeCd);
    inputDataComparisonReportModel.scopeItem = transformScopeItemForUI(assessData.vehAssmntScopeItemCd);
    inputDataComparisonReportModel.inputValue = status ? '' : assessData.vehAssmntActualData;
    inputDataComparisonReportModel.nvsValue = status ? '' : assessData.nvsDataReference;
    inputDataComparisonReportModel.validationStatus = this.determineValidationStatus(assessData.vehAssmntActualData, assessData.nvsDataReference, status);
    inputDataComparisonReportModel.comment = null;
    inputDataComparisonReportModel.evidences = [];
    this.inputDataComparisonReportList.push(inputDataComparisonReportModel);
  }
  determineValidationStatus(inputValue: any, nvsValue: any, status: boolean): ValidationStatusCdUI {
    let statusCd = ValidationStatusCdUI.Mismatch;
    if (status) {
      statusCd = ValidationStatusCdUI.Waived;
    } else {
      if (inputValue == nvsValue) {
        statusCd = ValidationStatusCdUI.Match;
      }
    }
    return statusCd;
  }
  createLineByLineVerificationReport(): void {
    this.lineByLineVerificationReportList = [];
    this.auditData.vehAssmntEventPlan.forEach((element: VehAssmntEventPlan) => {
      if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.MONRONEY_LABEL) {
        this.createMonroneyLineByLineVerificationReport(element);
      } else if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.PTAG_1) {
        this.createPtag1LineByLineVerificationReport(element);
      }
    });
  }
  createMonroneyLineByLineVerificationReport(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntData.forEach((assessData: VehAssmntData) => {
      if (assessData.vehAssmntScopeItemCd == VehAssmntEventScopeItemAPI.STANDARD_EQUIP) {
        this.setLineByLineVerificationReportData(assessPlan, assessData);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.OPTIONAL_EQUIP) {
        this.setLineByLineVerificationReportData(assessPlan, assessData);
      } else if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.COLOR) {
        this.setLineByLineVerificationReportData(assessPlan, assessData);
      }
    });
  }
  createPtag1LineByLineVerificationReport(assessPlan: VehAssmntEventPlan) {
    assessPlan.vehAssmntData.forEach((assessData: VehAssmntData) => {
      if (assessData.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.MODEL_FSA) {
        this.setLineByLineVerificationReportData(assessPlan, assessData);
      }
    });
  }
  setLineByLineVerificationReportData(assessPlan: VehAssmntEventPlan, assessData: VehAssmntData): void {
    const lineByLineVerificationReportModel = new LineByLineVerificationReportModel();
    lineByLineVerificationReportModel.scopeType = transformScopeTypeForUI(assessPlan.vehAssmntScopeTypeCd);
    lineByLineVerificationReportModel.scopeItem = transformScopeItemForUI(assessData.vehAssmntScopeItemCd);
    lineByLineVerificationReportModel.nvsValue = assessData.nvsDataReference;
    lineByLineVerificationReportModel.validationStatus = transformValidationStatusCdForUI(assessData.vehAssmntValidationStatusCd);
    lineByLineVerificationReportModel.comment = assessData.vehAssmntDataComment;
    lineByLineVerificationReportModel.evidence = new Array<EvidenceDtlModel>();
    assessData.vehAssmntDataEvidence.forEach((assessEvid: VehAssmntDataEvidence) => {
      this.getS3PresignedUrlForLineByLineReport(assessEvid.vehAssmntDataEvidencePath, lineByLineVerificationReportModel.evidence);
    });
    this.lineByLineVerificationReportList.push(lineByLineVerificationReportModel);
  }
  createBaseLabelEvidenceList(): void {
    this.baseLabelEvidenceList = [];
    for (const element of this.auditData.vehAssmntEventPlan) {
      if (element.vehAssmntScopeTypeCd !== VehAssmntEventScopeTypeAPI.ADDITIONAL_EVID) {
        this.setBaseLabelEvidenceData(element);
      }
    }
  }
  createAdditionalLabelEvidenceList(): void {
    this.additionalLabelEvidenceList = [];
    for (const element of this.auditData.vehAssmntEventPlan) {
      if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.ADDITIONAL_EVID) {
        this.setAdditionalLabelEvidenceData(element);
      }
    }
  }

  enablePdSingleImageFlag(): void {
    this.pdImageList = [];
    for (const element of this.auditData.vehAssmntEventPlan) {
      if (element.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.MONRONEY_LABEL) {
        for (const label of element.vehAssmntData) {
          if (label.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.PD_IMAGE) {
            this.pdImageFlag = true;
            //this.setPdSingleImageData(label);
          }
        }
      }
    }
  }

  setAdditionalLabelEvidenceData(assessPlan: VehAssmntEventPlan): void {
    assessPlan.vehAssmntPlanEvidence.forEach((assessPlanEvid: VehAssmntPlanEvidence) => {
      const additionalLabelEvidenceData = new AdditionalLabelEvidenceModel();
      additionalLabelEvidenceData.scopeType = transformScopeTypeForUI(assessPlan.vehAssmntScopeTypeCd);
      this.getS3PresignedUrlForBaseEvidence(assessPlanEvid.vehAssmntPlanEvidencePath, additionalLabelEvidenceData);
      additionalLabelEvidenceData.comment = assessPlanEvid.vehAssmntPlanEvidenceComment;
      this.additionalLabelEvidenceList.push(additionalLabelEvidenceData);
    });
  }
  setBaseLabelEvidenceData(assessPlan: VehAssmntEventPlan): void {
    const baseLabelEvidenceData = new BaseLabelEvidenceModel();
    baseLabelEvidenceData.scopeType = transformScopeTypeForUI(assessPlan.vehAssmntScopeTypeCd);
    if(assessPlan.vehAssmntScopeTypeCd === VehAssmntEventScopeTypeAPI.MONRONEY_LABEL) {
      if(this.pdImageFlag) {
        for (const label of assessPlan.vehAssmntData) {
          if (label.vehAssmntScopeItemCd === VehAssmntEventScopeItemAPI.PD_IMAGE) {
            this.getS3PresignedUrlForBaseEvidence(label.nvsDataReference, baseLabelEvidenceData);
          }
        }
      } else {
        assessPlan.vehAssmntPlanEvidence.forEach((assessPlanEvid: VehAssmntPlanEvidence) => {
          this.getS3PresignedUrlForBaseEvidence(assessPlanEvid.vehAssmntPlanEvidencePath, baseLabelEvidenceData);
        });
      }
    } else {
      assessPlan.vehAssmntPlanEvidence.forEach((assessPlanEvid: VehAssmntPlanEvidence) => {
        this.getS3PresignedUrlForBaseEvidence(assessPlanEvid.vehAssmntPlanEvidencePath, baseLabelEvidenceData);
      });
    }
    this.baseLabelEvidenceList.push(baseLabelEvidenceData);
  }

  getS3PresignedUrlForBaseEvidence(evidencePath: string, baseLabelEvidenceData: BaseLabelEvidenceModel): void {
    const params = {
      objectKey: evidencePath,
      action: S3PreSignedUrlOperation.Download
    }
    this.vehicleService.getS3PresignedUrl(params).subscribe(res => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        baseLabelEvidenceData.evidence = { imgUrl: res.data[0].data, key: evidencePath };
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }
  getS3PresignedUrlForLineByLineReport(evidencePath: string, evidenceDtl: Array<EvidenceDtlModel>): void {
    const params = {
      objectKey: evidencePath,
      action: S3PreSignedUrlOperation.Download
    }
    this.vehicleService.getS3PresignedUrl(params).subscribe(async res => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        evidenceDtl.push({ imgUrl: res.data[0].data, key: evidencePath });
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  previewImages(evidence: Array<EvidenceDtlModel>): void {
    this.images = [];
    this.displayGallery = true;
    this.images = evidence;
  }
  downloadEvidence(evidenceList: Array<EvidenceDtlModel>): void {
    evidenceList.forEach((element: EvidenceDtlModel) => {
      this.downloadS3SignedUrl(element.key);
    });
  }
  downloadS3SignedUrl(evidenceKey: string): void {
    const params = {
      objectKey: evidenceKey,
      action: S3PreSignedUrlOperation.Download
    }
    this.vehicleService.getS3PresignedUrl(params).subscribe(res => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        fs.saveAs(res.data[0].data);
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }
  getFileNameForDownload(evidenceKey: string): string {
    const temparray = evidenceKey.split("/");
    let fileName = "";
    temparray.forEach(element => {
      fileName = fileName + element + "-";
    });
    fileName = fileName.slice(0, -1)
    return fileName;
  }

  back() {
    if (this.auditReportFlag) {
      this.navigateToApprovedTab();
    } else if(this.sharedService.isNavigatedFromAuditedVehicle) {
      this.navigateToAuditedVehicle();
    } else if(this.sharedService.isNavigatedFromDashboard){
      this.navigateToDashboardHistory();
    } 
    else {
      this.navigateToSubmittedTab();
    }
  }

  navigateToSubmittedTab() {
    this.sharedService.setVehicleSelectionTabIndex(2);
    this.router.navigate(['audit/vehicle']);
  }
  navigateToApprovedTab() {
    if (this.sharedService.getDrcApprovalTabIndex() == 1) {
      this.sharedService.setDrcApprovalTabIndex(1);
    }
    else if (this.sharedService.getDrcApprovalTabIndex() == 0) {
      this.sharedService.setDrcApprovalTabIndex(0);
    }
    this.router.navigate(['audit/drc']);
  }

  navigateToAuditedVehicle() {
    this.sharedService.setAuditedVehicleFlag(false);
    this.router.navigate(['audit/auditedvehicles']);
  }

  navigateToDashboardHistory() {
    this.sharedService.setDashboardFlag(false);
    this.router.navigate(['audit/historyview']);
  }

  onMismatch(rowData: InputDataComparisonReportModel) {
    if (rowData.validationStatus === ValidationStatusCdUI.Mismatch) {
      rowData = this.setNotVerified(rowData);
      this.inputDataComparisonReportList[this.findIndexByData(rowData.scopeItem, this.inputDataComparisonReportList)] = rowData;
    } else {
      this.comparisonCommentModel = { ...rowData };
      this.comparisonCommentModel.comment = null;
      this.displayMismatchPopup = true;
    }
  }

  setNotVerified(rowdata: InputDataComparisonReportModel): InputDataComparisonReportModel {
    rowdata.validationStatus = ValidationStatusCdUI.Match;
    rowdata.comment = null;
    rowdata.evidences = [];
    return rowdata;
  }

  onConfirmMismatch() {
    if (this.comparisonCommentModel.comment) {
      this.comparisonCommentModel.validationStatus = ValidationStatusCdUI.Mismatch;
      this.inputDataComparisonReportList[this.findIndexByData(this.comparisonCommentModel.scopeItem, this.inputDataComparisonReportList)] = this.comparisonCommentModel;
      this.displayMismatchPopup = false;
    }
  }

  onConfirmNotMismatch() {
    this.displayMismatchPopup = false;
  }

  onAttachFiles(event: any) {
    this.comparisonCommentModel.evidences = event.currentFiles;
  }

  viewComment(rowData: InputDataComparisonReportModel) {
    this.comparisonCommentModel = { ...rowData };
    this.displayCommentPopup = true;
  }

  viewAttachments(rowData: InputDataComparisonReportModel) {
    this.comparisonCommentModel = { ...rowData };
    this.displayAttachmentsPopup = true;
  }

  onRemoveAttachment(event: any) {
    this.comparisonCommentModel.evidences.splice(this.comparisonCommentModel.evidences.indexOf(event.file), 1);
    this.inputDataComparisonReportList[this.findIndexByData(this.comparisonCommentModel.scopeItem, this.inputDataComparisonReportList)] = this.comparisonCommentModel;
    if (this.comparisonCommentModel.evidences.length === 0) {
      this.displayAttachmentsPopup = false;
    }
  }

  findIndexByData(data: string, dataList: Array<InputDataComparisonReportModel>): number {
    let index = -1;
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i].scopeItem === data) {
        index = i;
        break;
      }
    }
    return index;
  }

  save() {

  }

  saveGlobalCommentToDB(comment: string | null, actionState: WorkflowStatus) {
    const reqBody = {
      comment: comment
    }
    const params: object = {
      action: actionState
    }
    this.vehicleService.updateWorkflow(this.vehAssmntEventId, reqBody, params).subscribe(res => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.setAuditReportNVSData(this.auditData);
      }
      this.getAuditReportData();
    }, (error: HttpErrorResponse) => {
    })
  }

  saveGlobalComment(element) {
    this.saveGlobalCommentToDB(element, WorkflowStatus.DRC_REVIEWED);
    this.displayGlobalCommentPopup = false;
  }

  saveEditedGlobalComment(element) {
    this.editingGlobalComment = false;
    this.saveGlobalCommentToDB(element, WorkflowStatus.DRC_REVIEWED);
    this.viewGlobalCommentPopup = false;
  }

  onAddGlobalComment() {
    this.displayGlobalCommentPopup = true;
  }

  viewGlobalComment() {
    this.viewGlobalCommentPopup = true;
    this.editingGlobalComment = false;
  }

  onEditGlobalComment() {
    this.editingGlobalComment = true;
    this.existingGlobalComment = this.globalCommentData;
  }

  onDeleteGlobalComment() {
    this.displayDeleteConfirmPopup = true;
  }

  confirmDeleteComment() {
    this.tempGlobalComment = null;
    this.saveGlobalCommentToDB(null, WorkflowStatus.SUBMITTED);
    this.displayDeleteConfirmPopup = false;
    this.viewGlobalCommentPopup = false;
  }

  confirmNotDeleteComment() {
    this.displayDeleteConfirmPopup = false;
    this.viewGlobalCommentPopup = false;
  }

  cancelEditedComment() {
    this.globalCommentData = this.existingGlobalComment;
    this.editingGlobalComment = false;
    this.viewGlobalCommentPopup = false;
  }

  approve(element, text) {
    element.textContent = text;
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Approved', life: 3000 });
    element.disabled = true;
  }

  isMismatch() {
    let inputData = InputDataComparisonReportModel;
    let lineData = LineByLineVerificationReportModel;
    for (let i = 0; i < inputData.length; i++) {
      if (inputData[i].validationStatus === ValidationStatusCdUI.Mismatch) {
        this.anyMismatch = false;
      }
    }
    for (let i = 0; i < lineData.length; i++) {
      if (lineData[i].validationStatus === ValidationStatusCdUI.Mismatch) {
        this.anyMismatch = false;
      }
    }
    return this.anyMismatch;
  }

  removeAuditData() {
    let i = 0;
    let vehAssementData = null;
    while (i < this.auditData.vehAssmntEventPlan.length) {
      if (!isNullOrUndefined(this.auditData.vehScopeItems)) {
        vehAssementData = this.auditData.vehScopeItems.find(item => item['VMA_AUDIT_MODFD_SCOPE_TYPE_CD'] === this.auditData.vehAssmntEventPlan[i].vehAssmntScopeTypeCd);
      }
      if (!isNullOrUndefined(vehAssementData)) {
        this.auditData.vehAssmntEventPlan.splice(i, 1);
        // monroney label 
      } else {
        i++;
      }
    }
    // delete this.auditData.vehScopeItems;
    return this.auditData.vehAssmntEventPlan;
  }

  checkVehScopeItems(value) {
    let status = false;
    if (!isNullOrUndefined(this.auditData.vehScopeItems)) {
      for (let i = 0; i < this.auditData.vehScopeItems.length; i++) {
        if (this.auditData.vehScopeItems[i].VMA_AUDIT_MODFD_SCOPE_TYPE_CD === value) {
          status = true;
          break;
        }
      }
    }
    return status;
  }

  setAuditReportNVSData(auditData: AuditData) {
    console.log("rowData", auditData);
    this.selectedRowForNVSSync.modelYear = auditData.vehDocMaster.modelYear;
    this.selectedRowForNVSSync.modelCode = auditData.vehDocMaster.modelCode;
    this.selectedRowForNVSSync.vin = auditData.vehDocMaster.vin;
    this.selectedRowForNVSSync.dummyVin = auditData.vehDocMaster.dummyVin;
    this.selectedRowForNVSSync.vdc = auditData.vehDocMaster.facilityCd;
    this.selectedRowForNVSSync.action = 'drc_reviewed_audit';
    //console.log("selectedRowForNPPSSync", this.selectedRowForNPPSSync);
    // this.nppsDataSync(this.selectedRowForNVSSync, auditData.vehAssmntEventId);
  }

  // nppsDataSync(data: NPPSPropagationData, vehAssmntEventId: number) {
  //   this.vehicleService.nppsDataPropagation(data, vehAssmntEventId).subscribe(res => {
  //     if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
  //       console.log("NVS synced");
  //       this.messageService.add({ severity: 'info', summary: '', detail: "Data synced with NVS.", life: 5000  });
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     this.sharedService.handleHttpError(error);
  //   });
  // }

}
