import { Distributor } from "../models/dropdown.model";


export class StaticData {
    static getDistributorList() : Array<Distributor>{
        return  [
            {
                distributorId : 8,
                distributorCd : "TMS",
                distributorNm : "TMS",
                distributorDesc : ""
            }
        ]
    }
}
