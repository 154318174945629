export class AuditHistoryWaiverData {
    limit: number;
    offset: number;
    total: string;
    auditWaiverData: AuditWaiverHistoryDataDetails;
}

export class AuditWaiverHistoryDataDetails {
    modelYear: string;
    modelCode: string;
    seriesCd: string;
    auditWaiverId: number;
    auditWaiverExcp : string;
    activeInd: string;
    createdts: string;
    scope: Array<WaiverScopeList> = new Array<WaiverScopeList>();
    auditHistory: Array<WaiverHistoryList> = new Array<WaiverHistoryList>();
    constructor(data: any) {
        this.modelYear = data.modelYear;
        this.modelCode = data.modelCode;
        this.seriesCd = data.seriesCd;
        this.auditWaiverId = data.auditWaiverId;
        this.auditWaiverExcp = data.auditWaiverExcp;
        this.activeInd = data.activeInd;
        this.createdts = data.createdts;
        data.scope.forEach(item => {
            this.scope.push(new WaiverScopeList(item));
        });
        data.auditHistory.forEach(item => {
            this.auditHistory.push(new WaiverHistoryList(item));
        });
    }
}

export interface IWaiverScopeList {
    scopeId: number;
    scopeTypeCd: string;
}

export class WaiverScopeList implements IWaiverScopeList {
    scopeId: number;
    scopeTypeCd: string;

    constructor(data: any) {
        this.scopeId = data.scopeId;
        this.scopeTypeCd = data.scopeTypeCd;
    }
}

export interface IWaiverHistoryList {
    auditId: string;
    historyId: string;
    actorNm: string;
    actionDesc: string;
    updatedTs: string;
    comment: string;
}

export class WaiverHistoryList implements IWaiverHistoryList {
    public auditId: string;
    public historyId: string;
    public actorNm: string;
    public updatedTs: string;
    public actionDesc: string;
    public comment: string;

    constructor(data: any) {
        this.auditId = data.auditId;
        this.historyId = data.historyId;
        this.actorNm = data.actorNm;
        this.updatedTs = data.updatedTs;
        this.actionDesc = data.actionDesc
        this.comment = data.comment;
    }
}
