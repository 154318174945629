import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { MultiSelectVdcComponent } from './multi-select-vdc.component';

@NgModule({
  declarations: [
    MultiSelectVdcComponent
  ],
  imports: [
    CommonModule,
    MultiSelectModule,
    FormsModule
  ], exports: [
    MultiSelectVdcComponent
  ]
})
export class MultiSelectVdcModule { }
