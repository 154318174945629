import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleSelectAccessoryComponent } from './single-select-accessory.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    SingleSelectAccessoryComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule
  ], exports: [
    SingleSelectAccessoryComponent
  ]
})
export class SingleSelectAccessoryModule { }
