export class AuditAdminListDetails {
  dateAndTime: any;
  actionTaken: string;
  notes: string;
  userName: string;
}
export class AuditadminDataDetails {
  modelYear: string;
  series: string;
  modelCode: string;
  auditExceptionInfo: string;
  lastUpdatedInfo: string;
  status: string;
}
export class AuditWaiverData {
  auditActiveInd: string;
  auditWaiverExcp: string;
  historyNotes: string;
  historyAction: string;
  auditWaiverScope: Array<AuditScopeData>;
}
export class AuditScopeData {
  scopeType: string;
  scopeTypeDesc: string;
}

export class AddAuditData{
    modelYear: string;
    seriesCd: string;
    modelCode: string;
    auditWaiverExcp: string;
    activeInd: string;
    distMmyId: number;
    historyAction: string;
    auditWaiverScope?: Array<AuditScopeData>;
}

export class NPPSPropagationData {
  modelYear: string;
  modelCode: string;
  vin: string;
  dummyVin: string;
  vdc: string;
  action: string;
}
