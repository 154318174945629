import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandModule } from '../../dropdowns/brand/brand.module';
import { ModelyearModule } from '../../dropdowns/modelyear/modelyear.module';
import { SeriesModule } from '../../dropdowns/series/series.module';
import { ButtonModule } from 'primeng/button';
import { ModelViewComponent } from './model-view.component';
import { MultiSelectVdcModule } from '../../dropdowns/vdc/multi-select-vdc/multi-select-vdc.module';
import { MultiSelectModelcodeModule } from '../../dropdowns/modelcode/multi-select-modelcode/multi-select-modelcode.module';
import { MultiSelectAccessoriesModule } from '../../dropdowns/accessories/multi-select-accessories/multi-select-accessories.module';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ArrivalDateModule } from '../../checkbox/arrival-date/arrival-date.module';

@NgModule({
  declarations: [
    ModelViewComponent
  ],
  imports: [
    CommonModule,
    BrandModule,
    ModelyearModule,
    MultiSelectModelcodeModule,
    MultiSelectVdcModule,
    ArrivalDateModule,
    SeriesModule,
    ButtonModule,
    MultiSelectAccessoriesModule,
    FormsModule,
    CheckboxModule
  ],
  exports: [
    ModelViewComponent
  ]
})
export class ModelViewModule { }
