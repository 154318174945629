
import * as VMA_Util from '../../utilities/vma-common.util';

export class Response<T> {
  public data: any;
  public total: number;
  public errors: Error[];
  hasErrors() {
    return VMA_Util.isArrayWithLength(this.errors);
  }
  hasData() {
    return VMA_Util.isArrayWithLength(this.data) ||
      (!VMA_Util.isArray(this.data) && VMA_Util.isObject(this.data));
  }

  constructor();
  constructor(options: any);
  constructor(options: any, map: any);
  constructor(options?: any, map: any = undefined) {
    if (VMA_Util.isObject(options)) {
      this.data = [];
      if (VMA_Util.isArrayWithLength(options)) {
        if (VMA_Util.isUndefined(map)) {
          this.data = [...options];
        } else {
          this.data = options.map((o: any) => { return map(o); });
        }
      } else if (!VMA_Util.isArray(options)) {
        if (VMA_Util.isUndefined(map)) {
          this.data.push(options);
        } else {
          const obj = map(options);
          this.data.push(obj);
        }
      }
      this.errors = [];
      if (VMA_Util.hasOwnProperty(options, 'errors')) {
        this.errors = options.errors.map((e: any) => {
          return new Error(e);
        });
      }
      this.total = VMA_Util.init(options, 'total', 0);
    }
  }
}

export class ErrResponse {
  statusCode: string;
  errorCode: string;
  errorLayer: string;
  errorDesc: string;
  awsrequestId: string;
}

export class ApiResponseBody {
  data: any;
  error: ErrResponse
}
