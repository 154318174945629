import { ChronicHoldList, ModelList, ChronicHistList, ChronicHistoryData } from "../ui/chronic-hold-data.model";
import * as VMA_Util from '../../utilities/vma-common.util';

export class ChronicHoldListApiModel extends ChronicHoldList { }

export class ChronicHoldListApiRespModel {
    chronicHoldData: ChronicHoldListApiModel = new ChronicHoldListApiModel();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.chronicHoldArr)) {
                this.chronicHoldData.limit = options.data.limit;
                this.chronicHoldData.offset = options.data.offset;
                this.chronicHoldData.total = options.data.total;
                options.data.chronicHoldArr.forEach((element: any) => {
                    this.chronicHoldData.chronicHoldArr.push(new ModelList(element));                    
                });
            }
        }
    }
}

export class ChronicHistListApiModel extends ChronicHistList { }

export class ChronicHistListApiRespModel {
    chronicHistList: ChronicHistListApiModel = new ChronicHistListApiModel();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.chronicHistList)) {
                options.data.chronicHistList.forEach((element: any) => {
                    this.chronicHistList.chronicHistArr.push(new ChronicHistoryData(element));
                });
            }
        }
    }
}