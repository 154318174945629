import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'vma-vehicle-search-by-tls-hold',
  templateUrl: './tls-hold-view.component.html',
  styleUrls: ['./tls-hold-view.component.scss'],
  providers: [PrimeNGConfig]
})

export class TlsHoldViewComponent implements OnInit {

  brand: any;
  year: any;
  series: any;
  vdcValue: any;
  modelcodeValue: any;
  accessory: any;
  userComment: string;
  arrivaldate: boolean;
  tlsDialogFlag: boolean;
  buttonEnabler = true;
  editMode = false;
  dataCount = 0;
  @Output() searchEvent = new EventEmitter<any>();

  constructor(
    public sharedService: SharedService,
    private readonly messageService: MessageService
  ) { }

  ngOnInit(): void {
    // this.brand = { id: 1, name: "Toyota" };
    this.tlsDialogFlag = this.sharedService.tlsDialogFlag;
    this.userComment = this.sharedService.historyNotes;
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData) &&
      this.sharedService.vehicleSearchData.searchType === "TLSEDITVIEW") {
      this.editMode = true;
    }
  }

  selectedBrand(brandId: any) {
    this.brand = brandId;
    this.resetModelYearData();
  }

  selectedYear(yearNo: any) {
    console.log("yearNo", yearNo);
    const currentYear = new Date().getFullYear();
    console.log("currentYear", currentYear);
    if(yearNo && yearNo >= currentYear){
      this.year = yearNo;
    } else {  
      this.year = null;
      console.log(this.year,"this.year")
      this.messageService.add({ severity: 'error', summary: '', detail: 'Please select current model year and above for chronic hold and release', life: 5000 });
    }
    this.resetSeriesData();
  }

  selectedSeries(seriesId: any) {
    this.series = seriesId;
    this.resetModelCodeData();
  }

  selectedModelcode(modelcode: any) {
    this.modelcodeValue = modelcode;
    this.accessory = null;
    this.vdcValue = null;    
    this.dataCount++;
    this.setButtonEnabler();
  }

  selectedVdcValue(vdc: any) {
    this.vdcValue = vdc;
    this.accessory = null;
  }

  selectedAccessory(accessories: any) {
    this.accessory = accessories;
    this.dataCount++;
    this.setButtonEnabler();
  }

  resetModelYearData() {
    this.year = null;
    this.series = null;
    this.modelcodeValue = null;
    this.vdcValue = null;
    this.accessory = null;
  }

  resetSeriesData() {
    this.series = null;
    this.modelcodeValue = null;
    this.vdcValue = null;
    this.accessory = null;
  }

  resetModelCodeData() {
    this.modelcodeValue = null;
    this.vdcValue = null;
    this.accessory = null;
  }

  vehicleListSearch() {
    const searchModel= this.setSearchModel();
    searchModel.type = "search";    
    this.searchEvent.emit(searchModel);
  }

  textAreaChange() {
    this.dataCount++;
    this.setButtonEnabler();
  }

  saveTlsData() {
    const saveModel = this.setSearchModel();
    saveModel.type = "update";
    this.searchEvent.emit(saveModel);
  }

  cancelSave() {
    this.resetData();
  }

  setButtonEnabler() {
    console.log("dataCount *************", this.dataCount);
    if (isNullOrUndefined(this.modelcodeValue) || isNullOrUndefined(this.accessory)) {
      this.buttonEnabler = true;
    } else if (this.dataCount < 3) {
      this.buttonEnabler = true;
    } else {
      this.buttonEnabler = false;
    }
  }

  setSearchModel() {
    const searchModel: any = {};
    searchModel.brandId = this.brand;
    searchModel.yearNo = this.year;
    // need to check for update
    searchModel.seriesId = this.series;
    searchModel.modelCodeId = this.modelcodeValue;
    searchModel.vdcValue = this.vdcValue;
    searchModel.accessory = this.accessory;
    searchModel.historyNotes = this.userComment;
    return searchModel;
  }

  resetData() {
    this.brand = null;
    this.year = null;
    this.resetSeriesData();
    const searchModel: any = {};
    searchModel.type = "reset";
    this.searchEvent.emit(searchModel);
    // setTimeout(() => {
    //   this.brand = { id: 1, name: "Toyota" };
    // }, 500);
  }

  addAction() {
    const saveModel = this.setSearchModel();
    saveModel.type = "add";
    this.searchEvent.emit(saveModel);
  }

  cancelAdding() {
    console.log("cancel");
    this.resetData();
  }
}
