<div class="cmn-div-padding">
    <h3>EVIDENCE DOWNLOAD VIEW</h3>
    <em class="info-style"> ( Info 1 - Please expect delay in loading evidence preview. ) </em> <br>
    <em class="info-style"> ( Info 2 - Refreshing the page will clear the result table , please access the hyperlink
        again from exported excel. ) </em>


    <div class="grid-padding">
        <p-table [value]="downloadEvidenceList" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th class="cmn-table-header-style" scope="col">Evidence Key</th>
                    <th class="cmn-table-header-style" scope="col">Preview</th>
                    <th class="cmn-table-header-style" scope="col">Download</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td class="cmn-table-data-style">{{rowData.key}}</td>
                    <td class="cmn-table-data-style">
                        <p-image [src]="rowData.url" height="35" alt="Image" width="35" [preview]="true">
                        </p-image>
                    </td>
                    <td class="cmn-table-data-style">
                        <button [style]="{'height' : '35px' , 'width' :'35px'}" pButton pRipple icon="pi pi-download"
                            class="p-button-secondary" (click)="getS3SignedUrl(rowData.key,true)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="downloadEvidenceList.length" class="cmn-table-data-style">
                        No records found
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>