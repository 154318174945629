import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { MessageComponent } from './message/message.component';
import { LoaderComponent } from './loader/loader.component';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { RestApiModule } from './rest-api/rest-api.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MenuModule } from 'primeng/menu';
import { SessionComponent } from './session/session.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    MessageComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    SessionComponent
  ],
  imports: [
    CommonModule,
    MenubarModule,
    MessagesModule,
    BreadcrumbModule,
    DialogModule,
    RestApiModule,
    ConfirmDialogModule,
    NgxSpinnerModule,
    ToastModule,
    MenuModule,
    ButtonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    MessageComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    SessionComponent
  ]
})
export class CoreModule { }
