import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuditVehicleListComponent } from './vehicle-list.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ModelyearModule } from '../../modules/dropdowns/modelyear/modelyear.module';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import {ReleaseAuditModule} from 'src/app/modules/release-audit/release-audit.module';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [
    AuditVehicleListComponent
  ],
  imports: [
    CommonModule,
    ModelyearModule,
    ButtonModule,
    DialogModule,
    ConfirmDialogModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    TableModule,
    PaginatorModule,
    DividerModule,
    TooltipModule,
    ReleaseAuditModule, 
    CheckboxModule
  ], exports: [
    AuditVehicleListComponent
  ]
})

export class AuditVehicleListModule { }
