<div class="padding">
    <div>
        <div class="auditwaiver-header">
            <h3 style="margin: 0px 10px">Audit Vehicles</h3>
        </div>
        <div class="p-fluid p-formgrid">
            <div class="p-col-2" style="padding: 0px 10px;">
                <vma-modelyear [brandId]="brand" (yearEvent)="selectedYear($event)"></vma-modelyear>
            </div>
        </div>
        <!-- <div class="align-right page-action-panel-style">
            <p-button label="GO TO INBOX" styleClass="cmn-button-style" style="margin-right: 8px"
                (click)="submitAuditedVehicleReport(selectedAuditedVehicleRow)"
                [disabled]="!selectedAuditedVehicleRow || selectedAuditedVehicleRow?.workflowStatus !== 'INPROGRESS'">
            </p-button>
            <p-button label="GENERATE AUDIT REPORT" styleClass="cmn-button-style" style="margin-right: 8px"
                (click)="generateAuditedVehicleReport()"
                [disabled]="!selectedAuditedVehicleRow || selectedAuditedVehicleRow?.workflowStatus !== 'SUBMITTED'">
            </p-button>
            <p-button label="READY FOR AUDIT" *ngIf="sharedService.displayReadyForAuditButton" styleClass="cmn-button-style"
                style="margin-right: 8px" (click)="readyForAudit()"
                [disabled]="!selectedAuditedVehicleRow || selectedAuditedVehicleRow?.workflowStatus !== 'SELECTED'">
            </p-button>
            <p-button label="UNSELECT" *ngIf="sharedService.displayUnSelectAuditButton" styleClass="cmn-button-style"
                (click)="unselectAuditVehicle(selectedAuditedVehicleRow)"
                [disabled]="!selectedAuditedVehicleRow || selectedAuditedVehicleRow?.workflowStatus !== 'SELECTED'">
            </p-button>
        </div> -->
    </div>
    <p-divider align="center" type="dashed">
        <button pButton type="button" icon="pi pi-undo" class="p-button-secondary cmn-button-style p-mr-2" label="RESET"
        (click)="resetAllVehicles()"></button>
    <button pButton type="button" icon="pi pi-search" class="cmn-button-style p-mr-2" label="SEARCH"
        (click)="allVehicleSearch()"></button>
    <p-button label="GO TO INBOX" styleClass="cmn-button-style p-mr-2"
        (click)="goToInbox(selectedAuditedVehicleRow)"
        *ngIf="selectedAuditedVehicleRow && selectedAuditedVehicleRow?.workflowStatus === 'INPROGRESS'
            && selectedAuditedVehicleRow?.actorName === loggedInUser && validateReadOnly() && validateInboxData()">
    </p-button>
    <p-button label="GENERATE AUDIT REPORT" styleClass="cmn-button-style p-mr-2"
        (click)="generateAuditedVehicleReport()"
        *ngIf="selectedAuditedVehicleRow && selectedAuditedVehicleRow?.workflowStatus === 'SUBMITTED'">
    </p-button>
    <p-button label="VIEW AUDIT REPORT" styleClass="cmn-button-style p-mr-2"
        (click)="viewAuditReport()"
        *ngIf="selectedAuditedVehicleRow && selectedAuditedVehicleRow?.workflowStatus === 'DRC_REVIEWED' || selectedAuditedVehicleRow?.workflowStatus === 'RELEASED'">
    </p-button>
    <p-button label="RELEASE CHRONIC HOLD" styleClass="cmn-button-style p-mr-2" (click)="releaseTlsHold()" *ngIf="showButton">
    </p-button>
    <p-button label="READY FOR AUDIT"
        styleClass="cmn-button-style p-mr-2" (click)="readyForAudit()"
        *ngIf="sharedService.displayReadyForAuditButton && selectedAuditedVehicleRow && selectedAuditedVehicleRow?.workflowStatus === 'SELECTED'">
    </p-button>
    <p-button label="UNSELECT" styleClass="cmn-button-style"
        (click)="unselectAuditVehicle(selectedAuditedVehicleRow)"
        *ngIf="sharedService.displayUnSelectAuditButton && selectedAuditedVehicleRow && selectedAuditedVehicleRow?.workflowStatus === 'SELECTED'">
    </p-button>
    </p-divider>

    <!-- <em class="info-style"> (INFO: NA - Not Applicable for NAP Vehicles ) </em> -->
    <p-table #table1 [value]="allVehicelist" styleClass="p-datatable-sm" [rows]="limit" selectionMode="single"
        [(selection)]="selectedAuditedVehicleRow" responsiveLayout="scroll" scrollHeight="50vh" [globalFilterFields]="['vin' ,'accessories',
        'modelCode','vdc','arrivalDate','serialNo','colorCd', 'workflowStatus', 'actorName']">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 50px" scope="col">
                </th>
                <th class="cmn-table-header-style" style="width: 163px" scope="col"><span
                        style="margin-left: 18px">Vin</span>
                    <p-columnFilter type="text" field="vin" display="menu" matchMode="contains" [showMatchModes]="false"
                        [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                </th>
                <th class="cmn-table-header-style" style="width: 193px" scope="col">Accessories
                    <p-columnFilter type="text" field="accessories" display="menu" matchMode="contains"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                </th>
                <th class="cmn-table-header-style" style="width: 130px" pSortableColumn="modelCode" scope="col">Model
                    <p-sortIcon field="modelCode"></p-sortIcon>
                    <p-columnFilter type="text" field="modelCode" display="menu" matchMode="contains"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                </th>
                <th class="cmn-table-header-style" style="width: 90px" pSortableColumn="vdc" scope="col">Vdc
                    <p-sortIcon field="vdc"></p-sortIcon>
                    <p-columnFilter type="text" field="vdc" display="menu" matchMode="contains" [showMatchModes]="false"
                        [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                </th>
                <th class="cmn-table-header-style" style="width: 150px" pSortableColumn="arrivalDate" scope="col">
                    Arrival
                    Date <p-sortIcon field="arrivalDate"></p-sortIcon>
                    <p-columnFilter type="text" field="arrivalDate" display="menu" matchMode="contains"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                </th>
                <th class="cmn-table-header-style" style="width: 80px" scope="col">Catg.
                    <p-columnFilter type="text" field="nvsCatgStatCd" display="menu" matchMode="contains"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                    </p-columnFilter>
                </th>
                <th class="cmn-table-header-style" style="width: 144px" scope="col">Extr / Int Color
                    <p-columnFilter type="text" field="colorCd" display="menu" matchMode="contains" [showMatchModes]="false"
                        [showOperator]="false" [showAddButton]="false">
                    </p-columnFilter>
                </th>
                <th class="cmn-table-header-style" style="width: 120px" pSortableColumn="workflowStatus" scope="col">
                    Status
                    <p-sortIcon field="workflowStatus"></p-sortIcon>
                    <p-columnFilter type="text" field="workflowStatus" display="menu" matchMode="contains"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                </th>
                <!-- <th class="cmn-table-header-style" style="width: 100px" scope="col">NPPS Sync up
                </th> -->
                <th class="cmn-table-header-style" style="width: 193px" pSortableColumn="updateTs" scope="col">
                    Updated Timestamp
                    <p-sortIcon field="updateTs"></p-sortIcon>
                    <p-columnFilter type="text" field="updateTs" display="menu" matchMode="contains"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                </th>
                <th class="cmn-table-header-style" style="width: 130px" scope="col">User
                    <p-columnFilter type="text" field="actorName" display="menu" matchMode="contains"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                </th>
            </tr>
            <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <!-- <th scope="col"></th> -->
                <th scope="col"></th>
                <th scope="col"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                <td>
                    <input type="checkbox" class="checkBoxStyle" [value]="rowData" 
                        (change)="updateSelection(rowData)" [(ngModel)]="rowData.rowCheckbox" [disabled]="isSelectionLimitReached"
                        *ngIf="rowData.workflowStatus === 'DRC_REVIEWED' || rowData.workflowStatus === 'SUBMITTED_SECONDARY_AUDIT'">
                </td>
                <td class="cmn-table-data-style">
                    <span style="padding-left: 10px;" id="myInput">
                        {{rowData.vin}} </span>
                </td>
                <td class="cmn-table-data-style">{{rowData.accessories}}</td>
                <td class="cmn-table-data-style">{{rowData.modelCode}}</td>
                <td class="cmn-table-data-style">{{rowData.vdc}}</td>
                <td class="cmn-table-data-style">{{rowData.arrivalDate}}</td>
                <td class="cmn-table-data-style">{{rowData.nvsCatgStatCd}}</td>
                <td class="cmn-table-data-style">{{rowData.colorCd}}</td>
                <td class="cmn-table-data-style">
                    <span [class]="'workflow-status-badge workflow-status-' + rowData.workflowStatus">
                        {{rowData.workflowStatus}}
                    </span>
                </td>
                <!-- <td class="cmn-table-data-style" > 
                    <ng-container *ngIf = "isValidRowForNPPSSync(rowData)">
                            <button pButton pRipple icon="pi pi-sync" style="margin-left: 24px; font-size: 1.2rem" class="rowData-icon p-button-rounded p-button-text p-mr-2"
                                [ngClass]="{'p-button-success': rowData.nppsStatus === 'SENT_TO_NPPS' || rowData.nppsStatus === 'Sent', 'p-button-danger': rowData.nppsStatus === '' || rowData.nppsStatus === 'RETRY' || rowData.nppsStatus === 'Retry', 
                                    'p-button-secondary': rowData.nppsStatus === 'NOT_SENT_TO_NPPS' || rowData.nppsStatus === 'Not sent'}"
                                pTooltip="{{ (rowData.nppsStatus === 'SENT_TO_NPPS' || rowData.nppsStatus === 'Sent') ? 'Sent to NPPS' : (rowData.nppsStatus === 'NOT_SENT_TO_NPPS' || rowData.nppsStatus === 'Not sent') ? 'Not sent to NPPS' : 'Retry' }}"
                                tooltipPosition="top" (click)="setNPPSData(rowData)">
                            </button>
                    </ng-container>
                    <ng-container *ngIf = "!isValidRowForNPPSSync(rowData)">
                        <i class = "rowData" style="margin-left: 30px; font-size: 1rem"> NA</i> 
                    </ng-container>
                </td> -->
                <td class="cmn-table-data-style">{{rowData.updateTs}}</td>
                <td class="cmn-table-data-style">{{rowData.actorName}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="10" class="cmn-table-data-style">
                    No records found
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-paginator [style]="{'font-family' : 'Roboto' , 'font-size': '12px'}" [rows]="limit" [totalRecords]="totalRecords"
    [pageLinkSize]="totalPages" [hidden]="!showPagination" [rowsPerPageOptions]="[limit]"
    [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    (onPageChange)="onPaginator($event)">
    </p-paginator>

    <p-dialog [modal]="true" [style]="{width: '50%'}" (onHide)="dialogClose()" [closable]="true" [(visible)]="displayHoldReleasePopup" [showHeader]="true"
        [closeOnEscape]="false" [draggable]="false" [resizable]="true">
        <ng-template pTemplate="header">
            <h5 class="cmn-label-font-style">CHRONIC HOLD RELEASE INFORMATION</h5>
        </ng-template>
        <ng-template pTemplate="content">
            <vma-release-audit #ReleaseAuditComponent [dataFromVehicleList]="smartChronicObjArr"></vma-release-audit>
        </ng-template>
        <ng-template pTemplate="footer">
            <h4 class="info-style">
                The failed accessories are marked as red and successful accessories as green.
            </h4>
        </ng-template>
    </p-dialog>

</div>