import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TlsShipmentHoldComponent } from './tls-shipment-hold.component';
import { TlsHoldViewModule } from 'src/app/modules/vehicle-search-criteria/tls-hold-view/tls-hold-view.module';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { AccordionModule } from 'primeng/accordion';
import { BarcodeScannerLivestreamOverlayModule } from 'ngx-barcode-scanner';
import { TlsShipmentHoldRoutingModule } from './tls-shipment-hold-routing.module';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { CardModule } from 'primeng/card';
import { TimelineModule } from 'primeng/timeline';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';

// import { PrimeIcons } from 'primeng/api';


@NgModule({
  declarations: [
    TlsShipmentHoldComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DividerModule,
    DialogModule,
    FormsModule,
    InputTextModule,
    TableModule,
    TreeTableModule,
    AccordionModule,
    BarcodeScannerLivestreamOverlayModule,
    TlsShipmentHoldRoutingModule,
    ConfirmDialogModule,
    MessagesModule,
    CardModule,
    TimelineModule,
    BrowserModule,
    BrowserAnimationsModule,
    DropdownModule,
    TlsHoldViewModule,
    PaginatorModule,
    CheckboxModule
  ], exports: [
    TlsShipmentHoldComponent
  ]
})
export class TlsShipmentHoldModule { }
