import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DropDownModel, Distributor } from 'src/app/models/dropdown.model';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { PcpsDistributorService } from 'src/app/core/services/api/pcps-distributor.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { SeriesApiModel } from 'src/app/core/model/api/series-api.model';
import { HttpErrorResponse } from '@angular/common/http';
import { StaticData } from '../../static-data';

@Component({
  selector: 'vma-multi-select-series',
  templateUrl: './multi-select-series.component.html',
  styleUrls: ['./multi-select-series.component.scss']
})
export class MultiSelectSeriesComponent implements OnInit {

  selectedSeries: any;
  series: Array<DropDownModel> = new Array<DropDownModel>();
  selectedBrand: DropDownModel;
  selectedModelYear: number;
  selectedVdcCd: string;
  @Output() seriesEvent = new EventEmitter<any>();

  isMandatoryField: boolean;
  viewMode = false;
  editMode = false;

  constructor(
    private readonly sharedService: SharedService,
    private readonly pcpsDistributorService: PcpsDistributorService
  ) { }

  ngOnInit(): void {
    this.isMandatoryField = this.sharedService.isMandatoryField;
  }

  @Input()
  set brandId(brandId: DropDownModel) {
    this.series = [];
    this.selectedSeries = null;
    this.selectedBrand = brandId
    this.selectedModelYear = 0;
  }

  @Input()
  set yearNo(yearNo: number) {
    this.series = [];
    this.selectedSeries = null;
    const distributors = StaticData.getDistributorList();
    this.selectedModelYear = yearNo;
    // if(!isNullOrUndefined(this.selectedBrand)) {
    //   for(const id of this.selectedBrand) {
    //     this.listSeries(distributors[0], id, this.selectedModelYear);
    //   }
    // }
    this.listSeries(distributors[0], this.selectedBrand, this.selectedModelYear);
  }

  // @Input()
  // set brandId(brandId: any) {
  //   this.series = [];
  //   this.selectedSeries = null;
  //   this.selectedBrand = brandId;
  //   const distributors = StaticData.getDistributorList();
  //   // if(!isNullOrUndefined(this.selectedBrand)) {
  //   //   for(const id of this.selectedBrand) {
  //   //     this.listSeries(distributors[0], id, this.selectedModelYear);
  //   //   }
  //   // }
  //   this.listSeries(distributors[0], this.selectedBrand, this.selectedModelYear);
  // }

  // @Input()
  // set vdcCd(vdcCd: string) {
  //   this.series = [];
  //   this.selectedSeries = null;
  //   this.selectedVdcCd = vdcCd;
  //   const distributors = StaticData.getDistributorList();
  //   this.listSeries(distributors[0], this.selectedBrand, this.selectedModelYear);
  // }

  listSeries(distributor: Distributor, brandId: any, yearNo: number) {
    if (!isNullOrUndefined(distributor) && !isNullOrUndefined(brandId)
      && !isNullOrUndefined(yearNo)) {
      const brandIdRef: string = this.formatQueryParams(brandId);
      const parames = {
        brandid: brandIdRef,
        yearno: yearNo
      };
      this.sharedService.clearPreviousMessages();
      this.pcpsDistributorService.listSeries(distributor.distributorId, parames).subscribe(res => {
        //this.series = new Array<DropDownModel>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          const series: Array<SeriesApiModel> = res.data[0].productGroup;
          series.forEach(element => {
            const data: DropDownModel = {
              id: element.productGroupId, code: element.productGroupCd, name: element.productGroupNm
            };
            this.series.push(data);
          });
          this.setSelectedSeriesValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  formatQueryParams(object: any): string {
    let queryParams = '';
    if (object.selectAll) {
      queryParams = 'all';
    } else {
      let count = 0;
      object.forEach(element => {
        if (count !== 0) {
          queryParams = queryParams + ',';
        }
        queryParams = queryParams + element.id;
        count = count + 1;
      });
    }
    return queryParams;
  }

  onSeriesChange(event: any) {
    this.selectedSeries = event.value;
    this.seriesEvent.emit(this.selectedSeries);
  }

  setSelectedSeriesValue() {
    if (!isNullOrUndefined(this.sharedService.dashoardSearchData)
      && !isNullOrUndefined(this.sharedService.dashoardSearchData.seriesId)) {
      this.selectedSeries = this.sharedService.dashoardSearchData.seriesId;
      this.seriesEvent.emit(this.selectedSeries);
    } else {
      // admin component
      if (!isNullOrUndefined(this.sharedService.seriesName)) {
        const seriesName = this.sharedService.seriesName.toUpperCase();
        const series = this.series.find(item => item.name === seriesName);
        if (!isNullOrUndefined(series)) {
          this.selectedSeries = series;
          this.seriesEvent.emit(this.selectedSeries);
          if(this.sharedService.dashoardSearchData.searchType ==="adminview") {
            this.viewMode = true;
          }
          else if(this.sharedService.dashoardSearchData.searchType ==="TLSEDITVIEW") {
            this.editMode = true;
          }
        }        
      }
    }
  }
}
