import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SortEvent, TreeNode } from 'primeng/api';
import { Table } from 'primeng/table';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';

interface Column {
  field: string;
  header: string;
}

interface DashboardHistData {
  modelYear: string;
  modelCd: string;
  seriesCd: string;
  componentCd: string;
  vdcCD: string;
  workflowStatusCode: string;
  createdts: string;
  userName: string;
  userComments: string;
  vin: string;
}

@Component({
  selector: 'app-history-view',
  templateUrl: './history-view.component.html',
  styleUrls: ['./history-view.component.scss']
})
export class HistoryViewComponent implements OnInit {

  viewOptions: any[];
  selectedView = "series";
  searchType: string;
  modelYear = "2022";
  brand = "TOYOTA";
  series = "Camry";
  vdc = "JX"
  mockData: any[];
  vdcCodes: any[];
  rowGroupMetadata: any;
  selectedRowItems: any;
  rowNode: any;

  files!: any;


  accessoriesArr: any = [];
  showOverlay: boolean = false;

  filterAction = false;
  filteredSeries: string;
  filteredModelCd: string;
  event: any;
  offset = 0;
  limit = 100;
  totalRecords = 0;
  totalPages = 0;
  showPagination = false;
  params: any = {};
  dashboardHistList: any;
  setData: any;
  modelYearParams: any;
  modelCodeParams: any;
  seriesParams: any;
  selectedVinList:any =[];
  vdcListArray:any = [];
  Object = Object;
  dashboardHistoryDataList:any;
  allStatusList: any = [];
  inprogressVinList:any =[];
  submittedVinList:any =[];
  drcApprovedVinList:any =[];
  releasedVinList:any =[];



  constructor(
    private readonly router: Router,
    private readonly vehicleService: VehicleService,
    public sharedService: SharedService
  ) {
    this.viewOptions = [{ label: 'Series View', value: 'series' }, { label: 'VDC View', value: 'vdc' }];
  }

   


  ngOnInit() {

    this.setData = this.sharedService.getHistData();
    if (!isNullOrUndefined(this.setData)) {
      this.modelYearParams = this.setData.modelYear;
      this.modelCodeParams = this.setData.modelCode;
      this.seriesParams = this.setData.series;
      this.setParamHistoryData();
    }
  }

  setParamHistoryData() {
    if (!isNullOrUndefined(this.modelYearParams) && !isEmpty(this.modelYearParams)) {
      this.params.modelyear = this.modelYearParams;
      if (!isNullOrUndefined(this.modelCodeParams) && !isEmpty(this.modelCodeParams)) {
        this.params.modelcd = this.modelCodeParams;
      }
      if (!isNullOrUndefined(this.seriesParams) && !isEmpty(this.seriesParams)) {
        this.params.series = this.seriesParams;
      }
      this.getHistoryData(this.params);
    } else {
      this.sharedService.handledMandatoryFields();
    }
  }

  getHistoryData(params: any) {
    this.vehicleService.listDashboardHistory(params).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.dashboardHistList = res.data[0].dashboardHistory.HistoryDataList;
        this.uniqueVdcList(this.dashboardHistList);
      } else {
        this.sharedService.handledNoRecords();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    });
  }

  uniqueVdcList(histList) {
    this.vdcListArray = [];
    this.allStatusList = [];
    for (let i = 0; i < histList.length; i++) {
      if (this.vdcListArray.indexOf(histList[i].vdcCD) == -1) {
        this.vdcListArray.push(histList[i].vdcCD);
      }
    }
    this.selectedVdcList( histList);
  }

  selectedVdcList(histList) {
    const selectedArr: any = [];
    const vinListArr: any = [];
    for (let i = 0; i < histList.length; i++) {
      if (histList[i].workflowStatusCode === 'SELECTED') {
        const obj: any = {};
        obj.vin = histList[i].vin;
        obj.userName = histList[i].userName;
        obj.createdts = histList[i].createdts;
        obj.componentCd = histList[i].componentCd;
        obj.userComments = histList[i].userComments;
        obj.vehAssmntEventId = histList[i].vehAssmntEventId;
        vinListArr.push(histList[i].vinList);
        selectedArr.push(histList[i]);
      }
    }
    

    const temp: any = [];
    for (let i = 0; i < this.vdcListArray.length; i++) {
      const vinList: any = [];
      const obj1: any = {};
      obj1.vdcCD = this.vdcListArray[i];
      for (let j = 0; j < selectedArr.length; j++) {
        if (this.vdcListArray[i] === selectedArr[j].vdcCD) {
          const obj2: any = {};
          obj2.vin = selectedArr[j].vin;
          obj2.vdcCD = selectedArr[j].vdcCD;
          obj2.userName = selectedArr[j].userName;
          obj2.createdts = selectedArr[j].createdts;
          obj2.componentCd = selectedArr[j].componentCd;
          obj2.vehAssmntEventId = selectedArr[j].vehAssmntEventId;
          vinList.push(obj2);
        }
      }
      obj1.vinList = vinList;
      temp.push(obj1);
    }
    const obj3: any = {};
    this.selectedVinList = [];
    for (let i = 0; i < temp.length; i++) {
      obj3[temp[i].vdcCD] = temp[i].vinList;
    }
    this.selectedVinList.push(obj3);


    this.allStatusList.push({statuscode:'SELECTED',selectedList:this.selectedVinList});
    this.inprogressVdcList( histList);
    this.submittedVdcList( histList);
    this.drcApprovedVdcList( histList);
    this.releasedVdcList( histList);
  }

  inprogressVdcList(histList) {
    const inprogressArr: any = [];
    const vinListArr: any = [];
    for (let i = 0; i < histList.length; i++) {
      if (histList[i].workflowStatusCode === 'INPROGRESS') {
        const obj: any = {};
        obj.vin = histList[i].vin;
        obj.userName = histList[i].userName;
        obj.createdts = histList[i].createdts;
        obj.componentCd = histList[i].componentCd;
        obj.userComments = histList[i].userComments;
        obj.vehAssmntEventId = histList[i].vehAssmntEventId;
        vinListArr.push(histList[i].vinList);
        inprogressArr.push(histList[i]);
      }
    }
 

    const temp: any = [];
    for (let i = 0; i < this.vdcListArray.length; i++) {
      const vinList: any = [];
      const obj1: any = {};
      obj1.vdcCD = this.vdcListArray[i];
      for (let j = 0; j < inprogressArr.length; j++) {
        if (this.vdcListArray[i] === inprogressArr[j].vdcCD) {
          const obj2: any = {};
          obj2.vin = inprogressArr[j].vin;
          obj2.vdcCD = inprogressArr[j].vdcCD;
          obj2.userName = inprogressArr[j].userName;
          obj2.createdts = inprogressArr[j].createdts;
          obj2.componentCd = inprogressArr[j].componentCd;
          obj2.vehAssmntEventId = inprogressArr[j].vehAssmntEventId;
          vinList.push(obj2);
        }
      }
      obj1.vinList = vinList;
      temp.push(obj1);
    }

    const obj3: any = {};
    this.inprogressVinList = [];
    for (let i = 0; i < temp.length; i++) {
      obj3[temp[i].vdcCD] = temp[i].vinList;
    }
    this.inprogressVinList.push(obj3);


    this.allStatusList.push({statuscode:'INPROGRESS',inprogressList:this.inprogressVinList});

  }

  submittedVdcList(histList) {
    const submittedArr: any = [];
    const vinListArr: any = [];
    for (let i = 0; i < histList.length; i++) {
      if (histList[i].workflowStatusCode === 'SUBMITTED') {
        const obj: any = {};
        obj.vin = histList[i].vin;
        obj.userName = histList[i].userName;
        obj.createdts = histList[i].createdts;
        obj.componentCd = histList[i].componentCd;
        obj.userComments = histList[i].userComments;
        obj.vehAssmntEventId = histList[i].vehAssmntEventId;
        vinListArr.push(histList[i].vinList);
        submittedArr.push(histList[i]);
      }
    }
   


    const temp: any = [];
    for (let i = 0; i < this.vdcListArray.length; i++) {
      const vinList: any = [];
      const obj1: any = {};
      obj1.vdcCD = this.vdcListArray[i];
      for (let j = 0; j < submittedArr.length; j++) {
        if (this.vdcListArray[i] === submittedArr[j].vdcCD) {
          const obj2: any = {};
          obj2.vin = submittedArr[j].vin;
          obj2.vdcCD = submittedArr[j].vdcCD;
          obj2.userName = submittedArr[j].userName;
          obj2.createdts = submittedArr[j].createdts;
          obj2.componentCd = submittedArr[j].componentCd;
          obj2.vehAssmntEventId = submittedArr[j].vehAssmntEventId;
          vinList.push(obj2);
        }
      }
      obj1.vinList = vinList;
      temp.push(obj1);
    }

    const obj3: any = {};
    this.submittedVinList = [];
    for (let i = 0; i < temp.length; i++) {
      obj3[temp[i].vdcCD] = temp[i].vinList;
    }
    this.submittedVinList.push(obj3);


    this.allStatusList.push({statuscode:'SUBMITTED',submittedList:this.submittedVinList});

  }

  drcApprovedVdcList(histList) {
    const drcApprovedArr: any = [];
    const vinListArr: any = [];
    for (let i = 0; i < histList.length; i++) {
      if (histList[i].workflowStatusCode === 'DRC_REVIEWED') {
        const obj: any = {};
        obj.vin = histList[i].vin;
        obj.userName = histList[i].userName;
        obj.createdts = histList[i].createdts;
        obj.componentCd = histList[i].componentCd;
        obj.userComments = histList[i].userComments;
        obj.vehAssmntEventId = histList[i].vehAssmntEventId;
        vinListArr.push(histList[i].vinList);
        drcApprovedArr.push(histList[i]);
      }
    }
   


    const temp: any = [];
    for (let i = 0; i < this.vdcListArray.length; i++) {
      const vinList: any = [];
      const obj1: any = {};
      obj1.vdcCD = this.vdcListArray[i];
      for (let j = 0; j < drcApprovedArr.length; j++) {
        if (this.vdcListArray[i] === drcApprovedArr[j].vdcCD) {
          const obj2: any = {};
          obj2.vin = drcApprovedArr[j].vin;
          obj2.vdcCD = drcApprovedArr[j].vdcCD;
          obj2.userName = drcApprovedArr[j].userName;
          obj2.createdts = drcApprovedArr[j].createdts;
          obj2.componentCd = drcApprovedArr[j].componentCd;
          obj2.vehAssmntEventId = drcApprovedArr[j].vehAssmntEventId;
          vinList.push(obj2);
        }
      }
      obj1.vinList = vinList;
      temp.push(obj1);
    }

    const obj3: any = {};
    this.drcApprovedVinList = [];
    for (let i = 0; i < temp.length; i++) {
      obj3[temp[i].vdcCD] = temp[i].vinList;
    }
    this.drcApprovedVinList.push(obj3);

    this.allStatusList.push({statuscode:'DRC_REVIEWED',drcApprovedList:this.drcApprovedVinList});

  }

  releasedVdcList(histList) {
    const releasedArr: any = [];
    const vinListArr: any = [];
    for (let i = 0; i < histList.length; i++) {
      if (histList[i].workflowStatusCode === 'RELEASED') {
        const obj: any = {};
        obj.vin = histList[i].vin;
        obj.userName = histList[i].userName;
        obj.createdts = histList[i].createdts;
        obj.componentCd = histList[i].componentCd;
        obj.userComments = histList[i].userComments;
        obj.vehAssmntEventId = histList[i].vehAssmntEventId;
        vinListArr.push(histList[i].vinList);
        releasedArr.push(histList[i]);
      }
    }
   


    const temp: any = [];
    for (let i = 0; i < this.vdcListArray.length; i++) {
      const vinList: any = [];
      const obj1: any = {};
      obj1.vdcCD = this.vdcListArray[i];
      for (let j = 0; j < releasedArr.length; j++) {
        if (this.vdcListArray[i] === releasedArr[j].vdcCD) {
          const obj2: any = {};
          obj2.vin = releasedArr[j].vin;
          obj2.vdcCD = releasedArr[j].vdcCD;
          obj2.userName = releasedArr[j].userName;
          obj2.createdts = releasedArr[j].createdts;
          obj2.componentCd = releasedArr[j].componentCd;
          obj2.vehAssmntEventId = releasedArr[j].vehAssmntEventId;
          vinList.push(obj2);
        }
      }
      obj1.vinList = vinList;
      temp.push(obj1);
    }
    const obj3: any = {};
    this.releasedVinList = [];
    for (let i = 0; i < temp.length; i++) {
      obj3[temp[i].vdcCD] = temp[i].vinList;
    }
    this.releasedVinList.push(obj3);


    this.allStatusList.push({statuscode:'RELEASED',releasedList:this.releasedVinList});

  }

  
  viewAuditReport(vehAssmntEventId){
    this.sharedService.setDashboardFlag(true);
    this.sharedService.setVehAssmntEventId(vehAssmntEventId);
    this.router.navigate(['audit/report']);
  }
  



  onClickBack() {
    this.router.navigate(['audit/dashboard']);
  }

}

