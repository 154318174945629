import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChronicSeriesComponent } from './chronic-series.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ChronicSeriesComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule
  ], exports: [
    ChronicSeriesComponent
  ],
})
export class ChronicSeriesModule { }
