import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/auth/auth.guard';
import { HomeComponent } from './home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
         {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'vehicle',
        loadChildren: () => import('../vehicle-selection/vehicle-selection.routing.module').then(m => m.VehicleSelectionRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'scan',
        loadChildren: () => import('../add-vehicle/add-vehicle-routing.module').then(m => m.AddVehicleRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'tlsHold',
        loadChildren: () => import('../tls-shipment-hold/tls-shipment-hold-routing.module').then(m => m.TlsShipmentHoldRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'inbox',
        loadChildren: () => import('../audit-inbox/audit-inbox-routing.module').then(m => m.InboxRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'input',
        loadChildren: () => import('../audit-input/audit-input-routing.module').then(m => m.InputAuditFormRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'report',
        loadChildren: () => import('../audit-report/audit-report-routing.module').then(m => m.AuditReportRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'evidence',
        loadChildren: () => import('../evidence-download/evidence-download-routing.module').then(m => m.EvidenceDownloadRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'drc',
        loadChildren: () => import('../drc-approval/drc-approval-routing.module').then(m => m.DrcApprovalRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('../admin/admin-routing.module').then(m => m.AdminRoutingModule),
      },
      {
        path: 'auditedvehicles',
        loadChildren: () => import('../audited-vehicle/vehicle-list-routing.module').then(m => m.AuditVehicleListRoutingModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.routing.module').then(m => m.DashboardRoutingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'historyview',
        loadChildren: () => import('../history-view/history-view-routing.module').then(m => m.HistoryViewRoutingModule),
      }
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
