import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { IAuthenticatedUserProfile, IUserAuthorizeRequestModel } from '../model/ui/user.model';
import { UserService } from '../services/api/user.service';
import { isNullOrUndefined } from '../utilities/vma-common.util';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  authStatus = false;

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly msalBroadcastService: MsalBroadcastService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        switchMap(() => {
          const currentRoutePath = route.routeConfig?.path;
          if (this.authService.isUserLoggedIn()) {
            if (this.authService.isAuthorized()) {
              return of(true);
            } else {
              return this.getUserAccessibleResources();
            }
          } else {
            if (this.authService.isOfflineResources(currentRoutePath)) {
              return of(true);
            }
            if (this.authService.isEvidenceAccessRequest(currentRoutePath)) {
              this.authService.setEvidenceUrl(state.url);
              this.authService.login();
              return of(false);
            }
            this.authService.navigateToMyInbox();
            return of(false);
          }
        })
      );
  }

  getUserAccessibleResources(): Observable<boolean> {
    const authenticatedUserProfile: IAuthenticatedUserProfile = this.authService.getAuthenticatedUserProfile();
    const userAuthorizeRequestModel: IUserAuthorizeRequestModel = {
      usercd: authenticatedUserProfile.workdayId,
      usernm: authenticatedUserProfile.name,
      email: authenticatedUserProfile.email,
      roles: authenticatedUserProfile.roles
    };

    return this.userService.authorizeUser(userAuthorizeRequestModel, authenticatedUserProfile.workdayId).pipe(
      map(response => {
        let status = false;
        if (!isNullOrUndefined(response) && !isNullOrUndefined(response.data)) {
          this.authService.setAuthorizationModel(JSON.stringify(response.data[0].data));
          status = true;
        }
        if (status) {
          const evidenceurl = this.authService.getEvidenceUrl();
          if (evidenceurl != null) {
            this.authService.removeEvidenceUrl();
            this.authService.navigateToEvidenceView(evidenceurl);
            return !status;
          }
        }
        if (!status) {
          this.authService.navigateToUnauthorizedView();
        }
        return status;
      }), (catchError((error: any) => {
        this.authService.navigateToUnauthorizedView();
        return of(true);
      })
    ))
  }
}
