import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SortEvent, TreeNode } from 'primeng/api';
import { Table } from 'primeng/table';
import { DashboardDataListApiModel } from 'src/app/core/model/api/list-dashboard.model';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isEmpty, isNullOrUndefined, isNull } from 'src/app/core/utilities/vma-common.util';
import { ExportExcelService } from 'src/app/core/services/ui/export-excel.service';
import { VdcList } from '../../core/model/ui/dashboard-data.model';
import { OverlayPanel } from 'primeng/overlaypanel';

interface Column {
  field: string;
  header: string;
}

interface Accessory {
  accessory: string;
  status: string;
  configId: number;
  vdcCodes: any[];
}

interface DashboardData {
  accessories: Accessory[];
  modelYear: string;
  modelCode: string;
  series: string;
  selected?: boolean;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  viewOptions: any[];
  selectedView = "series";
  searchType: string;
  distributorCd = "91041";
  vdcCodes: any[];
  brandIdParams: any;
  yearNoParams: any;
  seriesIdParams: any;
  modelCodeIdParams: any;
  vdcValue: any;
  accessories: any;

  rowGroupMetadata: any;
  selectedRowItems: any;
  rowNode: any;
  originalData: any[] = [];
  transformedData: DashboardData[] = [];
  transformedVdcData: any[] =[];
  cols!: Column[];

  showOverlay: boolean = false;
  showSeriesList: any;
  @ViewChild('overlay') overlay: OverlayPanel;
  @ViewChild('op') op: OverlayPanel;
  filterAction = false;
  filteredSeries: string;
  filteredModelCd: string;
  //selectedViewHistoryRow: string;
  showResult = false;
  showVdcData= false;
  noVdcData = false;
  offset = 0;
  limit = 100;
  totalRecords = 0;
  totalPages = 0;
  showPagination = true;
  vdcStausCode: any;
  noVDCMessage:string;
  count = 0;

  params: any = {};
  selectedRowData: any;
  fetchedData: any;
  viewHistory = true;
  checked: any;

  constructor(
    private readonly vehicleService: VehicleService,
    private readonly router: Router,
    public sharedService: SharedService,
    private readonly exportExcelService: ExportExcelService
  ) { this.viewOptions = [{ label: 'Series View', value: 'series' }, { label: 'VDC View', value: 'vdc' }]; }

  ngOnInit(): void {
    if (!isNullOrUndefined(this.sharedService.dashoardSearchData) &&
      !isNullOrUndefined(this.sharedService.dashoardSearchData.viewType)) {
      this.selectedView = this.sharedService.dashoardSearchData.viewType;
    } else {
      this.searchType = "SERIESVIEW";
    }
    this.cols = [];
  }

  setColumnName() {
    this.cols = [];
    let accessoriesArr: any = [];
    for(let i=0; i<this.transformedData.length; i++) {
      accessoriesArr.push(this.transformedData[i].accessories);
    }
    let maxAccessories = accessoriesArr.reduce((maxArr, currentArr) => {
      return currentArr.length > maxArr.length ? currentArr : maxArr;
    }, []);

   let maxAccessoriesLength = (maxAccessories.length > 20) ? maxAccessories.length : 20;

    for(let j=0; j< maxAccessoriesLength; j++) {
      let col = {
        field: "accessory",
        header: ""
      }
      this.cols.push(col);
    }
  }

  setTransferredData() {
    this.transformedData = [];
    this.showResult = true;
    for (const item of this.originalData) {
      for(const element of item.data) {
        const transformedItem: any = {
          accessories: element.accessories,
          modelYear: element.modelYear,
          modelCode: element.modelCd,
          series: item.seriesCd,
          selected: false,
        };
        this.transformedData.push(transformedItem);
      }
    }
    this.setColumnName();
    this.updateRowGroupMetaData(this.transformedData);
  }


  basedOnModeofSearch(event: any) {
    if (event.value === 'series') {
      this.searchType = 'SERIESVIEW';
      this.sharedService.viewType = 'series';
    } else {
      this.searchType = 'VDCVIEW';
      this.sharedService.viewType = 'vdc';
    }
    this.resetDashboardListData();
    this.resetDashboardData();
  }

  searchVehicleEvent(searchData: any) {
    if (!isNullOrUndefined(searchData)) {
      this.brandIdParams = searchData.brandId;
      this.yearNoParams = searchData.yearNo;
      this.seriesIdParams = searchData.seriesId;
      this.modelCodeIdParams = searchData.modelCodeId;
      this.vdcValue = searchData.vdcValue;
      this.accessories = searchData.accessory;
      if (isNullOrUndefined(this.sharedService.dashoardSearchData)) {
        //this.resetDashboardListData();
      } else {
        this.count++;
        const totalcount = this.setDashboardCount(this.sharedService.dashoardSearchData);
        this.backToDashboardSearch(totalcount);
      }
    }
  }

  setDashboardCount(searchData) {
    let searchCount = 0;
    if (!isNullOrUndefined(searchData.brandId)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.yearNo)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.seriesId)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.modelCodeId)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.vdcValue)) {
      searchCount++;
    }
    if (!isNullOrUndefined(searchData.accessory)) {
      searchCount++;
    }
    return searchCount;
  }

  backToDashboardSearch(totalcount) {
    if (this.count === totalcount) {
      this.searchType = this.sharedService.dashoardSearchData.searchType;
      this.limit = this.sharedService.dashoardSearchData.limit;
      this.offset = this.sharedService.dashoardSearchData.offset;
      this.searchAuditVehicles();
    }
  }

  resetDashboardData() {
    this.sharedService.clearButton = true;
    this.showResult= false;
    this.fetchedData= null;
  }

  resetDashboardListData(){
    this.limit = 100;
    this.offset = 0;
    this.originalData = [];
    this.transformedData = [];
    this.showPagination = false;
    this.sharedService.dashoardSearchData = null;
   this.fetchedData= null;
  }

  searchAuditVehicles() {
    this.sharedService.clearPreviousMessages();
    this.sharedService.dashoardSearchData = null;
    if (this.searchType === "SERIESVIEW") {
      this.searchSeriesData();
    } else {
      this.searchVdcData();
    }
  }

  searchSeriesData() {
    if (!isNullOrUndefined(this.brandIdParams)  && !isEmpty(this.brandIdParams)
      && !isNullOrUndefined(this.yearNoParams) && !isNullOrUndefined(this.seriesIdParams)
      && !isEmpty(this.seriesIdParams)) {
      const params: any = {};
      params.brand = this.brandIdParams.map(x => x['id']).join(',');
      params.modelyear = this.yearNoParams;
      params.series = this.seriesIdParams.map(x => x['name']).join(',');
      if (!isNullOrUndefined(this.modelCodeIdParams) && !isEmpty(this.modelCodeIdParams)) {
        params.modelcd = this.modelCodeIdParams.map(x => x['name']).join(',');
      }
      params.limit = this.limit;
      params.offset = this.offset;
      if (!isNullOrUndefined(this.vdcValue) && !isEmpty(this.vdcValue)) {
        params.vdccd = this.vdcValue.map(x => x['code']).join(',');
      }
      if (!isNullOrUndefined(this.accessories) && !isEmpty(this.accessories)) {
        params.accessories = this.accessories.map(x => x['code']).join(',');
      }
      this.getSearchVehicleDetails(params);
    } else {
      this.sharedService.handledMandatoryFields();
    }
  }

  searchVdcData() {
    if (!isNullOrUndefined(this.brandIdParams) && !isNullOrUndefined(this.brandIdParams)
      && !isNullOrUndefined(this.yearNoParams) && !isNullOrUndefined(this.seriesIdParams)
      && !isNullOrUndefined(this.vdcValue) && !isNullOrUndefined(this.vdcValue['code'])) {
      const params: any = {};
      params.brand = this.brandIdParams.map(x => x['id']).join(',');
      params.modelyear = this.yearNoParams;
      params.series = this.seriesIdParams.map(x => x['name']).join(',');
      params.vdccd = this.vdcValue['code'];
      params.limit = this.limit;
      params.offset = this.offset;
      // if (!isNullOrUndefined(this.seriesIdParams) && !isEmpty(this.seriesIdParams)) {
      //   params.series = this.seriesIdParams.map(x => x['name']).join(',');
      // }
      if (!isNullOrUndefined(this.modelCodeIdParams) && !isEmpty(this.modelCodeIdParams)) {
        params.modelcd = this.modelCodeIdParams.map(x => x['code']).join(',');
      }
      if (!isNullOrUndefined(this.accessories) && !isEmpty(this.accessories)) {
        params.accessories = this.accessories.map(x => x['code']).join(',');
      }
      this.getSearchVehicleDetails(params);
    } else {
      this.sharedService.handledMandatoryFields();
    }
  }

  getSearchVehicleDetails(params: any) {
    this.vehicleService.listDashboardData(this.distributorCd, params).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data[0].dashboardDataObj.total)) {
        //this.originalData= new Array<DashboardDataListApiModel>();
        this.originalData = [];
        this.originalData = res.data[0].dashboardDataObj.dashboardData;
        this.originalData = this.originalData.filter(data => data.seriesCd != null)
        //this.setDashboardData();
        this.setTransferredData();
        this.totalRecords = res.data[0].dashboardDataObj.total;
        this.offset = res.data[0].dashboardDataObj.offset;
        this.setPaginationData();
        this.showPagination = true;
      } else {
        this.sharedService.handledNoRecords();
        this.resetDashboardListData();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    });
  }

  export() {
    this.exportExcelService.generateDashboardExcel(this.transformedData);
  }

  fetchVdcStatus(data, event: Event) {
    this.resetVdcList();
    if (!isNullOrUndefined(data)) {
      let configId = data.configId;
      if (!isNullOrUndefined(configId)) {
        let params = {};
        if(!isNullOrUndefined(this.vdcValue)) {
          params = {
            configId : configId,
            vdccd : this.vdcValue['code']
          };
        } else {
          params = {
            configId : configId
          };
        }
        this.vehicleService.listDashboardVdcData(params).subscribe((res: any) => {
          this.resetStatus();
          if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
            if (!isNullOrUndefined(res.data[0].dashboardVdcDataObj)) {
              this.vdcStausCode = res.data[0].dashboardVdcDataObj;
              this.transformedVdcData = this.vdcStausCode.map(vdc => ({
                vdc: vdc.vdcCodes[0].vdcCode,
                status: vdc.vdcCodes[0].vdcStatus,
                updatedTs: vdc.vdcCodes[0].updatedTs
              }));
              this.showVdcData = true;
              this.op.toggle(event);
            }
          } else if(isEmpty(res) || isNullOrUndefined(res)){
            this.showVdcData = true;
            this.noVdcData =true;
            this.noVDCMessage = "No vehicle associated";
            this.op.toggle(event);
          }
        })
      }
    }
  }

  resetVdcList(){
    this.transformedVdcData = [];
    this.noVDCMessage = '';
    this.showVdcData = false;
    this.noVdcData =false;
  }

  resetStatus(){
    this.transformedVdcData=[];
    this.noVdcData =false;
    this.showVdcData = false;
  }

  setPaginationData() {
    this.totalPages = Math.ceil(this.totalRecords / this.limit);
    if (this.totalPages > 5) {
      this.totalPages = 5;
    }
  }

  onFilter(event) {
    this.updateRowGroupMetaData(event.filteredValue);
  }

  customSort(event: SortEvent) {
    this.sort(event.data, 'series', event.order);
    this.sort(event.data, event.field, event.order);
    this.updateRowGroupMetaData(event.data);
  }

  sort(array, field, order) {
    array.sort((data1, data2) => {
      const value1 = data1[field];
      const value2 = data2[field];
      let result;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (
        typeof value1 === 'string' &&
        typeof value2 === 'string'
      ) {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }

      return order * result;
    });

  }

  updateRowGroupMetaData(data) {
    this.rowGroupMetadata = {};
    for (let i = 0; i < data.length; i++) {
      let rowData = data[i];
      let seriesName = rowData['series'];
      if (i == 0) {
        this.rowGroupMetadata[seriesName] = { index: 0, size: 1, seriesCheckbox: false, selectedChildren: 0 };
      } else {
        let previousRowData = data[i - 1];
        let previousRowGroup = previousRowData['series'];
        if (seriesName === previousRowGroup) {
          this.rowGroupMetadata[seriesName].size++;
        } else {
          this.rowGroupMetadata[seriesName] = { index: i, size: 1, seriesCheckbox: false, selectedChildren: 0 };
        }
      }
    }
  }

  calculateCustomerTotal(name) {
    let totalCount: any = [];
    for (let value of this.transformedData) {
      if (value['series'] === name) {
        if (!totalCount.includes(value.modelCode)) {
          totalCount.push(value.modelCode)
        }
      }
    }
    return totalCount.length;
  }

  onViewHistory() {
    this.sharedService.setHistData(this.fetchedData.modelYear, this.fetchedData.modelCode, this.fetchedData.series);
    this.sharedService.dashoardSearchData = this.setSearchValues();
    this.router.navigate(['audit/historyview']);
  }

  setSearchValues() {
    let searchModel: any = {};

    searchModel.brandId = this.brandIdParams;
    searchModel.yearNo = this.yearNoParams;
    searchModel.seriesId = this.seriesIdParams;
    searchModel.modelCodeId = this.modelCodeIdParams;
    searchModel.vdcValue = this.vdcValue;
    searchModel.accessory = this.accessories;
    searchModel.searchType = this.searchType;
    searchModel.viewType = this.selectedView;
    return searchModel;
  }


  selectForHistory(event, searchdata) {
    this.fetchedData = searchdata;
    for (let i = 0; i < this.transformedData.length; i++) {
      this.transformedData[i].selected = false;
    }
    // var checkedDataValue=document.getElementById("dataSelect") as HTMLInputElement ;
    searchdata.checked = event.currentTarget.checked;
    if (searchdata.checked) {
      this.viewHistory = false;
    } else{
      this.viewHistory = true;
    }
  }

  onClearSeries(table1: Table) {
    if (this.filteredSeries === '') {
      table1.filter(this.filteredSeries, 'series', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onClearModelCd(table1: Table) {
    if (this.filteredModelCd === '') {
      table1.filter(this.filteredModelCd, 'modelCode', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  checkFilterData() {
    if ((isNullOrUndefined(this.filteredSeries) || this.filteredSeries === "") &&
      (isNullOrUndefined(this.filteredModelCd) || this.filteredModelCd === "")) {
      return false;
    } else {
      return true;
    }
  }

  onFilterSeries(table1: Table) {
    table1.filter(this.filteredSeries, 'series', 'contains');
    this.showFilter();
  }

  onFilterModelCd(table1: Table) {
    table1.filter(this.filteredModelCd, 'modelCode', 'contains');
    this.showFilter();
  }

  showFilter() {
    if (this.filterAction) {
      this.filterAction = this.checkFilterData();
    } else {
      this.filterAction = true;
    }
  }

  showMore():number {
    let remainingCount;
    if(!isNullOrUndefined(this.seriesIdParams)){
      remainingCount = this.seriesIdParams.length - 3
    }
    return remainingCount > 0 ? remainingCount : 0;
  }

  showSeries(event:Event){
    this.overlay.toggle(event);
    // this.showOverlay = true;
    if(this.seriesIdParams){
      this.showSeriesList = this.seriesIdParams.map(x => x['name']).join(', ');

    }
  }
  toggleVdcStatusData(event: Event){
    this.op.toggle(event);
  }

  reloadSearchList(){
    this.searchAuditVehicles();
  }
  

}
