
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageProperty } from 'src/app/common/app.constants';

import { SharedService } from '../../services/ui/shared.service';
import * as VMA_Util from '../../utilities/vma-common.util';
import { HTTP_HEADER_PARAMS } from '../model/http-options';

@Injectable({
	providedIn: 'root'
})
export class HttpBaseService {

	public port: string;
	public secure: boolean;
	public server: string;
	public prefix: string;
	protected headers: HttpHeaders;

	constructor(@Inject(HttpClient) private readonly httpClient: HttpClient,
		private readonly sharedService: SharedService

	) {
		this.server = '';
		this.port = '';
		this.prefix = '';
		this.secure = false;
		this.headers = new HttpHeaders();
	}

	public createUrl(endpoint: string): string {
		let url = 'HTTP://SERVER';

		if (VMA_Util.isStringWithLength(this.port)) {
			url += ':PORT';
		}

		if (VMA_Util.isStringWithLength(this.prefix)) {
			url += '/PREFIX';
		}

		if (VMA_Util.isStringWithLength(endpoint)) {
			url += '/ENDPOINT';
		}

		url = url
			.replace(/HTTP/g, this.secure ? 'https' : 'http')
			.replace(/SERVER/g, VMA_Util.isStringWithLength(this.server) ? this.server : '')
			.replace(/PORT/g, VMA_Util.isStringWithLength(this.port) ? this.port : '')
			.replace(/PREFIX/g, VMA_Util.isStringWithLength(this.prefix) ? this.prefix : '')
			.replace(/ENDPOINT/g, VMA_Util.isStringWithLength(endpoint) ? endpoint : '')
			.trim();
		return url;
	}

	public get(endpoint: string, params?: any): Observable<any> {
		return this.httpClient.get(this.createUrl(endpoint), {
			headers: this.addCustomheaders(),
			params: params
		});
	}


	public put(endpoint: string, model: any = {}, params?: any): Observable<any> {
		return this.httpClient.put(this.createUrl(endpoint), JSON.stringify(model), {
			headers: this.addCustomheaders(),
			params: params
		});
	}

	public post(endpoint: string, model: any = {}, params?: any): Observable<any> {
		return this.httpClient.post(this.createUrl(endpoint), JSON.stringify(model), {
			headers: this.addCustomheaders(),
			params: params
		});
	}

	public delete(endpoint: string, params: any = {}): Observable<any> {
		return this.httpClient.delete(this.createUrl(endpoint), {
			headers: this.addCustomheaders(),
			params: params
		})
	}

	addCustomheaders() {
		if (this.headers.has('Authorization')) {
			this.headers = this.headers.delete(HTTP_HEADER_PARAMS.AUTHORIZATION);
		}
		if (localStorage.getItem(LocalStorageProperty.VMATok) != null && !this.sharedService.isValidSession() ) {
			this.headers = this.headers.append(HTTP_HEADER_PARAMS.AUTHORIZATION,
				'Bearer ' + localStorage.getItem(LocalStorageProperty.VMATok));
		}
		return this.headers;
	}
}
