import { Component } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuthService } from './core/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  title = 'vma-ui';
  constructor(
    private readonly dbService: NgxIndexedDBService,
    private readonly authService: AuthService
  ) { }
}
