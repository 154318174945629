import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectBrandComponent } from './multi-select-brand.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';


@NgModule({
  declarations: [
    MultiSelectBrandComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    MultiSelectModule
  ],
  exports: [
    MultiSelectBrandComponent
  ]
})
export class MultiSelectBrandModule { }
