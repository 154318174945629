import { ImageUploadStatus, SubmitStatus, VehAssmntEventScopeTypeUI } from "src/app/common/app.constants";
import { AuditData } from "./audit-data.model";

export class VehicleInfoModel {
    vin: string;
    accessories: string;
}

export class EvidenceUploadDataModel {
    scopeType: VehAssmntEventScopeTypeUI;
    scopeItem: any;
    imgSize: string;
    evidencePath: string;
    uploadProgress: number;
    uploadStatus: ImageUploadStatus;
    file: any;
}

export class VehicleSubmitModel {
    activeTabIndex = 0;
    submitStatus: SubmitStatus;
    vehicleInfo: VehicleInfoModel = new VehicleInfoModel();
    evidenceUploadDataList: Array<EvidenceUploadDataModel> = new Array<EvidenceUploadDataModel>();
    auditData: AuditData;
}
