import * as VMA_Util from '../../utilities/vma-common.util';
import { SecondaryAuditData } from '../ui/secondary-audit.model';

export class SecondaryAuditListApiModel extends SecondaryAuditData { }

export class SecondaryAuditListApiRespModel {
    secondaryAuditRecordsList: Array<SecondaryAuditListApiModel> = new Array<SecondaryAuditListApiModel>();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.secondaryAuditRecordsList)) {
                options.data.secondaryAuditRecordsList.forEach((element: any) => {
                    this.secondaryAuditRecordsList.push({
                        vehDocIdData: element.vehDocId,
                        accessories: element.accessories
                    })
                });
            }
        }
    }
}
