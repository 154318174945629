import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService, SortEvent } from 'primeng/api';
import { Message } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { PrimeIcons } from 'primeng/api';
import { ExportExcelService } from 'src/app/core/services/ui/export-excel.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Table } from 'primeng/table';
import {
  MultiChronicHoldData, MultiChronicHoldObj, ChronicHistoryData, ShipmentHoldRequestData,
  UpdateTlsRequestData
} from 'src/app/core/model/ui/chronic-hold-data.model';

@Component({
  selector: 'app-tls-shipment-hold',
  templateUrl: './tls-shipment-hold.component.html',
  styleUrls: ['./tls-shipment-hold.component.scss'],
  providers: [ConfirmationService, PrimeNGConfig, PrimeIcons]
})

export class TlsShipmentHoldComponent implements OnInit {

  cols: any[];
  selectedRowItems: any;
  offset = 0;
  limit = 100;
  rows = 5;
  totalRecords = 0;
  totalPages = 0;
  showPagination = false;
  count: any;
  chronicHoldList: any = [];
  allchronicHoldList: any = [];
  seriesList: any = [];
  chronicHistoryList: ChronicHistoryData;
  chronicHistList :any = [];
  tlsRequestData: UpdateTlsRequestData;
  filteredSeries: string;
  filteredModelCd: string;
  filteredVdc: string;
  filteredAccessory: string;
  filteredStatus: string;
  filteredUserInfo: string;
  filterActionTaken: string;
  toastMessages: Message[];
  displayHistoryLog: boolean;
  logs: any[];
  action: any;
  actionComment: any;
  series = [];
  select = '';
  displayModal: boolean;
  pos: string;
  searchType: string = "TLSVIEW";
  displayModal2: boolean;
  brandIdParams: any;
  yearNoParams: any;
  seriesIdParams: string;
  modelCodeIdParams: any;
  vdcValue: any;
  accessories: any;
  chronicHldData: any = {};
  chronicHoldId: string;
  actorNmParams: any;
  eventTsParams: any;
  eventNotesParams: any;
  eventStatusParams: any;
  eventERespParams: any;
  vehList: any[];
  rowGroupMetadata: any;
  rowCheckbox: boolean;
  headerCheckbox = false;
  childRowCount: number = 0;
  multiChronicData: MultiChronicHoldData;
  multiSelectObjArr: MultiChronicHoldObj[] = [];
  setTlsEvent: string;
  setComment: string;
  yearNo: string;
  seriesId: string;
  modelCodeId: any;
  accessory: any;
  vdc: any;
  userComment: string;
  addShipmentHoldData: ShipmentHoldRequestData;
  filterAction = false;
  emptyParam: any = {};
  params: any = {};
  // buttonEnabler = this.sharedService.buttonEnabler;

  constructor(
    private readonly vehicleService: VehicleService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private readonly exportExcelService: ExportExcelService,
    private readonly messageService: MessageService
  ) { }

  confirmationDecision() {
    this.confirmationService.confirm({
      message: 'Do you want to release TLS Shipment Hold?',
      header: 'Confirmation',
      accept: () => {
        this.releaseTls();
        this.toastMessages = [
          {
            severity: 'success',
            detail: 'TLS Shipment Hold Released',
          },
        ];
      },
    });
  }

  ngOnInit(): void {
    this.rowCheckbox = false;
    this.sharedService.isMandatoryField = false;
    this.sharedService.isMandatorySeries = false;
    this.sharedService.tlsDialogFlag = false;
    this.emptyParam.limit = this.limit;
    this.emptyParam.offset = this.offset;
    this.getSearchVehicleDetails(this.emptyParam);
    this.updateRowGroupMetaData(this.chronicHoldList);
  }

  historyLog(data) {
    const chronicHoldId = data.chronicHoldId;
    console.log("chronicHoldId", chronicHoldId);
    if (!isNullOrUndefined(chronicHoldId)) {
      this.vehicleService.listChronicHistData(chronicHoldId).subscribe((res: any) => {
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          this.chronicHistList = res.data[0].chronicHistList.chronicHistArr;
          console.log("chronicHistArr", this.chronicHistList);
          this.displayHistoryLog = true;
        } else {
          this.sharedService.handledNoRecords();
          this.resetVehicleData();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  showPosPos(pos: string) {
    this.pos = pos;
    this.displayModal = true;
  }

  addNewShipment() {
    this.displayModal = true;
    this.sharedService.tlsDialogFlag = true;
    this.sharedService.isMandatoryField = true;
    this.sharedService.historyNotes = "";
  }

  // cancelAdding() {
  //   this.displayModal = false;
  // }

  resetVehicleData() {
    this.limit = 100;
    this.offset = 0;
    this.multiSelectObjArr = [];
    this.chronicHoldList = [];
    this.allchronicHoldList = [];
    this.showPagination = false;
    this.sharedService.vehicleSearchData = null;
    this.sharedService.clearButton = true;
    // this.yearNoParams = null;
    this.params = {};
    this.params.limit = this.limit;
    this.params.offset = this.offset;
    setTimeout(() => {
      this.getSearchVehicleDetails(this.params);
    }, 500);
  }

  searchVehicleEvent(searchData: any) {
    if (!isNullOrUndefined(searchData)) {
      this.brandIdParams = searchData.brandId;
      this.yearNoParams = searchData.yearNo;
      this.seriesIdParams = searchData.seriesId;
      this.modelCodeIdParams = searchData.modelCodeId;
      this.vdcValue = searchData.vdcValue;
      this.accessories = searchData.accessory;
      if (searchData.type === "search") {
        this.searchAuditVehicles();
      } else if (searchData.type === "reset") {
        this.resetVehicleData();
      }

    }
  }

  searchAuditVehicles() {
    this.sharedService.clearPreviousMessages();
    this.multiSelectObjArr = [];
    // this.sharedService.vehicleSearchData = null;
    if (this.searchType === "TLSVIEW") {
      this.params = {};
      this.params.limit = this.limit;
      this.params.offset = this.offset;
      this.searchModelData();
    }
  }

  searchModelData() {
    //const params: any = {};
    if (!isNullOrUndefined(this.brandIdParams) && !isNullOrUndefined(this.brandIdParams['code']) && !isNullOrUndefined(this.yearNoParams)) {
      this.params.brand = this.brandIdParams['code'];
      this.params.modelyear = this.yearNoParams;
      if (!isNullOrUndefined(this.seriesIdParams) && !isEmpty(this.seriesIdParams)) {
        this.params.series = this.seriesIdParams['name'];
      }
      if (!isNullOrUndefined(this.modelCodeIdParams) && !isEmpty(this.modelCodeIdParams)) {
        this.params.modelCode = this.modelCodeIdParams['name'];
      }
      if (!isNullOrUndefined(this.vdcValue) && !isEmpty(this.vdcValue)) {
        this.params.vdc = this.vdcValue.map(x => x['code']).join(',');
      }
      if (!isNullOrUndefined(this.accessories) && !isEmpty(this.accessories)) {
        this.params.accessory = this.accessories.map(x => x['code']).join(',');
      }
      console.log("params:", this.params);
      this.params.limit = this.limit;
      this.params.offset = this.offset;
      this.getSearchVehicleDetails(this.params);
    } else {
      this.sharedService.handledMandatoryFields();
    }
  }

  getSearchVehicleDetails(params: any) {
    this.chronicHoldList = [];
    this.allchronicHoldList = [];
    this.vehicleService.listChronicHoldData(params).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        // this.chronicHoldList = res.data[0].chronicHoldData.chronicHoldArr;
        this.allchronicHoldList = res.data[0].chronicHoldData.chronicHoldArr;
        if (!isNullOrUndefined(res.data[0].chronicHoldData.total)) {
          // this.totalRecords = res.data[0].chronicHoldData.total;
          this.totalRecords = this.setTotalRecords();
          this.chronicHoldList = this.setChronicHoldList(0);
          this.offset = res.data[0].chronicHoldData.offset;
          this.setPaginationData();
          this.showPagination = true;
        } else {
          this.showPagination = false;
          this.sharedService.handledNoRecords();
        }
      }
    }, (error: HttpErrorResponse) => {
      this.showPagination = false;
      this.sharedService.handleHttpError(error);
    });
  }

  setChronicHoldList(pageNo) {
    const array: any = [];
    const seriesList = Object.assign([], this.seriesList);
    const data = seriesList.splice(pageNo, this.rows);
    for (let i = 0; i < this.allchronicHoldList.length; i++) {
      const rowData = data.find(item => item === this.allchronicHoldList[i].series);
      if (!isNullOrUndefined(rowData)) {
        array.push(this.allchronicHoldList[i]);
      }
    }
    return array;
  }

  setTotalRecords() {
    this.seriesList = [];
    for (let i = 0; i < this.allchronicHoldList.length; i++) {
      const rowData = this.seriesList.find(item => item === this.allchronicHoldList[i].series);
      if (isNullOrUndefined(rowData)) {
        this.seriesList.push(this.allchronicHoldList[i].series);
      }
    }
    return this.seriesList.length;
  }

  setPaginationData() {
    console.log("pagination data");
    this.totalPages = Math.ceil(this.totalRecords / this.rows);
    if (this.totalPages > this.rows) {
      this.totalPages = 5;
    }
  }

  onPaginator(event) {
    this.rows = event.rows;
    let pageNo = 0;
    if (event.page != 0) {
      pageNo = this.rows + (event.page - 1);
    };
    this.chronicHoldList = this.setChronicHoldList(pageNo);
  }

  export() {
    let infoMessage: string = '';
    if(!this.yearNoParams) {
      infoMessage = '( Info - Only Hold Active data will be loaded by default. )';
    }
    this.exportExcelService.generateChronicHoldExcel(this.chronicHoldList, infoMessage);
  }

  onClearSeries(table1: Table) {
    if (this.filteredSeries === '') {
      table1.filter(this.filteredSeries, 'yearSeriesComb', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterSeries(table1: Table) {
    table1.filter(this.filteredSeries, 'yearSeriesComb', 'contains');
    this.showFilter();
  }

  onClearModelCd(table1: Table) {
    if (this.filteredModelCd === '') {
      table1.filter(this.filteredModelCd, 'modelCode', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterModelCd(table1: Table) {
    table1.filter(this.filteredModelCd, 'modelCode', 'contains');
    this.showFilter();
  }

  onClearVdc(table1: Table) {
    if (this.filteredVdc === '') {
      table1.filter(this.filteredVdc, 'vdc', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterVdc(table1: Table) {
    table1.filter(this.filteredVdc, 'vdc', 'contains');
    this.showFilter();
  }

  onClearAccessory(table1: Table) {
    if (this.filteredAccessory === '') {
      table1.filter(this.filteredVdc, 'accessory', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterAccessory(table1: Table) {
    table1.filter(this.filteredAccessory, 'accessory', 'contains');
    this.showFilter();
  }

  onClearStatus(table1: Table) {
    if (this.filteredStatus === '') {
      table1.filter(this.filteredStatus, 'status', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterStatus(table1: Table) {
    table1.filter(this.filteredStatus, 'status', 'contains');
    this.showFilter();
  }

  onClearUserInfo(table1: Table) {
    if (this.filteredUserInfo === '') {
      table1.filter(this.filteredUserInfo, 'updatedTs', 'contains');
    }
    this.filterAction = this.checkFilterData();
  }

  onFilterUserInfo(table1: Table) {
    table1.filter(this.filteredUserInfo, 'updatedTs', 'contains');
    this.showFilter();
  }

  nppsStatus() {
    if (isNullOrUndefined(this.multiSelectObjArr) || isEmpty(this.multiSelectObjArr)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select atleast one row', life: 10000 });
    } else {
      this.multiSelectObjArr.forEach(data => {
        data.tlsEvent = 'Request Chronic Hold Status';
        data.comments = 'Model Launch Hold';
      });
      console.log(this.multiSelectObjArr);
      this.updateMultiSelectRowData(this.multiSelectObjArr);
    }
  }

  turnOnTls() {
    if (isNullOrUndefined(this.multiSelectObjArr) || isEmpty(this.multiSelectObjArr)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select atleast one row', life: 10000});
    } else {
      this.multiSelectObjArr.forEach(data => {
        data.tlsEvent = 'Request Chronic Hold';
        data.comments = 'Model Launch Hold';
      });
      console.log(this.multiSelectObjArr);
      this.updateMultiSelectRowData(this.multiSelectObjArr);
    }
  }

  releaseTls() {
    if (isNullOrUndefined(this.multiSelectObjArr) || isEmpty(this.multiSelectObjArr)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Select atleast one row', life: 10000 });
    } else {
      this.confirmationService.confirm({
        message: 'Do you want to release TLS Shipment Hold?',
        header: 'Confirmation',
        accept: () => {
          this.multiSelectObjArr.forEach(data => {
            data.tlsEvent = 'Request Chronic Hold Release';
            data.comments = 'Model Launch Hold';
          });
          console.log("this.multiSelectObjArr", this.multiSelectObjArr);
          this.updateMultiSelectRowData(this.multiSelectObjArr);
          // setTimeout(() => {
          //   this.messageService.add({ severity: 'success', summary: '', detail: 'TLS Shipment Hold Released' });
          // }, 800);
        },
      });
    }
  }

  updateRowGroupMetaData(data) {
    this.rowGroupMetadata = {};
    for (let i = 0; i < data.length; i++) {
      let rowData = data[i];
      let seriesName = rowData['yearSeriesComb'];
      if (i == 0) {
        this.rowGroupMetadata[seriesName] = { index: 0, size: 1, seriesCheckbox: false, selectedChildren: 0 };
      } else {
        let previousRowData = data[i - 1];
        let previousRowGroup = previousRowData['yearSeriesComb'];
        if (seriesName === previousRowGroup) {
          this.rowGroupMetadata[seriesName].size++;
        } else {
          this.rowGroupMetadata[seriesName] = { index: i, size: 1, seriesCheckbox: false, selectedChildren: 0 };
        }
      }
    }
  }

  calculateCustomerTotal(name) {
    let totalCount : any = []; 
    for (let value of this.chronicHoldList) {
      if (value['yearSeriesComb'] === name) {
        if (!totalCount.includes(value.modelCode)) {
          totalCount.push(value.modelCode)
        }
      }
    }
    return totalCount.length;
  }

  onFilter(event) {
    this.updateRowGroupMetaData(event.filteredValue);
  }

  customSort(event: SortEvent) {
    this.sort(event.data, 'yearSeriesComb', event.order);
    this.sort(event.data, event.field, event.order);
    this.updateRowGroupMetaData(event.data);
  }

  sort(array, field, order) {
    array.sort((data1, data2) => {
      const value1 = data1[field];
      const value2 = data2[field];
      let result;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (
        typeof value1 === 'string' &&
        typeof value2 === 'string'
      ) {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }

      return order * result;
    });

  }

  headerCheckboxEvent(event, searchData) {  
    searchData.headerCheckbox = event.target.checked;
    console.log(searchData.headerCheckbox, "searchData");
    if (searchData.headerCheckbox) {
      this.addAllChronicHoldData(searchData);
    } else {
      this.removeAllChronicHoldData(searchData);
    }
    console.log("multiSelectObjArr", this.multiSelectObjArr);
  }

  addAllChronicHoldData(data) {
    const chronicleArray: any = this.chronicHoldList.filter((item: any) => item.yearSeriesComb === data.yearSeriesComb);
    for (let i = 0; i < chronicleArray.length; i++) {
      chronicleArray[i].rowCheckbox = true;
      const rowData = this.multiSelectObjArr.find(item => item.chronicHoldId === chronicleArray[i].chronicHoldId);
      if (isNullOrUndefined(rowData)) {
        this.setMultiSelectData(chronicleArray[i]);
      }
    }
    return this.multiSelectObjArr;
  }

  removeAllChronicHoldData(data) {
    for (let i = 0; i < this.chronicHoldList.length; i++) {
      if (this.chronicHoldList[i].yearSeriesComb === data.yearSeriesComb) {
        this.chronicHoldList[i].rowCheckbox = false;
        this.removeChronicHoldData(this.chronicHoldList[i]);
      }
    }
    return this.multiSelectObjArr;
  }

  removeRowChechbox(data) {
    for (let i = 0; i < this.chronicHoldList.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (this.chronicHoldList[i].yearSeriesComb === (data[j].modelYear + ' ' + data[j].series)) {
          this.chronicHoldList[i].rowCheckbox = false;
          this.multiSelectObjArr = [];
        }
      }
    }
    return this.multiSelectObjArr;
  }

  rowCheckboxEvent(searchData) {
    console.log("searchData: ", searchData);
    const rowData = this.multiSelectObjArr.find(item => item.chronicHoldId === searchData.chronicHoldId);
    if (isNullOrUndefined(rowData)) {
      searchData.rowCheckbox = true;
      this.setMultiSelectData(searchData);
    } else {
      searchData.rowCheckbox = false;
      this.removeChronicHoldData(searchData);
    }
    console.log(this.multiSelectObjArr);
  }

  removeChronicHoldData(data) {
    for (let i = 0; i < this.multiSelectObjArr.length; i++) {
      if (this.multiSelectObjArr[i].chronicHoldId === data.chronicHoldId) {
        this.multiSelectObjArr.splice(i, 1);
        break;
      }
    }
    return this.multiSelectObjArr;
  }

  setMultiSelectData(rowData: any) {
    console.log("rowData", rowData);
    let multiChronicDataObj = new MultiChronicHoldObj();
    multiChronicDataObj.chronicHoldId = rowData.chronicHoldId;
    multiChronicDataObj.modelYear = rowData.modelYear;
    multiChronicDataObj.modelCode = rowData.modelCode;
    multiChronicDataObj.series = rowData.series;
    multiChronicDataObj.vdc = rowData.vdc;
    multiChronicDataObj.accessory = rowData.accessory;
    this.multiSelectObjArr.push(multiChronicDataObj);
    console.log(this.multiSelectObjArr);
  }

  updateMultiSelectRowData(multiChronicData: MultiChronicHoldObj[]) {
    multiChronicData.forEach(item => item.chronicHoldId = item.chronicHoldId.toString());
    const requestObj: MultiChronicHoldData = {
      "data": multiChronicData
    };
    console.log("multiChronicData", requestObj);
    this.vehicleService.updateMultiSelectChronic(requestObj).subscribe(res => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.multiSelectObjArr = [];
        this.params.limit = this.limit;
        this.params.offset = this.offset;
        this.getSearchVehicleDetails(this.params);
        if (res.data[0].data) {
          const successData = res.data[0].data;
          console.log("successData", successData);
          const typeInfoMap: { [key: string]: boolean } = {};
          let infoMessage = '';
          successData.forEach((msg) => {
            if (!typeInfoMap[msg.type]) {
              switch (msg.type) {
                case 'HOLD_ACTIVE':
                  infoMessage += `Selected combination is hold active `;
                  break;
                case 'NO_HOLD_EXISTS':
                  infoMessage += `No hold exists for this selected combination `;
                  break;
                case 'COMBINATION_EXISTS':
                  infoMessage += `Selected combination is already on hold `;
                  break;
                case 'ALREADY_RELEASED':
                  infoMessage += `Selected combination cannot be released again `;
                  break;
                default:
                  break;
              }
              typeInfoMap[msg.type] = true;
            }
            infoMessage += `${msg.message}, `;
          });
          infoMessage = infoMessage.slice(0, -2);
          this.messageService.add({ severity: 'info', summary: '', detail: infoMessage, life: 10000  });
          //this.messageService.add({ severity: 'info', summary: '', detail: res.data[0].data, life: 10000  });
        }
      }
    }, (error: HttpErrorResponse) => {
      if (error.status === 404) {
        this.multiSelectObjArr = [];
        this.params.limit = this.limit;
        this.params.offset = this.offset;
        this.getSearchVehicleDetails(this.params);
        this.messageService.add({ severity: 'error', summary: '', detail: error.error.error.errorDesc, life: 10000 });
      } else {
        this.multiSelectObjArr = [];
        this.params.limit = this.limit;
        this.params.offset = this.offset;
        this.getSearchVehicleDetails(this.params);
        this.sharedService.handleHttpError(error);
      }
      //this.getSearchVehicleDetails(this.emptyParam);
      this.removeRowChechbox(this.multiSelectObjArr);
    })
  }

  addModelData(value: any) {
    // const dataObj = new ShipmentHoldRequestData;
    console.log("value", value);
    if (value.type === 'reset') {
      this.displayModal = false;
    } else {
      this.sharedService.clearPreviousMessages();
      this.setAddTlsData(value);
      //this.addTlsData(value);
    }
  }

  setAddTlsData(data) {
    const tlsAddData = new ShipmentHoldRequestData();
    if (!isNullOrUndefined(data.brandId) && !isNullOrUndefined(data.yearNo) && !isNullOrUndefined(data.seriesId) && !isNullOrUndefined(data.modelCodeId) && !isNullOrUndefined(data.vdcValue)
      && !isNullOrUndefined(data.accessory)) {
      // tlsAddData.brand = data.brandId;
      tlsAddData.modelYear = data.yearNo;
      tlsAddData.seriesCd = data.seriesId.name;
      tlsAddData.modelCode = data.modelCodeId.name;
      tlsAddData.vdc = data.vdcValue.code;
      tlsAddData.accessory = data.accessory.code;
      if (!isNullOrUndefined(data.historyNotes)) {
        tlsAddData.historyNotes = data.historyNotes;
      } else {
        tlsAddData.historyNotes = '';
      }
      this.addNewShipmentHold(tlsAddData);
    } else {
      this.sharedService.handledMandatoryFields();
    }
    return tlsAddData;
  }


  addNewShipmentHold(tlsAddData) {
    console.log(tlsAddData, "tlsAddData");
    // console.log("addShipmentHoldData", this.addShipmentHoldData);
    this.vehicleService.addShipmentHold(tlsAddData).subscribe(res => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.displayModal = false;
        this.getSearchVehicleDetails(this.emptyParam);
      }
    }, (error: HttpErrorResponse) => {
      if (error.status === 404) {
        this.params.limit = this.limit;
        this.params.offset = this.offset;
        this.getSearchVehicleDetails(this.params);
        this.messageService.add({ severity: 'info', summary: '', detail: error.error.error.errorDesc, life: 10000 });
      } else {
        console.log("error", error);
      }
    })
  }

  onEditRowData(searchData) {
    console.log("searchData 1", searchData);
    this.chronicHldData = {};
    this.sharedService.vehicleSearchData = {};
    this.sharedService.vehicleSearchData.searchType = "TLSEDITVIEW";
    this.chronicHldData.chronicHoldId = searchData.chronicHoldId;
    this.chronicHldData.comment = '';
    this.vehicleSearchData(searchData);
    this.displayModal2 = true;
    this.sharedService.tlsDialogFlag = true;
    this.sharedService.isMandatoryField = true;
  }

  vehicleSearchData(searchData) {
    console.log("searchData", searchData);
    this.sharedService.brandCd = searchData.brand;
    console.log("brandId", searchData.brand);
    this.sharedService.vehicleSearchData.yearNo = searchData.modelYear;
    this.sharedService.seriesName = searchData.series;
    this.setModelCodeId(searchData.modelCode);
    this.sharedService.vdcName = searchData.vdc;
    this.sharedService.accessoryName = searchData.accessory;
    this.sharedService.historyNotes = searchData.historyNotes;
  }

  searchShipment(event: any) {
    console.log("event", event);
    if (!isNullOrUndefined(event) && event.type === "update") {
      this.updateTlsData(event);
    } else if (!isNullOrUndefined(event) && event.type === "reset") {
      this.displayModal2 = false;
      this.resetSearchData();
    } else {
      console.log("event is undefined");
    }
  }

  dialogClose() {
    this.sharedService.tlsDialogFlag = false;
    console.log("dialogClose");
    this.resetSearchData();
  }

  // cancelSave() {
  //   this.displayModal2 = false;
  //   this.resetSearchData();
  // }


  setModelCodeId(value) {
    this.sharedService.vehicleSearchData.modelCodeId = { code: value, name: value };
  }

  setTlsData(data) {
    const tlsData = new UpdateTlsRequestData();
    if (!isNullOrUndefined(data.yearNo)) {
      tlsData.modelYear = data.yearNo;
    }
    if (!isNullOrUndefined(data.modelCodeId) && !isNullOrUndefined(data.modelCodeId.code)) {
      tlsData.modelCode = data.modelCodeId.code;
    }
    if (!isNullOrUndefined(data.vdcValue) && !isNullOrUndefined(data.vdcValue.code)) {
      tlsData.vdc = data.vdcValue.code;
    }
    if (!isNullOrUndefined(data.seriesId.name)) {
      tlsData.seriesCd = data.seriesId.name;
    }
    if (!isNullOrUndefined(data.accessory) && !isNullOrUndefined(data.accessory.code)) {
      tlsData.accessory = data.accessory.code;
    }
    if (!isNullOrUndefined(data.historyNotes)) {
      tlsData.historyNotes = data.historyNotes;
    } else {
      tlsData.historyNotes = '';
    }
    return tlsData;
  }

  updateTlsData(data) {
    this.sharedService.clearPreviousMessages();
    const tlsData = this.setTlsData(data);
    console.log("Tls Data", tlsData);
    if (this.validateTlsData(tlsData)) {
      if (isNullOrUndefined(tlsData.accessory) || isEmpty(tlsData.accessory)) {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Accessory cannot be empty', life: 10000 });
      } else if (isNullOrUndefined(tlsData.modelCode) || isEmpty(tlsData.modelCode)) {
        this.messageService.add({ severity: 'error', summary: '', detail: 'ModelCode cannot be empty', life: 10000 });
      } else {
        this.updateShipment(tlsData);
      }
    } else {
      console.log("model year & modelcode & vdc is null");
    }
  }

  updateShipment(tlsData) {
    const chronicId = this.chronicHldData.chronicHoldId;
    if (!isNullOrUndefined(chronicId)) {
      this.vehicleService.updateTlsHold(tlsData, chronicId).subscribe((res: any) => {
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          this.params.limit = this.limit;
          this.params.offset = this.offset;
          this.getSearchVehicleDetails(this.params);
          this.displayModal2 = false;
          this.resetSearchData();
          console.log("API call");
        }
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.params.limit = this.limit;
          this.params.offset = this.offset;
          this.getSearchVehicleDetails(this.params);
          this.messageService.add({ severity: 'info', summary: '', detail: error.error.error.errorDesc,life: 10000 });
        } else {
          this.sharedService.handleHttpError(error);
        }
      })
    } else {
      console.log("ChronicId not found");
    }
  }

  validateTlsData(data) {
    let status = true;
    if (isNullOrUndefined(data.modelYear) || isNullOrUndefined(data.modelCode) ||
      isNullOrUndefined(data.vdc)) {
      status = false;
    }
    return status;
  }

  resetSearchData() {
    this.sharedService.seriesName = null;
    this.sharedService.vehicleSearchData = null;
    this.sharedService.vdcName = null;
    this.sharedService.accessoryName = null;
    this.sharedService.brandCd = null;
  }

  showFilter() {
    if (this.filterAction) {
      this.filterAction = this.checkFilterData();
    } else {
      this.filterAction = true;
    }
  }

  checkFilterData() {
    if ((isNullOrUndefined(this.filteredSeries) || this.filteredSeries === "") &&
      (isNullOrUndefined(this.filteredModelCd) || this.filteredModelCd === "") &&
      (isNullOrUndefined(this.filteredVdc) || this.filteredVdc === "") &&
      (isNullOrUndefined(this.filteredAccessory) || this.filteredAccessory === "") &&
      (isNullOrUndefined(this.filteredStatus) || this.filteredStatus === "") &&
      (isNullOrUndefined(this.filteredUserInfo) || this.filteredUserInfo === "")) {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.resetSearchData();
  }

}
