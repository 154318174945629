<div *ngIf="!tlsDialogFlag">
    <div class="p-fluid p-formgrid p-grid" style="justify-content: center; margin: auto;">
        <div class="p-col-12 p-md-2">
            <vma-brand (brandEvent)="selectedBrand($event)"></vma-brand>
        </div>
        <div class="p-col-12 p-md-2">
            <vma-modelyear [brandId]="brand" (yearEvent)="selectedYear($event)"></vma-modelyear>
        </div>
        <div class="p-col-12 p-md-2">
            <vma-series [brandId]="brand" [yearNo]="year" (seriesEvent)="selectedSeries($event)"></vma-series>
        </div>
        <div class="p-col-12 p-md-2">
            <vma-single-select-modelcode [brandId]="brand"  [yearNo]="year" 
                [seriesId]="series" (modelcodeEvent)="selectedModelcode($event)">
            </vma-single-select-modelcode>
        </div>
        <div class="p-col-12 p-md-2">
            <vma-multi-select-vdc  [brandId]="brand" [yearNo]="year" [seriesId]="series" 
                [modelcodeId]="modelcodeValue" (vdcEvent)="selectedVdcValue($event)">
            </vma-multi-select-vdc>
        </div>
        <div class="p-col-12 p-md-2">
            <vma-multi-select-accessories [brandId]="brand" [yearNo]="year" [seriesId]="series" 
                 [modelcodeId]="modelcodeValue" [vdcCd]="vdcValue"
               (accessoryEvent)="selectedAccessory($event)"></vma-multi-select-accessories>
        </div>        
    </div>
    <p-divider align="center" type="dashed">
        <button pButton type="button" icon="pi pi-undo" class="p-button-secondary cmn-button-style p-mr-2" label="RESET"
            (click)="resetData()"></button>
        <button pButton type="button" icon="pi pi-search" class="cmn-button-style" label="SEARCH"
            (click)="vehicleListSearch()"></button>
    </p-divider>
</div>


<div *ngIf="tlsDialogFlag">
    <div class="p-field">
        <vma-brand (brandEvent)="selectedBrand($event)"></vma-brand>
    </div>
    <div class="p-field">
        <vma-modelyear [brandId]="brand" (yearEvent)="selectedYear($event)"></vma-modelyear>
    </div>
    <div class="p-field">
        <chronic-series [brandId]="brand" [yearNo]="year" (seriesEvent)="selectedSeries($event)"></chronic-series>
    </div>
    <div class="p-field">
        <vma-single-select-modelcode [brandId]="brand" [yearNo]="year" [seriesId]="series"
            (modelcodeEvent)="selectedModelcode($event)">
        </vma-single-select-modelcode>
    </div>
    <!-- <div class="p-field"
        style="padding-left:70px;display:flex; flex-direction: row; justify-content: left; align-items: center;margin: auto;">
        <vma-multi-select-vdc [brandId]="brand" [yearNo]="year" [seriesId]="series" [modelcodeId]="modelcodeValue"
            (vdcEvent)="selectedVdcValue($event)">
        </vma-multi-select-vdc>
    </div> -->
    <div  class="p-field">
        <vma-tls-hold-select-vdc [brandId]="brand" [yearNo]="year" [seriesId]="series"
        [modelcodeId]="modelcodeValue" (vdcEvent)="selectedVdcValue($event)"></vma-tls-hold-select-vdc>
    </div>
    <div class="p-field">
        <vma-single-select-accessory [brandId]="brand" [yearNo]="year" [seriesId]="series" [modelcodeId]="modelcodeValue"
            [vdcCd]="vdcValue" (accessoryEvent)="selectedAccessory($event)"></vma-single-select-accessory>
    </div>
    <div class="p-field">
        <label class="cmn-label-font-style text-comment">Comment</label>
        <textarea rows="5" cols="20" pInputTextarea class="cmn-input-font-style" maxlength="125"
            required [(ngModel)]="userComment" (ngModelChange)="textAreaChange()">
        </textarea>
    </div>
    <div class="text-center" *ngIf="editMode">
        <p-button label="Save" styleClass="cmn-button-style" [disabled] ="buttonEnabler" (onClick)="saveTlsData()"></p-button>
        &nbsp;&nbsp;<p-button label="Cancel" styleClass="p-button-outlined cmn-button-style" 
            (onClick)="cancelSave()"></p-button>
    </div>
    <div  class="text-center" *ngIf="!editMode">
        <p-button label="Add" styleClass="cmn-button-style" (click)="addAction()"></p-button>
        &nbsp;&nbsp;<p-button label="Cancel" styleClass="p-button-outlined cmn-button-style" 
            (onClick)="cancelAdding()"></p-button>
    </div>
 </div>
