import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/core/services/ui/shared.service';

@Component({
  selector: 'vma-dashboard-search-by-series',
  templateUrl: './series-view.component.html',
  styleUrls: ['./series-view.component.scss']
})

export class SeriesViewComponent implements OnInit {

  brand: any;
  year: any;
  series: any;
  vdcValue: any;
  modelcodeValue: any;
  accessory: any;
  arrivaldate: boolean;
  @Output() searchEvent = new EventEmitter<any>();

  constructor(
    private readonly sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.sharedService.isMandatoryField = true;
  }

  selectedBrand(brandId: any) {
    this.brand = brandId;
    this.resetYearData();
  }

  selectedYear(yearNo: any) {
    this.year = yearNo;
    this.resetSeriesData();
  }

  selectedSeries(seriesId: any) {
    this.series = seriesId;
    this.resetModelCodeData();
  }

  selectedModelCode(modelcode: any) {
    this.modelcodeValue = modelcode;
    this.accessory = null;
    //this.vdcValue = null;
    this.vehicleListSearch();
  }

  selectedVdcValue(vdc: any) {
    this.vdcValue = vdc;
    this.accessory = null;
    this.vehicleListSearch();
  }

  selectedAccessory(accessories: any) {
    this.accessory = accessories;
    this.vehicleListSearch();
  }

  resetYearData() {
    this.year = null;
    this.series = null;
    this.modelcodeValue = null;
    this.vdcValue = null;
    this.accessory = null;
    this.vehicleListSearch();
  }

  resetSeriesData() {
    this.series = null;
    this.modelcodeValue = null;
    this.vdcValue = null;
    this.accessory = null;
    this.vehicleListSearch();
  }

  resetModelCodeData() {
    this.modelcodeValue = null;
    this.vdcValue = null;
    this.accessory = null;
    this.vehicleListSearch();
  }

  vehicleListSearch() {
    const searchModel: any = {};
    searchModel.brandId = this.brand;
    searchModel.yearNo = this.year;
    searchModel.seriesId = this.series;
    searchModel.modelCodeId = this.modelcodeValue;
    searchModel.vdcValue = this.vdcValue;
    searchModel.accessory = this.accessory;
    searchModel.arrivaldate = this.arrivaldate;
    searchModel.type = "SERIESVIEW";
    this.searchEvent.emit(searchModel);
  }

  onArrivalDateEvent(arrivaldate: any) {
    this.arrivaldate = arrivaldate;
    this.vehicleListSearch();
  }
}
