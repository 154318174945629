import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VdcViewComponent } from './vdc-view.component';
import { BrandModule } from '../../dropdowns/brand/brand.module';
import { ModelyearModule } from '../../dropdowns/modelyear/modelyear.module';
import { SeriesModule } from '../../dropdowns/series/series.module';
import { ButtonModule } from 'primeng/button';
import { SingleSelectVdcModule } from '../../dropdowns/vdc/single-select-vdc/single-select-vdc.module';
import { MultiSelectModelcodeModule } from '../../dropdowns/modelcode/multi-select-modelcode/multi-select-modelcode.module';
import { MultiSelectAccessoriesModule } from '../../dropdowns/accessories/multi-select-accessories/multi-select-accessories.module';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ArrivalDateModule } from '../../checkbox/arrival-date/arrival-date.module';

@NgModule({
  declarations: [
    VdcViewComponent
  ],
  imports: [
    CommonModule,
    BrandModule,
    ModelyearModule,
    SeriesModule,
    SingleSelectVdcModule,
    ArrivalDateModule,
    MultiSelectModelcodeModule,
    MultiSelectAccessoriesModule,
    ButtonModule,
    CheckboxModule,
    FormsModule
  ],
  exports: [
    VdcViewComponent
  ]
})
export class VdcViewModule { }
