import * as VMA_Util from '../../utilities/vma-common.util';
import { AuditData } from "../ui/audit-data.model";

export class GetAuditApiModel extends AuditData { }

export class GetAuditApiResModel {
    auditData: GetAuditApiModel = new GetAuditApiModel();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (!VMA_Util.isNullOrUndefined(options.data.auditData)) {
                this.auditData = options.data.auditData;
            }
        }
    }
}
