import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { S3PreSignedUrlOperation } from 'src/app/common/app.constants';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as fs from 'file-saver';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { DownloadEvidenceModel } from 'src/app/core/model/ui/audit-report.model';
import { SharedService } from 'src/app/core/services/ui/shared.service';

@Component({
  selector: 'app-evidence-download',
  templateUrl: './evidence-download.component.html',
  styleUrls: ['./evidence-download.component.scss']
})
export class EvidenceDownloadComponent implements OnInit {

  downloadEvidenceList: DownloadEvidenceModel[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly vehicleService: VehicleService,
    private readonly router: Router,
    private readonly sharedService: SharedService
  ) { }
  ngOnInit(): void {
    this.getEvidenceKey();
  }
  getEvidenceKey(): void {
    this.route.queryParams.subscribe(async (params) => {
      if (!isNullOrUndefined(params.key)) {
        const evidenceKey = params.key;
        this.getS3SignedUrl(evidenceKey, false);
      }
    });
  }
  createEvidenceList(evidenceKey: string, signedUrl: string) {
    this.downloadEvidenceList = [];
    this.downloadEvidenceList.push({
      key: evidenceKey,
      url: signedUrl
    })
  }
  getS3SignedUrl(evidenceKey: string, download: boolean) {
    const params = {
      objectKey: evidenceKey,
      action: S3PreSignedUrlOperation.Download
    }
    this.vehicleService.getS3PresignedUrl(params).subscribe(res => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        if (download) {
          fs.saveAs(res.data[0].data);
        } else {
          this.createEvidenceList(evidenceKey, res.data[0].data);
        }
        this.clearRouteQueryParams();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }
  clearRouteQueryParams() {
    this.router.navigate([], {
      queryParams: {
        "key": null
      },
      queryParamsHandling: 'merge'
    })
  }
}
