

import { Instance } from 'src/app/common/app.constants';
import * as VMA_Util from '../../utilities/vma-common.util';

class BrandModel {
    brandId: number;
    brandCd: string;
    brandNm: string;
    brandTxt: string;
    instance() {
        return Instance.BrandModel;
    }
}

export class BrandApiModel extends BrandModel { }

export class BrandApiResModel {
    brands: Array<BrandApiModel> = new Array<BrandApiModel>();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.brands)) {
                options.data.brands.forEach(element => {
                    this.brands.push(this.getRowdata(element));
                });
            }
        }
    }
    getRowdata(element: any): BrandApiModel {
        const brandModel: BrandApiModel = new BrandApiModel();
        brandModel.brandId = element.brandId;
        brandModel.brandCd = VMA_Util._trim(element.brandCd);
        brandModel.brandNm = VMA_Util._trim(element.brandNm);
        brandModel.brandTxt = VMA_Util._trim(element.brandTxt);
        return brandModel;
    }
}
