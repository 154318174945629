<div class="cmn-div-padding">
    <h3>AUDIT REPORT</h3>
    <em class="info-style"> ( Info - Please expect delay in loading evidence preview. ) </em>
    <br>
    <br>
    <vma-page-title-info [auditVehicleInfo]="auditVehicleInfo"></vma-page-title-info>

    <p-divider align="right" type="dashed">
        <button type="button" label="Back" pButton pRipple icon="pi pi-arrow-left" style="margin: 0.25rem 0.125rem;"
            class="p-button-secondary cmn-button-style p-mr-2" (click)="back()"></button>

        <button type="button" label="Export" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
            style="margin: 0.25rem 0.125rem;" class="p-button-success cmn-button-style p-mr-2" pTooltip="XLS"
            tooltipPosition="bottom"></button>

        <button *ngIf="auditReportFlag && globalCommentData.comment == null" type="button" label="Add Comment" pButton pRipple
            icon="pi pi-comment" style="margin: 0.25rem 0.125rem;" class="p-button-info cmn-button-style"
            (click)="onAddGlobalComment()"></button>

        <button *ngIf="globalCommentData.comment != null" type="button" label="View Comment" pButton pRipple icon="pi pi-inbox"
            style="margin: 0.25rem 0.125rem;" class="p-button-help cmn-button-style"
            (click)="viewGlobalComment()"></button>
    </p-divider>

    <h4>Input vs NVS Value Comparison Report</h4>
    <p-table [value]="inputDataComparisonReportList" responsiveLayout="scroll" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
            <tr>
                <th class="cmn-table-header-style" scope="col">Label Type</th>
                <th class="cmn-table-header-style" scope="col">Parameter Name</th>
                <th class="cmn-table-header-style" scope="col">Input Value</th>
                <th class="cmn-table-header-style" scope="col">NVS Value</th>
                <th class="cmn-table-header-style" scope="col">Status</th>
                <th *ngIf="auditReportFlag == false" class="cmn-table-header-style" scope="col">Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="cmn-table-data-style">{{rowData.scopeType}}</td>
                <td class="cmn-table-data-style">{{rowData.scopeItem}}</td>
                <td class="cmn-table-data-style">{{rowData.inputValue}}</td>
                <td class="cmn-table-data-style">{{rowData.nvsValue}}</td>
                <td class="cmn-table-data-style">
                    <span [class]="'cmn-validation-status-badge cmn-validation-status-' + rowData.validationStatus">
                        {{rowData.validationStatus}}</span>
                </td>
                <td *ngIf="auditReportFlag == false && rowData.validationStatus != 'Waived'" class="cmn-table-data-style">
                    <button *ngIf="rowData.validationStatus != 'Mismatch' || rowData.comment != null" pButton pRipple
                        icon="pi pi-times" [style]="{'height' : '25px' , 'width' :'25px'}"
                        class="p-button-rounded p-button-danger p-mr-2" (click)="onMismatch(rowData)"></button>

                    <button *ngIf="rowData.comment != null" pButton pRipple icon="pi pi-comment"
                        class="p-button-rounded p-button-secondary p-mr-2"
                        [style]="{'height' : '25px' , 'width' :'25px'}" (click)="viewComment(rowData)"></button>

                    <button *ngIf="rowData.evidences.length != 0" pButton pRipple icon="pi pi-image"
                        class="p-button-rounded p-button-secondary p-mr-2"
                        [style]="{'height' : '25px' , 'width' :'25px'}" (click)="viewAttachments(rowData)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="inputDataComparisonReportList.length" class="cmn-table-data-style">
                    No Records found
                </td>
            </tr>
        </ng-template>
    </p-table>

    <br>
    <h4>Line By Line Verification Report</h4>
    <p-table [value]="lineByLineVerificationReportList" responsiveLayout="scroll" styleClass="p-datatable-sm"
        rowGroupMode="rowspan" groupRowsBy="scopeItem">
        <ng-template pTemplate="header">
            <tr>
                <th class="cmn-table-header-style" scope="col">Parameter Name</th>
                <th class="cmn-table-header-style" scope="col">NVS Value</th>
                <th class="cmn-table-header-style" scope="col">Status</th>
                <th class="cmn-table-header-style" scope="col">Comment</th>
                <th class="cmn-table-header-style" scope="col">Preview</th>
                <th class="cmn-table-header-style" scope="col">Download</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowgroup="rowgroup" let-rowspan="rowspan">
            <tr>
                <td class="cmn-table-data-style" *ngIf="rowgroup" [attr.rowspan]="rowspan">{{rowData.scopeItem}}</td>
                <td class="cmn-table-data-style">{{rowData.nvsValue}}</td>
                <td class="cmn-table-data-style">
                    <span [class]="'cmn-validation-status-badge cmn-validation-status-' + rowData.validationStatus">
                        {{rowData.validationStatus}}</span>
                </td>
                <td class="cmn-table-data-style">{{rowData.comment}}</td>
                <td class="cmn-table-data-style">
                    <p-image *ngIf="rowData.evidence?.length  == 1" [src]="rowData.evidence[0].imgUrl" height="25"
                        alt="Image" width="25" [preview]="true">
                    </p-image>
                    <button *ngIf="rowData.evidence?.length  > 1" [style]="{'height' : '25px' , 'width' :'25px'}"
                        pButton type="button" icon="pi pi-images" class="p-button-secondary"
                        (click)="previewImages(rowData.evidence)"></button>
                </td>
                <td class="cmn-table-data-style">
                    <button *ngIf="rowData.evidence?.length  != 0" [style]="{'height' : '25px' , 'width' :'25px'}"
                        pButton type="button" icon="pi pi-download" class="p-button-secondary"
                        (click)="downloadEvidence(rowData.evidence)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="lineByLineVerificationReportList.length" class="cmn-table-data-style">
                    No Records found
                </td>
            </tr>
        </ng-template>
    </p-table>

    <br>
    <h4>Base Label Evidences</h4>
    <p-table [value]="baseLabelEvidenceList" responsiveLayout="scroll" styleClass="p-datatable-sm"
        rowGroupMode="rowspan" groupRowsBy="scopeItem">
        <ng-template pTemplate="header">
            <tr>
                <th class="cmn-table-header-style" scope="col">Label Type</th>
                <th class="cmn-table-header-style" scope="col">Preview</th>
                <th class="cmn-table-header-style" scope="col">Download</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="cmn-table-data-style">{{rowData.scopeType}}</td>
                <td class="cmn-table-data-style">
                    <p-image *ngIf="rowData.evidence" [src]="rowData.evidence.imgUrl" height="25" alt="Image" width="25"
                        [preview]="true">
                    </p-image>
                </td>
                <td class="cmn-table-data-style">
                    <button *ngIf="rowData.evidence" [style]="{'height' : '25px' , 'width' :'25px'}" pButton pRipple
                        icon="pi pi-download" class="p-button-secondary"
                        (click)="downloadEvidence([rowData.evidence])"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="baseLabelEvidenceList.length" class="cmn-table-data-style">
                    No Records found
                </td>
            </tr>
        </ng-template>
    </p-table>
    <br>

    <h4>Additional Label Evidences</h4>
    <p-table [value]="additionalLabelEvidenceList" responsiveLayout="scroll" styleClass="p-datatable-sm"
        rowGroupMode="rowspan" groupRowsBy="scopeItem">
        <ng-template pTemplate="header">
            <tr>
                <th class="cmn-table-header-style" scope="col">Label Type</th>
                <th class="cmn-table-header-style" scope="col">Comment</th>
                <th class="cmn-table-header-style" scope="col">Preview</th>
                <th class="cmn-table-header-style" scope="col">Download</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="cmn-table-data-style">{{rowData.scopeType}}</td>
                <td class="cmn-table-data-style">{{rowData.comment}}</td>
                <td class="cmn-table-data-style">
                    <p-image *ngIf="rowData.evidence" [src]="rowData.evidence.imgUrl" height="25" alt="Image" width="25"
                        [preview]="true">
                    </p-image>
                </td>
                <td class="cmn-table-data-style">
                    <button *ngIf="rowData.evidence" [style]="{'height' : '25px' , 'width' :'25px'}" pButton pRipple
                        icon="pi pi-download" class="p-button-secondary"
                        (click)="downloadEvidence([rowData.evidence])"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="additionalLabelEvidenceList.length" class="cmn-table-data-style">
                    No evidences found
                </td>
            </tr>
        </ng-template>
    </p-table>
    <br>
    <br>
    <p-galleria [value]="images" [(visible)]="displayGallery" [responsiveOptions]="responsiveOptions" [numVisible]="5"
        [showThumbnails]="false" [fullScreen]="true" [showIndicators]="true" indicatorsPosition="bottom">
        <ng-template pTemplate="item" let-item>
            <p-image [src]="item.imgUrl" alt="Image" width="250" [preview]="true">
            </p-image>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="grid grid-nogutter justify-content-center">
                <img [src]="item.imgUrl" style="display: block;" alt="image" />
            </div>
        </ng-template>
    </p-galleria>
    <br>

    <!-- GLOBAL COMMENT SECTION -->
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayGlobalCommentPopup" [style]="{'width': '50vw'}">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Add Comment</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <textarea rows="12" cols="100" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="tempGlobalComment" maxlength="250"
                required [style]="{'width': '100%'}"></textarea>
        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-save" styleClass="cmn-button-style" [disabled]="tempGlobalComment == null"
                (onClick)="saveGlobalComment(tempGlobalComment)" label="Save"> </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="viewGlobalCommentPopup" [style]="{'width': '50vw'}">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Comment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <textarea rows="12" cols="100" [disabled]="!editingGlobalComment" [autoResize]="true" pInputTextarea
                class="comment-font-style" [(ngModel)]="globalCommentData.comment" maxlength="250"
                [style]="{'width': '100%'}"> </textarea> <br>

            <div class="edit-container">
                <div>
                    <p-button *ngIf="auditReportFlag" icon="pi pi-pencil" styleClass="p-button-secondary p-mr-1"
                        [style]="{'margin-right': '.5em'}" title="Edit" (click)="onEditGlobalComment()"></p-button>
                    <p-button *ngIf="auditReportFlag" icon="pi pi-trash" styleClass="p-button-danger"
                        [style]="{'margin-right': '.5em'}" title="Delete" (click)="onDeleteGlobalComment()"></p-button>
                </div>
                <div>
                    <h4 class="user-info-style">
                        Comment entered by <strong>{{globalCommentData.userName}}</strong> on
                        <strong>{{globalCommentData.updatedDate}}</strong>
                    </h4>
                </div>
                <div>
                    <button *ngIf="editingGlobalComment" pButton pRipple type="button" icon="pi pi-check"
                        [style]="{'margin-right': '.5em'}" (click)="saveEditedGlobalComment(globalCommentData.comment)"
                        class="p-button-success p-mr-1"></button>
                    <button *ngIf="editingGlobalComment" pButton pRipple type="button" icon="pi pi-times"
                        [style]="{'margin-right': '.5em'}" (click)="cancelEditedComment()"
                        class="p-button-secondary"></button>
                </div>
            </div>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayDeleteConfirmPopup" [style]="{'width': '28vw'}">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">
                <strong> Are you sure to delete the comment and change the status to Submitted?</strong>
            </h4>
        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style"
                (click)="confirmNotDeleteComment()" label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" (click)="confirmDeleteComment()" label="Yes">
            </p-button>
        </ng-template>
    </p-dialog>

    <!-- ROW COMMENTS SECTION -->
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayMismatchPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <h4 class="cmn-input-font-style">Are you sure to mark this value as<strong>
                    Mismatch</strong> ?
            </h4>
            <textarea rows="6" cols="50" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
                placeholder="Do you want to add some comments ?" [(ngModel)]="comparisonCommentModel.comment"
                maxlength="250" required> </textarea>
            <div *ngIf="!comparisonCommentModel.comment" class="cmn-validation-error">
                <small>
                    Reason for mismatch is mandatory.
                </small>
            </div>
            <br>
            <p-fileUpload chooseIcon="pi pi-plus" chooseLabel="Add Attachment" [multiple]="true"
                styleClass="cmn-input-font-style" [showUploadButton]="false" [showCancelButton]="false" accept="image/*"
                capture="camera" (onSelect)="onAttachFiles($event)">
            </p-fileUpload>

        </ng-template>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" styleClass="p-button-text cmn-button-style" (click)="onConfirmNotMismatch()"
                label="No"> </p-button>
            <p-button icon="pi pi-check" styleClass="cmn-button-style" [disabled]="!comparisonCommentModel.comment"
                (click)="onConfirmMismatch()" label="Yes"> </p-button>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayCommentPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Comment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <textarea rows="6" cols="50" [disabled]="true" [autoResize]="true" pInputTextarea
                class="cmn-input-font-style" [(ngModel)]="comparisonCommentModel.comment" maxlength="250"> </textarea>
            <br>
        </ng-template>
    </p-dialog>
    <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayAttachmentsPopup">
        <ng-template pTemplate="header">
            <h1 class="cmn-label-font-style">Attachment Section</h1>
        </ng-template>
        <ng-template pTemplate="content">
            <p-fileUpload chooseIcon="pi pi-plus" [disabled]="true" [files]="comparisonCommentModel.evidences"
                chooseLabel="Add Attachment" styleClass="cmn-input-font-style" [multiple]="true"
                [showUploadButton]="false" [showCancelButton]="false" accept="image/*" capture="camera"
                (onRemove)="onRemoveAttachment($event)">
            </p-fileUpload>
        </ng-template>
    </p-dialog>

    <div *ngIf="auditReportFlag == false" class="p-d-flex p-jc-between page-action-panel-style">
        <div>
            <button pButton type="button" icon="pi pi-save" class="p-button-secondary cmn-button-style p-mr-2"
                label="Save Marked Issues" [disabled]="true" (click)="save()"></button>
            <button pButton type="button" icon="pi pi-check-circle" class="cmn-button-style" label="APPROVE"
                (click)="approve($event.target, 'Approved')" [disabled]="anyMismatch"></button>
        </div>
    </div>
</div>