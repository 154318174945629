import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { transformScopeItemForUI, transformScopeTypeForUI } from 'src/app/core/utilities/data-transform.util';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { AuditVehicleListDetails } from 'src/app/core/model/ui/audit-vehicle.data.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MessageService } from 'primeng/api';
import { SmartChronicData, SmartChronicHoldObj, SmartChronicHoldReqObj, SmartChronicRequestData, VehAssmntIdData } from 'src/app/core/model/ui/chronic-hold-data.model';
import { AuditVehicleListComponent } from 'src/app/features/audited-vehicle/vehicle-list.component';
import { ReleaseStatus, SubmitStatus } from 'src/app/common/app.constants';

@Component({
  selector: 'vma-release-audit',
  templateUrl: './release-audit.component.html',
  styleUrls: ['./release-audit.component.scss'],
})
export class ReleaseAuditComponent implements OnInit {
  releaseList: any[];
  allVehicelist: AuditVehicleListDetails[] = [];
  totalRecords = 0;
  @Input() dataFromVehicleList: any;
  groupedData: any[] = [];
  mockData: any[];
  smartChronicObjArr: SmartChronicHoldObj[] = [];
  rowRetryObjArr: SmartChronicHoldReqObj[] = [];
  smartChronicArray: SmartChronicHoldReqObj[] = [];
  smartChronicReqObj: any;
  errorMessage: string = '';
  externalMessage: string = '';
  alreadyExistMessage: string = '';
  tooManyRequestMessage: string = '';
  accessoryNotfoundMessage: string = '';
  apiStat: ReleaseStatus;
  accessoriesFromApi: any[] = [];
  flaggedAccessories: any[] = [];
  globalFailedAcc: any[] = [];
  failedAccessories: any[] = [];
  succeededAccessories: any[] = [];
  releasedAccessories: any[] = [];
  globalFailedAccFlag = false;
  errorArray: Array<any> = [];
  hideColumn: boolean = false;
  externalFailedArray: Array<any> = [];
  releasedErrorArray: Array<any> = [];
  alreadyExistArray: Array<any> = [];
  tooManyRequestsArray: Array<any> = [];
  constructor(public sharedService: SharedService,
    private readonly vehicleService: VehicleService,
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly indexedDBService: NgxIndexedDBService,
    private readonly vehicleList: AuditVehicleListComponent,
    public authService: AuthService

  ) { }

  ngOnInit(): void {
    this.groupedData = this.groupData(this.dataFromVehicleList, 'vdc', 'modelCode', 'accessoryArray');
    this.globalFailedAcc = [];
    this.groupedData.forEach((item) => {
      const groupedDataArray: any[] = [];
      item.errStatus = false;
      item.loadingStatus = true;
      item.apiStatus = ReleaseStatus.NULL;
      this.apiStat = ReleaseStatus.NULL;
      groupedDataArray.push(item);
      this.setSmartChronicReqData(groupedDataArray);
      this.updateMultiSelectRowData(this.smartChronicArray, item);
      item.apiStatus = ReleaseStatus.NULL;
      this.apiStat = ReleaseStatus.NULL;
      this.errorArray = [];
      this.externalFailedArray = [];
    });
    this.globalFailedAcc = [];
  }

  groupData(data: any[], vdc: string, modelCode: string, accessoryArray: any): any[] {
    this.succeededAccessories = [];

    let finalArray: any[] = [];
    let groupObj;
    data.forEach((item) => {
      if (isNullOrUndefined(groupObj)) {
        groupObj = item;
        // groupObj['accessoryArray'] = [{'code':item.accessories,'errorInd':false}]
        const splitAccyArr = item.accessories.split('|');
        groupObj['accessoryArray'] = [];
        for (let i = 0; i < splitAccyArr.length; i++) {
          groupObj['accessoryArray'].push({ 'code': splitAccyArr[i], 'errorInd': false })
        }
        groupObj['assessmentData'] = [{ 'vehAssmntId': item.vehAssmntId, 'accessory': item.accessories }]
        finalArray.push(groupObj);

      } else {
        const groupVar = finalArray.find(element => element.modelCode === item.modelCode && item.vdc === element.vdc);
        if (isNullOrUndefined(groupVar)) {
          groupObj = item;
          const splitAccyArr = item.accessories.split('|');
          groupObj['accessoryArray'] = [];
          for (let i = 0; i < splitAccyArr.length; i++) {
            groupObj['accessoryArray'].push({ 'code': splitAccyArr[i], 'errorInd': false })
          }

          groupObj['assessmentData'] = [{ 'vehAssmntId': item.vehAssmntId, 'accessory': item.accessories }]
          finalArray.push(groupObj);
        } else {
          let index = finalArray.findIndex(element => element.modelCode === item.modelCode && item.vdc === element.vdc);
          //check acc and then push teh new value

          if (index > -1) {

            // finalArray[index].accessoryArray.push({'code':item.accessories,'errorInd':false});
            const splitAccyArr = item.accessories.split('|');
            // groupObj['accessoryArray'] = [];
            for (let i = 0; i < splitAccyArr.length; i++) {
              // const accessoryConst = finalArray[index].accessoryArray.find(value => value.code == splitAccyArr[i] );
              if (isNullOrUndefined(finalArray[index].accessoryArray.find(value => value.code == splitAccyArr[i]))) {
                finalArray[index].accessoryArray.push({ 'code': splitAccyArr[i], 'errorInd': false })
              }

            }

            finalArray[index].assessmentData.push({ 'vehAssmntId': item.vehAssmntId, 'accessory': item.accessories });
            finalArray[index].vin = finalArray[index].vin.concat("\n").concat(item.vin);
          }
        }

      }
    });
    return finalArray;
  }

  setRowRetryData(rowData: any) {
    let rowRetryDataObj = new SmartChronicHoldReqObj();
    for (const id of rowData.assessmentData) {
      const vehId: VehAssmntIdData = {
        "vehAssmntId": id.vehAssmntId.toString(),
        "accessory": id.accessory
      };
      rowRetryDataObj.vehAssmntData.push(vehId);
    }
    rowRetryDataObj.modelYear = rowData.modelYear;
    rowRetryDataObj.modelCode = rowData.modelCode;
    rowRetryDataObj.vdc = rowData.vdc;
    rowRetryDataObj.accessoryArray = rowData.accessoryArray;
    rowRetryDataObj.loadingPercentage = 0;

    if (!isNullOrUndefined(this.failedAccessories) && !isEmpty(this.failedAccessories)) {
      rowRetryDataObj.accessories = this.failedAccessories.join('|');
    } else {
      // rowRetryDataObj.accessories = rowData.accessories;
      const accessoryConstArray: any = [];
      for (let i = 0; i < rowData.accessoryArray.length; i++) {
        accessoryConstArray.push(rowData.accessoryArray[i].code);
      }
      rowRetryDataObj.accessories = accessoryConstArray.join('|');
    }
    this.rowRetryObjArr.push(rowRetryDataObj);
  }

  setSmartChronicReqData(groupedData: any) {
    this.smartChronicArray = [];
    let smartChronicDataObj = new SmartChronicHoldReqObj();
    for (const id of groupedData[0].assessmentData) {
      const vehId: VehAssmntIdData = {
        "vehAssmntId": id.vehAssmntId.toString(),
        "accessory": id.accessory
      };
      smartChronicDataObj.vehAssmntData.push(vehId);
    }
    smartChronicDataObj.modelYear = groupedData[0].modelYear;
    smartChronicDataObj.modelCode = groupedData[0].modelCode;
    smartChronicDataObj.vdc = groupedData[0].vdc;
    const accessoryConstArray: any = [];
    for (let i = 0; i < groupedData[0].accessoryArray.length; i++) {
      accessoryConstArray.push(groupedData[0].accessoryArray[i].code);
    }
    smartChronicDataObj.accessories = accessoryConstArray.join('|');
    // smartChronicDataObj.accessories = groupedData[0].accessories;
    smartChronicDataObj.loadingPercentage = groupedData[0].loadingPercentage;
    smartChronicDataObj.accessoryArray = groupedData[0].accessoryArray;
    smartChronicDataObj.errStatus = groupedData[0].errStatus;
    this.smartChronicArray.push(smartChronicDataObj);
  }

  updateMultiSelectRowData(multiSmartChronicData: SmartChronicHoldReqObj[], rowData: any) {
    if (!isNullOrUndefined(multiSmartChronicData)) {
      multiSmartChronicData.forEach(item => item.vehAssmntData = item.vehAssmntData);
      const requestObj: SmartChronicHoldReqObj = multiSmartChronicData[0];
      rowData.loadingPercentage = 0;
      rowData.loadingStatus = true;
      let interval = setInterval(() => {
        rowData.loadingPercentage = rowData.loadingPercentage + Math.floor(Math.random() * 10) + 1;
        if (rowData.loadingPercentage >= 100) {
          rowData.loadingPercentage = 100;
        }
      }, 100);

      this.vehicleService.updateMultiSelectSmartChronic(requestObj).subscribe((res: any) => {

        if (!isNullOrUndefined(res.data[0].data)) {
          console.log("data.succeededAccessories", res.data[0].data.succeededAccessories);
          this.succeededAccessories = [];
          this.releasedAccessories = [];
          this.succeededAccessories = res.data[0].data.succeededAccessories;
          this.releasedAccessories = res.data[0].data.alreadyReleasedAcc;
          this.modifyAccessoryRowData(this.releasedAccessories, requestObj, this.succeededAccessories);
          if(this.releasedAccessories.length === requestObj.accessoryArray.length) {
            this.apiStat = ReleaseStatus.ALREADY_RELEASED;
            rowData.apiStatus = ReleaseStatus.ALREADY_RELEASED;
          } else {
            this.apiStat = ReleaseStatus.RELEASE_SUCCESS;
            rowData.apiStatus = ReleaseStatus.RELEASE_SUCCESS;
          }
        }
        clearInterval(interval);
        rowData.loadingPercentage = 100;
        if (rowData.loadingPercentage === 100) {
          this.hideColumn = true;
        }
        rowData.loadingStatus = false;

      }, (error: HttpErrorResponse) => {
        if (error.status === 500) {  // External failed accessories
          this.errorMessage = '';
          this.externalMessage = `${this.externalMessage}` + ` ` + `${requestObj.modelCode}` + ` at ` + `${requestObj.vdc}` + `, `;
          this.succeededAccessories = [];
          this.succeededAccessories = error.error.error.errorDesc.succeededAccessories;
          clearInterval(interval);
          rowData.loadingPercentage = 100;
          if (rowData.loadingPercentage === 100) {
            this.hideColumn = true;
          }
          this.failedAccessories = [];
          this.failedAccessories = error.error.error.errorDesc.failedAccessories;
          //rowData.items[0].loadingStatus = true;
          this.modifyAccessoryRowData(this.failedAccessories, rowData, this.succeededAccessories);
          rowData.errStatus = false;
          if (!isEmpty(this.failedAccessories) && (this.failedAccessories.length < requestObj.accessoryArray.length)) {
            this.apiStat = ReleaseStatus.SYNC_ISSUE;
            rowData.apiStatus = ReleaseStatus.SYNC_ISSUE;
          } else {
            this.apiStat = ReleaseStatus.RELEASE_FAILED;
            rowData.apiStatus = ReleaseStatus.RELEASE_FAILED;/// To be discussed
          }
        } else if (error.status === 404) { //Accessory not found in the database
          this.accessoryNotfoundMessage = `${this.accessoryNotfoundMessage}` + `${error.error.error.errorDesc}` + `, `;
          this.errorArray = [];
          this.succeededAccessories = [];
          this.errorArray.push(this.accessoryNotfoundMessage);
          clearInterval(interval);
          rowData.loadingPercentage = 100;
          rowData.errStatus = false;
          if (rowData.loadingPercentage === 100) {
            this.hideColumn = true;
          }
          rowData.apiStatus = ReleaseStatus.RELEASE_FAILED;
          this.apiStat = ReleaseStatus.RELEASE_FAILED;
        } else if (error.status === 409 && error.error.error.errorCode === 'Resource Conflict') {  //Combination already released
          this.alreadyExistMessage = '';
          this.alreadyExistMessage = error.error.error.errorDesc;
          this.errorArray = [];
          this.externalFailedArray = [];
          clearInterval(interval);
          rowData.loadingPercentage = 100;
          rowData.errStatus = false;
          // rowData.items[0].loadingStatus = true;
          if (rowData.loadingPercentage === 100) {
            this.hideColumn = true;
          }
          this.apiStat = ReleaseStatus.ALREADY_RELEASED;
          rowData.apiStatus = ReleaseStatus.ALREADY_RELEASED;

        } else if (error.status === 409 && error.error.error.errorCode === 'Resource not found') {  // Too many requests
          this.tooManyRequestMessage = '';
          this.tooManyRequestMessage = `${this.tooManyRequestMessage}` + ` ` + `${requestObj.modelCode}` + ` at ` + `${requestObj.vdc}` + `, `;
          this.tooManyRequestsArray = [];
          this.tooManyRequestsArray.push(this.tooManyRequestMessage);
          this.failedAccessories = [];
          this.failedAccessories = error.error.error.errorDesc.failedAccessories;
          this.succeededAccessories = [];
          this.succeededAccessories = error.error.error.errorDesc.succeededAccessories;
          clearInterval(interval);
          rowData.loadingPercentage = 100;
          rowData.errStatus = false;
          // rowData.items[0].loadingStatus = true;
          if (rowData.loadingPercentage === 100) {
            this.hideColumn = true;
          }
          // rowData.items[0].loadingStatus = true;
          this.modifyAccessoryRowData(this.failedAccessories, rowData, this.succeededAccessories);
          this.setErrorStatus(rowData);
          this.setGlogalAccFlag();
          if (!isEmpty(this.failedAccessories)) {
            const retryObj = {
              failedAcc: this.failedAccessories,
              configDetails: requestObj,
              rowLevelData: rowData
            }
            this.globalFailedAcc = [];
            this.globalFailedAcc.push(retryObj);
          }
          this.apiStat = ReleaseStatus.RELEASE_FAILED;
          rowData.apiStatus = ReleaseStatus.RELEASE_FAILED;
        }
        else {
          this.messageService.add({ severity: 'error', summary: '', detail: error.error.error.errorDesc, life: 2000  });
          this.errorMessage = error.error.error.errorDesc;
          this.errorArray = [];
          this.succeededAccessories = [];
          this.releasedAccessories = [];
          this.failedAccessories = [];
          this.externalFailedArray = [];
          clearInterval(interval);
          rowData.loadingPercentage = 100;
          rowData.errStatus = false;
          // rowData.items[0].loadingStatus = true;
          if (rowData.loadingPercentage === 100) {
            this.hideColumn = true;
          }
          this.apiStat = ReleaseStatus.RELEASE_FAILED;
          rowData.apiStatus = ReleaseStatus.RELEASE_FAILED;
        }
        this.vehicleList.removeRowChechbox(this.smartChronicObjArr);
        rowData.loadingPercentage = 0;
        // this.globalFailedAcc = [];
      })
    }
  }

  modifyAccessoryRowData(failedAccessories: any[], rowData: any, succeededAccessories: any[]) {
    const accessoryData = rowData?.accessoryArray;
    for (let i = 0; i < failedAccessories.length; i++) {
      const accessoryItem = accessoryData?.find(item => item.code === failedAccessories[i]);
      if (!isNullOrUndefined(accessoryItem)) {
        accessoryItem.errorInd = true;
        rowData.errStatus = true;
      }
    }
    for (let i = 0; i < succeededAccessories.length; i++) {
      const accessoryPassed = accessoryData?.find(item => item.code === succeededAccessories[i]);
      if (!isNullOrUndefined(accessoryPassed)) {
        accessoryPassed.errorInd = false;
        rowData.errStatus = false;
      }
    }
  }

  setErrorStatus(rowData) {
    for (let i = 0; i < rowData?.accessoryArray?.length; i++) {
      if (rowData.accessoryArray[i].errorInd) {
        rowData.errStatus = true;
        break;
      }
    }
  }

  setGlobalRetryData() {
    this.errorMessage = '';
    this.externalMessage = '';
    this.hideColumn = false;
    this.globalFailedAcc.forEach(element => {
      element.configDetails.accessories = element.failedAcc.join('|').toString();
      let globalRetryObjArr: SmartChronicHoldReqObj[] = [];
      globalRetryObjArr.push(element.configDetails);
      this.errorArray = [];
      this.externalFailedArray = [];
      const rowData = element.rowLevelData;
      this.updateMultiSelectRowData(globalRetryObjArr, rowData);
      this.globalFailedAcc = [];
      globalRetryObjArr = [];
      this.externalFailedArray = [];
    });

  }

  setGlogalAccFlag() {
    for (let i = 0; i < this.groupedData.length; i++) {
      if (this.groupedData[i].errStatus) {
        this.globalFailedAccFlag = true;
        break;
      }
    }
  }

  retry(rowItem) {
    this.errorMessage = '';
    this.externalMessage = '';
    this.hideColumn = false;
    this.setRowRetryData(rowItem);
    this.errorArray = [];
    this.externalFailedArray = [];
    this.updateMultiSelectRowData(this.rowRetryObjArr, rowItem);
    this.failedAccessories = [];
    this.rowRetryObjArr = [];
    this.succeededAccessories = [];
  }

}
