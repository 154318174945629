<div class="padding">
    <h4>HISTORY VIEW</h4>

    <!-- <p-button label="modelYearParams" styleClass="p-button-tertiary"></p-button>
    <p-button label="Toyota" styleClass="p-button-tertiary"></p-button>
    <p-button label="seriesParams" styleClass="p-button-tertiary"></p-button> -->
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field" style="margin-bottom: 0;">
            <p-badge [value]="modelYearParams" size="large" class="p-col-fixed" severity="info" styleClass="mr-2">
            </p-badge>
        </div>
        <div class="p-field" style="margin-bottom: 0;">
            <p-badge [value]="brand" size="large" class="p-col-fixed" severity="info" styleClass="mr-2">
            </p-badge>
        </div>
        <div class="p-field" style="margin-bottom: 0;">
            <p-badge [value]="seriesParams" size="large" class="p-col-fixed" severity="info" styleClass="mr-2">
            </p-badge>
        </div>
    </div>
<br>
    <div style="width: 115%; display: block;">
        <p-accordion [activeIndex]="0">
            <p-accordionTab [header]="modelCodeParams" id="accHead">
                <div class="card">
                    <p-timeline [value]="allStatusList" align="left" style-class="customized-timeline">
                        <ng-template pTemplate="marker" let-rowData>
                            <span class="custom-marker" >
                                <i class='fas fa-arrow-circle-right' style='font-size:2rem;color:#0288D1 !important'></i>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content" let-rowData let-rowIndex="rowIndex">
                            <!-- <i class='pi pi-arrow-circle-right' style='font-size:24px;color:lightblue'></i> -->
                            <!-- <th class="tableHeadingSize" rowspan="2" *ngFor="let col of cols">{{col.header}}</th> -->
                            <table class="tab" >
                                <tr>
                                    <th colspan="6">{{&#160;rowData.statuscode}}</th>
                                    <!-- <th colspan="6">inprogress</th>
                                    <th colspan="6">submitted</th> -->
                                </tr>
                                <tr id="accHead">
                                    <td>VDC</td>
                                    <td *ngFor="let vdc of vdcListArray" style="width:20%">{{vdc}}</td>
                                </tr>
                                <tr *ngFor="let data of rowData.selectedList">
                                    <td></td>
                                    <td *ngFor="let key of Object.keys(data)" style="vertical-align:top">
                                        <div *ngFor="let element of data[key]">
                                            {{element.userName}}; {{element.createdts}}; {{element.vin}}; {{element.componentCd}}
                                        <hr>
                                        </div>
                                        
                                    </td>
                                </tr> 
                                <tr *ngFor="let data of rowData.inprogressList">
                                    <td></td>
                                    <td *ngFor="let key of Object.keys(data)" style="vertical-align:top">
                                        <div *ngFor="let element of data[key]">
                                            {{element.userName}}; {{element.createdts}}; {{element.vin}}; {{element.componentCd}}
                                        <hr>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor="let data of rowData.submittedList">
                                    <td></td>
                                    <td *ngFor="let key of Object.keys(data)" style="vertical-align:top">
                                        <div *ngFor="let element of data[key]">
                                            {{element.userName}}; {{element.createdts}};
                                            <a style="color:blue;"  (click)="viewAuditReport(element.vehAssmntEventId)"> {{element.vin}}; </a>
                                             {{element.componentCd}}
                                        <hr>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor="let data of rowData.drcApprovedList">
                                    <td></td>
                                    <td *ngFor="let key of Object.keys(data)" style="vertical-align:top">
                                        <div *ngFor="let element of data[key]">
                                            {{element.userName}}; {{element.createdts}}; 
                                            <a style="color:blue;"  (click)="viewAuditReport(element.vehAssmntEventId)"> {{element.vin}}; </a>
                                             {{element.componentCd}}
                                        <hr>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor="let data of rowData.releasedList">
                                    <td></td>
                                    <td *ngFor="let key of Object.keys(data)" style="vertical-align:top">
                                        <div *ngFor="let element of data[key]">
                                            {{element.userName}}; {{element.createdts}};
                                            <a style="color:blue;"  (click)="viewAuditReport(element.vehAssmntEventId)"> {{element.vin}}; </a>
                                              {{element.componentCd}}
                                        <hr>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </ng-template>
                    </p-timeline>

                </div>
            </p-accordionTab>
            </p-accordion>
            </div>

    <div class="align-right page-action-panel-style">
        <p-button label="Back" styleClass="cmn-button-style" (click)="onClickBack()">
        </p-button>
    </div>

</div>