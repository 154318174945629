import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { IndexedDBConfig } from './core/indexed-db/indexed-db.config';
import { MsalModule } from '@azure/msal-angular';
import { HomeModule } from './features/home/home.module';
import { UnauthorizedModule } from './features/unauthorized/unauthorized.module';
import { ProdEnvironment } from 'src/environments/environment.prod';

export const APP_IMPORTS = [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    HomeModule,
    UnauthorizedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: ProdEnvironment.production,
        registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxIndexedDBModule.forRoot(IndexedDBConfig),
    MsalModule
]
