import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { DashboardComponent } from './dashboard.component';
import { FormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SeriesViewModule } from 'src/app/modules/dashboard-search-criteria/series-view/series-view.module';
import { VdcViewModule } from 'src/app/modules/dashboard-search-criteria/vdc-view/vdc-view.module';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { MenuModule } from 'primeng/menu';
import { BadgeModule } from 'primeng/badge';
import { TreeTableModule } from 'primeng/treetable';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';


@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    VdcViewModule,
    SeriesViewModule,
    FormsModule,
    SelectButtonModule,
    DividerModule,
    ButtonModule,
    TabViewModule,
    TableModule,
    PanelModule,
    InputTextModule,
    PaginatorModule,
    MenuModule,
    BadgeModule,
    TagModule,
    TooltipModule,
    TreeTableModule,
    OverlayPanelModule
  ],
  exports: [
    DashboardComponent
  ],
  providers: []
})
export class DashboardModule { }
