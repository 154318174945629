import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxRoutingModule } from './audit-inbox-routing.module';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { BarcodeScannerLivestreamOverlayModule } from 'ngx-barcode-scanner';
import { AuditInboxComponent } from './audit-inbox.component';
import { InputTextModule } from 'primeng/inputtext';
import { SubmitAuditModule } from 'src/app/modules/submit-audit/submit-audit.module';
import { DividerModule } from 'primeng/divider';

@NgModule({
  declarations: [
    AuditInboxComponent
  ],
  imports: [
    CommonModule,
    InboxRoutingModule,
    InputTextModule,
    TableModule,
    ButtonModule,
    DialogModule,
    ConfirmDialogModule,
    TabViewModule,
    SubmitAuditModule,
    DividerModule,
    BarcodeScannerLivestreamOverlayModule
  ],
  exports: [
    AuditInboxComponent
  ]
})
export class AuditInboxModule { }
