import { EnvironmentModel } from "src/app/core/model/ui/environment-property.model";

export const QaEnvironment  : EnvironmentModel  = {
    production: false,
    ENV_NAME: 'qa',
    VMA_API_ENDPOINT: 'apig.vmaqa.toyota.com',
    PCPS_API_ENDPOINT: 'api.qa.pcps.toyota.com',
    TENANT_ID: '9107b728-2166-4e5d-8d13-d1ffdf0351ef',
    CLIENT_ID: '306150b0-637e-47a7-9318-73ff855002b0',
    INSTANCE_ID: 'https://login.microsoftonline.com/',
    UI_URL: 'https://ui.vmaqa.toyota.com/',
    REDIRECT_URI: '/',
    POST_LOGOUT_REDIRECT_URI: '',
    NAVIGATE_TO_LOGIN_REQUEST_URL: true,
    CACHE_LOCATION: 'localStorage'
};
