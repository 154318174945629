import { ActivatedRoute } from "@angular/router";

export function isNull(o: any) {
    return (o === null);
}
export function isUndefined(o: any) {
    return (o === undefined);
}
export function isNullOrUndefined(o: any) {
    return (isNull(o) || isUndefined(o));
}

export function isDefined(o: any) {
    return (!isNullOrUndefined(o));
}

export function isObject(o: any) {
    if (isDefined(o)) { return (typeof o === 'object'); }
    return false;
}

export function isFunction(f: any) {
    return (isDefined(f) && typeof f === 'function');
}

export function isNumber(o: any) {
    return !isNaN(parseFloat(o)) && isFinite(o);
}

export function isDecimal(o: any) {
    return (isNumber(o) && (o % 1 !== 0));
}

export function isString(o: any) {
    return (isDefined(o) && typeof o === 'string');
}
export function isEmpty(o: any) {

    if (isString(o)) {
        return o === '';
    } else if (isObject(o)) {
        if (Array.isArray(o)) {
            return o.length === 0;
        } else if (isDate(o)) {
            return isNullOrUndefined(o);
        } else {
            return (Object.getOwnPropertyNames(o).length === 0);
        }
    }

    return false;
}
export function isStringWithLength(o: any) {
    return (isString(o) && o.length > 0);
}
export function camelCase(o: any) {
    if (isStringWithLength(o)) {
        return o
            .replace(/\s(.)/g, ($1: any) => {
                return $1.toUpperCase();
            })
            .replace(/\s/g, '')
            .replace(/^(.)/, ($1: any) => {
                return $1.toLowerCase();
            });
    }
    return o;
}
export function isArray(o: any) {
    return (isDefined(o) && Array.isArray(o));
}

export function isArrayWithLength(o: any) {
    return (isArray(o) && o.length > 0);
}
export function isTrue(o: any) {
    return (isDefined(o) && typeof o === 'boolean' && o === true);
}
export function isFalse(o: any) {
    return (isDefined(o) && typeof o === 'boolean' && o === false);
}
export function isBoolean(o: any) {
    return (isTrue(o) || isFalse(o));
}
export function hasOwnProperty(o: any, prop: any) {
    return (isDefined(o) && (o.hasOwnProperty(prop) || Object.getOwnPropertyDescriptor(o, prop)));
}
export function init(obj: any, prop: any, value: any) {
    return (hasOwnProperty(obj, prop) ? obj[prop] : value);
}
export function replaceKeyValueWithString(o: any, obj: object) {
    let val = '';
    if (isStringWithLength(o)) {
        if (isObject(obj)) {
            val = o;
            Object.entries(obj).forEach(([key, value]) => {
                const exp = new RegExp(`${key}`, 'i');
                if (exp.test(val)) {
                    val = val.replace(exp, value);
                }
            });
        }
    }
    return val;
}
export function isDate(o: any) {
    if (isDefined(o)) {
        return (!isNaN((new Date(o)).valueOf()));
    }
    return false;
}

export function findItem(items: any, obj: any, prop = 'id') {
    if (isArrayWithLength(items)) {
        let nIndex = -1;

        if (isObject(obj)) {
            nIndex = items.findIndex((i: any) => {
                return i[prop] === obj[prop];
            });
        } else if (isStringWithLength(obj) || isNumber(obj)) {
            nIndex = items.findIndex((i: any) => {
                return i[prop] === obj;
            });
        }

        if (nIndex > -1) {
            return items[nIndex];
        }
    }
}

/**
 * standardTime()
 * @param o
 * @returns {Date}
 */
export function localeTime(o: any, local = 'en-US') {
    if (isDate(o)) {
        return o.toLocaleTimeString(local);
    }
}

/**
 * wordCount()
 * @param s
 * @returns {number}
 * @constructor
 */
export function wordCount(s: any) {
    return (isStringWithLength(s) ? s.split(/\b\W+\b/).length : 0);
}



/**
 * isBrowser()
 * Detects whether or not we are running in a browser context or not.
 * @returns {*}
 */
export function isBrowser() {
    return new Function('try{return this===window;}catch(e){return false;}')();
}

export function isDateObject(o: any) {
    if (isObject(o)) {
        return (hasOwnProperty(o, 'year') &&
            hasOwnProperty(o, 'month') &&
            hasOwnProperty(o, 'dayOfMonth') &&
            hasOwnProperty(o, 'hourOfDay') &&
            hasOwnProperty(o, 'minute') &&
            hasOwnProperty(o, 'second'));
    }

    return false;
}

export function toDate(o: any) {
    if (isDateObject(o)) {
        return new Date(parseInt(o['year'].toString(), 10),
            parseInt(o['month'].toString(), 10),
            parseInt(o['dayOfMonth'].toString(), 10),
            parseInt(o['hourOfDay'].toString(), 10),
            parseInt(o['minute'].toString(), 10),
            parseInt(o['second'].toString(), 10));
    }
    return undefined;
}

export function parseDate(o: any) {
    if (isDefined(o) && isDate(o)) {
        return new Date(o);
    }
    return null;
}


export function _trim(data: string): string {
    if (!isNullOrEmpty(data)) {
        data = data.trim();
    }
    return data;
}

export function isNullOrEmpty(data: string) {
    let status = true;
    if (typeof data != 'undefined' && data) {
        status = false;
    }
    return status;
}

export function getUrlParameter(route: ActivatedRoute, name: any) {
    return route.snapshot.paramMap.get(name);
}

export function getQueryParameter(route: ActivatedRoute, name: any) {
    return route.snapshot.queryParamMap.get(name);
}

export function formatDateAsISOString(date: string) {
    return date;
}

/**
 * @param  {string} date
 * @returns string
 */
export function formatDateWithOutZone(date: string): string {
    if (isNullOrUndefined(date)) {
        return date;
    } else {
        return formatDateAsISOString(date).replace('Z', '');
    }
}

/**
 * @param  {string} date
 * @returns string
 */
export function formatDateWithZone(date: string): string {
    if (isNullOrUndefined(date)) {
        return date;
    } else {
        return formatDateAsISOString(date);
    }
}

/**
 * @param  {string} date
 * @returns string
 */
export function formatDateWithOutT(date: string): string {
    if (isNullOrUndefined(date)) {
        return date;
    } else {
        return formatDateAsISOString(date).replace(/T/, ' ').replace(/\..+/, '');
    }
}

/**
 * @param  {string} date
 * @returns string
 */
export function formatDateWithT(date: string): string {
    if (isNullOrUndefined(date)) {
        return date;
    } else {
        return new Date(date).toISOString().replace(/\..+/, '');
    }
}


/**
 * @param  {string} date
 * @returns string
 */
export function formatDateMilliSecsWithOutT(date: string): string {
    if (isNullOrUndefined(date)) {
        return date;
    } else {
        return formatDateAsISOString(date).replace(/T/, ' ');
    }
}

export default {
    hasOwnProperty,
    init,
    isArray,
    isArrayWithLength,
    isBoolean,
    isDate,
    isDecimal,
    isDefined,
    isEmpty,
    isFalse,
    isFunction,
    isNull,
    isNullOrUndefined,
    isNumber,
    isObject,
    isString,
    isStringWithLength,
    isTrue,
    isDateObject,
    toDate,
    parseDate,
    replaceKeyValueWithString,
    isBrowser,
    isUndefined,
    findItem,
    localeTime,
    wordCount,
    camelCase,
    _trim,
    getUrlParameter,
    getQueryParameter,
    formatDateWithOutZone,
    formatDateWithZone,
    formatDateWithOutT,
    formatDateWithT
};
