import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardVdcComponent } from './dashboard-vdc.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    DashboardVdcComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule
  ], exports: [
    DashboardVdcComponent
  ]
})
export class DashboardVdcModule { }
