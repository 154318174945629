import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrivalDateComponent } from './arrival-date.component';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [
    ArrivalDateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CheckboxModule
  ] , exports : [
    ArrivalDateComponent
  ]
})
export class ArrivalDateModule { }
