import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
export default class Validation {

    static validateMlVersion(sourceValue: any) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && sourceValue && (control.value !== sourceValue)) {
                return { InvalidMlVersion: true };
            }
            return null;
        }
    }
    static validateMlFinalAssemblyPoint(sourceData: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && sourceData && (control.value !== sourceData)) {
                return { InvalidMlFinalAssemblyPoint: true };
            }
            return null;
        };
    }
    static validateUsCanadaPartsContent(sourceData: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && sourceData && (control.value !== sourceData)) {
                return { InvalidUsCanadaPartsContent: true };
            }
            return null;
        };
    }
    static validateAALAFinalAssemblyPoint(sourceData: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && sourceData && (control.value !== sourceData)) {
                return { InvalidAALAFinalAssemblyPoint: true };
            }
            return null;
        };
    }
    static validateCountryOfOriginEngineParts(sourceData: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && sourceData && (control.value !== sourceData)) {
                return { InvalidCountryOfOriginEngineParts: true };
            }
            return null;
        };
    }
    static validateCountryOfOriginTransmissionParts(sourceData: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && sourceData && (control.value !== sourceData)) {
                return { InvalidCountryOfOriginTransmissionParts: true };
            }
            return null;
        };
    }
    static validateGvwr(sourceData: any) {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && sourceData && (control.value !== sourceData)) {
                return { InvalidGvwr: true };
            }
            return null;
        };
    }
    static validateMajorSourcesLine1(countrySourceValue: any, percentSourceValue: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && countrySourceValue && percentSourceValue && (control.value.country !== countrySourceValue || control.value.percent !== percentSourceValue)) {
                return { InvalidMajorSourcesLine1: true };
            }
            return null;
        };
    }
    static validateMajorSourcesLine2(countrySourceValue: any, percentSourceValue: any): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && countrySourceValue && percentSourceValue && (control.value.country !== countrySourceValue || control.value.percent !== percentSourceValue)) {
                return { InvalidMajorSourcesLine2: true };
            }
            return null;
        };
    }
}
