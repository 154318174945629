import { ValidationStatusCdUI, VehAssmntEventScopeItemUI, VehAssmntEventScopeTypeUI, WorkflowStatus } from "src/app/common/app.constants";
import { AuditData, VehAssmntEventWorkflow } from "./audit-data.model";

export class InputDataComparisonReportModel {
    scopeType: VehAssmntEventScopeTypeUI;
    scopeItem: VehAssmntEventScopeItemUI;
    data: string;
    inputValue: any;
    nvsValue: any;
    validationStatus: ValidationStatusCdUI;
    comment: any;
    evidences: Array<File>
}

export class LineByLineVerificationReportModel {
    scopeType: VehAssmntEventScopeTypeUI;
    scopeItem: VehAssmntEventScopeItemUI;
    nvsValue: string;
    validationStatus: ValidationStatusCdUI;
    comment: any;
    evidence: Array<EvidenceDtlModel>;
}

export class EvidenceDtlModel {
    imgUrl: string;
    key: string;
}

export class BaseLabelEvidenceModel {
    scopeType: VehAssmntEventScopeTypeUI;
    evidence: EvidenceDtlModel;
}

export class AdditionalLabelEvidenceModel {
    scopeType: VehAssmntEventScopeTypeUI;
    evidence: EvidenceDtlModel;
    comment: string;
}

export class PDSingleImageModel {
    scopeType: VehAssmntEventScopeItemUI;
    evidence: EvidenceDtlModel;
}
export class DrcCommentModel {
    comment: any;
    userName: any;
    updatedDate: any;
}
export class DownloadEvidenceModel {
    key: string;
    url: string;
}

export class CommentSaveModel {
    allowedUpdateWorkflowStatus: WorkflowStatus;
    auditData: AuditData;
    vehAssmntEventWorkflow: VehAssmntEventWorkflow;
}
