import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Environment_URL, MessageDetail, MessageSeverity, MessageSummary, SessionTime, VMARole } from 'src/app/common/app.constants';
import { DevEnvironment } from 'src/environments/environment.dev';
import { LocalEnvironment } from 'src/environments/environment.local';
import { ProdEnvironment } from 'src/environments/environment.prod';
import { QaEnvironment } from 'src/environments/environment.qa';
import { TestEnvironment } from 'src/environments/environment.test';
import { AuthService } from '../../auth/auth.service';
import { EnvironmentModel } from '../../model/ui/environment-property.model';
import { ApiResponseBody } from '../../rest-api/model/response';
import { isUndefined } from '../../utilities/vma-common.util';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  vehAssmntEventId: number;
  vehicleSelectionActiveTabIndex = 0;
  auditInboxActiveTabIndex = 0;
  drcApprovalActiveTabIndex = 0;
  displaySelectAuditButton: boolean;
  displayReadyForAuditButton: boolean;
  displayUnSelectAuditButton: boolean;
  displayDrcApprovalMenu: boolean;
  displayVehicleSelectionMenu: boolean;
  displayTlsHoldMenu: boolean;
  displayAuditVehicle: boolean;
  displayAdminMenu: boolean;
  displayDashboard: boolean;
  isSessionExpired: boolean;
  sessionTime: string;
  vehicleSearchData: any;
  dashoardSearchData: any;
  viewType: any;
  isNavigatedFromDrc: boolean;
  isMandatoryField: boolean;
  isMandatoryVdc: boolean;
  isMandatorySeries: boolean;
  isAdminView: boolean = true;
  seriesName: any;
  // buttonEnabler: boolean = true;
  tlsDialogFlag: boolean = false;
  vdcName: any;
  accessoryName: any;
  historyNotes : string;
  brandCd: any;
  retainModelYear: any;
  backFromReport: boolean;
  public _clearButton = false;
  private readonly _myObservableSubject = new Subject<boolean>();
  myObservable$: Observable<boolean>;
  private subject = new Subject<any>();

  selectedAuditedRow: any;
  isNavigatedFromAuditedVehicle: boolean;
  isNavigatedFromDashboard: boolean;
  historyCombinationData: any;
  historyDataParams: any = {};
  releaseProgress: number= 0;

  secondaryAuditInputFlag: boolean;
  private initiallyNotVerifiedIndexes: Set<number>;
  private localStorageKey = 'initiallyNotVerifiedIndexes';

  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  get clearButton(): boolean {
    return this._clearButton;
  }
  set clearButton(value: boolean) {
    this._clearButton = value;
    this._myObservableSubject.next(value);
  }

  constructor(
    private readonly messageService: MessageService,
    private readonly authService: AuthService
  ) {
    this._myObservableSubject = new Subject();
    this.myObservable$ = this._myObservableSubject.asObservable();
    const storedIndexes = localStorage.getItem(this.localStorageKey);
    this.initiallyNotVerifiedIndexes = storedIndexes ? new Set(JSON.parse(storedIndexes)) : new Set();
  }


  setVehAssmntEventId(vehAssmntEventId: number): void {
    this.vehAssmntEventId = vehAssmntEventId;
  }
  getVehAssmntEventId(): number {
    return this.vehAssmntEventId;
  }
  handleHttpError(error: HttpErrorResponse): void {
    this.clearPreviousMessages();
    const errorResp: ApiResponseBody = error.error;
    let errMsg: any = MessageDetail.SOMETHING_WENT_WRONG;
    if (!isUndefined(errorResp.error.errorDesc)) {
      errMsg = errorResp.error.errorDesc;
    }
    this.messageService.add(
      {
        severity: MessageSeverity.ERROR,
        summary: MessageSummary.ERROR,
        detail: errMsg,
        sticky: true
      }
    )
  }
  handleIndexedDBError(error: HttpErrorResponse): void {
    this.messageService.add(
      {
        severity: MessageSeverity.ERROR,
        summary: MessageSummary.ERROR,
        detail: MessageDetail.INDEXED_DB_ERROR,
        sticky: true
      }
    )
  }
  clearPreviousMessages(): void {
    this.messageService.clear();
  }
  getCurrentEnvironmentDtl(): EnvironmentModel {
    let envModel: EnvironmentModel = DevEnvironment;
    const url = window.location.href;
    if (url.search(Environment_URL.LOCAL) !== -1) {
      envModel = LocalEnvironment;
    } else if (url.search(Environment_URL.PROD) !== -1) {
      envModel = ProdEnvironment;
    } else if (url.search(Environment_URL.TEST) !== -1) {
      envModel = TestEnvironment;
    } else if (url.search(Environment_URL.QA) !== -1) {
      envModel = QaEnvironment;
    }
    return envModel;
  }
  enableRoleBasedAccessControl() {
    const authorizedUserProfile = this.authService.getAuthorizedUserProfile();
    switch (authorizedUserProfile.role.name) {
      case VMARole.VOGTeamMember: {
        this.displaySelectAuditButton = true;
        this.displayReadyForAuditButton = true;
        this.displayUnSelectAuditButton = true;
        this.displayDrcApprovalMenu = false;
        this.displayVehicleSelectionMenu = true;
        this.displayAdminMenu = true;
        this.displayAuditVehicle = true;
        this.displayTlsHoldMenu = true;
        this.displayDashboard = true;
        break;
      }
      case VMARole.AuditSupportMember: {
        this.displaySelectAuditButton = true;
        this.displayReadyForAuditButton = true;
        this.displayUnSelectAuditButton = true;
        this.displayDrcApprovalMenu = false;
        this.displayVehicleSelectionMenu = true;
        this.displayAdminMenu = true;
        this.displayAuditVehicle = true;
        this.displayTlsHoldMenu = false;
        this.displayDashboard = true;
        break;
      }
      case VMARole.VOGAdmin: {
        this.displaySelectAuditButton = true;
        this.displayReadyForAuditButton = true;
        this.displayUnSelectAuditButton = true;
        this.displayDrcApprovalMenu = true;
        this.displayVehicleSelectionMenu = true;
        this.displayAdminMenu = true;
        this.displayAuditVehicle = true;
        this.displayTlsHoldMenu = true;
        this.displayDashboard = true;
        break;
      }
      case VMARole.QPAApprover: {
        this.displaySelectAuditButton = false;
        this.displayReadyForAuditButton = false;
        this.displayUnSelectAuditButton = false;
        this.displayDrcApprovalMenu = true;
        this.displayVehicleSelectionMenu = false;
        this.displayAdminMenu = true;
        this.displayAuditVehicle = true;
        this.displayTlsHoldMenu = false;
        this.displayDashboard = true;
        break;
      }
      case VMARole.ReadyOnly: {
        this.displaySelectAuditButton = false;
        this.displayReadyForAuditButton = false;
        this.displayUnSelectAuditButton = false;
        this.displayDrcApprovalMenu = false;
        this.displayVehicleSelectionMenu = true;
        this.displayAdminMenu = true;
        this.displayAuditVehicle = true;
        this.displayTlsHoldMenu = false;
        this.displayDashboard = true;
        break;
      }
    }
  }
  setVehicleSelectionTabIndex(index: number) {
    this.vehicleSelectionActiveTabIndex = index;
  }
  getVehicleSelectionTabIndex(): number {
    return this.vehicleSelectionActiveTabIndex;
  }
  setAuditInboxTabIndex(index: number) {
    this.auditInboxActiveTabIndex = index;
  }
  getAuditInboxTabIndex(): number {
    return this.auditInboxActiveTabIndex;
  }
  setDrcApprovalTabIndex(index: number) {
    this.drcApprovalActiveTabIndex = index;
  }
  getDrcApprovalTabIndex(): number {
    return this.drcApprovalActiveTabIndex;
  }
  setAuditReportFlag(flag: boolean) {
    this.isNavigatedFromDrc = flag;
  }
  getAuditReportFlag(): any {
    return this.isNavigatedFromDrc;
  }
  setAuditedVehicleFlag(flag: boolean) {
    this.isNavigatedFromAuditedVehicle = flag;
  }
  getAuditedVehicleFlag(): boolean {
    return this.isNavigatedFromAuditedVehicle;
  }

  setDashboardFlag(flag: boolean) {
    this.isNavigatedFromDashboard = flag;
  }

  setSessionInfo() {
    if (this.authService.isUserLoggedIn()) {
      const sessionTimeInMs = this.getSessionTimeInMs();
      this.setTime();
      const sessionNotifyTime = sessionTimeInMs - (SessionTime.Secs * 1000);
      if (sessionTimeInMs > 0) {
        this.setSessionNotification(sessionNotifyTime);
      }
    }
  }
  getSessionTimeInMs(): number {
    const currentTime = new Date().getTime();
    const authenticatedUserProfile = this.authService.getAuthenticatedUserProfile();
    const sessionExpiryTime = authenticatedUserProfile.expiryTime * 1000;
    return (sessionExpiryTime - currentTime);
  }
  setTime() {
    const x = setInterval(() => {
      const sessionValue = this.getSessionTimeInMs();
      const timeValue = sessionValue - SessionTime.ExtendSession;
      const minutes = Math.floor((timeValue % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeValue % (1000 * 60)) / 1000);
      this.sessionTime = `${minutes}:${seconds}`;
      if (timeValue < 0) {
        this.sessionTime = '0';
        if (!this.isSessionExpired) {
          this.isSessionExpired = true;
        }
        clearInterval(x);
      }
    }, 1000);
  }
  setSessionNotification(sessionNotifyTime: number) {
    if (sessionNotifyTime < 0) {
      sessionNotifyTime = 10;
    }
    this.showSessionNotification(sessionNotifyTime);
  }
  showSessionNotification(sessionNotifyTime: number) {
    setTimeout(() => {
      this.isSessionExpired = true;
    }, sessionNotifyTime);
  }
  isValidSession() {
    const sessionTime = this.convertSecToMinutes();
    this.setTime();
    const sessionSec = (sessionTime * 60);
    const serviceTime = SessionTime.Secs;
    if (sessionSec < serviceTime) {
      this.isSessionExpired = true;
      return true;
    } else {
      return false;
    }
  }
  convertSecToMinutes() {
    const minutesTime = this.getSessionTimeInMs();
    let minutesDifference = Math.floor(minutesTime / 1000 / 60);
    if (minutesDifference < 1) {
      minutesDifference = 0;
    }
    return minutesDifference;
  }
  refreshToken() {
    this.authService.refreshAccessToken();
    setTimeout(() => {
      this.isSessionExpired = false;
    }, 1000);
  }

  handledNoRecords() {
    this.messageService.add(
      {
        severity: MessageSeverity.INFO,
        summary: MessageSummary.INFO,
        detail: MessageDetail.NO_RECORDS_FOUND,
        sticky: true
      }
    )
  }

  handledMandatoryFields() {
    this.messageService.add(
      {
        severity: MessageSeverity.ERROR,
        summary: MessageSummary.ERROR,
        detail: MessageDetail.MANDATORY,
        sticky: true
      }
    )
  }
  convertTimeStamp(cstDate: Date) {
    const localTime = cstDate.getTime();
    const localOffset = cstDate.getTimezoneOffset() * 60000;
    const utc = localTime + localOffset;
    const offset = -5;
    const cst = utc + (3600000 * offset);
    const nd = new Date(cst);
    return (nd.toLocaleString());
  }

  setHistData(modelYear, modelCode, series){
  this.historyDataParams.modelYear = modelYear;
  this.historyDataParams.modelCode = modelCode;
  this.historyDataParams.series = series;

  }

  getHistData(){
    return this.historyDataParams;
  }

  setSecondaryAuditInputFlag(flag: boolean) {
    this.secondaryAuditInputFlag = flag;
  }

  getSecondaryAuditInputFlag(): any {
    return this.secondaryAuditInputFlag;
  }

  setNotVerified(index: number): void {
    this.initiallyNotVerifiedIndexes.add(index);
    this.saveToLocalStorage();
  }

  getNotVerified(index: number): boolean {
    return this.initiallyNotVerifiedIndexes.has(index);
  }

  private saveToLocalStorage(): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(Array.from(this.initiallyNotVerifiedIndexes)));
  }
}


