import { ReleaseLoadStatus, ReleaseStatus } from "src/app/common/app.constants";

export class ChronicHoldList {
    limit: number;
    offset: number;
    total: string;
    chronicHoldArr: Array<ModelList> = new Array<ModelList>();
}

export class ChronicHoldListDetails {
    data: Series;
    children: Array<ModelListObj> = new Array<ModelListObj>();
    constructor(data: any) {
        this.data = new Series(data);
        data.children.forEach(item => {
            this.children.push(new ModelListObj(item));
        });
    }
}

export class Series {
    series: string;
    constructor(data: any) {
        this.series = data.series;
    }
}

export class ModelListObj {
    data: ModelList;
    constructor(data: any) {
        this.data = new ModelList(data);
    }
}

export class ModelList {
    chronicHoldId: string;
    brand: string;
    modelYear: string;
    series: string;
    modelCode: string;
    vdc: string;
    accessory: string;
    status: string;
    updatedTs: string;
    historyNotes: string;
    headerCheckbox: boolean;
    rowCheckbox: boolean;
    yearSeriesComb: string;
    constructor(data: any) {
        this.chronicHoldId = data.chronicHoldId;
        this.brand = data.brand;
        this.modelYear = data.modelYear;
        this.series = data.series;
        this.modelCode = data.modelCode;
        this.vdc = data.vdc;
        this.accessory = data.accessory;
        this.status = data.status;
        this.updatedTs = data.updatedTs;
        this.historyNotes = data.historyNotes;
        this.headerCheckbox = false;
        this.rowCheckbox = false;
        this.yearSeriesComb = data.modelYear + ' ' + data.series;
    }
}

export class MultiChronicHoldData {
    data: Array<MultiChronicHoldObj>;
}

export class MultiChronicHoldObj {
    tlsEvent: string;
    chronicHoldId: string;
    comments: string;
    modelYear: string;
    modelCode: string;
    series: string;
    vdc: string;
    accessory: string;
}

export class ChronicHistList {
    chronicHistArr: Array<ChronicHistoryData> = new Array<ChronicHistoryData>();
}

export class ChronicHistoryDataObj {
    data: ChronicHistoryData;
    constructor(data: any) {
        this.data = new ChronicHistoryData(data);
    }
}

export class ChronicHistoryData {
    chronicHoldId: number;
    actorId: string;
    actorNm: string;
    eventTs: string;
    eventNotes: string;
    eventStatus: string;
    eventEResp: string;
    constructor(data: any) {
        this.chronicHoldId = data.chronicHoldId;
        this.actorId = data.actorId;
        this.actorNm = data.actorNm;
        this.eventTs = data.eventTs;
        this.eventNotes = data.eventNotes;
        this.eventStatus = data.eventStatus;
        this.eventEResp = data.eventEResp;
    }
}

export class ShipmentHoldRequestData {
    modelYear: string;
    seriesCd: string;
    modelCode: string;
    accessory: string;
    vdc: string;
    historyNotes: string;
}

export class UpdateTlsRequestData {
    modelYear: string;
    seriesCd: string;
    modelCode: string;
    accessory: string;
    vdc: string;
    historyNotes: any;
}

export class TlsReqObj {
    chronicHldId: string;
    modelYear: string;
    seriesCd: string;
    modelCode: string;
    accessory: string;
    vdc: string;
    historyNotes: string;
}

export class SmartChronicData {
    data: Array<SmartChronicHoldObj>;
}

export class SmartChronicHoldObj {
    vehAssmntId: any;
    modelYear: string;
    modelCode: string;
    vdc: string;
    accessories: any;
    workFlowStatus: any;
    vin: string;
    accessoryArray: Array<any>;
    errStatus: any;
    loadingPercentage: number;
    loadingStatus: boolean;
    apiStatus: ReleaseStatus
}

export class SmartChronicRequestData {
    data: Array<SmartChronicHoldReqObj>;
}

export class SmartChronicHoldReqObj {
    vehAssmntData: Array<VehAssmntIdData>  = new Array<VehAssmntIdData>();
    modelYear: string;
    modelCode: string;
    vdc: string;
    accessories: any;
    accessoryArray: Array<any>;
    loadingPercentage: number;
    errStatus: any;
}

export class VehAssmntIdData {
    vehAssmntId: any;
    accessory: any;
}
