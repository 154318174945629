export const RolePrefix = "VMA";
export enum AppEnvironment {
    LOCAL = 'local',
    DEV = 'dev',
    TEST = 'test',
    QA = 'qa',
    PROD = 'prod'
}

export enum Environment_URL {
    LOCAL = 'localhost:4200',
    DEV = 'ui.vmadev.toyota.com',
    TEST = 'ui.vmatest.toyota.com',
    QA = 'ui.vmaqa.toyota.com',
    PROD = 'ui.vma.toyota.com'
}

export enum MessageSeverity {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success'
}

export enum MessageSummary {
    ERROR = 'Error',
    INFO = 'Information',
    SUCCESS = 'Saved Successfully'
}

export enum MessageDetail {
    BAD_REQUEST = 'Bad Request',
    RESOURCE_NOT_FOUND = 'Resource Not Found',
    INTERNAL_SERVER_ERROR = 'Internal Server Error',
    INDEXED_DB_ERROR = 'Indexed DB Error',
    SOMETHING_WENT_WRONG = "Something went wrong!",
    NO_RECORDS_FOUND = 'No Records Found',
    MANDATORY = 'Please enter the mandatory fields.'
}

export enum WorkflowStatus {
    READY = 'READY_FOR_AUDIT',
    SELECTED = 'SELECTED',
    UN_SELECTED = 'UN_SELECTED',
    INPROGRESS = 'INPROGRESS',
    SUBMITTED = 'SUBMITTED',
    READY_FOR_AUDIT = "READY FOR AUDIT",
    READY_TO_SUBMIT = "READY TO SUBMIT",
    PARTIALLY_AUDITED = "PARTIALLY AUDITED",
    APPROVED = 'APPROVED',
    DRC_APPROVED = 'DRC_APPROVED',
    APPROVAL_PENDING = 'APPROVAL PENDING',
    DRC_REVIEWED = 'DRC_REVIEWED',
    RELEASED = 'RELEASED',
    SECONADRY_INPROGRESS = 'SECONDARY_INPROGRESS',
    SUBMITTED_SECONDARY_AUDIT = 'SUBMITTED_SECONDARY_AUDIT',
    READY_FOR_SECONDARY_AUDIT = "READY FOR SECONDARY AUDIT",
    PARTIALLY_SECONDARY_AUDITED = "PARTIALLY SECONDARY AUDITED",
    READY_TO_SECONDARY_SUBMIT = "READY TO SECONDARY SUBMIT",
}


export const ALL = "ALL";

export enum VehAssmntEventScopeTypeAPI {
    MONRONEY_LABEL = 'MONRONEY_LABEL',
    AALA_LABEL = 'AALA_LABEL',
    AWR_LABEL = 'AWR_LABEL',
    CR_LABEL = 'CR_LABEL',
    PTAG_1 = 'PTAG_1',
    PTAG_2 = 'PTAG_2',
    ADDITIONAL_EVID = 'ADDITIONAL_EVID',
    EMISSION_LABEL = 'EMISSION_LABEL'
}

export enum VehAssmntEventScopeTypeUI {
    MONRONEY_LABEL = 'Monroney Label',
    AALA_LABEL = 'AALA Label',
    AWR_LABEL = 'AWR Label',
    CR_LABEL = 'CR Label',
    PTAG_1 = 'PTAG1 Label',
    PTAG_2 = 'PTAG2 Label',
    ADDITIONAL_EVID = 'Additional Label',
    EMISSION_LABEL = 'Emissions Label'
}

export enum VehAssmntEventScopeItemAPI {
    MONRONEY_LABEL_VERSION = 'MONRONEY_LABEL_VERSION',
    FINAL_ASSEMBLY_POINT = 'FINAL_ASSEMBLY_POINT',
    MJR_SRC_FOR_PRT_CNTNT_CNTRY_1 = 'MJR_SRC_FOR_PRT_CNTNT_CNTRY#1',
    MJR_SRC_FOR_PRT_CNTNT_CNTRY_2 = 'MJR_SRC_FOR_PRT_CNTNT_CNTRY#2',
    MJR_SRC_FOR_PRT_CNTNT_PERCENT_1 = 'MJR_SRC_FOR_PRT_CNTNT_PERCENT#1',
    MJR_SRC_FOR_PRT_CNTNT_PERCENT_2 = 'MJR_SRC_FOR_PRT_CNTNT_PERCENT#2',
    TRANS_SRL_NUM = 'TRANS_SRL_NUM',
    COLOR = 'COLOR',
    STANDARD_EQUIP = 'STANDARD_EQUIP',
    OPTIONAL_EQUIP = 'OPTIONAL_EQUIP',
    US_CAN_PRT_CNTNT = 'US_CAN_PRT_CNTNT',
    CNTRY_ORIGIN_ENG_PRT = 'CNTRY_ORIGIN_ENG_PRT',
    CNTRY_ORIGIN_TRANS_PRT = 'CNTRY_ORIGIN_TRANS_PRT',
    GVWR = 'GVWR',
    MODEL_FSA = 'MODEL_FSA',
    PD_IMAGE = 'PD_IMAGE'
}


export enum VehAssmntEventScopeItemUI {
    MONRONEY_LABEL_VERSION = 'Monroney Label Version',
    FINAL_ASSEMBLY_POINT = 'Final Assembly Point',
    COLOR = 'Color',
    STANDARD_EQUIP = 'Standard Features',
    OPTIONAL_EQUIP = 'Optional Features',
    US_CAN_PRT_CNTNT = 'US/CANADA Parts Content',
    TRANS_SRL_NUM = 'Transmission Serial Number',
    CNTRY_ORIGIN_ENG_PRT = 'Country of Origin - Engine Parts',
    CNTRY_ORIGIN_TRANS_PRT = 'Country of Origin - Transmission Parts',
    GVWR = 'Gvwr',
    MJR_SRC_FOR_PRT_CNTNT_CNTRY_1 = 'Major Sources of Foreign Parts Content - Country#1',
    MJR_SRC_FOR_PRT_CNTNT_CNTRY_2 = 'Major Sources of Foreign Parts Content - Country#2',
    MJR_SRC_FOR_PRT_CNTNT_PERCENT_1 = 'Major Sources of Foreign Parts Content - Percent#1',
    MJR_SRC_FOR_PRT_CNTNT_PERCENT_2 = 'Major Sources of Foreign Parts Content - Percent#2',
    MODEL_FSA = 'PPO Model FSA',
    PD_IMAGE = 'PD Image'
}

export enum ValidationStatusCdUI {
    Match = 'Match',
    Mismatch = 'Mismatch',
    NotVerified = 'NotVerified',
    Waived = 'Waived'
}

export enum ValidationStatusCdAPI {
    MATCH = 'MATCH',
    MISMATCH = 'MISMATCH',
    NOT_VERIFIED = 'NOT_VERIFIED'
}

export enum FileUploadProgressStatus {
    READY = 'Ready',
    SUCCESS = 'Success',
    FAILED = 'Failed',
    INPROGRESS = 'InProgress'
}

export const MandatoryBaseEvidenceCount = 6;

export enum S3PreSignedUrlOperation {
    Upload = "upload",
    Download = "download"
}

export enum IndexedDBKey {
    FinalAssemblyPoints = 'FinalAssemblyPoints',
    CountryOfOrigins = 'CountryOfOrigins'
}


export enum ImageUploadStatus {
    READY = 'Ready',
    SUCCESS = 'Success',
    FAILED = 'Failed',
    INPROGRESS = 'InProgress'
}

export enum ReleaseLoadStatus {
    READY = 'Ready',
    SUCCESS = 'Success',
    FAILED = 'Failed',
    INPROGRESS = 'InProgress'
}

export enum HttpMethod {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    DELETE = 'DELETE'
}

export enum SubmitStatus {
    READY = 'Ready',
    SUCCESS = 'Success',
    FAILED = 'Failed',
    INPROGRESS = 'InProgress'
}


export enum Instance {
    DistributorModel = 'distributormodel',
    BrandModel = 'brandmodel',
    YearModel = 'yearmodel',
    SeriesModel = 'seriesmodel',
    ModelCodeModel = 'modelcodemodel',
    DistModelCodeModel = 'distModelCodeModel',
    DashboardModel = 'dashboardModel'
}

export enum ApiType {
    VMA = 'VMA',
    PCPS = 'PCPS'
}

export enum VMARole {
    ReadyOnly = "Readonly",
    Enrolled = "Enrolled",
    VOGTeamMember = "VOGTeamMember",
    AuditSupportMember = "AuditSupportMember",
    VOGAdmin = "VOGAdmin",
    QPAApprover = "QPAApprover",
}

export enum LocalStorageProperty {
    VMATok = "VMATok",
    VMAAuthenticatedUserProfile = "VMAAuthenticatedUserProfile",
    VMAAuthorizedUserProfile = "VMAAuthorizedUserProfile"
}

export enum SessionStorageProperty {
    VMASavedEvidenceUrl = "VMASavedEvidenceUrl"
}

export enum SessionTime {
    Secs = 900,
    ExtendSession = 300000 
}

export interface TreeNode {
    data?: any;
    children?: TreeNode[];
    leaf?: boolean;
    expanded?: boolean;
}

export interface UploadOperation {
    fileName?: any;
    fileSize?: any;
    preSignedURL?: any;
    desc?: any;
}
export enum ReleaseStatus {
    RELEASE_SUCCESS = 'Release Successful',
    RELEASE_FAILED = 'Release failed',
    ALREADY_RELEASED = 'Already released',
    SYNC_ISSUE = 'Data sync issue',
    NULL = ' '
}
