import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as VMA_Util from '../../utilities/vma-common.util';
import { Response } from '../../rest-api/model/response';
import { RestService } from '../../rest-api/service/rest.service';
import { IUserAuthorizeRequestModel } from '../../model/ui/user.model';
import { ApiType } from 'src/app/common/app.constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  static readonly AUTHORIZE_USER_PUT_API: string = 'user/usercd/authorization';
  constructor(private readonly restService: RestService) {
  }
  authorizeUser(userAuthorizeRequestModel: IUserAuthorizeRequestModel, usercd: string): Observable<Response<Object>> {
    this.restService.setApiBaseEndPoint(ApiType.VMA);

    const url = VMA_Util.replaceKeyValueWithString(UserService.AUTHORIZE_USER_PUT_API, { usercd });
    return this.restService._put<any>(url, userAuthorizeRequestModel, {});
  }
}
