import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { AdminComponent } from 'src/app/features/admin/admin.component';

@Component({
  providers: [AdminComponent],
  selector: 'vma-vehicle-search-by-admin',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss']
})

export class AdminViewComponent implements OnInit {
  
  isAdminView: boolean;
  brand: any;
  year: any;
  series: any;
  modelcodeValue: any;
  @Output() searchEvent = new EventEmitter<any>();

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.isAdminView = this.sharedService.isAdminView;
    this.brand = {id:1,name:"Toyota"};
  }

  public onAddWaiveList(): void {
    this.sharedService.sendClickEvent();
  }

  selectedYear(yearNo: any) {
    this.year = yearNo;
    this.resetSeriesData();
    this.vehicleSideBarSearch();
  }

  selectedSeries(seriesId: any) {
    this.series = seriesId;
    this.modelcodeValue = null;
    this.vehicleSideBarSearch();
  }

  selectedModelcode(modelcode: any) {
    this.modelcodeValue = modelcode;
    this.vehicleSideBarSearch();
  }
  
  resetSeriesData() {
    this.series = null;
    this.modelcodeValue = null;    
  }

  vehicleListSearch() {
    const searchModel: any = {};
    searchModel.yearNo = this.year;
    searchModel.seriesId = this.series;
    searchModel.modelCodeId = this.modelcodeValue;
    searchModel.type = "ADMINVIEW";
    this.searchEvent.emit(searchModel);
  }

  vehicleSideBarSearch() {
    const searchModel: any = {};
    searchModel.yearNo = this.year;
    searchModel.seriesId = this.series;
    searchModel.modelCodeId = this.modelcodeValue;
    searchModel.type = "SIDEBARVIEW";
    this.searchEvent.emit(searchModel);
  }
  
  resetVehicleList(){
    this.brand = null;
    this.year = null;
    this.resetSeriesData();
    const searchModel: any = {};
    searchModel.type = "RESETVIEW";
    this.searchEvent.emit(searchModel);
    setTimeout(()=>{
      this.brand = {id:1,name:"Toyota"};
    }, 500);    
  }
  
}
