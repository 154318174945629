<p-menubar [model]="headerMenuItems" styleClass="cmn-header-style">
    <ng-template pTemplate="start">
        <div class="trwd-logo horizontal-logo">
            <img src="../../../assets/icons/toyota_logo.png" height="20" class="mr-2" alt="toyota logo">
            <h1>VMA</h1>
        </div>
    </ng-template>
    <ng-template pTemplate="end">

        <button pButton pRipple *ngIf="!isLoggedIn" type="button" icon="pi pi-sign-in" iconPos="right" (click)="login()"
            label="Login" class="p-button-raised cmn-button-style"></button>

        <button type="button" *ngIf="isLoggedIn" [label]="userName" pButton icon="pi pi-user"
            (click)="menu.toggle($event)" class="p-button-secondary cmn-button-style"></button>
        <p-menu #menu [popup]="true" styleClass="cmn-logout-style" [model]="userMenuItems"></p-menu>

    </ng-template>
</p-menubar>