import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent} from './admin.component';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { AdminViewModule } from 'src/app/modules/vehicle-search-criteria/admin-view/admin-view.module';
import { SidebarModule } from 'primeng/sidebar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PickListModule } from 'primeng/picklist';
import { TabViewModule } from 'primeng/tabview';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AccordionModule } from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';

@NgModule({
  declarations: [
    AdminComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DividerModule,
    DialogModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    TableModule,
    AdminViewModule,
    PaginatorModule,
    InputTextareaModule,
    SidebarModule,
    InputSwitchModule,
    BrowserModule,
    BrowserAnimationsModule,
    AccordionModule,
    MultiSelectModule,
    ContextMenuModule,
    PickListModule,
    TabViewModule
  ], exports: [
    AdminComponent
  ]
})
export class AdminModule { }
