
import { Instance } from 'src/app/common/app.constants';
import * as VMA_Util from '../../utilities/vma-common.util';

class YearModel {    
    modelyearNo: number;    
    instance() {
        return Instance.YearModel;
    }
}

export class YearApiModel extends YearModel { }

export class YearApiResModel {
    years: Array<YearApiModel> = new Array<YearApiModel>();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.years)) {
                options.data.years.forEach(element => {
                    this.years.push(this.getRowdata(element));
                });
            }
        }
    }
    getRowdata(element: any): YearApiModel {
        const yearModel: YearApiModel = new YearApiModel();
        yearModel.modelyearNo = element.modelyearNo;
        return yearModel;
    }
}
