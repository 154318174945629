
import { Instance } from 'src/app/common/app.constants';
import * as VMA_Util from '../../utilities/vma-common.util';

class ModelCodeModel {
    modelCd: number;
    instance() {
        return Instance.ModelCodeModel;
    }
}

export class ModelCodeApiModel extends ModelCodeModel { }

export class ModelCodeApiResModel {
    models: Array<ModelCodeApiModel> = new Array<ModelCodeApiModel>();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.models)) {
                options.data.models.forEach(element => {
                    this.models.push(this.getRowdata(element));
                });
            }
        }
    }
    getRowdata(element: any): ModelCodeApiModel {
        const modelcodeModel: ModelCodeApiModel = new ModelCodeApiModel();
        modelcodeModel.modelCd = element.modelCd;
        return modelcodeModel;
    }
}

class DistModelCodeModel {
    modelCd: number;
    distributorMmyId: number;
    instance() {
        return Instance.DistModelCodeModel;
    }
}

export class DistModelCodeApiModel extends DistModelCodeModel { }

export class DistModelCodeApiResModel {
    models: Array<DistModelCodeApiModel> = new Array<DistModelCodeApiModel>();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.models)) {
                options.data.models.forEach(element => {
                    this.models.push(this.getRowdata(element));
                });
            }
        }
    }
    getRowdata(element: any): DistModelCodeApiModel {
        const distmodelcodeModel: DistModelCodeApiModel = new DistModelCodeApiModel();
        distmodelcodeModel.modelCd = element.modelCd;
        distmodelcodeModel.distributorMmyId = element.distributorMmyId;
        return distmodelcodeModel;
    }
}

