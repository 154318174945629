import * as VMA_Util from '../../utilities/vma-common.util';
import { AuditHistoryWaiverData, AuditWaiverHistoryDataDetails } from "../ui/waiver-history.data.model";


export class ListWaiverHistoryApiModel extends AuditHistoryWaiverData { }

export class ListWaiverHistoryApiRespModel {
    waiverHistoryList: ListWaiverHistoryApiModel = new ListWaiverHistoryApiModel();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (!VMA_Util.isNullOrUndefined(options.data.waiverHistoryData)) {
                this.waiverHistoryList.limit = options.data.limit;
                this.waiverHistoryList.offset = options.data.offset;
                this.waiverHistoryList.total = options.data.total;
                const auditWaiverHistoryData = new AuditWaiverHistoryDataDetails(options.data.waiverHistoryData);
                this.waiverHistoryList.auditWaiverData = this.formatTimeStamp(auditWaiverHistoryData);
            }
        }
    }

    formatTimeStamp(data) {
        for (let i = 0; i < data.auditHistory.length; i++) {
            data.auditHistory[i].updatedTs = this.removeTimeZone(data.auditHistory[i].updatedTs);
        }
        return data;
    }

    removeTimeZone(timeStamp) {
        let updatedDate = '';
        if (!VMA_Util.isNullOrUndefined(timeStamp)) {
            const timewithoutZone = VMA_Util.formatDateWithOutZone(timeStamp);
            updatedDate = VMA_Util.formatDateWithOutT(timewithoutZone);
        }
        return updatedDate;
    }
}
