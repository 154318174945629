import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { SharedService } from '../services/ui/shared.service';

@Component({
  selector: 'vma-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  constructor(
    public sharedService: SharedService,
    private readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.sharedService.setSessionInfo();
  }
  extendSession() {
    this.sharedService.refreshToken();
  }
  login() {
    this.authService.login();
  }
}
