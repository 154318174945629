import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { SharedService } from 'src/app/core/services/ui/shared.service';

enum Type {
  YES = 'Y',
  NO = 'N'
}

@Component({
  selector: 'vma-arrival-date',
  templateUrl: './arrival-date.component.html',
  styleUrls: ['./arrival-date.component.scss']
})
export class ArrivalDateComponent implements OnInit {

  arrivaldate: boolean;
  arrivalDateLabel: string;

  WithArrivalDate = "With Arrival Date";
  WithoutArrivalDate = "Without Arrival Date";
  @Output() arrivalDateEvent = new EventEmitter<any>();


  constructor(private readonly sharedService: SharedService) { }

  ngOnInit(): void {
    if (!isNullOrUndefined(this.sharedService.vehicleSearchData)) {
      this.setSelectedArrivalDateValue();
    } else {
      this.arrivaldate = true;
      this.arrivalDateLabel = this.WithArrivalDate;
      let emitEvent = Type.NO;
      if (this.arrivaldate) {
        emitEvent = Type.YES;
      }
      this.arrivalDateEvent.emit(emitEvent);
    }
  }

  onArrivalDateChange(event: any) {
    if (event.checked) {
      this.arrivaldate = true;
      this.arrivalDateLabel = this.WithArrivalDate;
    } else {
      this.arrivaldate = false;
      this.arrivalDateLabel = this.WithoutArrivalDate;
    }
    let emitEvent = Type.NO;
    if (this.arrivaldate) {
      emitEvent = Type.YES;
    }
    this.arrivalDateEvent.emit(emitEvent);
  }

  setSelectedArrivalDateValue() {   
    const emitEvent = this.sharedService.vehicleSearchData.arrivaldate;
    if (emitEvent === Type.YES) {
      this.arrivaldate = true;
      this.arrivalDateLabel = this.WithArrivalDate;
    } else {
      this.arrivaldate = false;
      this.arrivalDateLabel = this.WithoutArrivalDate;
    }
    this.arrivalDateEvent.emit(emitEvent);    
  }
}
