<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12 p-md-2">
        <vma-brand (brandEvent)="selectedBrand($event)"></vma-brand>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-modelyear [brandId]="brand" (yearEvent)="selectedYear($event)"></vma-modelyear>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-series [brandId]="brand" [yearNo]="year" (seriesEvent)="selectedSeries($event)"></vma-series>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-multi-select-modelcode [brandId]="brand" [yearNo]="year" 
            [seriesId]="series" (modelcodeEvent)="selectedModelcode($event)">
        </vma-multi-select-modelcode>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-multi-select-vdc [brandId]="brand" [yearNo]="year" [seriesId]="series" 
            [modelcodeId]="modelcodeValue" (vdcEvent)="selectedVdcValue($event)">
        </vma-multi-select-vdc>
    </div>
    <div class="p-col-12 p-md-2">
        <vma-multi-select-accessories [brandId]="brand" [yearNo]="year" [seriesId]="series" 
             [modelcodeId]="modelcodeValue" [vdcCd]="vdcValue"
           (accessoryEvent)="selectedAccessory($event)"></vma-multi-select-accessories>
    </div>

    <div class="p-col-12 p-md-2">
        <vma-arrival-date (arrivalDateEvent)="onArrivalDateEvent($event)"> </vma-arrival-date>
    </div>
</div>
