import { DashboardData, SeriesData, Accessory, VdcList } from "../ui/dashboard-data.model";
import * as VMA_Util from '../../utilities/vma-common.util';
import { Vdc } from '../../../models/dropdown.model';


export class DashboardDataVdcListApiModel extends Accessory { }

export class DashboardDataListVdcApiRespModel {
    dashboardVdcDataObj: Array<DashboardDataVdcListApiModel> = new Array<DashboardDataVdcListApiModel>();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.dashboardVdcData)) {
                options.data.dashboardVdcData.forEach((element) => {
                    this.dashboardVdcDataObj.push({
                        accessory: element.accessory,
                        configId: element.configId,
                        status: element.status,
                        vdcCodes: this.getRowData(element)
                    }
                    )
                });
            }
        }
    }

    getRowData(data: any): Array<VdcList> {
        const dashboardDataResp: VdcList = new VdcList();
        data.vdcCodes.forEach(element => {
            dashboardDataResp.vdcCode = element.vdcCode;
            dashboardDataResp.vdcStatus = element.vdcStatus;
            dashboardDataResp.updatedTs =  this.removeTimeZone(element.updatedTs);
        });
        return [dashboardDataResp];
    }

    removeTimeZone(timeStamp) {
        let updatedDate = '';
        if (!VMA_Util.isNullOrUndefined(timeStamp)) {
            const timewithoutZone = VMA_Util.formatDateWithOutZone(timeStamp);
            updatedDate = VMA_Util.formatDateWithOutT(timewithoutZone);
        }
        return updatedDate;
    }


}