<div class="padding">

  <!-- <h3>ADMIN VIEW</h3> -->

  <vma-vehicle-search-by-admin (searchEvent)="searchListData($event)"></vma-vehicle-search-by-admin>
  <div style="justify-content: space-between;margin-top: 15px;margin-bottom: 25px;">
    <div class="auditwaiver-header">
      <h3 style="margin-left: 5px;">Audit Waiver Dashboard
        <button type="button" label="Export" pButton pRipple icon="pi pi-file-excel" (click)="generateadminDataExcel()"
          style="margin: 0.150rem;" class="button-export p-button-success cmn-button-style p-mr-2" pTooltip="XLS"
          tooltipPosition="bottom"></button>
      </h3>
    </div>
    <!-- <p-tabPanel> -->
    <p-table #table1 [value]="auditWaiverList" styleClass="p-datatable-sm" [rows]="5" responsiveLayout="scroll"
      [paginator]="true" [rowsPerPageOptions]="[5,10,25,50]" selectionMode="single" [scrollable]="true" scrollHeight="400px"
      [globalFilterFields]="['modelYear','productGroupId','modelCd','auditWaiverExcp','createdts','activeInd']">
      <ng-template pTemplate="header">
        <tr>
          <th class="cmn-table-header-style" style="width: 16px"> </th>
          <th class="cmn-table-header-style custom" style="width: 130px" scope="col" >Model Year
            <p-sortIcon pSortableColumn="modelYear" field="modelYear"></p-sortIcon>
            <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
              (click)="onFiltermodelYear(table1)"></button>
          </th>
          <th class="cmn-table-header-style custom" style="width: 130px" scope="col">Series
            <p-sortIcon pSortableColumn="productGroupId" field="productGroupId"></p-sortIcon>
            <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
            (click)="onFilterseries(table1)"></button>
          </th>
          <th class="cmn-table-header-style custom" style="width: 130px"  scope="col">
          Model Code<p-sortIcon pSortableColumn="modelCd" field="modelCd"></p-sortIcon>
          <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
          (click)="onFiltermodelCode(table1)"></button>
          </th>
          <th class="cmn-table-header-style custom" style="width: 155px"scope="col">
            Audit Exception Info <p-sortIcon pSortableColumn="auditWaiverExcp" field="auditWaiverExcp"></p-sortIcon>
            <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
            (click)="onFilterauditExceptionInfo(table1)"></button>
          </th>
          <th class="cmn-table-header-style custom" style="width: 130px" scope="col">
            Status<p-sortIcon pSortableColumn="activeInd" field="activeInd"></p-sortIcon>
            <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
            (click)="onFilterstatus(table1)"></button>
          </th>
          <th class="cmn-table-header-style custom" style="width: 130px"  scope="col">
            Last Updated Info<p-sortIcon pSortableColumn ="createdts"field="createdts"></p-sortIcon>
            <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
            (click)="onFilterlastUpdatedInfo(table1)"></button>
          </th>
        </tr>
        
        <tr>
          <th class="cmn-table-header-style" style="width: 16px"scope="col"> </th>
          <th style="width: 135px" scope="col">
            <div class="p-inputgroup" *ngIf="filterAction">
              <input class="cmn-table-data-style" [(ngModel)]="filtermodelYear" type="text" pInputText
                placeholder="Filter" Visibity="hide" (input)="onClearmodelYear(table1)">

            </div>
          </th>
          <th style="width: 140px" scope="col">
            <div class="p-inputgroup"  *ngIf="filterAction">
              <input class="cmn-table-data-style" [(ngModel)]="filterseries" type="text" pInputText placeholder="Filter"
                (input)="onClearseries(table1)">
             
            </div>
          </th>
          <th style="width: 140px" scope="col">
            <div class="p-inputgroup"  *ngIf="filterAction">
              <input class="cmn-table-data-style" [(ngModel)]="filtermodelCode" type="text" pInputText
                placeholder="Filter" (input)="onClearmodelCode(table1)">

            </div>
          </th>
          <th style="width: 140px" scope="col">
            <div class="p-inputgroup"  *ngIf="filterAction">
              <input class="cmn-table-data-style" [(ngModel)]="filterauditExceptionInfo" type="text" pInputText
                placeholder="Filter" (input)="onClearauditExceptionInfo(table1)">

            </div>
          </th>
          <th style="width: 140px" scope="col">
            <div class="p-inputgroup"  *ngIf="filterAction">
              <input class="cmn-table-data-style" [(ngModel)]="filterstatus" type="text" pInputText placeholder="Filter"
                (input)="onClearstatus(table1)">

            </div>
          </th>
          <th style="width: 140px" scope="col">
            <div class="p-inputgroup"  *ngIf="filterAction">
              <input class="cmn-table-data-style" [(ngModel)]="filterlastUpdatedInfo" type="text" pInputText
                placeholder="Filter" (input)="onClearlastUpdatedInfo(table1)">
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-adminData>
        <tr [pSelectableRow]="adminData">
          <td>
            <div class="icon-container">
              <span><i class="pi pi-pencil" (click)="editAuditWaiverDetails(adminData)"></i></span>
              <span class="spacer"></span>
              <span [ngClass]="adminData.activeInd === 'Inactive' ?'audit-disabled':''">
                <i class="pi pi-trash" (click)="deleteAuditWaiverDetails(adminData)"></i>
              </span>
            </div>
          </td>
          <td class="cmn-table-data-style">{{adminData.modelYear}}</td>
          <td class="cmn-table-data-style">{{adminData.productGroupId}}</td>
          <td class="cmn-table-data-style">{{adminData.modelCd}}</td>
          <td class="cmn-table-data-style">{{adminData.auditWaiverExcp}}</td>
          <td class="cmn-table-data-style">{{adminData.activeInd}}</td>
          <td class="cmn-table-data-style">{{adminData.createdts}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="6" class="cmn-table-data-style">
            No records found
          </td>
        </tr>
      </ng-template>
    </p-table>
    <!-- <p-paginator [style]="{'font-family' : 'Roboto' , 'font-size': '12px', 'margin-bottom' :'20px'}" 
    [rows]="5" [totalRecords]="totalRecords"
      [pageLinkSize]="totalPages"  [rowsPerPageOptions]="[5,10,20,30]"
      (onPageChange)="onPaginator($event)">
    </p-paginator> -->
    <!-- </p-tabPanel> -->

    <p-sidebar class="audit-side-bar" [modal]="true" [(visible)]="displayAddWaiveListPanel" position="right"
      [style]="{width:'50%'}" [blockScroll]="true" (onHide)="sidenavClosed()" [baseZIndex]="100">
      <ng-template pTemplate="content">
        <div class="audit_fix_header">
          <h4 *ngIf="!auditWaiverData.auditWaiverId">View Waived Audit</h4>
          <h4 *ngIf="auditWaiverData.auditWaiverId">Edit Waived Audit</h4>
        </div>
        <div class="grid">
          <div class="input-part">
            <vma-vehicle-search-by-admin (searchEvent)="searchSideBar($event)"></vma-vehicle-search-by-admin>
            <div class="width100 display_inline">
              <div class="p-col-4">
                <label class="cmn-label-font-style">Status</label>
              </div>
              <div class="p-col-8 display_inline">
                <p-inputSwitch [(ngModel)]="auditWaiverData.active"></p-inputSwitch>
                <h5 *ngIf="auditWaiverData.active" class="toggle" style="margin-left: 10px; color: blue;">Active</h5>
                <h5 *ngIf="!auditWaiverData.active" class="toggle" style="margin-left: 10px;">Inactive</h5>
              </div>
            </div>
          </div>
          <div class="col-2">
            <p-divider layout="vertical"></p-divider>
          </div>
          <div class="input-part">
            <h4 style="margin: 2%;">Available Actions (Only One Selectable)</h4>
            <p-button *ngIf="!fullAuditWaived" class="button action-buttons"
              styleClass="cmn-button-style p-button-secondary" [disabled]="false" (onClick)="waiveFullAudit()"
              label="Waive Full Audit"> </p-button>
            <p-button *ngIf="fullAuditWaived" class="button action-buttons"
              styleClass="cmn-button-style p-button-success" [disabled]="true" label="Full Audit Waived"> </p-button>
            <p-button *ngIf="!secondaryAuditWaived" class="button action-buttons"
              styleClass="cmn-button-style p-button-secondary" [disabled]="false" (onClick)="waiveSecondaryAudit()"
              label="Waive Secondary Audit"> </p-button>
            <p-button *ngIf="secondaryAuditWaived" class="button action-buttons"
              styleClass="cmn-button-style p-button-success" [disabled]="true" label="Secondary Audit Waived">
            </p-button>
            <p-button *ngIf="!modifiedWaived" class="button action-buttons"
              styleClass="cmn-button-style p-button-secondary" [disabled]="false" (click)="modifyAuditScope()"
              label="Modify Audit Scope"> </p-button>
            <p-button *ngIf="modifiedWaived" class="button action-buttons"
              styleClass="cmn-button-style p-button-success" [disabled]="true" label="Modify Audit Scope"> </p-button>
          </div>
        </div>
        <div class="grid">
          <div class="p-col-5 padd-bot0">
            <div class="grid">
              <div class="p-col-5">
                <label class="cmn-label-font-style" style="padding: 0.5rem;float: right;">Comment</label>
              </div>
              <div class="p-col-7">
                <textarea rows="3" cols="20" pInputTextarea class="cmn-input-font-style" style="margin-right: 50px;"
                  placeholder="Do you want to add some comments?" [autoResize]="true" maxlength="125" required
                  [(ngModel)]="auditWaiverData.comment">
                </textarea>
              </div>
            </div>
          </div>
          <div class="p-col-2"></div>
          <div class="p-col-5 marg-padd0">
            <button type="button" label="Save" pButton pRipple icon="pi pi-save"
              [disabled]="!fullAuditWaived && !secondaryAuditWaived && !modifiedWaived" class="cmn-button-style p-mr-2"
              (click)="saveAction()"></button>
            <button type="button" label="Cancel" pButton pRipple icon="pi pi-times"
              [disabled]="!fullAuditWaived && !secondaryAuditWaived && !modifiedWaived"
              class="p-button-outlined cmn-button-style p-mr-2" (click)="cancelAction()"></button>
          </div>
        </div>
        <br>
        <!--Picklist-->
        <p-accordion *ngIf="displayPicklist">
          <p-accordionTab header=" Please choose the scope items that are applicable for audit"
            [(selected)]="activeTab">
            <p-pickList [source]="waivedScopeItems" [target]="inScopeItems" sourceHeader="Waived Scope Items"
              targetHeader="In-Scope Items" [dragdrop]="true" [responsive]="true" [sourceStyle]="{ height: '300px' }"
              [targetStyle]="{ height: '300px' }">
              <ng-template let-scopeItems pTemplate="item">
                <div>
                  <div>
                    <h5 class="mb-2">{{scopeItems.scopeTypeCd}}</h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </p-accordionTab>
        </p-accordion>
        <span></span>
        <!--Grid area-->
        <p-table #table2 [value]="auditHistoryList" styleClass="p-datatable-sm" [rows]="5" selectionMode="single"
          responsiveLayout="scroll" [paginator]="true" [rowsPerPageOptions]="[5,10,25,50]">
          <ng-template pTemplate="header">
            <tr>
              <th class="cmn-table-header-style" style="width: 140px" scope="col" pSortableColumn="dateAndTime"> Date
                &
                Time
                <p-sortIcon field="dateAndTime"></p-sortIcon>
              </th>
              <th class="cmn-table-header-style" style="width: 146px" scope="col" pSortableColumn="actionTaken">
                Action
                Taken
                <p-sortIcon field="actionTaken"></p-sortIcon>
              </th>
              <th class="cmn-table-header-style" style="width: 140px" pSortableColumn="notes" scope="col">Notes
                <p-sortIcon field="notes"></p-sortIcon>
              </th>
              <th class="cmn-table-header-style" style="width: 140px" pSortableColumn="userName" scope="col">
                UserName <p-sortIcon field="userName"></p-sortIcon>
              </th>
            <tr>
              <th style="width: 140px" scope="col">
                <div class="p-inputgroup">
                  <input class="cmn-table-data-style" [(ngModel)]="filterDateAndTime" type="text" pInputText
                    placeholder="Filter" (input)="onClearDateAndTime(table2)">
                  <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
                    (click)="onFilterDateAndTime(table2)"></button>
                </div>
              </th>
              <th style="width: 140px" scope="col">
                <div class="p-inputgroup">
                  <input class="cmn-table-data-style" [(ngModel)]="filterActionTaken" type="text" pInputText
                    placeholder="Filter" (input)="onClearActionTaken(table2)">
                  <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
                    (click)="onFilterActionTaken(table2)"></button>
                </div>
              </th>
              <th style="width: 140px" scope="col">
                <div class="p-inputgroup">
                  <input class="cmn-table-data-style" [(ngModel)]="filterNotes" type="text" pInputText
                    placeholder="Filter" (input)="onClearNotes(table2)">
                  <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
                    (click)="onFilterNotes(table2)"></button>
                </div>
              </th>
              <th style="width: 140px" scope="col">
                <div class="p-inputgroup">
                  <input class="cmn-table-data-style" [(ngModel)]="filterUserName" type="text" pInputText
                    placeholder="Filter" (input)="onClearUserName(table2)">
                  <button type="button" icon="pi pi-search" class="p-button-secondary" pButton pRipple
                    (click)="onFilterUserName(table2)"></button>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-gridDataList>
            <tr [pSelectableRow]="gridDataList">
              <td class="cmn-table-data-style">{{gridDataList.updatedTs}}</td>
              <td class="cmn-table-data-style">{{gridDataList.actionDesc}}</td>
              <td class="cmn-table-data-style">{{gridDataList.comment}}</td>
              <td class="cmn-table-data-style">{{gridDataList.actorNm}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="4" class="cmn-table-data-style">
                No records found
              </td>
            </tr>
          </ng-template>
        </p-table>

      </ng-template>

      <p-dialog [modal]="true" [showHeader]="true" [closeOnEscape]="false" [draggable]="false" [resizable]="false"
        [closable]="true" [(visible)]="displayConfirmPopup" [style]="{'width': '28vw'}" position="center">
        <ng-template pTemplate="header">
          <h1 class="cmn-label-font-style">Confirmation</h1>
        </ng-template>
        <ng-template pTemplate="content">
          <h4 class="cmn-input-font-style">
            Are you sure to <strong>{{auditWaiverData.ActionDesc}}</strong>?
          </h4>
        </ng-template>
        <ng-template pTemplate="footer">
          <p-button *ngIf="waiveAction" styleClass="cmn-button-style" (click)="confirmWaiveFullAudit()" label="Proceed">
          </p-button>
          <p-button *ngIf="!waiveAction" styleClass="cmn-button-style" (click)="confirmWaiveSecondaryAudit()"
            label="Proceed"></p-button>
          <p-button styleClass="p-button-outlined cmn-button-style" (click)="cancelAudit()" label="Cancel"> </p-button>
        </ng-template>
      </p-dialog>
    </p-sidebar>
  </div>
</div>

<div id="deleteConfirmationPopup">
  <p-dialog [closable]="true" [closeOnEscape]="false" [draggable]="false" [style]="{width: '25.2vw'}"
    [resizable]="false" [(visible)]="deleteConfirmationPopup" [modal]="true" [responsive]="true">
    <ng-template pTemplate="header">
      <h1 class="cmn-label-font-style">Delete Confirmation</h1>
    </ng-template>
    <ng-template pTemplate="content">
      <h4 class="cmn-input-font-style">
        Are you sure to delete the Audit Waiver data?
      </h4>
      <textarea rows="6" cols="40" [autoResize]="true" pInputTextarea class="cmn-input-font-style"
        placeholder="Do you want to add some comments?" maxlength="125" required [(ngModel)]="auditWaiverData.comment">
      </textarea>
    </ng-template>
    <ng-template pTemplate="footer">
      <button type="button" label="Yes" pButton pRipple icon="pi pi-trash" class="cmn-button-style p-mr-2"
        (click)="deleteAuditData()"></button>
      <button type="button" label="No" pButton pRipple icon="pi pi-times"
        class="cmn-button-style p-button-outlined p-mr-2" (click)="closeAuditData()"></button>
    </ng-template>
  </p-dialog>
</div>