import { DBConfig } from "ngx-indexed-db";

export enum DbStores {
    VEH_ASSMNT_EVENT = 'VEH_ASSMNT_EVENT',
    FINAL_ASSEMBLY_POINT = 'FINAL_ASSEMBLY_POINT',
    COUNTRY_OF_ORIGIN = 'COUNTRY_OF_ORIGIN'
}

export const IndexedDBConfig: DBConfig = {
    name: 'VMA',
    version: 1,
    objectStoresMeta: [
        {
            store: DbStores.VEH_ASSMNT_EVENT,
            storeConfig: { keyPath: 'vehAssmntEventId', autoIncrement: false },
            storeSchema: []
        }, {
            store: DbStores.FINAL_ASSEMBLY_POINT,
            storeConfig: { keyPath: 'key', autoIncrement: false },
            storeSchema: []
        }, {
            store: DbStores.COUNTRY_OF_ORIGIN,
            storeConfig: { keyPath: 'key', autoIncrement: false },
            storeSchema: []
        }
    ]
}
