import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpBaseService } from './http/http.base.service';
import { HttpServiceImpl } from './http/http.service.impl';
import { RestService } from './service/rest.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    HttpBaseService,
    HttpServiceImpl,
    RestService
  ],
})
export class RestApiModule { }
