import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { Environment_URL } from './app/common/app.constants';
import { DevEnvironment } from './environments/environment.dev';
import { LocalEnvironment } from './environments/environment.local';
import { ProdEnvironment } from './environments/environment.prod';
import { QaEnvironment } from './environments/environment.qa';
import { TestEnvironment } from './environments/environment.test';

let production = false;
const url: string = window.location.href;
if (url.search(Environment_URL.LOCAL) !== -1) {
  production = LocalEnvironment.production;
} else if (url.search(Environment_URL.DEV) !== -1) {
  production = DevEnvironment.production;
} else if (url.search(Environment_URL.TEST) !== -1) {
  production = TestEnvironment.production;
} else if (url.search(Environment_URL.QA) !== -1) {
  production = QaEnvironment.production;
} else {
  production = ProdEnvironment.production;
}

if (production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
