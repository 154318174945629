import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Accessory, DropDownModel } from 'src/app/models/dropdown.model';
import { isNullOrUndefined, isEmpty, isArray } from 'src/app/core/utilities/vma-common.util';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'vma-accessories-dashboard',
  templateUrl: './accessories-dashboard.component.html',
  styleUrls: ['./accessories-dashboard.component.scss']
})
export class AccessoriesDashboardComponent implements OnInit {
  selectedAccessories: any;
  accessories: Accessory[] = [];

  selectedBrand: any;
  selectedModelYear: any;
  selectedSeries: any;
  selectedVdc: any;
  selectedModelCode: any;

  @Output() accessoryEvent = new EventEmitter<any>();

  constructor(private readonly sharedService: SharedService,
    private readonly vehicleService: VehicleService) { }

  ngOnInit(): void {
  }

  /**
   * @param  {DropDownModel} setbrandId   * 
   */
  @Input()
  set brandId(brandId: DropDownModel) {
    this.accessories = [];
    this.selectedBrand = brandId;
    this.selectedModelYear = null;
    this.selectedSeries = null;
    this.selectedVdc = null;
    this.selectedModelCode = null;
    this.selectedAccessories = null;
  }

  /**
   * @param  {number} setyearNo   *
   */
  @Input()
  set yearNo(yearNo: number) {
    this.accessories = [];
    this.selectedModelYear = yearNo;
    this.selectedSeries = null;
    this.selectedVdc = null;
    this.selectedModelCode = null;
    this.selectedAccessories = null;
  }

  /**
   * @param  {DropDownModel} setseriesId   * 
   */
  @Input()
  set seriesId(series: DropDownModel) {
    this.accessories = [];
    this.selectedSeries = series;
    this.selectedVdc = null;
    this.selectedModelCode = null;
    this.selectedAccessories = null;
  }

  /**
   * @param  {DropDownModel} setseriesId   * 
   */
  @Input()
  set vdcCd(vdc: any) {
    this.accessories = [];
    this.selectedVdc = vdc;
    this.selectedAccessories = null;
    if (!isNullOrUndefined(this.selectedVdc)) {
      if (!isArray(this.selectedVdc)) {
        this.selectedModelCode = null;
      } else if (this.selectedVdc.length !== 0) {
        this.listAccessoriesWithVdc();
      }
    }    
  }

  @Input()
  set modelcodeId(modelcode: any) {
    this.accessories = [];
    this.selectedAccessories = null;
    this.selectedModelCode = modelcode;
    if (!isNullOrUndefined(this.selectedModelCode)) {
      if (!isArray(this.selectedModelCode)) {
        this.selectedVdc = null;
      } else if (this.selectedModelCode.length !== 0) {
        this.listAccessoriesWithModelCode();
      }
    }
  }


  /**
   * @param  {Distributor} distributorId 
   * @param  {DropDownModel} brandId
   * @param  {number} yearNo
   * @param  {DropDownModel} seriesId
   */
  listAccessoriesWithModelCode() {
    const distributorCd = "91041";
    if (!isNullOrUndefined(this.selectedBrand) && !isNullOrUndefined(this.selectedModelYear)
      && !isNullOrUndefined(this.selectedSeries) && !isNullOrUndefined(this.selectedModelCode)) {
      let modelcodeValue = null;
      if (isArray(this.selectedModelCode)) {
        modelcodeValue = this.selectedModelCode.map(x => x.name).join(',');
      } else {
        modelcodeValue = this.selectedModelCode.name;
      }
      const brandIdRef: string = this.formatQueryParams(this.selectedBrand);
      const seriesIdRef: string = this.formatQueryParams(this.selectedSeries);
      const parames = {
        brandid: brandIdRef,
        modelyear: this.selectedModelYear,
        series: seriesIdRef,
        //vdccd: vdcValue,
        modelcd: modelcodeValue
      };
      this.sharedService.clearPreviousMessages();
      this.vehicleService.getAccessoriesList(distributorCd, parames).subscribe(res => {
        //this.accessories = new Array<Accessory>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          this.accessories = res.data[0].accessoryList.accessory.filter(obj => obj.name === 'F');
          this.setSelectedAccessoriesValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  /**
   * @param  {Distributor} distributorId 
   * @param  {DropDownModel} brandId
   * @param  {number} yearNo
   * @param  {DropDownModel} seriesId
   */
  listAccessoriesWithVdc() {
    const distributorCd = "91041";
    if (!isNullOrUndefined(this.selectedBrand) && !isNullOrUndefined(this.selectedModelYear)
      && !isNullOrUndefined(this.selectedSeries) && !isNullOrUndefined(this.selectedVdc)
      && !isNullOrUndefined(this.selectedModelCode)) {
      let modelcodeValue = null; let vdcValue = null;
      if (isArray(this.selectedVdc)) {
        vdcValue = this.selectedVdc.map(x => x.code).join(',');
      } else {
        vdcValue = this.selectedVdc.code;
      }
      if (isArray(this.selectedModelCode)) {
        modelcodeValue = this.selectedModelCode.map(x => x.name).join(',');
      } else {
        modelcodeValue = this.selectedModelCode.name;
      }
      const brandIdRef: string = this.formatQueryParams(this.selectedBrand);
      const seriesIdRef: string = this.formatQueryParams(this.selectedSeries);
      const parames = {
        brandid: brandIdRef,
        modelyear: this.selectedModelYear,
        series: seriesIdRef,
        vdccd: vdcValue,
        modelcd: modelcodeValue
      };
      this.sharedService.clearPreviousMessages();
      this.vehicleService.getAccessoriesList(distributorCd, parames).subscribe(res => {
        //this.accessories = new Array<Accessory>();
        if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
          this.accessories = res.data[0].accessoryList.accessory.filter(obj => obj.name === 'F');
          this.setSelectedAccessoriesValue();
        }
      }, (error: HttpErrorResponse) => {
        this.sharedService.handleHttpError(error);
      });
    }
  }

  formatQueryParams(object: any): string {
    let queryParams = '';
    if (object.selectAll) {
      queryParams = 'all';
    } else {
      let count = 0;
      object.forEach(element => {
        if (count !== 0) {
          queryParams = queryParams + ',';
        }
        queryParams = queryParams + element.id;
        count = count + 1;
      });
    }
    return queryParams;
  }

  onAccessoriesChange(event: any) {
    this.selectedAccessories = event.value;
    this.accessoryEvent.emit(this.selectedAccessories);
  }

  setSelectedAccessoriesValue() {
    if (!isNullOrUndefined(this.sharedService.dashoardSearchData) 
      && !isNullOrUndefined(this.sharedService.dashoardSearchData.accessory)) {
      this.selectedAccessories = this.sharedService.dashoardSearchData.accessory;
      this.accessoryEvent.emit(this.selectedAccessories);
    }
    else { //for tls component
      // if (!isNullOrUndefined(this.sharedService.accessoryName)) {
      //   const accessoryName = this.sharedService.accessoryName.toUpperCase();
      //   const accessoryValue = this.accessories.find(item => item.code === accessoryName);
      //   if (!isNullOrUndefined(accessoryValue)) {
      //     this.selectedAccessories = [accessoryValue];
      //     console.log("selectedAccessories", this.selectedAccessories);
      //     this.accessoryEvent.emit(this.selectedAccessories);
      //   }        
      // }
    }
  }
}
