import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { MessageSeverity, WorkflowStatus, VMARole, ReleaseStatus } from 'src/app/common/app.constants';
import { AuditVehicleListDetails, IVehicleAccessories } from 'src/app/core/model/ui/audit-vehicle.data.model';
import { VehicleService } from 'src/app/core/services/api/audit-vehicle.service';
import { SharedService } from 'src/app/core/services/ui/shared.service';
import { isEmpty, isNullOrUndefined } from 'src/app/core/utilities/vma-common.util';
import { VehicleSelectionComponent } from '../vehicle-selection/vehicle-selection.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DbStores } from 'src/app/core/indexed-db/indexed-db.config';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { SmartChronicData, SmartChronicHoldObj } from 'src/app/core/model/ui/chronic-hold-data.model';
import { ReleaseAuditComponent } from 'src/app/modules/release-audit/release-audit.component';
import { NPPSPropagationData } from 'src/app/core/model/ui/audit-admin.data.model';
import { AuditData } from 'src/app/core/model/ui/audit-data.model';
import * as _ from 'lodash';

@Component({
  providers: [VehicleSelectionComponent],
  selector: 'vma-audit-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})

export class AuditVehicleListComponent implements AfterViewInit {
  allVehicelist: AuditVehicleListDetails[] = [];
  selectedVeh: AuditVehicleListDetails[] = [];
  brand: any;
  filteredVin: string;
  yearNo: any;
  auditedVehicleRow: any;
  selectedAuditedVehicleRow: any;
  offset = 0;
  limit = 50;
  totalRecords = 0;
  totalPages = 0;
  showPagination = false;
  loggedInUser: string;
  isDatainMyInbox: boolean;
  indexDBData: any[];
  displayHoldReleasePopup = false;
  showButton: any;
  selectedData: any[] = [];
  smartChronicData: SmartChronicData;
  smartChronicObjArr: SmartChronicHoldObj[] = [];
  params: any = {};
  rowCheckbox: boolean;
  dataFromChild: any;
  @ViewChild('ReleaseAuditComponent') releaseAuditComp: ReleaseAuditComponent;
  onDialogClose: boolean;
  retainYear: any;
  maxSelection: number = 10;
  isSelectionLimitReached = false;
  selectedRowForNPPSSync: NPPSPropagationData = new NPPSPropagationData ();

  auditData: AuditData;
  auditedVehicleEventId: number=0;
  selectedRowForNVSSync: NPPSPropagationData = new NPPSPropagationData ();

  nppsSyncFlag: boolean = false;

  constructor(public sharedService: SharedService,
    private readonly vehicleService: VehicleService,
    private readonly router: Router,
    private readonly indexedDBService: NgxIndexedDBService,
    private readonly messageService: MessageService,
    private vehicleComp: VehicleSelectionComponent,
    private readonly confirmationService: ConfirmationService,
    public authService: AuthService )
  { }

  ngAfterViewInit(): void {
    if (this.releaseAuditComp) {
    }
  }

  ngOnInit() {
    this.sharedService.vehicleSearchData = {};
    this.setYearNo();
    this.allVehicleSearch();
    this.getDataFromIndexedDB();
  }

  setYearNo() {
    this.brand = { id: 1, name: "Toyota" };
    if (this.sharedService.backFromReport) {
      this.yearNo = this.sharedService.retainModelYear;
    } else if (this.retainYear) {
      this.yearNo = this.retainYear;
    } else {
      this.yearNo = new Date().getFullYear().toString();
    }
    this.sharedService.vehicleSearchData.yearNo = this.yearNo;
    this.sharedService.backFromReport = false;
  }

  selectedYear(value) {
    this.yearNo = value;
    this.selectedAuditedVehicleRow = null;
  }

  setUserName() {
    this.loggedInUser = this.authService.getAuthenticatedUserProfile().name;
  }

  allVehicleSearch() {
    if (!isNullOrUndefined(this.yearNo)) {
      const params: any = {};
      params.modelyear = this.yearNo;
      params.limit = this.limit;
      params.offset = this.offset;
      this.selectedAuditedVehicleRow = null;
      this.getSearchVehicleDetails(params);
    } else {
      this.messageService.add({
        severity: MessageSeverity.ERROR,
        summary: 'Error',
        sticky: true,
        detail: 'Select the Model year'
      });
    }
  }

  getSearchVehicleDetails(params: any) {
    this.vehicleService.getAllVehicleList(params).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.allVehicelist = res.data[0].auditVehicle.vehicleList;
        this.totalRecords = 0;
        if (!isNullOrUndefined(res.data[0].auditVehicle.total)) {
          this.totalRecords = res.data[0].auditVehicle.total;
          this.offset = res.data[0].auditVehicle.offset;
          this.setPaginationData();
          this.showPagination = true;
          this.setUserName();
        } else {
          this.sharedService.handledNoRecords();
        }
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    });
  }

  setPaginationData() {
    this.totalPages = Math.ceil(this.totalRecords / this.limit);
    if (this.totalPages > 5) {
      this.totalPages = 5;
    }
  }

  onFilterVin(table1: Table) {
    if (this.filteredVin.length === 17) {
      const filteredRes = this.allVehicelist.filter(value => value.vin.startsWith(this.filteredVin));
      if (isEmpty(filteredRes)) {
        this.allVehicleSearch();
      } else {
        table1.filter(this.filteredVin, 'vin', 'contains');
      }
    } else {
      this.allVehicleSearch();
    }
  }

  onClearVin(table1: Table) {
    if (this.filteredVin === '') {
      table1.filter(this.filteredVin, 'vin', 'contains');
    }
  }

  onPaginator(event: any) {
    this.offset = event.first;
    this.limit = event.rows;
    this.allVehicleSearch();
  }

  resetAllVehicles() {
    this.brand = null;
    this.allVehicelist = [];
    this.showPagination = false;
    setTimeout(() => {
      this.setYearNo();
      this.allVehicleSearch();
    }, 10);
  }

  getDataFromIndexedDB(): void {
    this.indexedDBService.getAll(DbStores.VEH_ASSMNT_EVENT).subscribe((result) => {
      this.indexDBData = result;
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    });
  }

  validateInboxData(): boolean {
    if (!isNullOrUndefined(this.indexDBData)) {
      for (let i = 0; i < this.indexDBData.length; i++) {
        if (this.indexDBData[i].vehAssmntEventId === this.selectedAuditedVehicleRow.vehAssmntEventId) {
          return true;
        }
      }
    }
    return false;
  }

  goToInbox(selectedRow): void {
    this.sharedService.selectedAuditedRow = selectedRow;
    this.sharedService.isNavigatedFromAuditedVehicle = true;
    this.router.navigate(['audit/inbox']);
    this.selectedAuditedVehicleRow = null;
  }

  validateReadOnly(): boolean {
    const authUserProfile = this.authService.getAuthorizedUserProfile();
    if (authUserProfile.role.name !== "Readonly" && authUserProfile.role.name !== "QPAApprover") {
      return true;
    }
    return false;
  }

  navigateToAuditReport() {
    this.sharedService.backFromReport = true;
    this.sharedService.retainModelYear = this.yearNo;

    // this.setSearchVehicleData();
    this.router.navigate(['audit/report']);
  }

  setSearchVehicleData() {
    const searchParams: any = {};
    searchParams.yearNo = this.yearNo;
    searchParams.limit = this.limit;
    searchParams.offset = this.offset;
    this.sharedService.vehicleSearchData = searchParams;
  }

  generateAuditedVehicleReport(): void {
    this.sharedService.setAuditedVehicleFlag(true);
    if (this.selectedAuditedVehicleRow) {
      this.sharedService.setVehAssmntEventId(this.selectedAuditedVehicleRow.vehAssmntEventId);
      this.navigateToAuditReport();
    }
  }

  viewAuditReport() {
    this.sharedService.setAuditedVehicleFlag(true);
    if (this.selectedAuditedVehicleRow) {
      this.sharedService.setVehAssmntEventId(this.selectedAuditedVehicleRow.vehAssmntEventId);
    }
    this.navigateToAuditReport();
  }

  readyForAudit(): void {
    if (this.selectedAuditedVehicleRow) {
      this.setReadytoAuditNVSData(this.selectedAuditedVehicleRow);
      this.vehicleComp.getFinalAssemblyPoints();
      this.vehicleComp.getCountryOfOrigins();
      this.createAudit(this.selectedAuditedVehicleRow.vehAssmntEventId);
    }
    this.selectedAuditedVehicleRow = null;
  }

  createAudit(vehAssmntEventId: number): void {
    this.vehicleService.addVehicle(vehAssmntEventId).subscribe((res: any) => {
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.auditedVehicleEventId = vehAssmntEventId;
        this.getAuditData();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  getAuditData(): void {
    let vehAssmntEventId: number;
    if (this.auditedVehicleEventId !== 0) {
      vehAssmntEventId = this.auditedVehicleEventId;
      this.auditedVehicleEventId = 0;
    } else {
      vehAssmntEventId = this.selectedAuditedVehicleRow.vehAssmntEventId;
    }
    this.vehicleService.getAudit(vehAssmntEventId).subscribe(res => {
      this.auditData = new AuditData();
      if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
        this.auditData = res.data[0].auditData;
        this.addAuditDataToIndexedDB();
        // this.nppsDataSync(this.selectedRowForNVSSync, vehAssmntEventId);
        this.confirmNavigateToInbox();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  addAuditDataToIndexedDB(): void {
    this.auditData.vehAssmntEventWorkflow.workflowStatusCd = WorkflowStatus.READY_FOR_AUDIT;
    this.indexedDBService.add(DbStores.VEH_ASSMNT_EVENT, this.auditData).subscribe((result) => {
    }, (error) => {
      this.sharedService.handleIndexedDBError(error);
    })
  }

  confirmNavigateToInbox(): void {
    this.confirmationService.confirm({
      message: 'Do you want to Stay on Same Screen or Go to Audit Inbox ?',
      acceptLabel: 'Go to Inbox',
      rejectLabel: 'Stay on same page',
      accept: () => {
        this.goToMyAuditInbox();
      }, reject: () => {
        this.stayOnSameScreen();
      }
    });
  }

  goToMyAuditInbox(): void {
    this.router.navigate(['audit/inbox']);
  }

  stayOnSameScreen(): void {
    this.allVehicleSearch();
  }

  unselectAuditVehicle(selectedRow: any): void {
    if (selectedRow) {
      this.setUnselectNPPSData(selectedRow);
      this.updateAuditWorkflow(selectedRow.vehAssmntEventId, WorkflowStatus.UN_SELECTED);
    }
    this.selectedAuditedVehicleRow = null;
  }

  updateAuditWorkflow(vehAssmntEventId: number, actionState: WorkflowStatus): void {
    const params: object = {
      action: actionState
    }
    const reqBody = {
      comment: null
    }
    this.vehicleService.updateWorkflow(vehAssmntEventId, reqBody, params).subscribe(res => {
      if (actionState === WorkflowStatus.READY) {
        //this.confirmNavigateToInbox();
      } else {
        // this.nppsDataSync(this.selectedRowForNPPSSync, vehAssmntEventId);
        this.allVehicleSearch();
      }
    }, (error: HttpErrorResponse) => {
      this.sharedService.handleHttpError(error);
    })
  }

  getStatusColor(nppsStatus: string): string {
    const NotSentToNPPS = "NOT_SENT_TO_NPPS";
    const SentToNpps = "SENT_TO_NPPS";
    const Retry = "RETRY";
    switch (nppsStatus) {
      case SentToNpps:
        return 'green'; //Green
      case NotSentToNPPS:
        return 'gray'; // Gray
      case Retry:
        return 'red'; // Red
      default:
        return '';
    }
  }

  getStatusText(nppsStatus: string): any {
    if (!nppsStatus) {
      return '';
    }
    if (nppsStatus.indexOf('_') === -1) {
      return nppsStatus;
    }
    const words = nppsStatus.split('_');
    // const words = nppsStatus.includes('_') ? nppsStatus.replace(/_/g,  ' ') : nppsStatus
    const transformedStatus = words.map(word => {
      return word.charAt(0) + word.slice(1).toLowerCase();
    }).join(' ');
    return transformedStatus;
  }

  setNPPSData(rowData: any) {
    let vehAssmntEventId = rowData.vehAssmntEventId;
    this.selectedRowForNPPSSync.modelYear = rowData.modelYear;
    this.selectedRowForNPPSSync.modelCode = rowData.modelCode;
    this.selectedRowForNPPSSync.vin = rowData.vin;
    if(!isNullOrUndefined(rowData.dummyVin)) {
      this.selectedRowForNPPSSync.dummyVin = rowData.dummyVin;
    } else {
      this.selectedRowForNPPSSync.dummyVin = rowData.vin;
    }
    this.selectedRowForNPPSSync.vdc = rowData.vdc;
    if(rowData.workflowStatus === WorkflowStatus.SELECTED) {
      this.selectedRowForNPPSSync.action = 'selected_for_audit';
    } else if(rowData.workflowStatus === WorkflowStatus.INPROGRESS) {
      this.selectedRowForNPPSSync.action = 'in_progress_audit';
    }

    // this.nppsDataSync(this.selectedRowForNPPSSync, vehAssmntEventId);
    if(this.nppsSyncFlag) {
      rowData.nppsStatus = 'RETRY';
    }
  }

  setReadytoAuditNVSData(rowData: any) {
    this.selectedRowForNVSSync.modelYear = rowData.modelYear;
    this.selectedRowForNVSSync.modelCode = rowData.modelCode;
    this.selectedRowForNVSSync.vin = rowData.vin;
    this.selectedRowForNVSSync.dummyVin = rowData.dummyVin;
    this.selectedRowForNVSSync.vdc = rowData.vdc;
    this.selectedRowForNVSSync.action = 'in_progress_audit';
  }

  setUnselectNPPSData(rowData: any) {
    this.selectedRowForNPPSSync.modelYear = rowData.modelYear;
    this.selectedRowForNPPSSync.modelCode = rowData.modelCode;
    this.selectedRowForNPPSSync.vin = rowData.vin;
    this.selectedRowForNPPSSync.dummyVin = rowData.dummyVin;
    this.selectedRowForNPPSSync.vdc = rowData.vdc;
    if(rowData.workflowStatus === WorkflowStatus.SELECTED) {
      this.selectedRowForNPPSSync.action = 'unselected_for_audit';
    }
  }

  // nppsDataSync(data: NPPSPropagationData, vehAssmntEventId: number) {
  //   this.vehicleService.nppsDataPropagation(data, vehAssmntEventId).subscribe(res => {
  //     if (!isNullOrUndefined(res.data) && !isEmpty(res.data)) {
  //       this.messageService.add({ severity: 'info', summary: '', detail: "Data synced with NPPS and NVS.", life: 5000  });
  //       setTimeout(() => {
  //         this.setYearNo();
  //         this.allVehicleSearch();
  //       }, 5000);
  //     }
  //   }, (error: HttpErrorResponse) => {
  //     this.nppsSyncFlag = true;
  //     this.sharedService.handleHttpError(error);
  //   })
  // }

  isValidRowForNPPSSync(row: any): boolean {
    const validCbuVdcs = ['LA', 'JX', 'PT', 'NY'];
    const validStatuses = ['SELECTED', 'INPROGRESS'];

    return validCbuVdcs.includes(row.vdc) && validStatuses.includes(row.workflowStatus);
  }

  releaseTlsHold() {
    if (!isNullOrUndefined(this.selectedVeh) && !isEmpty(this.selectedVeh)) {
      this.setMultiSelectData(this.selectedVeh);
      this.confirmationService.confirm({
        message: 'Do you want to Release the selected configuration(s)?',
        acceptLabel: 'Release',
        rejectLabel: 'Cancel',
        accept: () => {
          // this.goToMyAuditInbox();
          this.displayHoldReleasePopup = true;
          // this.releaseAuditComp.updateMultiSelectRowData(this.smartChronicObjArr);
        }, reject: () => {
          // this.stayOnSameScreen();
          this.smartChronicObjArr = [];
        }
      });
    } else {
      this.messageService.add({ severity: 'error', summary: '', detail: "Select atleast one record for release.", life: 2000  });
    }
  }

  updateSelection(searchData) {
    this.showButton = true;

    if (searchData.rowCheckbox) {
      this.pushMatchingVeh(searchData);
    } else {
      const originalSelectedVeh = {
        modelYear: searchData.modelYear,
        modelCode: searchData.modelCode,
        vdc: searchData.vdc,
        workflowStatus: searchData.workflowStatus,
        rowCheckbox: true,
        accessorList: searchData.accessorList.filter((acc) => acc.componentTypeCd === 'F').map((acc) => acc.componentCd)
      };
  
      // Find all duplicates based on the original selected vehicle
      const duplicateVehicles = this.selectedVeh.filter((veh) => {
        return (
          veh.modelYear === originalSelectedVeh.modelYear &&
          veh.modelCode === originalSelectedVeh.modelCode &&
          veh.vdc === originalSelectedVeh.vdc &&
          veh.workflowStatus === originalSelectedVeh.workflowStatus &&
          originalSelectedVeh.accessorList.every((accCd) => veh.accessorList.some((acc) => acc.componentCd == accCd))
        );
      });
      //Deselect original selected vehicle
      originalSelectedVeh.rowCheckbox = false;
  
      //Deselect all automatically selected duplicates
      duplicateVehicles.forEach(vehicle => vehicle.rowCheckbox = false);
  
      //Remove duplicates from selectedVeh
      this.selectedVeh = [...new Set(this.selectedVeh.filter(vehicle => !duplicateVehicles.includes(vehicle)))];
    }
  }

  pushMatchingVeh(veh: any) {
    const criteria = {
      modelYear: veh.modelYear,
      modelCode: veh.modelCode,
      vdc: veh.vdc,
      workflowStatus: veh.workflowStatus,
      rowCheckbox: true,
      accessorList: veh.accessorList.filter((acc) => acc.componentTypeCd === 'F').map((acc) => acc.componentCd)
    };

    if(this.selectedVeh.length >= 10) {
      this.isSelectionLimitReached = true;
      this.messageService.add({ severity: 'info', summary: '', detail: "Maximum selection limit reached.", life: 2000  });
      return;
    }

    const remainingSlots = 10 - this.selectedVeh.length;
    const matchingVeh = this.allVehicelist.filter((veh) => {
      return (
        veh.modelYear === criteria.modelYear &&
        veh.modelCode === criteria.modelCode &&
        veh.vdc === criteria.vdc &&
        veh.workflowStatus === criteria.workflowStatus &&
        criteria.accessorList.every((accCd) => veh.accessorList.some((acc) => acc.componentCd == accCd))
      ) && !this.selectedVeh.some((addedVeh) => this.isSameVehicle(veh, addedVeh));
    }).slice(0, remainingSlots);
    this.selectedVeh.push(...matchingVeh);
  }

  isSameVehicle(vehicle1: any, vehicle2: any) {
    const filteredAccessorList = (vehicle: any) =>
    vehicle.accessorList.filter((acc) => acc.componentTypeCd === 'F').map((acc) => acc.componentCd);
    return (
      vehicle1.modelYear === vehicle2.modelYear &&
      vehicle1.modelCode === vehicle2.modelCode &&
      vehicle1.vdc === vehicle2.vdc &&
      vehicle1.status === vehicle2.status && 
      _.isEqual(filteredAccessorList(vehicle1), filteredAccessorList(vehicle2)) // Or any suitable comparison method
    );
  }

  removeRowChechbox(data) {
    for (let i = 0; i < this.allVehicelist.length; i++) {
      for (let j = 0; j < data.length; j++) {
        this.allVehicelist[i].rowCheckbox = false;
        this.smartChronicObjArr = [];
      }
    }
    return this.smartChronicObjArr;
  }

  setMultiSelectData(dataArr: any[]) {
    for (const rowData of dataArr) {
      let multiSmartChronicDataObj = new SmartChronicHoldObj();
      multiSmartChronicDataObj.vehAssmntId = rowData.vehAssmntEventId;
      multiSmartChronicDataObj.modelYear = rowData.modelYear;
      multiSmartChronicDataObj.modelCode = rowData.modelCode;
      multiSmartChronicDataObj.vdc = rowData.vdc;
      const accessoryCd: Array<any> = [];
      for (let i = 0; i < rowData.accessorList.length; i++) {
        if (rowData.accessorList[i].componentTypeCd === 'F' && !rowData.accessorList[i].componentCd.startsWith('H')) {
          accessoryCd.push(rowData.accessorList[i].componentCd);
        }
      }
      multiSmartChronicDataObj.accessoryArray = this.setAccessoryArray(accessoryCd);
      multiSmartChronicDataObj.errStatus = false;
      multiSmartChronicDataObj.loadingStatus = false;
      multiSmartChronicDataObj.apiStatus = ReleaseStatus.NULL;
      multiSmartChronicDataObj.accessories = accessoryCd.join('|');
      multiSmartChronicDataObj.workFlowStatus = rowData.workflowStatus;
      multiSmartChronicDataObj.vin = rowData.vin;
      multiSmartChronicDataObj.loadingPercentage = 0;
      this.smartChronicObjArr.push(multiSmartChronicDataObj);
    }
  }

  setAccessoryArray(accessory) {
    const accessoryArray: any = [];
    for (let i = 0; i < accessory.length; i++) {
      const obj: any = {};
      obj.code = accessory[i].trim();
      obj.errorInd = false;
      accessoryArray.push(obj);
    }
    return accessoryArray;
  }

  rowCheckboxEvent(searchData) {
    const rowData = this.smartChronicObjArr.find(item => item.vehAssmntId === searchData.vehAssmntEventId);
    if (isNullOrUndefined(rowData)) {
        if(this.smartChronicObjArr.length < this.maxSelection){
          searchData.rowCheckbox = true;
          this.setMultiSelectData(searchData);
        } else {
          searchData.rowCheckbox = false;
        }
    } else {
      searchData.rowCheckbox = false;
      this.removeSmartChronicHoldData(searchData);
    }
  }

  removeSmartChronicHoldData(data) {
    for (let i = 0; i < this.smartChronicObjArr.length; i++) {
      if (this.smartChronicObjArr[i].vehAssmntId === data.vehAssmntEventId) {
        this.smartChronicObjArr.splice(i, 1);
        break;
      }
    }
    return this.smartChronicObjArr;
  }

  dialogClose() {
    this.showButton = false;
    this.isSelectionLimitReached = false;
    this.resetAllVehicles();
    this.selectedData = [];
    this.selectedVeh = [];
    this.smartChronicObjArr = [];
    this.retainYear = this.yearNo;
    this.onDialogClose = true
  }

  ngOnDestroy() {
    this.sharedService.vehicleSearchData = null;
    this.onDialogClose = false;
    this.showButton = false;
  }

  copyFunction() {
    let copyText = document.getElementById("myInput");
    navigator.clipboard.writeText(copyText!.innerHTML);
  }
}
