<div class="padding">
    <h3>MODEL LAUNCH AUDIT CONFIGURATION VIEW</h3>
    <p-divider align="center" type="dashed">
        <p-selectButton class="cmn-button-style" [options]="viewOptions" [(ngModel)]="selectedView" optionLabel="label"
            optionValue="value" (onChange)="basedOnModeofSearch($event)">
        </p-selectButton>
    </p-divider>

    <div *ngIf="selectedView == 'series'">
        <vma-dashboard-search-by-series (searchEvent)="searchVehicleEvent($event)"></vma-dashboard-search-by-series>
    </div>
    <div *ngIf="selectedView != 'series'">
        <vma-dashboard-search-by-vdc (searchEvent)="searchVehicleEvent($event)"></vma-dashboard-search-by-vdc>
    </div>

    <p-divider align="center" type="dashed">
        <button pButton type="button" icon="pi pi-undo" class="p-button-secondary cmn-button-style p-mr-2" label="RESET"
            (click)="resetDashboardData()"></button>
        <button pButton type="button" icon="pi pi-search" class="cmn-button-style" label="SEARCH"
            (click)="searchAuditVehicles()"></button>
    </p-divider>

<div *ngIf= "showResult">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field" style="margin-bottom: 0;">
            <label class="p-col-fixed cmn-label-font-style" style="font-size: large; font-style: italic;">{{selectedView}} View</label>
            <p-badge *ngFor="let brand of brandIdParams" [value]="brand.name" size="large" class="p-col-fixed" severity="info" styleClass="mr-2">
            </p-badge>
        </div>
        <div class="p-field" style="margin-bottom: 0;">
            <div>
                <p-badge [value]="yearNoParams" size="large" class="p-col-fixed" severity="info" styleClass="mr-2">
                </p-badge>
            </div>
        </div>
        <div class="p-field" style="margin-bottom: 0;">
            <div>
                <p-badge *ngFor="let series of seriesIdParams?.slice(0,3)" [value]="series.name" size="large" class="p-col-fixed" severity="info" styleClass="mr-2">
                </p-badge>
                <p-badge *ngIf="showMore() > 0"  [value]="'+' +showMore()+  ' more'" size="large" (click)="showSeries($event)" class="p-col-fixed" severity="info" styleClass="mr-2">
                </p-badge>
            </div>
        </div>
        <div *ngIf="selectedView == 'vdc' && vdcValue" class="p-field" style="margin-bottom: 0;">
            <div> <p-badge [value]="vdcValue.code" size="large" class="p-col-fixed" severity="info" styleClass="mr-2">
                </p-badge>
            </div>
        </div>
    </div>

    <!-- <p-treeTable [value]="files" [columns]="cols" [scrollable]="true" [tableStyle]="{'min-width':'50rem'}">
        <ng-template pTemplate="header" let-columns>
            <tr [ttRow]="rowNode">
                <th *ngFor="let col of columns">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
            <tr>
                <td *ngFor="let col of columns; let i = index">
                    <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
                    {{ rowData[col.field] }}
                </td>
            </tr>
        </ng-template>
    </p-treeTable> -->

    <div class="hold-header" style="display: flex; justify-content: flex-end;">
        <button type="button" label="Reload" pButton pRipple icon="pi pi-refresh" 
            style="margin: 0.150rem;" class="button-export p-button-secondary cmn-button-style p-mr-2"
            (click)="reloadSearchList()"></button>
        <button type="button" label="Export" pButton pRipple icon="pi pi-file-excel" (click)="export()"
            style="margin: 0.150rem;" class="button-export p-button-success cmn-button-style p-mr-2" pTooltip="XLS"
            tooltipPosition="bottom" [disabled]="!transformedData.length"></button>
    </div>

    <div class="table-conatainer">
        <p-table #table1 [value]="transformedData" styleClass="p-datatable-striped" sortField="series" sortMode="single"  scrollHeight="400px" scrollDirection="both"
            responsiveLayout="scroll" 
            [globalFilterFields]="['series','modelCode']" [(selection)]="selectedRowItems" dataKey="series" 
            (onFilter)="onFilter($event)"
            (sortFunction)="customSort($event)" [customSort]="true" [columns]="cols">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <!-- <th class="cmn-table-header-style" style="width: 16%">Series</th> -->
                    <th id="series" class="cmn-table-header-style filterStyle p-col-fixed"  style="width: 10% !important">Series
                        <p-sortIcon [pSortableColumn]="'series'" [field]="'series'"></p-sortIcon>
                        <button type="button" icon="pi pi-filter" class="p-button-secondary" style="font-size: 0.5rem" pButton pRipple
                        (click)="onFilterSeries(table1)"></button>
                    </th>
                    <!-- <th class="cmn-table-header-style" style="width: 10%">Model Code</th> -->
                    <th id="modelCode" class="cmn-table-header-style filterStyle p-col-fixed"  style="width: 10% !important">Model Code
                        <p-sortIcon [pSortableColumn]="'modelCode'" [field]="'modelCode'"></p-sortIcon>
                        <button type="button" icon="pi pi-filter" class="p-button-secondary" style="font-size: 0.5rem" pButton pRipple
                        (click)="onFilterModelCd(table1)"></button>
                    </th>
                    <th class="cmn-table-header-style p-col-fixed" style="text-align: center;" [colSpan]="columns.length + 2"> Accessories </th>
                </tr>
                <tr>
                    <th style="width: 10%" scope="col">
                        <div class="p-inputgroup" *ngIf="filterAction">
                            <input class="cmn-table-data-style" minlength="2" [(ngModel)]="filteredSeries" type="text"
                                pInputText placeholder="Filter" (input)="onClearSeries(table1)">
                        </div>
                    </th>
                    <th style="width: 10%" scope="col">
                        <div class="p-inputgroup" *ngIf="filterAction">
                            <input class="cmn-table-data-style" minlength="2" [(ngModel)]="filteredModelCd" type="text"
                                pInputText placeholder="Filter" (input)="onClearModelCd(table1)">
                        </div>
                    </th>
                    <th *ngFor="let col of columns">
                        {{ col.header }}
                    </th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-searchData let-rowIndex="rowIndex" let-expanded="expanded">
                <tr class="text-data" *ngIf="rowGroupMetadata[searchData.series].index === rowIndex">
                    <td  colspan="100%" class="cmn-table-data-style">
                        <span class="p-text-bold p-ml-2">
                            <div class="row-wrapper">
                                <div class="col-md-3 padd-left-30" style="display: inline-flex; align-items: center;">
                                    <button type="button" pButton pRipple [pRowToggler]="searchData"
                                        class="p-button-text p-button-rounded p-button-plain p-button-sm"
                                        style="font-size: 0.8rem"
                                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                    &nbsp;
                                    {{searchData.series}}
                                </div>
                                <div class="col-md-5 marg-top7">
                                    <div class="row-wrapper">
                                        <div class="col-md-4 total-ind">
                                            <span> Total
                                                Models
                                                :{{calculateCustomerTotal(searchData.series)}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-searchData class="inner-table" let-rowIndex let-columns="columns">
                <tr  id="tabledata" class="table-inner-data row-hover"  [ngClass]= "searchData.selected? 'visited': ''" [pSelectableRow]="searchData"  >
                    <td style="width: 10% !important">
                        <input type="checkbox" id="dataSelect" class="checkBoxStyle" [(ngModel)]="searchData.selected"
                        (click)="selectForHistory($event,searchData)" />
                    </td>
                    <td class="cmn-table-data-style"  style="width: 10% !important">
                    {{searchData.modelCode}}
                    </td>
                    <td *ngFor="let col of columns; let i = index" class="cmn-table-data-style">
                        <span *ngIf="searchData.accessories[i]?.status == 'RELEASED'">
                        <p-tag styleClass="acc-status-released mr-2" class="accessory-tags p-col-fixed" (click)="fetchVdcStatus(searchData.accessories[i], $event)"
                            size="medium"
                            tooltipPosition="top">
                            {{searchData.accessories[i][col.field]}}</p-tag>
                        </span>
                        <span *ngIf="searchData.accessories[i]?.status == 'IN_PROGRESS'">
                        <p-tag styleClass="acc-status-inprogress mr-2" class="accessory-tags p-col-fixed" (click)="fetchVdcStatus(searchData.accessories[i], $event)"
                            size="medium"
                            tooltipPosition="top">
                            {{searchData.accessories[i][col.field]}}</p-tag>
                        </span>
                        <span  *ngIf="searchData.accessories[i]?.status == 'ON_HOLD'">
                        <p-tag styleClass="acc-status-onhold mr-2" class="accessory-tags p-col-fixed" (click)="fetchVdcStatus(searchData.accessories[i], $event)"
                            size="medium"
                            tooltipPosition="top">
                            {{searchData.accessories[i][col.field]}}</p-tag>
                        </span>

                    <!-- <span [class]="'acc-status-badge acc-status-' + searchData.accessories[i]?.status" tooltipPosition="top"
                        pTooltip="VDC Status <br> JX On hold <br> LA Released <br> NY On hold <br> PT Inprogress <br> SF Released">
                        {{searchData.accessories[i][col.field]}}
                    </span> -->
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="6" class="cmn-table-data-style">
                        No records found
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="align-right page-action-panel-style">

        <button pButton type="button" class="p-button-secondary cmn-button-style p-mr-2" label="View history"
            [disabled]="viewHistory" (click)="onViewHistory()"></button>
    </div>

    <div class="copyright-container">
        <div style="display: flex; align-items: center;">
            <p-tag  styleClass="acc-status-onhold" class="info-tags" size="medium" [rounded]="true" >
            </p-tag>
            <label class="p-col-fixed cmn-label-font-style" style="margin-right: 10px;">On hold</label>
            <p-tag styleClass="acc-status-inprogress" class="info-tags" size="medium" [rounded]="true">
            </p-tag>
            <label class="p-col-fixed cmn-label-font-style" style="margin-right: 10px;">In progress</label>
            <p-tag styleClass="acc-status-released" class="info-tags" size="medium" [rounded]="true" >
            </p-tag>
            <label class="p-col-fixed cmn-label-font-style">Released</label>
        </div>
    </div>
    
    <!-- <p-overlayPanel *ngIf="showOverlay">
        <ng-template pTemplate="content">
            <h4>Custom Content</h4>
        </ng-template>
    </p-overlayPanel>

    <p-table #vdcTable [hidden]="true" [value]="vdcCodes">
        <ng-template pTemplate="header">
            <tr>
                <th>VDC</th>
                <th>Status</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td>{{ product.vdc }}</td>
                <td>{{ product.status }}</td>
            </tr>
        </ng-template>
    </p-table> -->
</div>

<p-overlayPanel #op [showCloseIcon]="true">
        <p-table [value]="transformedVdcData" [paginator]="false">
            <ng-template pTemplate="header" >
                <tr class="fontToolTip">
                    <th>VDC </th>
                    <th>Status </th>
                    <th>Updated TS </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-product >
                <tr class="fontToolTip">
                    <td>{{product.vdc}}</td>
                    <td>{{product.status}}</td>
                    <td>{{product.updatedTs}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                    <tr class="fontToolTip">
                        <td>{{noVDCMessage}}</td>
                        <td></td>
                    </tr>
                </ng-template>
        </p-table>
</p-overlayPanel>
<p-overlayPanel [showCloseIcon]="true"  #overlay>
    <ng-template pTemplate ="content">
        <div  style="width: 500px;">
            {{showSeriesList}}
        </div>
    </ng-template>
</p-overlayPanel>