import { EnvironmentModel } from "src/app/core/model/ui/environment-property.model";

export const LocalEnvironment: EnvironmentModel = {
    production: false,
    ENV_NAME: 'local',
    VMA_API_ENDPOINT: 'apig.vmadev.toyota.com', 
    PCPS_API_ENDPOINT: 'api.dev.pcps.toyota.com',
    TENANT_ID: '9107b728-2166-4e5d-8d13-d1ffdf0351ef',
    CLIENT_ID: '46c2982a-92ad-4ee9-8509-0e06d69aec50',
    INSTANCE_ID: 'https://login.microsoftonline.com/',
    UI_URL: 'https://localhost:4200/',
    REDIRECT_URI: '/',
    POST_LOGOUT_REDIRECT_URI: '',
    NAVIGATE_TO_LOGIN_REQUEST_URL: true,
    CACHE_LOCATION: 'localStorage',
};
