import { Instance } from 'src/app/common/app.constants';
import * as VMA_Util from '../../utilities/vma-common.util';

class SeriesModel {
    productGroupId: number;
    productGroupCd: string;
    productGroupNm: string;
    productGroupDesc: string;
    instance() {
        return Instance.SeriesModel;
    }
}

export class SeriesApiModel extends SeriesModel { }

export class SeriesApiResModel {
    productGroup: Array<SeriesApiModel> = new Array<SeriesApiModel>();
    constructor();
    constructor(options: object);
    constructor(options?: any) {
        if (VMA_Util.isObject(options.data)) {
            if (VMA_Util.isArray(options.data.productGroup)) {
                options.data.productGroup.forEach(element => {
                    this.productGroup.push(this.getRowdata(element));
                });
            }
        }
    }
    getRowdata(element: any): SeriesApiModel {
        const seriesModel: SeriesApiModel = new SeriesApiModel();
        seriesModel.productGroupId = element.productGroupId;
        seriesModel.productGroupCd = element.productGroupCd;
        seriesModel.productGroupNm = element.productGroupNm;
        seriesModel.productGroupDesc = element.productGroupDesc;
        return seriesModel;
    }
}
